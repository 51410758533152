import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { styled } from '@mui/material/styles'
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { useLogin, useLogOut } from 'Components/Auth/Hooks/AuthHooks'
import { ReactComponent as LoginIcon } from 'assets/images/login.svg'
import { editSnackbar } from 'Features/snackbar'

const Content = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.palette.secondary.dark,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const InputWrapper = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: '5px',
  marginTop: 24,
  minWidth: 300
}))

const LoginPage: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [org, setOrg] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { passwordResetDone } = useTypedSelector(state => state.auth)
  const { login, queryLoading, mutationLoading, error } = useLogin(true)
  const logOut = useLogOut()

  useEffect(() => {
    logOut()
  }, [])

  useEffect(() => {
    if (passwordResetDone) {
      dispatch(editSnackbar({ message: 'Hooray! Password changed.' }))
    }
  }, [passwordResetDone])

  const submit = (event: React.FormEvent) => {
    event.preventDefault()

    login({
      variables: {
        email: email,
        password: password,
        organization: org,
        type: 'ORGUSER'
      }
    })
  }

  return (
    <Content>
      <form style={{ textAlign: 'center' }} onSubmit={submit}>
        <Box sx={{ paddingBottom: '8px' }}>
          <LoginIcon />
        </Box>
        <InputWrapper>
          <TextField
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            placeholder="Email"
            fullWidth
            variant="outlined"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            placeholder="Password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            value={org}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrg(e.target.value)}
            placeholder="Organization"
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error}
          />
        </InputWrapper>
        <Box sx={{ position: 'relative' }}>
          {mutationLoading || queryLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '23px',
                width: '100%',
                textAlign: 'center'
              }}
            />
          ) : null}
          <Button
            sx={{
              margin: '24px 0px',
              paddingLeft: '32px',
              paddingRight: '32px',
              '&:hover': {
                background: theme => theme.palette.secondary.light
              }
            }}
            color="secondary"
            type="submit"
            variant="contained"
            disabled={mutationLoading || queryLoading}
          >
            LOGIN
          </Button>
        </Box>
        <Button
          variant="text"
          sx={{
            color: theme => theme.palette.grey[400],
            textDecoration: 'underline',
            '& .MuiButton-text': {
              color: theme => theme.palette.grey[400],
              fontWeight: 400
            },
            '&:hover': {
              background: 'none'
            }
          }}
          onClick={() => {
            navigate('/reset-password')
          }}
          disabled={mutationLoading || queryLoading}
        >
          Forgot my password
        </Button>
      </form>
    </Content>
  )
}

export default LoginPage
