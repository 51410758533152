import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqBottomNavigationAction, UqBottomNavigationActionProps } from '@uniqore/wrapper'

interface UqBottomNavigationActionRenderProps {
  componentProps: UqBottomNavigationActionProps
  showLabel: boolean
}

const UqBottomNavigationActionRender: React.FC<UqBottomNavigationActionRenderProps> = ({
  componentProps,
  showLabel
}) => {
  return (
    <UqBottomNavigationAction
      showLabel={showLabel}
      label={componentProps.label}
      icon={
        componentProps.icon ? React.createElement(Icons[componentProps.icon as IconType]) : null
      }
    />
  )
}

export default UqBottomNavigationActionRender
