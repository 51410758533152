import React from 'react'
import { useTypedSelector } from 'Store'
import { Box, Typography } from '@mui/material'
import { ReactComponent as IntroImg } from 'assets/images/intro-img.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import { ButtonStyled } from '../WorkspaceView'

interface WorkspaceIntroProps {
  setNewDesignDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const WorkspaceIntro: React.FC<WorkspaceIntroProps> = ({ setNewDesignDialogOpen }) => {
  const { firstName } = useTypedSelector(state => state.loggedInUser)

  return (
    <Box sx={{ textAlign: 'center', marginTop: '80px' }}>
      <IntroImg />
      <Typography
        variant="h4"
        sx={{ paddingTop: '16px', color: theme => theme.palette.text.primary }}
      >
        Hi {firstName}
      </Typography>
      <Typography variant="body1" sx={{ color: theme => theme.palette.text.primary }}>
        Great to see you here! Take a look on our intro video (2 min)
        <br /> or go wild and launch a new project.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '16px'
        }}
      >
        <ButtonStyled
          color="secondary"
          variant="contained"
          disableElevation
          startIcon={<PlayIcon />}
          disabled
        >
          Watch intro video
        </ButtonStyled>
        <ButtonStyled
          onClick={() => setNewDesignDialogOpen(true)}
          color="secondary"
          variant="text"
          disableElevation
        >
          Launch new project
        </ButtonStyled>
      </Box>
    </Box>
  )
}

export default WorkspaceIntro
