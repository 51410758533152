import { authLink } from './authLink'
import { analyticsMiddleware } from './analyticsMiddlware'
import { errorLink } from './errorLink'
import { ApolloClient, InMemoryCache, createHttpLink, from, StoreObject } from '@apollo/client'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL_SERVICE as string
})

const ServiceClient = new ApolloClient({
  link: from([analyticsMiddleware, authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          designStudioWorkspaces: {
            merge(existing: StoreObject[], incoming: StoreObject[], { readField, mergeObjects }) {
              const merged: StoreObject[] = []
              const workspaceIdToIndex = Object.create(null)
              if (existing) {
                existing.forEach((workspace, index) => {
                  workspaceIdToIndex[readField<string>('id', workspace) as string] = index
                })
              }
              incoming.forEach(workspace => {
                const id = readField<string>('id', workspace)
                const index = workspaceIdToIndex[id as string]
                if (typeof index === 'number') {
                  merged[index] = mergeObjects(merged[index], workspace)
                } else {
                  workspaceIdToIndex[id as string] = merged.length
                  merged.push(workspace)
                }
              })
              return merged
            }
          },
          designStudioDocuments: {
            merge(existing: StoreObject[], incoming: StoreObject[], { readField, mergeObjects }) {
              const merged: StoreObject[] = []
              const documentIdToIndex = Object.create(null)
              if (existing) {
                existing.forEach((document, index) => {
                  documentIdToIndex[readField<string>('id', document) as string] = index
                })
              }
              incoming.forEach(document => {
                const id = readField<string>('id', document)
                const index = documentIdToIndex[id as string]
                if (typeof index === 'number') {
                  merged[index] = mergeObjects(merged[index], document)
                } else {
                  documentIdToIndex[id as string] = merged.length
                  merged.push(document)
                }
              })
              return merged
            }
          }
        }
      }
    }
  })
})

export default ServiceClient
