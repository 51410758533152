import React, { useState, useEffect } from 'react'
import { useTypedSelector } from 'Store'
import equal from 'fast-deep-equal'
import { useTheme } from '@mui/material/styles'
import { Box, Button, SelectChangeEvent } from '@mui/material'
import TypographysBasic from './TypographysBasic'
import TypographysAdvanced from './TypographysAdvanced'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import SpinnerMui from 'Components/Common/SpinnerMui'
import TypographyButton from 'Components/Common/TypographyButton'
import { TypographyVariant, TypographySchema } from 'Util/ThemeValues'
import { WorkspaceSchema, DesignSchema } from 'types/firebase'

interface TypographysProps {
  workspace: WorkspaceSchema
  selectedDesign: DesignSchema
  combinedTypography: TypographySchema
}

const Typographys: React.FC<TypographysProps> = ({
  workspace,
  selectedDesign,
  combinedTypography
}) => {
  const theme = useTheme()
  const isCurrentlySaving = useTypedSelector(state => state.saveHelper.isCurrentlySaving)
  const { updateDesign } = useDesigns(workspace.id)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [typography, setTypography] = useState<TypographySchema>(combinedTypography)

  useEffect(() => {
    setTypography(combinedTypography)
  }, [combinedTypography])

  const updateFontWeight = (event: SelectChangeEvent<string>, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], fontWeight: event.target.value }
    })
  }

  const updateFontStyle = (isItalic: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], fontStyle: isItalic ? 'italic' : 'normal' }
    })
  }

  const updateTextDecoration = (isUnderline: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], textDecoration: isUnderline ? 'underline' : 'initial' }
    })
  }

  const updateTextTransform = (isUpperCase: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], textTransform: isUpperCase ? 'uppercase' : 'none' }
    })
  }

  const handleSaveTypographys = () => {
    updateDesign(selectedDesign, {
      themeOverride: {
        ...selectedDesign.themeOverride,
        typography
      }
    })
  }

  const handleResetTypography = () => {
    const updatedThemeOverride = JSON.parse(JSON.stringify(selectedDesign.themeOverride))
    delete updatedThemeOverride.typography
    updateDesign(selectedDesign, {
      themeOverride: updatedThemeOverride
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: '16px', top: '-50px', background: 'white' }}>
        {isCurrentlySaving ? (
          <SpinnerMui
            color="white"
            size={25}
            style={{
              position: 'absolute',
              right: '40px',
              top: '3px'
            }}
          />
        ) : null}
        <Button
          onClick={handleSaveTypographys}
          disabled={equal(typography, combinedTypography)}
          color="primary"
          variant="contained"
          disableElevation
          sx={{ borderRadius: '100px', backgroundColor: 'primary.light' }}
          size="small"
        >
          Save changes
        </Button>
      </div>
      <TypographysBasic
        typography={typography}
        updateFontWeight={updateFontWeight}
        updateFontStyle={updateFontStyle}
        updateTextDecoration={updateTextDecoration}
        updateTextTransform={updateTextTransform}
      />
      <TypographysAdvanced
        selectedDesign={selectedDesign}
        isCurrentlySaving={isCurrentlySaving}
        typography={typography}
        combinedTypography={combinedTypography}
        updateFontWeight={updateFontWeight}
        updateFontStyle={updateFontStyle}
        updateTextDecoration={updateTextDecoration}
        updateTextTransform={updateTextTransform}
        showAdvanced={showAdvanced}
        setShowAdvanced={setShowAdvanced}
        handleSaveTypographys={handleSaveTypographys}
        handleResetTypography={handleResetTypography}
      />

      <Box sx={{ margin: '16px' }}>
        <TypographyButton
          text="Advanced settings"
          onClick={() => setShowAdvanced(true)}
          color={theme.palette.secondary.main}
          style={{ marginBottom: '8px' }}
        />
        <TypographyButton
          text="Reset to theme"
          disabled={!selectedDesign.themeOverride?.typography || isCurrentlySaving}
          onClick={handleResetTypography}
          color={theme.palette.error.main}
        />
      </Box>
    </div>
  )
}

export default Typographys
