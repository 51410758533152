import React from 'react'
import { Onboarding, OnboardingButtonProps } from '@uniqore/module'
import { OnboardingPropsCustom } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'

interface OnboardingRenderProps {
  component: ScreenComponent
}

const OnboardingRender: React.FC<OnboardingRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const props = component.props as OnboardingPropsCustom

  return (
    <Onboarding
      carouselItems={
        props.carouselItems &&
        props.carouselItems.map(item => ({
          headline: item.headline,
          body: item.body,
          image: {
            src: item.imageSource || '',
            width: item.imageWidth || 100
          }
        }))
      }
      actionComponents={props.actionComponents?.map(v => {
        const actionProps = v as OnboardingButtonProps & { actions?: ComponentActionSchema[] }
        const color = actionProps.color as OnboardingButtonProps['color'] & 'default'
        return {
          ...actionProps,
          color: color !== 'default' ? color : undefined,
          onClick: () => onClickAction(actionProps.actions)
        }
      })}
      actionDirection={props.actionDirection}
      buttonsColor={props.buttonsColor}
      autoplay={false}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    />
  )
}

export default OnboardingRender
