import React, { useState, useEffect } from 'react'
import { useTypedSelector } from 'Store'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import PublicClient from 'Api/publicClient'
import { ReactComponent as ProtoIcon } from 'assets/images/proto-logo.svg'
import { useTheme } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'
import { useDidMountEffect } from 'Components/Common/Hooks/CommonHooks'
import SpinnerMui from 'Components/Common/SpinnerMui'
import Preview from 'Components/Layout/Preview'
import PreviewNavBar from 'Components/Layout/Preview/PreviewNavBar'
import { usePreview } from 'Components/Layout/Preview/Hooks/PreviewHooks'
import { SHARED_DESIGN_STUDIO_DOCUMENT } from 'Components/Layout/Share/Queries'
import { editSnackbar } from 'Features/snackbar'
import { ScreenId } from 'Features/canvas'
import { setProtoDocumentData, openPreviewDialog, clearShare } from 'Features/share'
import { DesignSchema, ThemeSchema, CanvasScreen } from 'types/firebase'
import { useKeyboardShortcuts } from 'Util/Hooks/KeyboardShortcutListener'

export interface ProtoDocumentData {
  screens: Record<ScreenId, CanvasScreen>
  theme: ThemeSchema
  design: DesignSchema
}

const ProtoView: React.FC = () => {
  const { listenPreviewKeys, removeListenerPreviewKeys } = useKeyboardShortcuts()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { sharelink } = useParams()
  const { openPreview } = usePreview(true)
  const previewOn = useTypedSelector(state => state.preview.previewOn)
  const protoDocumentData = useTypedSelector(state => state.share.protoDocumentData)
  const [protoNotFound, setProtoNotFound] = useState<boolean>(false)

  const { data, loading, error } = useQuery(SHARED_DESIGN_STUDIO_DOCUMENT, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    variables: {
      shareKey: sharelink
    },
    onError(error) {
      setProtoNotFound(true)
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  useEffect(() => {
    if (data) {
      if (data.sharedDesignStudioDocument) {
        if (protoNotFound) setProtoNotFound(false)
        const { Screens, Theme, ...documentData } = data.sharedDesignStudioDocument
        const screens: Record<ScreenId, CanvasScreen> = {}
        Screens.forEach((s: CanvasScreen) => {
          screens[s.id] = {
            ...s
          }
        })
        const protoData: ProtoDocumentData = {
          screens,
          theme: Theme,
          design: documentData
        }
        dispatch(setProtoDocumentData({ protoDocumentData: protoData }))
      }
      if (data.sharedDesignStudioDocument === null) {
        setProtoNotFound(true)
      }
    }
  }, [data])

  useEffect(() => {
    if (protoDocumentData) {
      dispatch(openPreviewDialog({ value: true }))
      openPreview(protoDocumentData.screens)
    }
  }, [protoDocumentData])

  // Handles listeners when preview is on/off
  useDidMountEffect(() => {
    if (previewOn) {
      listenPreviewKeys()
    } else {
      removeListenerPreviewKeys()
    }
  }, [previewOn])

  useEffect(() => {
    return () => {
      dispatch(clearShare())
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100%',
        backgroundColor: theme.palette.secondary.dark
      }}
    >
      {!previewOn && !protoDocumentData && (
        <div style={{ height: '100%', backgroundColor: theme.palette.secondary.dark }}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {loading && (
              <div style={{ textAlign: 'center' }}>
                <SpinnerMui color={theme.custom.pastelGreen[750]} size={50} />
                <Typography variant="h5" sx={{ color: 'white', fontWeight: 300, fontSize: '24px' }}>
                  Loading prototype...
                </Typography>
              </div>
            )}
            {error || protoNotFound ? (
              <div style={{ textAlign: 'center' }}>
                <Box>
                  <ProtoIcon />
                </Box>
                <Typography
                  variant="h5"
                  sx={{ color: 'white', fontWeight: 300, fontSize: '24px', marginTop: '8px' }}
                >
                  Prototype not found
                </Typography>
                <div style={{ width: '328px' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme => theme.palette.text.disabled,
                      marginTop: '16px',
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                  >
                    Either this prototype doesn’t exist or you don’t have permissions to view it.
                    Ask the file owner to verify the link and/or update permissions.
                  </Typography>
                </div>
                <Button
                  variant="text"
                  color="success"
                  sx={{
                    marginTop: '24px',
                    '&:hover': {
                      background: 'none',
                      textDecoration: 'underline'
                    }
                  }}
                  onClick={() => window.open('https://www.uniqore.com/', '_blank')}
                >
                  uniqore.com
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {previewOn && protoDocumentData && (
        <>
          <PreviewNavBar protoDocumentData={protoDocumentData} />
          <Preview protoDocumentData={protoDocumentData} />
        </>
      )}
    </Box>
  )
}

export default ProtoView
