import React from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Box, InputAdornment } from '@mui/material'
import { default as MuiTextField } from '@mui/material/TextField'
import Icons, { IconType } from 'Components/Common/Icons'
import { editHelpers } from 'Features/helpers'

export interface TextFieldProps {
  value?: string
  defaultValue?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onClick?: React.MouseEventHandler<HTMLDivElement>
  label?: React.ReactNode
  placeholder?: string
  helperText?: React.ReactNode
  disabled?: boolean
  disablePadding?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  multiline?: boolean
  error?: boolean
  maxRows?: number
  prefixIcon?: IconType
  suffixIcon?: IconType
  style?: React.CSSProperties
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  defaultValue,
  onChange,
  onClick,
  label,
  placeholder,
  helperText,
  disabled,
  disablePadding,
  readOnly,
  autoFocus,
  multiline = true,
  error,
  maxRows,
  prefixIcon,
  suffixIcon,
  style
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  return (
    <Box style={style} sx={disablePadding ? {} : { padding: '4px 16px' }}>
      <MuiTextField
        onFocus={() => dispatch(editHelpers({ key: 'isTextFieldFocused', value: true }))}
        onBlur={() => dispatch(editHelpers({ key: 'isTextFieldFocused', value: false }))}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onClick={onClick}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        disabled={disabled}
        multiline={multiline}
        maxRows={maxRows}
        error={error}
        autoFocus={autoFocus}
        fullWidth
        variant="filled"
        InputProps={{
          readOnly,
          startAdornment: prefixIcon ? (
            <InputAdornment position="start" sx={!label ? { marginTop: '0px !important' } : {}}>
              {React.createElement(Icons[prefixIcon], {
                style: { color: theme.palette.text.secondary }
              })}
            </InputAdornment>
          ) : null,
          endAdornment: suffixIcon ? (
            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
              {React.createElement(Icons[suffixIcon], {
                style: { color: theme.palette.text.secondary }
              })}
            </InputAdornment>
          ) : null
        }}
        sx={{
          '& .MuiFilledInput-root': {
            backgroundColor: 'white !important',
            border: theme => `1px solid ${theme.custom.borderColor}`,
            borderRadius: '4px',
            paddingTop: multiline ? (label ? '18px' : '0px') : '0px'
          },
          '& .MuiFilledInput-input': {
            paddingTop: !multiline ? (label ? '25px' : '8px') : '8px'
          },
          '& .MuiFormLabel-root': {
            top: '2px',
            color: disabled
              ? theme => `${theme.palette.text.disabled} !important`
              : theme => `${theme.palette.text.secondary} !important`
          },
          '& > div': {
            '&::before': {
              borderBottom: 'none !important'
            },
            '&::after': {
              borderBottom: 'none !important'
            }
          }
        }}
      />
    </Box>
  )
}

export default TextField
