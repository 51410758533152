import { useCallback } from 'react'
import * as UqWrappers from '@uniqore/wrapper'
import { useDispatch } from 'react-redux'
import { CanvasComponentName } from 'Util/CanvasComponents'
import {
  ScreenComponent,
  genComponentId,
  ComponentSections,
  deepCopyComponent
} from 'Features/canvas'
import { updateComponent, addComponent, deleteComponent } from 'Features/canvasScreens'

const addListItem = (parentComponentId: string, props: unknown | undefined) => {
  const id = genComponentId()
  let listItemProps: UqWrappers.UqListItemProps = {}
  const prop = props as UqWrappers.UqListItemProps | undefined
  if (prop) {
    listItemProps = prop
  } else {
    listItemProps.alignItems = 'center'
    listItemProps.dense = false
    listItemProps.disableGutters = false
    listItemProps.disablePadding = false
    listItemProps.divider = false
    listItemProps.primary = 'List item'
    listItemProps.secondary = 'Secondary'
  }
  const newListItem: ScreenComponent = {
    id,
    componentName: 'uqListItem',
    parentComponentId,
    props: listItemProps
  }
  return newListItem
}

interface ChildComponent {
  componentName: CanvasComponentName
  props?: unknown
}

const createChildComponent = (parentComponent: ScreenComponent, childComponent: ChildComponent) => {
  let component: ScreenComponent | null = null
  switch (childComponent.componentName) {
    case 'uqListItem':
      component = addListItem(parentComponent.id, childComponent.props)
      break
    default:
      component = null
  }
  return component
}

export const useChildComponent = () => {
  const dispatch = useDispatch()

  /**
   * Creates child component and add its id inside
   * 'childrenComponents' array under child component's parent component
   * @param screenId
   * @param parentComponent
   * @param section
   * @param childComponent
   */
  const addChildComponent = useCallback(
    (
      screenId: string,
      parentComponent: ScreenComponent,
      section: keyof ComponentSections,
      childComponent: ChildComponent
    ) => {
      const createdChildComponent = createChildComponent(parentComponent, childComponent)
      if (createdChildComponent) {
        const component = deepCopyComponent(parentComponent)
        component.props.childrenComponents.push(createdChildComponent.id)
        dispatch(updateComponent({ screenId, component, section }))
        dispatch(addComponent({ screenId, component: createdChildComponent, section }))
      }
    },
    []
  )

  /**
   * Deletes child component and updated parent-component's childrenComponents-array
   * @param screenId
   * @param parentComponent
   * @param componentId
   * @param section
   */
  const deleteChildComponent = useCallback(
    (
      screenId: string,
      parentComponent: ScreenComponent,
      componentId: string,
      section: keyof ComponentSections | undefined
    ) => {
      const component = deepCopyComponent(parentComponent)
      component.props.childrenComponents = component.props.childrenComponents.filter(
        (id: string) => id !== componentId
      )
      dispatch(updateComponent({ screenId, component, section }))
      dispatch(deleteComponent({ screenId, componentId, section }))
    },
    []
  )

  return { addChildComponent, deleteChildComponent }
}
