import { IconType } from 'Components/Common/Icons'
import { CanvasComponentName } from 'Util/CanvasComponents'
import {
  uqButton,
  uqCheckbox,
  uqRadio,
  uqRating,
  uqSelect,
  uqSwitch,
  uqTextField,
  uqChip,
  uqDivider,
  uqAlert,
  uqDialog,
  uqSnackbar,
  uqAppBar,
  uqSpeedDial,
  uqList,
  uqTypography,
  uqDatePicker,
  radioButtonGroup,
  selectButtonGroup,
  login,
  image,
  onboarding,
  flowNav,
  uqInputAdornment,
  uqBottomNavigationAction,
  uqSpeedDialAction,
  uqAvatar,
  uqIconButton,
  uqLinearProgress,
  uqListItem,
  uqListItemIcon,
  uqListItemAvatar,
  uqListItemSecondaryAction,
  add
} from './uqComponentsDataValues'
import { ButtonIconProps } from 'Components/Common/Editing/ButtonIcon'
import { TextFieldProps } from 'Components/Common/TextField'
import { SmallTextFieldProps } from 'Components/Common/Editing/SmallTextField'
import { SmallSelectProps } from 'Components/Common/Editing/SmallSelect'

interface UqComponentsData {
  [key: string]: UqComponentsDataValues
}

export type Section = 'CONTENT' | 'STYLE'

export type PropType =
  | 'node'
  | 'string'
  | 'bool'
  | 'number'
  | 'button'
  | 'smallTextField'
  | 'dynamic'
  | 'module'

export interface CustomProperties {
  button?: ButtonIconProps
  textField?: TextFieldProps
  smallTextField?: SmallTextFieldProps
  smallSelect?: SmallSelectProps
  dynamic?: DynamicComponentProps
  module?: ModuleComponentProps
}

export interface DynamicComponentProps {
  componentName: CanvasComponentName
  maxLimit?: number
  propAllowList?: string[]
}

export interface ModuleComponentProps {
  object: {
    [key: string]: PropType | string[]
  }
  maxLimit?: number
  primaryTextKey: string // value that is shown on ComponentBox -component on PropEdit-view (must be of type 'string')
  secondaryTextKey?: string // value that is shown on ComponentBox -component on PropEdit-view (must be of type 'string')
}

export interface PropsSchema {
  [prop: string]: Prop
}

/**
 * {type}: Type of the prop (see Mui's component API's, also custom prop values exists)
 * {section}: In which section prop is placed in edit-component-view
 * {label}: Optional label for the prop in edit-view (default label is the prop's name)
 * {propName}: Prop's name, (is also set automatically from object's key)
 * {customProperties}: Which Design Studio component is used to edit prop values. Overrides default component.
 * (defaults so far {type} = {component}:
 *   node = TextField
 *   string = TextField
 *   string[] = SmallSelect
 *   bool = Switch
 *   number = SmallTextField
 *   button = ButtonIcon
 *   smallTextField = smallTextField
 * )
 */
export interface Prop {
  type: PropType | string[]
  section: Section
  label?: string
  propName?: string
  customProperties?: CustomProperties
  moduleProps?: ModuleComponentProps
}

export interface UqComponentsDataValues {
  name: string // Name is shown on ScreenEdit view
  component: CanvasComponentName
  icon?: string // Icon shown on AddSection
  editIcon?: IconType // Icon shown on ScreenEdit view
  category?: Categories // category must be provided if component is to be seen in AddSection
  forSearch?: string[]
  props: PropsSchema
}

export type Categories = 'content' | 'screenAndModules'

const uqComponentsData: UqComponentsData = {
  uqTypography,
  uqTextField,
  uqSelect,
  uqCheckbox,
  selectButtonGroup,
  uqRadio,
  radioButtonGroup,
  uqButton,
  uqRating,
  uqSwitch,
  uqChip,
  uqDatePicker,
  image,
  onboarding,
  uqList,
  uqAlert,
  uqDialog,
  uqSnackbar,
  uqDivider,
  uqSpeedDial,
  uqAppBar,
  login,
  flowNav,
  uqInputAdornment,
  uqBottomNavigationAction,
  uqSpeedDialAction,
  uqAvatar,
  uqIconButton,
  uqLinearProgress,
  uqListItem,
  uqListItemIcon,
  uqListItemAvatar,
  uqListItemSecondaryAction,
  add
}

export default uqComponentsData
