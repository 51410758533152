import React from 'react'
import useTypedSelector from 'Store'
import { NodeProps } from 'react-flow-renderer'
import { SCREEN_HEIGHT, SCREEN_WIDTH, SCREEN_MARGIN } from 'Features/canvas'

const CanvasIndicator: React.FC<NodeProps> = () => {
  const indicator = useTypedSelector(state => state.canvas.indicator)
  // TODO: set border size based on the zoom level, now it disapears when we are zoomed far enough
  const border = indicator.onEmptySpace
    ? { border: '2px dashed #3096e5' }
    : { borderLeft: '2px solid #3096e5' }
  const marginOffset = SCREEN_MARGIN / 2

  return (
    <>
      <div
        style={{
          // If indicator is hidden, we still need to display it as a single pixel
          // if it's 0 size or display is 'none' it wont get rendered when we
          // try to display it.
          // This hack should be fine since when the position is not 'absolute'
          // the single pixel is hidden under a screen
          // Note that this is only needed when we are using the single preloaded
          // indicator. If dynamic indicator is used, this can be removed
          width: indicator.hidden ? 1 : SCREEN_WIDTH + marginOffset,
          height: indicator.hidden ? 1 : SCREEN_HEIGHT + marginOffset,
          position: indicator.hidden ? undefined : 'absolute',
          // + 2 comes from the border size
          top: -(marginOffset / 2 + 2),
          left: -(marginOffset / 2 + 2),
          background: 'rgb(255, 0, 0, 0)',
          ...border
        }}
      ></div>
    </>
  )
}

export default CanvasIndicator
