import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqSnackbar, UqSnackbarProps, UqAlertProps, UqAlertTitle } from '@uniqore/wrapper'
import { UqSnackbarPropsExtended } from 'Util/CanvasComponents'
import MuiAlert from '@mui/material/Alert'
import { ScreenComponent } from 'Features/canvas'

const Alert = React.forwardRef<HTMLDivElement, UqAlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface UqSnackbarRenderProps {
  component: ScreenComponent
}

const UqSnackbarRender: React.FC<UqSnackbarRenderProps> = ({ component }) => {
  const props = component.props as UqSnackbarProps & UqSnackbarPropsExtended

  return (
    <UqSnackbar
      style={{ ...component.style }}
      open={true}
      anchorOrigin={{
        horizontal: props.originHorizontal,
        vertical: props.originVertical
      }}
    >
      <Alert
        icon={props.icon ? React.createElement(Icons[props.icon as IconType]) : false}
        color={props.color}
      >
        {props.title && <UqAlertTitle>{props.title}</UqAlertTitle>}
        {props.body}
      </Alert>
    </UqSnackbar>
  )
}

export default UqSnackbarRender
