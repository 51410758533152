import React, { useEffect } from 'react'
import {
  UqDialog,
  UqDialogProps,
  UqDialogTitle,
  UqDialogContent,
  UqDialogContentText,
  UqDialogActions
} from '@uniqore/wrapper'
import { UqDialogPropsExtended } from 'Util/CanvasComponents'
import UqButtonRender from './UqButtonRender'
import { ScreenComponent } from 'Features/canvas'

interface UqDialogRenderProps {
  component: ScreenComponent
  container?: React.MutableRefObject<null>
}

const UqDialogRender: React.FC<UqDialogRenderProps> = ({ component, container }) => {
  const [containerCurrent, setContainerCurrent] = React.useState<Element | null | undefined>(
    container?.current
  )
  const props = component.props as UqDialogProps & UqDialogPropsExtended

  useEffect(() => {
    setContainerCurrent(container?.current)
  }, [container])

  return (
    <UqDialog
      disableAutoFocus
      disableEnforceFocus
      open={!component.style?.display ? true : false}
      fullWidth={props.fullWidth}
      container={containerCurrent}
    >
      {props.title && <UqDialogTitle>{props.title}</UqDialogTitle>}
      {props.body && (
        <UqDialogContent>
          <UqDialogContentText>{props.body}</UqDialogContentText>
        </UqDialogContent>
      )}
      {props.buttons && (
        <UqDialogActions>
          {Object.values(props.buttons).map(button => (
            <UqButtonRender key={button.id} component={button} />
          ))}
        </UqDialogActions>
      )}
    </UqDialog>
  )
}

export default UqDialogRender
