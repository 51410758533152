/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import { IconType } from 'Components/Common/Icons'
import ButtonIcon from 'Components/Common/Editing/ButtonIcon'
import Switch from 'Components/Common/Switch'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import TextField from 'Components/Common/TextField'
import SmallTextField from 'Components/Common/Editing/SmallTextField'
import { Prop } from 'Util/UqComponentsData'
import { ScreenComponent } from 'Features/canvas'
import Dynamic from './Dynamic'
import Module from './Module'

/**
 * Separates camelCase to separate words and changes first character to uppercase
 */
export const stringHandler = (value: string): string => {
  const result = value.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

interface PropsEditProps {
  componentProps: unknown
  setComponentProps: React.Dispatch<unknown>
  componentToEdit: ScreenComponent
  prop: Prop
  onChange?: (propValue: unknown) => void
  screenId: string
}

const PropEdit: React.FC<PropsEditProps> = ({
  componentProps,
  setComponentProps,
  componentToEdit,
  prop,
  onChange,
  screenId
}) => {
  const { propName, type, label, customProperties = {}, moduleProps } = prop

  if (!propName) return <></>

  const updateProps = (propValue: unknown) => {
    if (onChange) {
      onChange(propValue)
    } else {
      // @ts-ignore
      setComponentProps({ ...componentProps, [propName]: propValue })
    }
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    updateProps(event.target.value)
  }

  const handleBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateProps(event.target.checked)
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateProps(event.target.value)
  }

  const handleSmallTextFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    type: string
  ) => {
    if (type === 'number') {
      updateProps(Number(event.target.value))
    } else {
      updateProps(event.target.value)
    }
  }

  const handleIconClick = (icon: IconType | '') => {
    updateProps(icon)
  }

  const handleDynamicChange = (value: { [componentId: string]: ScreenComponent }) => {
    updateProps(value)
  }

  return (
    <>
      {Array.isArray(type) && (
        <SmallSelect
          // @ts-ignore
          value={componentProps[propName]}
          onChange={handleSelectChange}
          label={stringHandler(label || propName)}
          options={type.map(v => ({ label: stringHandler(v), value: v }))}
          doNotFormatValue={customProperties['smallSelect']?.doNotFormatValue}
        />
      )}

      {type === 'bool' && (
        <Switch
          // @ts-ignore
          checked={componentProps[propName]}
          onChange={handleBoolChange}
          label={stringHandler(label || propName)}
        />
      )}

      {(type === 'node' || type === 'string') && (
        <TextField
          // @ts-ignore
          defaultValue={componentProps[propName]}
          onChange={handleTextFieldChange}
          label={stringHandler(label || propName)}
          helperText={customProperties['textField']?.helperText}
          multiline={customProperties['textField']?.multiline}
        />
      )}

      {type === 'smallTextField' && (
        <SmallTextField
          // @ts-ignore
          defaultValue={componentProps[propName]}
          onChange={e =>
            handleSmallTextFieldChange(e, customProperties['smallTextField']?.type || 'text')
          }
          label={stringHandler(label || propName)}
          type={customProperties['smallTextField']?.type || 'text'}
          helperText={customProperties['smallTextField']?.helperText}
          suffixText={customProperties['smallTextField']?.suffixText}
        />
      )}

      {type === 'number' && (
        <SmallTextField
          // @ts-ignore
          defaultValue={componentProps[propName]}
          onChange={e => handleSmallTextFieldChange(e, 'number')}
          label={stringHandler(label || propName)}
          type="number"
          helperText={customProperties['smallTextField']?.helperText}
          suffixText={customProperties['smallTextField']?.suffixText}
        />
      )}

      {type === 'button' && (
        <ButtonIcon
          // @ts-ignore
          icon={componentProps[propName]}
          onClickIcon={handleIconClick}
          onClickRemove={handleIconClick}
          label={stringHandler(label || propName)}
        />
      )}

      {type === 'dynamic' && customProperties.dynamic && (
        <Dynamic
          label={stringHandler(label || propName)}
          config={customProperties.dynamic}
          // @ts-ignore
          value={componentProps[propName]}
          propName={propName}
          onChange={handleDynamicChange}
          idPrefix={componentToEdit.id}
        />
      )}

      {type === 'module' && moduleProps && (
        <Module
          componentProps={componentProps}
          setComponentProps={setComponentProps}
          label={stringHandler(label || propName)}
          moduleProps={moduleProps}
          propName={propName}
          componentToEdit={componentToEdit}
          screenId={screenId}
        />
      )}
    </>
  )
}

export default PropEdit
