import React, { useState, useEffect } from 'react'
import { useTypedSelector } from 'Store'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { useLogin, useLogOut } from 'Components/Auth/Hooks/AuthHooks'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ReactComponent as WaveHandImage } from 'assets/images/wave-hand.svg'

const InputWrapper = styled('div')(() => ({
  marginTop: 22
}))

const LoginDialog: React.FC = () => {
  const { sessionExpired } = useTypedSelector(state => state.auth)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [org, setOrg] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { login, queryLoading, mutationLoading, error } = useLogin()
  const logOut = useLogOut()

  useEffect(() => {
    if (sessionExpired) {
      logOut()
    }
  }, [sessionExpired])

  const submit = (event: React.FormEvent) => {
    event.preventDefault()

    login({
      variables: {
        email: email,
        password: password,
        organization: org,
        type: 'ORGUSER'
      }
    })
  }

  const handleForgotPassword = () => {
    logOut('/reset-password')
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={sessionExpired}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
      BackdropProps={{
        sx: { backdropFilter: 'blur(8px)' }
      }}
    >
      <form onSubmit={submit}>
        <Box sx={{ textAlign: 'center', marginTop: '48px' }}>
          <WaveHandImage />
          <Typography
            variant="h4"
            sx={{ paddingTop: '16px', color: theme => theme.palette.text.primary }}
          >
            Hi friend!
          </Typography>
          <Typography variant="body1" sx={{ color: theme => theme.palette.text.primary }}>
            Your session has expired. Please login again.
          </Typography>
        </Box>
        <DialogContent>
          <InputWrapper>
            <TextField
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              placeholder="Email"
              fullWidth
              variant="outlined"
              color="secondary"
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              placeholder="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              color="secondary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <TextField
              value={org}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrg(e.target.value)}
              placeholder="Organization"
              fullWidth
              variant="outlined"
              color="secondary"
              error={!!error}
              helperText={error}
            />
          </InputWrapper>
        </DialogContent>
        <Box sx={{ padding: '24px 24px 32px 24px' }}>
          <Box sx={{ position: 'relative' }}>
            {mutationLoading || queryLoading ? (
              <SpinnerMui
                color="white"
                size={30}
                style={{
                  position: 'absolute',
                  bottom: '3px',
                  width: '100%',
                  textAlign: 'center'
                }}
              />
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disableElevation
              type="submit"
              disabled={mutationLoading || queryLoading}
              sx={{ borderRadius: '100px' }}
            >
              Login
            </Button>
          </Box>
          <Button
            variant="text"
            color="secondary"
            size="large"
            fullWidth
            sx={{ marginTop: '16px', borderRadius: '100px' }}
            disabled={mutationLoading || queryLoading}
            onClick={handleForgotPassword}
          >
            Forgot password
          </Button>
        </Box>
      </form>
    </Dialog>
  )
}

export default LoginDialog
