import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import ComponentBox from 'Components/Common/ComponentBox'
import AddElementsMenu from 'Components/Common/Editing/AddElementsMenu'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'
import { NavigationActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection/NavigationAction'
import { ScreenComponent, genComponentId } from 'Features/canvas'
import {
  addComponent,
  deleteComponent,
  selectComponent,
  updateComponent
} from 'Features/canvasScreens'
import uqComponentsData from 'Util/UqComponentsData'
import { canvasComponents } from 'Util/CanvasComponents'

const Footer: React.FC = () => {
  const dispatch = useDispatch()
  const { screens, selectedScreenId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  if (!selectedScreenId || Object.keys(screens).length === 0) return <></>

  const flowNavComponent: ScreenComponent | undefined =
    screens[selectedScreenId].components.footers[0]

  const handleAddComponent = () => {
    const props = JSON.parse(JSON.stringify(canvasComponents.flowNav.props))
    const thisScreen = screens[selectedScreenId]
    // Add action to go forward if connection exists
    if (thisScreen && thisScreen.connections.length > 0) {
      const action: ComponentActionSchema & NavigationActionSchema = {
        id: uuidv4(),
        type: 'NAVIGATION',
        navigateTo: thisScreen.connections[0].target
      }
      if (props.actionComponents && props.actionComponents.length > 1) {
        props.actionComponents[1].actions = [action]
      }
    }
    // Add action to go back if sourceId exists
    if (thisScreen && thisScreen.sourceId) {
      const action: ComponentActionSchema & NavigationActionSchema = {
        id: uuidv4(),
        type: 'NAVIGATION',
        navigateTo: thisScreen.sourceId
      }
      if (props.actionComponents && props.actionComponents.length > 0) {
        props.actionComponents[0].actions = [action]
      }
    }

    dispatch(
      addComponent({
        screenId: selectedScreenId,
        component: {
          id: genComponentId(),
          componentName: 'flowNav',
          props: props
        },
        section: 'footers'
      })
    )
  }

  const handleRemoveComponent = (componentId: string) => {
    dispatch(deleteComponent({ screenId: selectedScreenId, componentId, section: 'footers' }))
  }

  const handleSingleClick = (component: ScreenComponent) => {
    dispatch(
      selectComponent({
        stageComponent: true,
        screenId: selectedScreenId,
        componentId: component.id
      })
    )
  }

  const handleDoubleClick = (component: ScreenComponent) => {
    dispatch(
      selectComponent({
        stageComponent: false,
        screenId: selectedScreenId,
        componentId: component.id
      })
    )
  }

  const handleVisibility = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    e.stopPropagation()
    const isVisible = component.style ? component.style.display !== 'none' : true
    const editedComponent: ScreenComponent = {
      ...component,
      style: isVisible ? { display: 'none' } : {}
    }
    dispatch(
      updateComponent({
        screenId: selectedScreenId,
        component: editedComponent,
        section: 'footers'
      })
    )
  }

  return (
    <AddElementsMenu
      label="Footer"
      action={
        !flowNavComponent
          ? { type: 'add', onClick: handleAddComponent }
          : { type: 'remove', onClick: () => handleRemoveComponent(flowNavComponent.id) }
      }
    >
      {flowNavComponent && (
        <div style={{ margin: '16px', marginTop: '8px' }}>
          <ComponentBox
            componentId={flowNavComponent.id}
            primary={uqComponentsData.flowNav.name}
            icon={uqComponentsData.flowNav.editIcon || 'FiberManualRecord'}
            secondaryIcon={
              flowNavComponent.style
                ? flowNavComponent.style.display !== 'none'
                  ? 'Visibility'
                  : 'VisibilityOff'
                : 'Visibility'
            }
            isVisible={flowNavComponent.style ? flowNavComponent.style.display !== 'none' : true}
            onClick={() => handleSingleClick(flowNavComponent)}
            onDoubleClick={() => handleDoubleClick(flowNavComponent)}
            onClickIcon={e => handleVisibility(e, flowNavComponent)}
            notDraggable
          />
        </div>
      )}
    </AddElementsMenu>
  )
}

export default Footer
