import React, { useState } from 'react'
import { Box, Typography, Dialog, DialogActions, DialogContent } from '@mui/material'
import TextField from 'Components/Common/TextField'
import { ButtonStyled } from '../../WorkspaceView'

interface NewFolderDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  folders: string[]
  setFolders: React.Dispatch<React.SetStateAction<string[]>>
}

const NewFolderDialog: React.FC<NewFolderDialogProps> = ({
  open,
  setOpen,
  folders,
  setFolders
}) => {
  const [folderName, setFolderName] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')

  const handleCreateFolder = () => {
    if (!folderName) {
      setErrorText('Folder must have a name.')
      return
    }
    if (folders.includes(folderName)) {
      setErrorText('Folder with this name already exists.')
      return
    }
    setFolders([...folders, folderName])
    handleClose()
  }

  const handleClose = () => {
    if (errorText !== '') {
      setErrorText('')
    }
    setFolderName('')
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <div>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Create new folder
          </Typography>
        </Box>
        <DialogContent>
          <TextField
            disablePadding
            label="Folder name"
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
            error={errorText ? true : false}
            helperText={errorText}
          />
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          <ButtonStyled onClick={handleClose} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={handleCreateFolder}
            disableElevation
            color="secondary"
            variant="contained"
          >
            Create folder
          </ButtonStyled>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default NewFolderDialog
