import React from 'react'
import { Property } from 'csstype'
import { Box, SelectChangeEvent } from '@mui/material'
import TypographyCard from './TypographyCard'
import { TypographyVariant, TypographySchema } from 'Util/ThemeValues'

interface TypographysBasicProps {
  typography: TypographySchema
  updateFontWeight: (event: SelectChangeEvent<string>, variant: TypographyVariant) => void
  updateFontStyle: (isItalic: boolean, variant: TypographyVariant) => void
  updateTextDecoration: (isUnderline: boolean, variant: TypographyVariant) => void
  updateTextTransform: (isUpperCase: boolean, variant: TypographyVariant) => void
}

const TypographysBasic: React.FC<TypographysBasicProps> = ({
  typography,
  updateFontWeight,
  updateFontStyle,
  updateTextDecoration,
  updateTextTransform
}) => {
  return (
    <Box sx={{ margin: '12px 16px' }}>
      <TypographyCard
        text="Headline"
        fontSize={typography.h5.fontSize as Property.FontSize}
        fontWeight={typography.h5.fontWeight}
        fontWeightOnChange={value => updateFontWeight(value, 'h5')}
        fontStyle={typography.h5.fontStyle}
        fontStyleOnChange={value => updateFontStyle(value, 'h5')}
        textDecoration={typography.h5.textDecoration as Property.TextDecoration}
        textDecorationOnChange={value => updateTextDecoration(value, 'h5')}
        textTransform={typography.h5.textTransform}
        textTransformOnChange={value => updateTextTransform(value, 'h5')}
      />
      <TypographyCard
        text="Title"
        fontSize={typography.h6.fontSize as Property.FontSize}
        fontWeight={typography.h6.fontWeight}
        fontWeightOnChange={value => updateFontWeight(value, 'h6')}
        fontStyle={typography.h6.fontStyle}
        fontStyleOnChange={value => updateFontStyle(value, 'h6')}
        textDecoration={typography.h6.textDecoration as Property.TextDecoration}
        textDecorationOnChange={value => updateTextDecoration(value, 'h6')}
        textTransform={typography.h6.textTransform}
        textTransformOnChange={value => updateTextTransform(value, 'h6')}
      />
      <TypographyCard
        text="Subtitle"
        fontSize={typography.subtitle1.fontSize as Property.FontSize}
        fontWeight={typography.subtitle1.fontWeight}
        fontWeightOnChange={value => updateFontWeight(value, 'subtitle1')}
        fontStyle={typography.subtitle1.fontStyle}
        fontStyleOnChange={value => updateFontStyle(value, 'subtitle1')}
        textDecoration={typography.subtitle1.textDecoration as Property.TextDecoration}
        textDecorationOnChange={value => updateTextDecoration(value, 'subtitle1')}
        textTransform={typography.subtitle1.textTransform}
        textTransformOnChange={value => updateTextTransform(value, 'subtitle1')}
      />
      <TypographyCard
        text="Body"
        fontSize={typography.body1.fontSize as Property.FontSize}
        fontWeight={typography.body1.fontWeight}
        fontWeightOnChange={value => updateFontWeight(value, 'body1')}
        fontStyle={typography.body1.fontStyle}
        fontStyleOnChange={value => updateFontStyle(value, 'body1')}
        textDecoration={typography.body1.textDecoration as Property.TextDecoration}
        textDecorationOnChange={value => updateTextDecoration(value, 'body1')}
        textTransform={typography.body1.textTransform}
        textTransformOnChange={value => updateTextTransform(value, 'body1')}
      />
      <TypographyCard
        text="Buttons"
        fontSize={typography.button.fontSize as Property.FontSize}
        fontWeight={typography.button.fontWeight}
        fontWeightOnChange={value => updateFontWeight(value, 'button')}
        fontStyle={typography.button.fontStyle}
        fontStyleOnChange={value => updateFontStyle(value, 'button')}
        textDecoration={typography.button.textDecoration as Property.TextDecoration}
        textDecorationOnChange={value => updateTextDecoration(value, 'button')}
        textTransform={typography.button.textTransform}
        textTransformOnChange={value => updateTextTransform(value, 'button')}
      />
    </Box>
  )
}

export default TypographysBasic
