// Fixes console-warning "symbol.observable as defined by redux and redux devtools do not match"
// https://github.com/reduxjs/redux-toolkit/issues/2008#issuecomment-1032025129
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Symbol_observable from 'symbol-observable'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { store } from 'Store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import PublicClient from 'Api/publicClient'
import theme from './theme'
import * as serviceWorker from './serviceWorker'
import { App } from './App'
import { ReactFlowProvider } from 'react-flow-renderer'
import { FirebaseAppProvider } from 'reactfire'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

const render = () => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <ApolloProvider client={PublicClient}>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <ThemeProvider theme={theme}>
              <ReactFlowProvider>
                <DndProvider backend={HTML5Backend}>
                  <App />
                </DndProvider>
              </ReactFlowProvider>
            </ThemeProvider>
          </FirebaseAppProvider>
        </ApolloProvider>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
