import React, { Fragment, useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import PropEdit from './PropEdit'
import CollapseMenu from 'Components/Common/CollapseMenu'
import UqListEdit from './Components/UqListEdit'
import PaddingSection from './Sections/PaddingSection'
import ActionSection from './Sections/ActionSection'
import { Box, Divider, ListItemIcon, ListItem, ListItemText, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ScreenComponent } from 'Features/canvas'
import { selectComponent, deleteComponent } from 'Features/canvasScreens'
import uqComponentsData, { Prop, Section } from 'Util/UqComponentsData'
import { ConditionalWrapper } from 'Util/ConditionalWrapper'
import { CanvasComponentName } from 'Util/CanvasComponents'
import { CanvasScreen } from 'types/firebase'
import { useChildComponent } from './Components/ChildComponentHooks'
import { useProps } from './Hooks/PropHooks'

const debounceTimer = 700

/**
 * Decides which components don't have a padding-section in component-edit view
 * @returns boolean
 */
const showPaddingSection = (componentName: CanvasComponentName): boolean => {
  switch (componentName) {
    case 'uqAppBar':
    case 'uqDialog':
    case 'uqSnackbar':
    case 'uqInputAdornment':
    case 'uqBottomNavigationAction':
    case 'uqSpeedDialAction':
    case 'uqAvatar':
    case 'uqIconButton':
    case 'uqLinearProgress':
    case 'uqListItem':
    case 'uqListItemIcon':
    case 'uqListItemAvatar':
    case 'uqListItemSecondaryAction':
      return false
    default:
      return true
  }
}

const showActionSection = (componentName: CanvasComponentName): boolean => {
  switch (componentName) {
    case 'uqButton':
    case 'uqIconButton':
    case 'uqSpeedDialAction':
    case 'uqListItem':
    case 'image':
      return true
    default:
      return false
  }
}

const handleComponentName = (name: string): string => {
  const replaceUq = name.replace('uq', '')
  const result = replaceUq.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const findComponent = (
  screens: Record<string, CanvasScreen>,
  selectedScreenId: string,
  selectedComponentId: string
) => {
  if (Object.keys(screens).length > 0) {
    const headers = Object.values(screens[selectedScreenId].components.headers)
    const contents = Object.values(screens[selectedScreenId].components.contents)
    const footers = Object.values(screens[selectedScreenId].components.footers)
    return [...headers, ...contents, ...footers].find(c => c.id === selectedComponentId)
  }
  return undefined
}

type MappedProps = { [categoryName in Section]?: Prop[] }

interface ComponentEditProps {
  selectedScreenId: string
  selectedComponentId: string
  /**
   * @description The component to edit. If not specified, active component will be loaded from store.
   */
  component?: ScreenComponent
  /**
   * @description Event handler that receives all prop updates. If not specified, updates are saved
   *   directly to store. Should only be provided when we don't want updates to go directly to store,
   *   such as for child components.
   */
  onPropChange?: (propName: string, newValue: unknown) => void
  /**
   * @description Hides the top row header with the component name and back arrow.
   * @default false
   */
  hideHeader?: boolean
  /**
   * @description Disables the categorization of props into different sections.
   * @default false
   */
  disableCategories?: boolean
  /**
   * @description Takes an array of prop IDs. When specified, only displays the props included in the list.
   * @default undefined
   */
  propAllowList?: string[]
}

const ComponentEdit: React.FC<ComponentEditProps> = ({
  selectedScreenId,
  selectedComponentId,
  component,
  onPropChange,
  hideHeader = false,
  disableCategories = false,
  propAllowList
}) => {
  const dispatch = useDispatch()
  const [propsByCategory, setPropsByCategory] = useState<MappedProps>({})
  const { screens, parentComponent } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  const { deleteChildComponent } = useChildComponent()
  const { updateProps } = useProps()

  const componentToEdit: ScreenComponent | undefined =
    component ?? findComponent(screens, selectedScreenId, selectedComponentId)

  // Stores all props in this component, these are passed to PropEdit-component
  // This handles the real-time updates on TextField, Select etc. -components
  // in PropEdit. Redux changes are then debounced and props edited based on
  // 'componentProps' -value.
  const [componentProps, setComponentProps] = useState(componentToEdit?.props)

  useEffect(() => {
    if (componentToEdit) {
      setComponentProps(componentToEdit.props)
    }
  }, [componentToEdit])

  // When 'componentProps' is changed, they are saved to reducer.
  // 'updateProps'- function checks if 'componentProps' & 'componentEdit.props'
  // are equal or not. If not equal, changes are dispatched.
  useEffect(() => {
    if (componentProps && componentToEdit) {
      saveProps(componentProps, componentToEdit, selectedScreenId)
    }
  }, [componentProps, componentToEdit])

  // Debounce updating props
  const saveProps = useCallback(debounce(updateProps, debounceTimer), [])

  useEffect(() => {
    if (!componentToEdit) return
    const propEntries = Object.entries(uqComponentsData[componentToEdit.componentName].props)

    const result: MappedProps = {}
    propEntries.forEach(([propId, prop]) => {
      if (propAllowList && !propAllowList.includes(propId)) return
      const { section } = prop

      if (!prop.propName) prop.propName = propId

      if (!Object.prototype.hasOwnProperty.call(result, section)) result[section] = []
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore For some reason TS refuses to understand that section does exist in result
      result[section].push(prop)
    })
    setPropsByCategory(result)
  }, [componentToEdit])

  const handleGoBack = () => {
    if (!parentComponent) {
      dispatch(selectComponent({ component: null }))
    } else {
      dispatch(selectComponent({ component: parentComponent }))
    }
  }

  const handleDeleteComponent = () => {
    if (componentToEdit) {
      if (!parentComponent) {
        dispatch(selectComponent({ component: null }))
        dispatch(deleteComponent({ screenId: selectedScreenId, componentId: componentToEdit.id }))
      } else {
        dispatch(selectComponent({ component: parentComponent }))
        deleteChildComponent(selectedScreenId, parentComponent, componentToEdit.id, undefined)
      }
    }
  }

  return (
    <>
      {!hideHeader && (
        <Box style={{ position: 'fixed', zIndex: 2, background: '#fff', width: '315px' }}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleDeleteComponent} edge="end">
                <DeleteOutlineOutlinedIcon sx={{ color: theme => theme.palette.text.secondary }} />
              </IconButton>
            }
          >
            <ListItemIcon sx={{ cursor: 'pointer', minWidth: '38px' }} onClick={handleGoBack}>
              <ArrowBackIcon sx={{ color: theme => theme.palette.text.secondary }} />
            </ListItemIcon>
            <ListItemText
              primary={handleComponentName(componentToEdit?.componentName || '')}
              primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
            />
          </ListItem>
          <Divider />
        </Box>
      )}
      {componentToEdit && (
        <Box sx={!hideHeader ? { marginTop: '52px' } : {}}>
          {componentToEdit.componentName === 'uqList' ? (
            <UqListEdit
              setComponentProps={setComponentProps}
              componentToEdit={componentToEdit}
              selectedScreenId={selectedScreenId}
            />
          ) : (
            Object.entries(propsByCategory).map(([category, props], i) => (
              <Fragment key={i}>
                <ConditionalWrapper
                  if={!disableCategories}
                  with={nodes => (
                    <>
                      <CollapseMenu text={category}>
                        <>{nodes}</>
                        <div style={{ marginTop: 12 }} />
                      </CollapseMenu>
                      <Divider />
                    </>
                  )}
                >
                  {props.map((prop, i) => (
                    <PropEdit
                      key={i}
                      onChange={
                        onPropChange
                          ? newValue => onPropChange(prop.propName as string, newValue)
                          : undefined
                      }
                      componentProps={componentProps}
                      setComponentProps={setComponentProps}
                      componentToEdit={componentToEdit}
                      prop={prop}
                      screenId={selectedScreenId}
                    />
                  ))}
                </ConditionalWrapper>
              </Fragment>
            ))
          )}
          {showPaddingSection(componentToEdit.componentName) ? (
            <PaddingSection
              componentProps={componentProps}
              setComponentProps={setComponentProps}
              hideHeader={hideHeader}
            />
          ) : null}
          {showActionSection(componentToEdit.componentName) ? (
            <ActionSection
              component={componentToEdit}
              selectedComponentId={selectedComponentId}
              screenId={selectedScreenId}
            />
          ) : null}
        </Box>
      )}
    </>
  )
}

export default ComponentEdit
