import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import { Box, Typography, Dialog, DialogActions, DialogContent, Collapse } from '@mui/material'
import ButtonIcon from 'Components/Common/Editing/ButtonIcon'
import TextField from 'Components/Common/TextField'
import SpinnerMui from 'Components/Common/SpinnerMui'
import {
  setAlphaToHex,
  removeAlphaFromHex,
  convertRgbObjectToString,
  ColorContainer,
  IndicatorContainer,
  InputContainer,
  RoundColorIndicator
} from 'Components/Common/ColorPicker'
import { ButtonStyled } from '../../WorkspaceView'
import { doc, updateDoc, DocumentReference, serverTimestamp } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { WorkspaceSchema, Document, DesignSchema } from 'types/firebase'
import { IconType } from 'Components/Common/Icons'
import { editSnackbar } from 'Features/snackbar'

interface ThumbnailDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentForDialogs: { document: Document; type: 'design' | 'theme' } | undefined
  workspace: WorkspaceSchema
}

const ThumbnailDialog: React.FC<ThumbnailDialogProps> = ({
  open,
  setOpen,
  documentForDialogs,
  workspace
}) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const [loading, setLoading] = useState<boolean>(false)
  const [expand, setExpand] = useState<boolean>(false)
  const [colors, setColors] = useState<{ thumbnail: string }>({ thumbnail: '' })
  const [thumbnailIcon, setThumbnailIcon] = useState<IconType>('StarBorder')
  const [docRef, setDocRef] = useState<DocumentReference>()

  useEffect(() => {
    if (open && documentForDialogs) {
      if (documentForDialogs.type === 'design') {
        const design = documentForDialogs.document as DesignSchema
        setThumbnailIcon(design.icon || 'StarBorder')
        setColors({
          thumbnail: design.thumbnailColor ? design.thumbnailColor : 'black'
        })
        setDocRef(doc(firestore, 'Workspaces', workspace.id, 'Designs', design.id))
      }
    }
  }, [open, documentForDialogs])

  const handleThumbnailSave = async () => {
    if (docRef) {
      try {
        setLoading(true)
        await updateDoc(docRef, {
          thumbnailColor: colors.thumbnail,
          icon: thumbnailIcon,
          updatedAt: serverTimestamp()
        })
        setLoading(false)
        setExpand(false)
        setOpen(false)
      } catch (error) {
        setLoading(false)
        dispatch(
          editSnackbar({
            message: `Error. ${error}`,
            type: 'error'
          })
        )
      }
    } else {
      dispatch(
        editSnackbar({
          message: `Something went wrong, please try again.`,
          type: 'error'
        })
      )
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Thumbnail settings
          </Typography>
        </Box>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ flexGrow: 1 }}>
              <ColorContainer>
                <IndicatorContainer>
                  <RoundColorIndicator
                    onClick={() => setExpand(!expand)}
                    style={{ backgroundColor: convertRgbObjectToString(colors.thumbnail) }}
                  />
                </IndicatorContainer>
                <InputContainer>
                  <TextField
                    label="Primary"
                    value={removeAlphaFromHex(colors.thumbnail)}
                    onClick={() => setExpand(!expand)}
                    readOnly
                    disablePadding
                    suffixIcon={expand ? 'ExpandLess' : 'ExpandMore'}
                  />
                  <Collapse in={expand} timeout="auto" unmountOnExit>
                    <SketchPicker
                      color={colors.thumbnail}
                      onChange={color => setColors({ thumbnail: setAlphaToHex(color) })}
                    />
                  </Collapse>
                </InputContainer>
              </ColorContainer>
            </Box>
            <ButtonIcon
              icon={thumbnailIcon}
              onClickIcon={icon => setThumbnailIcon(icon)}
              disablePadding
              buttonHeight={58}
              style={{ marginBottom: '8px' }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {loading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '72px'
              }}
            />
          ) : null}
          <ButtonStyled onClick={() => setOpen(false)} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={handleThumbnailSave}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Save changes
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ThumbnailDialog
