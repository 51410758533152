import React from 'react'
import ColorPicker from 'Components/Common/ColorPicker'
import { BackgroundColorProps } from '../ColorsAdvanced'

const BackgroundColor: React.FC<BackgroundColorProps> = ({ colors, palette, updateBackground }) => {
  return (
    <div style={{ marginLeft: '16px' }}>
      <ColorPicker
        label="Default"
        colors={colors}
        colorFromState={palette.background.default}
        paletteKey="background"
        colorKey="default"
        updateColor={(color, stateUpdate) => updateBackground(color, stateUpdate, 'default')}
      />
      <ColorPicker
        label="Paper"
        colors={colors}
        colorFromState={palette.background.paper}
        paletteKey="background"
        colorKey="paper"
        updateColor={(color, stateUpdate) => updateBackground(color, stateUpdate, 'paper')}
      />
    </div>
  )
}

export default BackgroundColor
