import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import TextField from 'Components/Common/TextField'
import Select, { SelectOption } from 'Components/Common/Select'
import SpinnerMui from 'Components/Common/SpinnerMui'
import AddIcon from '@mui/icons-material/Add'
import { ButtonStyled } from '../../WorkspaceView'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import { WorkspaceSchema } from 'types/firebase'

const getFolderOptions = (folders: string[]): SelectOption[] => {
  const folderOptions: SelectOption[] = [
    {
      label: 'All projects',
      value: 'none'
    }
  ]
  folders.forEach(folder => {
    folderOptions.push({
      label: folder,
      value: folder
    })
  })
  return folderOptions
}

interface NewDesignDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  folders: string[]
  workspace: WorkspaceSchema
}

const NewDesignDialog: React.FC<NewDesignDialogProps> = ({ open, setOpen, folders, workspace }) => {
  const { createDesign, createDesignData, createDesignLoading, openDesign } = useDesigns(
    workspace.id
  )

  const [newDesignError, setNewDesignError] = useState<boolean>(false)
  const [newDesign, setNewDesign] = useState<{ folder: string; title: string }>({
    folder: 'none',
    title: ''
  })

  // When new design is created
  useEffect(() => {
    if (createDesignData) {
      setNewDesignError(false)
      setNewDesign({
        folder: 'none',
        title: ''
      })
      setOpen(false)
      openDesign(createDesignData.createDesignStudioDocument)
    }
  }, [createDesignData])

  const handleNewDesign = () => {
    if (!newDesign.title) {
      setNewDesignError(true)
    } else {
      setNewDesignError(false)
      createDesign(newDesign.title, newDesign.folder === 'none' ? '' : newDesign.folder)
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <Box sx={{ textAlign: 'center', marginTop: '24px', marginBottom: '16px' }}>
        <AddIcon sx={{ color: theme => theme.palette.text.secondary, fontSize: '66px' }} />
        <Typography
          variant="h4"
          sx={{ color: theme => theme.palette.text.primary, marginTop: '8px' }}
        >
          New project
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme => theme.palette.text.primary, marginTop: '8px' }}
        >
          Let’s configure some basic settings first.
        </Typography>
        <DialogContent sx={{ padding: '20px 140px' }}>
          <Select
            value={newDesign.folder}
            onChange={e => setNewDesign({ ...newDesign, folder: e.target.value })}
            label="Folder"
            options={getFolderOptions(folders)}
          />
          <TextField
            value={newDesign.title}
            onChange={e => setNewDesign({ ...newDesign, title: e.target.value })}
            label="Project name"
            error={newDesignError}
            helperText={newDesignError ? 'This field cannot be empty.' : ''}
            style={{ paddingTop: '8px', paddingBottom: '8px' }}
          />
        </DialogContent>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {createDesignLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '50px',
                width: '100%',
                textAlign: 'center'
              }}
            />
          ) : null}
          <ButtonStyled
            onClick={handleNewDesign}
            color="secondary"
            variant="contained"
            disableElevation
            disabled={createDesignLoading}
          >
            Launch
          </ButtonStyled>
          <ButtonStyled
            onClick={() => setOpen(false)}
            color="secondary"
            variant="text"
            disableElevation
          >
            Cancel
          </ButtonStyled>
        </Box>
      </Box>
    </Dialog>
  )
}

export default NewDesignDialog
