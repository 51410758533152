import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface HelpersSchema {
  openNewThemeDialog: boolean
  // When Design Project is opened, all the screens are fetched from firebase,
  // and saved to reducer, however past is created (redux-undo) and it needs
  // to be cleared, since user should not be able to undo fetched screens from canvas.
  // Whenn this value is true, past is cleared.
  screensFetchedFromFirebase: boolean
  // This is used to add & remove listener for 'Delete'-key,
  // since if user clicks Delete or Backspace when typing on text fields,
  // the whole component would be deleted also
  isTextFieldFocused: boolean
  // Which component is hovered, either in canvas or screen-edit view
  componentIdHover: string
  // Is set to true when dragging edge. When is true, Handle's z-index is set to 2.
  // Target Handle's z-index is set to 0, this allows screen's component hovering,
  // since Target Handle's size is the whole screen size and is on top of the screen.
  edgeConnecting: boolean
  // This is set true when dragging node (screen). This prevents certain hover
  // functions while dragging
  isNodeDragging: boolean
}

const initialState: HelpersSchema = {
  openNewThemeDialog: false,
  screensFetchedFromFirebase: false,
  isTextFieldFocused: false,
  componentIdHover: '',
  edgeConnecting: false,
  isNodeDragging: false
}

const helpersSlice = createSlice({
  name: 'helpers',
  initialState,
  reducers: {
    editHelpers: (
      state,
      action: PayloadAction<{ key: keyof HelpersSchema; value: boolean | string }>
    ) => {
      const { value, key } = action.payload
      if (key === 'componentIdHover') {
        state[key] = value as string
      } else {
        state[key] = value as boolean
      }
    }
  }
})

export const { editHelpers } = helpersSlice.actions
export default helpersSlice.reducer
