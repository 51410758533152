import { useState } from 'react'
import { ActionCreators } from 'redux-undo'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import ServiceClient from 'Api/serviceClient'
import { changeAuth } from 'Features/auth'
import { clearWorkspaceView } from 'Features/workspaceView'
import { initLoggedInUser, clearLoggedInUser } from 'Features/loggedInUser'
import { clearfirebaseDocuments } from 'Features/firebaseDocuments'
import { clearSaveHelper } from 'Features/saveHelper'
import { clearCanvasScreens } from 'Features/canvasScreens'
import { editHelpers } from 'Features/helpers'

export const THIS_USER = gql`
  query thisUser {
    thisUser {
      id
      email
      firstName
      lastName
      organization {
        name
      }
    }
  }
`

const LOGIN = gql`
  mutation login($email: EmailAddress!, $password: String!, $organization: ID, $type: LoginType) {
    login(email: $email, password: $password, organization: $organization, type: $type) {
      token
      error
      uid
    }
  }
`

export const useLogin = (forwardToFrontPage?: boolean) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState<string>('')

  // After successful login, fetch logged-in org user data
  const [getUser, { loading: queryLoading }] = useLazyQuery(THIS_USER, {
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (result.thisUser) {
        dispatch(initLoggedInUser({ value: result.thisUser }))
        if (forwardToFrontPage) {
          navigate('/')
        }
      } else {
        setError('Something went wrong, please try again.')
      }
    },
    onError(error) {
      if (error) {
        setError(`Error: ${error.message}`)
      }
    }
  })

  // Login
  const [login, { loading: mutationLoading }] = useMutation(LOGIN, {
    onCompleted(result) {
      if (result.login.error) {
        setError(`${result.login.error} / organization`)
      } else if (result.login.token) {
        localStorage.setItem('token', result.login.token)
        dispatch(changeAuth({ value: false, key: 'sessionExpired' }))
        dispatch(changeAuth({ value: false, key: 'passwordResetDone' }))
        getUser()
      }
    },
    onError(error) {
      if (error) {
        setError(`Error: ${error.message}`)
      }
    }
  })

  return {
    login,
    queryLoading,
    mutationLoading,
    error
  }
}

export const useLogOut = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Clear all necessary data here when logging out
  return (url?: string) => {
    dispatch(editHelpers({ key: 'screensFetchedFromFirebase', value: false }))
    dispatch(clearLoggedInUser())
    dispatch(clearWorkspaceView())
    dispatch(clearfirebaseDocuments())
    dispatch(clearSaveHelper())
    dispatch(clearCanvasScreens())
    dispatch(ActionCreators.clearHistory())
    ServiceClient.clearStore()
    localStorage.removeItem('token')
    if (url) {
      navigate(url)
    }
  }
}
