/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { IconType } from 'Components/Common/Icons'
import ButtonIcon from 'Components/Common/Editing/ButtonIcon'
import { SelectChangeEvent } from '@mui/material/Select'
import Switch from 'Components/Common/Switch'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import TextField from 'Components/Common/TextField'
import SmallTextField from 'Components/Common/Editing/SmallTextField'
import { stringHandler } from './PropEdit'
import { PropType } from 'Util/UqComponentsData'

interface PropEditModulesProps {
  componentProps: unknown
  setComponentProps: React.Dispatch<unknown>
  propKey: string
  type: PropType | string[]
  propValue: unknown
  index: number
  propName: string
}

const PropEditModules: React.FC<PropEditModulesProps> = ({
  componentProps,
  setComponentProps,
  propKey,
  type,
  propValue,
  index,
  propName
}) => {
  const updateProps = (propValue: unknown, key: string, index: number) => {
    // @ts-ignore
    const updatedPropValue = JSON.parse(JSON.stringify(componentProps[propName]))
    updatedPropValue[index][key] = propValue
    // @ts-ignore
    setComponentProps({ ...componentProps, [propName]: updatedPropValue })
  }

  const handleSelectChange = (event: SelectChangeEvent, key: string, index: number) => {
    updateProps(event.target.value, key, index)
  }

  const handleBoolChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    index: number
  ) => {
    updateProps(event.target.checked, key, index)
  }

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    key: string,
    index: number
  ) => {
    updateProps(event.target.value, key, index)
  }

  const handleSmallTextFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    key: string,
    index: number,
    type: string
  ) => {
    if (type === 'number') {
      updateProps(Number(event.target.value), key, index)
    } else {
      updateProps(event.target.value, key, index)
    }
  }

  const handleIconClick = (icon: IconType | '', key: string, index: number) => {
    updateProps(icon, key, index)
  }

  return (
    <>
      {Array.isArray(type) ? (
        <SmallSelect
          // @ts-ignore
          value={propValue[propKey]}
          onChange={e => handleSelectChange(e, propKey, index)}
          label={stringHandler(propKey)}
          options={type.map(v => ({ label: stringHandler(v), value: v }))}
        />
      ) : null}

      {type === 'bool' && (
        <Switch
          // @ts-ignore
          checked={propValue[propKey]}
          onChange={e => handleBoolChange(e, propKey, index)}
          label={stringHandler(propKey)}
        />
      )}

      {(type === 'node' || type === 'string') && (
        <TextField
          // @ts-ignore
          value={propValue[propKey]}
          onChange={e => handleTextFieldChange(e, propKey, index)}
          label={stringHandler(propKey)}
        />
      )}

      {type === 'smallTextField' && (
        <SmallTextField
          // @ts-ignore
          value={propValue[propKey]}
          onChange={e => handleSmallTextFieldChange(e, propKey, index, 'text')}
          label={stringHandler(propKey)}
        />
      )}

      {type === 'number' && (
        <SmallTextField
          // @ts-ignore
          value={propValue[propKey]}
          onChange={e => handleSmallTextFieldChange(e, propKey, index, 'number')}
          label={stringHandler(propKey)}
          type="number"
        />
      )}

      {type === 'button' && (
        <ButtonIcon
          // @ts-ignore
          icon={propValue[propKey]}
          onClickIcon={icon => handleIconClick(icon, propKey, index)}
          onClickRemove={icon => handleIconClick(icon, propKey, index)}
          label={stringHandler(propKey)}
        />
      )}
    </>
  )
}

export default PropEditModules
