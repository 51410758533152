import React, { useEffect, useState } from 'react'
import { useDidMountEffect } from 'Components/Common/Hooks/CommonHooks'
import { useTypedSelector } from 'Store'
import { useDispatch } from 'react-redux'
import { editLeftSidebar } from 'Features/layout'
import { selectComponent } from 'Features/canvasScreens'
import { useKeyboardShortcuts } from 'Util/Hooks/KeyboardShortcutListener'

const CanvasClickListener: React.FC = () => {
  const dispatch = useDispatch()
  const {
    listenCanvasKeys,
    removeListenerCanvasKeys,
    listenUndoRedoKeys,
    removeListenerUndoRedoKeys,
    listenPreviewKeys,
    removeListenerPreviewKeys,
    listenRemoveKeys,
    removeListenerRemoveKeys
  } = useKeyboardShortcuts()
  const leftSidebar = useTypedSelector(state => state.layout.leftSidebar)
  const { previewOn } = useTypedSelector(state => state.preview)
  const { isTextFieldFocused } = useTypedSelector(state => state.helpers)
  const { selectedScreenId, selectedComponentId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  const [currentSelectedScreenId, setCurrentSelectedScreenId] = useState<string>()

  useEffect(() => {
    if (selectedScreenId && !currentSelectedScreenId) {
      setCurrentSelectedScreenId(selectedScreenId)
    }
    // When 'currentSelectedScreenId' is set and 'selectedScreenId' is different,
    // make sure to set 'selectedComponent' as null, to prevent showing blank leftsidebar-screen
    if (
      selectedScreenId &&
      currentSelectedScreenId &&
      currentSelectedScreenId !== selectedScreenId
    ) {
      setCurrentSelectedScreenId(selectedScreenId)
      dispatch(selectComponent({ component: null }))
    }
  }, [selectedScreenId])

  useEffect(() => {
    if (selectedScreenId) {
      if (!leftSidebar.open) {
        dispatch(editLeftSidebar({ stateKey: 'open', value: true }))
      }
      if (leftSidebar.section !== 'edit') {
        dispatch(editLeftSidebar({ stateKey: 'section', value: 'edit' }))
      }
    }
    if (!selectedScreenId) {
      dispatch(selectComponent({ component: null }))
    }
  }, [selectedScreenId])

  useEffect(() => {
    if (selectedComponentId) {
      if (!leftSidebar.open) {
        dispatch(editLeftSidebar({ stateKey: 'open', value: true }))
      }
      if (leftSidebar.section !== 'edit') {
        dispatch(editLeftSidebar({ stateKey: 'section', value: 'edit' }))
      }
    }
  }, [selectedComponentId])

  // Listen keyboard shortcuts
  useEffect(() => {
    listenCanvasKeys()
    listenUndoRedoKeys()
    listenRemoveKeys()
    return () => {
      removeListenerCanvasKeys()
      removeListenerUndoRedoKeys()
      removeListenerRemoveKeys()
    }
  }, [])

  // Removes listeners when TextField is focused
  useDidMountEffect(() => {
    if (isTextFieldFocused) {
      removeListenerCanvasKeys()
      removeListenerRemoveKeys()
    } else {
      listenCanvasKeys()
      listenRemoveKeys()
    }
  }, [isTextFieldFocused])

  // Handles listeners when preview is on/off
  useDidMountEffect(() => {
    if (previewOn) {
      listenPreviewKeys()
      removeListenerCanvasKeys()
      removeListenerUndoRedoKeys()
      removeListenerRemoveKeys()
    } else {
      removeListenerPreviewKeys()
      listenCanvasKeys()
      listenUndoRedoKeys()
      listenRemoveKeys()
    }
  }, [previewOn])

  return null
}

export default React.memo(CanvasClickListener)
