import React, { useEffect, useState, useRef } from 'react'
import { useTypedSelector } from 'Store'
import { Box, Typography } from '@mui/material'
import { useScreens } from 'Components/Firebase/Hooks/FirebaseHooks'
import { DesignSchema } from 'types/firebase'

interface ScreenSyncNotificationProps {
  workspaceId: string
  selectedDesignId: string
}

const ScreenSyncNotification: React.FC<ScreenSyncNotificationProps> = ({
  workspaceId,
  selectedDesignId
}) => {
  const { getScreens, clearScreens } = useScreens(workspaceId)

  // When user opens Design/project, all the latest screens are fetched from firebase.
  // So this ref is a helper to start listening changes only after all the screens are fetched
  // and when some changes start to occur with fetched screens
  const listenDesignChanges = useRef(false)
  const loggedInUser = useTypedSelector(state => state.loggedInUser)
  const designs = useTypedSelector(state => state.firebaseDocuments.designs)

  const [thisDesign, setThisDesign] = useState<DesignSchema>()
  const [showNotification, setShowNotification] = useState<boolean>(false)

  // thisDesign is necessary to update only when notification is not visible
  useEffect(() => {
    if (!showNotification) {
      setThisDesign(designs.find(d => d.id === selectedDesignId))
    }
  }, [designs])

  // If another user has modified screens, display notification
  useEffect(() => {
    if (thisDesign) {
      if (listenDesignChanges.current && !showNotification) {
        if (loggedInUser.id !== thisDesign.modifiedBy) {
          setShowNotification(true)
        }
      }
      if (!listenDesignChanges.current) {
        listenDesignChanges.current = true
      }
    }
  }, [thisDesign])

  const handleFetchLatestChanges = () => {
    clearScreens()
    listenDesignChanges.current = false
    setShowNotification(false)
    getScreens(selectedDesignId)
  }

  return (
    <Box
      style={showNotification ? {} : { display: 'none' }}
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: theme => theme.props.navBarHeight,
        height: '36px',
        backgroundColor: theme => theme.custom.borderColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          position: 'relative',
          zIndex: 9999
        }}
      >
        Another user is currently editing.{' '}
        <Typography
          onClick={handleFetchLatestChanges}
          component="span"
          variant="subtitle2"
          sx={{
            color: theme => theme.palette.info.dark,
            position: 'relative',
            cursor: 'pointer',
            textDecoration: 'underline',
            zIndex: 9999
          }}
        >
          Click here to see the latest changes.
        </Typography>
      </Typography>
    </Box>
  )
}

export default React.memo(ScreenSyncNotification)
