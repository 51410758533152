import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useTheme, Theme } from '@mui/material/styles'
import { ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material'
import Icons, { IconType } from 'Components/Common/Icons'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import { editHelpers } from 'Features/helpers'

const getOutline = (
  isVisible: boolean,
  isHover: boolean,
  isDragging: boolean,
  componentId: string,
  componentIdHover: string,
  stagedComponentId: string | null,
  theme: Theme
) => {
  if (isHover || componentId === componentIdHover || componentId === stagedComponentId) {
    return `2px solid ${theme.palette.info.main}`
  }
  if (!isVisible) {
    return ''
  }
  if (isDragging) {
    return `1px solid ${theme.palette.info.main}`
  }
}

const getVisibleValue = (isVisible: boolean, value: 'primary' | 'secondary', theme: Theme) => {
  return isVisible ? theme.palette.text[value] : theme.palette.text.disabled
}

interface Props {
  componentId: string
  isDragging?: boolean
  primary: string
  secondary?: string
  icon?: IconType
  secondaryIcon: IconType
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  onDoubleClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  onClickIcon: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onClickCopy?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isVisible?: boolean
  notDraggable?: boolean
}

const ComponentBox: React.FC<Props> = ({
  componentId,
  isDragging = false,
  primary,
  secondary,
  icon,
  secondaryIcon,
  onClick,
  onDoubleClick,
  onClickIcon,
  onClickCopy,
  isVisible = true,
  notDraggable = false
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const componentIdHover = useTypedSelector(state => state.helpers.componentIdHover)
  const stagedComponentId = useTypedSelector(
    state => state.undoables.present.canvasScreens.stagedComponentId
  )
  const [isHover, setIsHover] = useState<boolean>(false)

  const handleMouseEnter = (componentId: string) => {
    setIsHover(true)
    dispatch(editHelpers({ key: 'componentIdHover', value: componentId }))
  }

  const handleMouseLeave = () => {
    setIsHover(false)
    dispatch(editHelpers({ key: 'componentIdHover', value: '' }))
  }

  return (
    <div
      onMouseEnter={() => handleMouseEnter(componentId)}
      onMouseLeave={() => handleMouseLeave()}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: '60px',
        borderRadius: '4px',
        border: isVisible
          ? `1px solid ${theme.custom.borderColor}`
          : `1px solid ${theme.custom.borderColor}67`,
        backgroundColor: theme.custom.nightBlue[40],
        boxShadow: isDragging ? theme.shadows[4] : '',
        outline: getOutline(
          isVisible,
          isHover,
          isDragging,
          componentId,
          componentIdHover,
          stagedComponentId,
          theme
        )
      }}
    >
      <ListItem
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        secondaryAction={
          <>
            <IconButton
              onClick={onClickCopy}
              sx={onClickCopy && isHover ? {} : { display: 'none' }}
            >
              <ContentCopyRoundedIcon sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
            <IconButton onClick={onClickIcon} sx={isHover ? {} : { display: 'none' }} edge="end">
              {React.createElement(Icons[secondaryIcon], {
                style: {
                  color: getVisibleValue(isVisible, 'secondary', theme)
                }
              })}
            </IconButton>
          </>
        }
      >
        <ListItemIcon>
          {!notDraggable && (isHover || isDragging) ? (
            <DragIndicatorIcon
              sx={{
                color: isDragging
                  ? theme.palette.text.primary
                  : getVisibleValue(isVisible, 'secondary', theme)
              }}
            />
          ) : icon ? (
            React.createElement(Icons[icon], {
              style: { color: getVisibleValue(isVisible, 'secondary', theme) }
            })
          ) : null}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={secondary}
          primaryTypographyProps={{
            variant: 'body2',
            sx: { color: getVisibleValue(isVisible, 'primary', theme) }
          }}
          secondaryTypographyProps={{
            sx: { color: getVisibleValue(isVisible, 'secondary', theme) }
          }}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        />
      </ListItem>
    </div>
  )
}

export default ComponentBox
