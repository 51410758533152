import { Palette, TypographyVariants } from '@mui/material/styles'

export interface ColorSchema {
  main: string
  dark?: string
  light?: string
  contrastText: string
}

export interface TextSchema {
  primary: string
  secondary?: string
  disabled?: string
}

export interface BackgroundSchema {
  default: string
  paper?: string
}

export type PaletteSchema = Pick<
  Palette,
  'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success' | 'text' | 'background'
>

export const defaultPaletteSimple = {
  primary: {
    main: '#002c38'
  },
  secondary: {
    main: '#0060AB'
  },
  error: {
    main: '#EA4274'
  },
  info: {
    main: '#44ADFF'
  },
  warning: {
    main: '#FF993F'
  },
  success: {
    main: '#76D275'
  },
  text: {
    primary: '#002c38'
  },
  background: {
    default: '#ffffff'
  }
}

export const defaultPalette: PaletteSchema = {
  primary: {
    main: '#002c38',
    dark: '#00563F',
    light: '#76D275',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#0060AB',
    dark: '#002C38',
    light: '#3096E5',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#EA4274',
    dark: '#AC234C',
    light: '#FF97B6',
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#44ADFF',
    dark: '#0B73C3',
    light: '#4FC3F7',
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#FF993F',
    dark: '#DF6901',
    light: '#FFC593',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#76D275',
    dark: '#43A047',
    light: '#99F994',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#002c38',
    secondary: '#80959B',
    disabled: '#B3C0C3'
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff'
  }
}

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'overline'

export type TypographySchema = Pick<
  TypographyVariants,
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'overline'
>

export const defaultTypography: TypographySchema = {
  h1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: 1.167,
    letterSpacing: '-0.01562em'
  },
  h2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    fontSize: '3.75rem',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em'
  },
  h3: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: 1.167,
    letterSpacing: '0em'
  },
  h4: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em'
  },
  h5: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em'
  },
  h6: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  subtitle1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em'
  },
  subtitle2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em'
  },
  body1: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  body2: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  button: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  },
  caption: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  overline: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase'
  }
}
