import React from 'react'
import { Box, Divider, ListItem, ListItemText } from '@mui/material'
import AddElementsMenu from 'Components/Common/Editing/AddElementsMenu'
import ContentBox from 'Components/Common/Editing/ContentBox'
import TextField from 'Components/Common/TextField'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import ReactMarkdown from 'react-markdown'

const NavigatorSection: React.FC = () => {
  const [prefixElements, setPrefixElements] = React.useState<React.ReactElement>()
  const [markdownValue, setMarkdownValue] = React.useState<string>('')
  const [dialogButtons, setDialogButtons] = React.useState({
    primaryVisible: false,
    secondaryVisible: false
  })

  const handlePrefixElement = (prefixElements: React.ReactElement | undefined) => {
    setPrefixElements(
      prefixElements ? undefined : (
        <>
          <SmallSelect label="Type" value={'Icon'} options={[{ value: 'Icon', label: 'Icon' }]} />
        </>
      )
    )
  }

  const handleDialogButtons = () => {
    if (!dialogButtons.primaryVisible) {
      setDialogButtons({ ...dialogButtons, primaryVisible: true })
    } else if (!dialogButtons.secondaryVisible) {
      setDialogButtons({ ...dialogButtons, secondaryVisible: true })
    }
  }

  const handleClickRemoveDialogButton = (
    e: React.MouseEvent<HTMLButtonElement>,
    type: 'primary' | 'secondary'
  ) => {
    e.stopPropagation()
    if (type === 'primary') {
      setDialogButtons({ ...dialogButtons, primaryVisible: false })
    }
    if (type === 'secondary') {
      setDialogButtons({ ...dialogButtons, secondaryVisible: false })
    }
  }

  return (
    <>
      <ListItem>
        <ListItemText
          primary="Navigator"
          primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
        />
      </ListItem>
      <Divider />
      <Box>
        <AddElementsMenu
          label="Prefix"
          action={{
            type: prefixElements ? 'remove' : 'add',
            onClick: () => handlePrefixElement(prefixElements)
          }}
        >
          {prefixElements}
        </AddElementsMenu>
        <AddElementsMenu
          label="Content"
          action={{
            type: !dialogButtons.primaryVisible || !dialogButtons.secondaryVisible ? 'add' : null,
            onClick: handleDialogButtons
          }}
        >
          <ContentBox
            style={dialogButtons.primaryVisible ? {} : { display: 'none' }}
            primaryText="Continue"
            secondaryText="Primary button"
            collapsible
            onClickRemove={e => handleClickRemoveDialogButton(e, 'primary')}
          >
            <TextField label="Label" value="Continue" />
            <SmallSelect
              label="Type"
              value={'Primary'}
              options={[{ value: 'Primary', label: 'Primary' }]}
            />
            <SmallSelect label="Icon" />
          </ContentBox>
          <ContentBox
            style={dialogButtons.secondaryVisible ? {} : { display: 'none' }}
            primaryText="Cancel"
            secondaryText="Secondary button"
            collapsible
            onClickRemove={e => handleClickRemoveDialogButton(e, 'secondary')}
          >
            <TextField label="Label" value="Cancel" />
            <SmallSelect
              label="Type"
              value={'Secondary'}
              options={[{ value: 'Secondary', label: 'Secondary' }]}
            />
            <SmallSelect label="Icon" />
          </ContentBox>
        </AddElementsMenu>
        <TextField
          value={markdownValue}
          onChange={e => setMarkdownValue(e.target.value)}
          helperText="Markdown"
          multiline
        />
        <div style={{ margin: '8px 16px' }}>
          <ReactMarkdown>{markdownValue}</ReactMarkdown>
        </div>
      </Box>
    </>
  )
}

export default NavigatorSection
