import React, { useState } from 'react'
import { useTypedSelector } from 'Store'
import { UqRadio, UqRadioProps, UqFormControlLabel, UqRadioGroup } from '@uniqore/wrapper'
import { UqRadioPropsExtended } from 'Util/CanvasComponents'
import { handleOptions } from '../ComponentRender'
import { ScreenComponent } from 'Features/canvas'

interface UqRadioRenderProps {
  component: ScreenComponent
}

const UqRadioRender: React.FC<UqRadioRenderProps> = ({ component }) => {
  const { previewOn } = useTypedSelector(state => state.preview)
  const [value, setValue] = useState('')

  const props = component.props as UqRadioProps & UqRadioPropsExtended

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  return (
    <UqRadioGroup
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      value={previewOn ? value : undefined}
      onChange={previewOn ? handleChange : undefined}
      style={{ ...component.style }}
    >
      {handleOptions(props.options).map((option, i) => (
        <UqFormControlLabel
          key={i}
          control={
            <UqRadio
              checked={!previewOn ? props.checked : undefined}
              size={props.size}
              color={props.color}
              disabled={props.disabled}
            />
          }
          value={option}
          label={option}
        />
      ))}
    </UqRadioGroup>
  )
}

export default UqRadioRender
