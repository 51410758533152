import React, { useState, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuList,
  Typography
} from '@mui/material'
import Icons from 'Components/Common/Icons'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { handleUpdatedAtDate, MenuItemStyled, ListItemIconStyled } from './DesignCard'
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg'
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg'
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg'
import { serverTimestamp } from 'firebase/firestore'
import { setSelectedTheme } from 'Features/workspaceView'
import { Document, ThemeSchema, WorkspaceSchema } from 'types/firebase'

const ChipStyled = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  '& > svg': {
    width: 24,
    height: 24
  },
  '& > svg > path': {
    fill: theme.custom.iconColor
  }
}))

// function is exported here since it is also used in WorkspaceAppBar-component
export const handleThemeDelete = (
  setDocumentForDialogs: Dispatch<
    SetStateAction<{ document: Document; type: 'theme' | 'design' } | undefined>
  >,
  setRemoveThemeDialogOpen: Dispatch<SetStateAction<boolean>>,
  theme: ThemeSchema,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  setDocumentForDialogs({ document: theme, type: 'theme' })
  setRemoveThemeDialogOpen(true)
  handleCloseThemeMenu(setAnchorEl)
}

// function is exported here since it is also used in WorkspaceAppBar-component
export const handleThemeDuplicate = async (
  theme: ThemeSchema,
  createTheme: (theme: Omit<ThemeSchema, 'id'>) => Promise<void>,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  handleCloseThemeMenu(setAnchorEl)
  // "id" here removes the id from the new copied theme
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...duplicateTheme } = theme
  duplicateTheme.title = `${theme.title} copy`
  duplicateTheme.updatedAt = serverTimestamp() as unknown as string
  duplicateTheme.createdAt = serverTimestamp() as unknown as string
  createTheme(duplicateTheme)
}

// function is exported here since it is also used in WorkspaceAppBar-component
export const handleThemeChangeFolder = (
  setDocumentForDialogs: Dispatch<
    SetStateAction<{ document: Document; type: 'theme' | 'design' } | undefined>
  >,
  setChangeFolderDialogOpen: Dispatch<SetStateAction<boolean>>,
  theme: ThemeSchema,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  setDocumentForDialogs({ document: theme, type: 'theme' })
  setChangeFolderDialogOpen(true)
  handleCloseThemeMenu(setAnchorEl)
}

// function is exported here since it is also used in WorkspaceAppBar-component
export const handleResetTheme = (
  setDocumentForDialogs: Dispatch<
    SetStateAction<{ document: Document; type: 'theme' | 'design' } | undefined>
  >,
  setResetThemeDialogOpen: Dispatch<SetStateAction<boolean>>,
  theme: ThemeSchema,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  setDocumentForDialogs({ document: theme, type: 'theme' })
  setResetThemeDialogOpen(true)
  handleCloseThemeMenu(setAnchorEl)
}

// function is exported here since it is also used in WorkspaceAppBar-component
export const handleCloseThemeMenu = (setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>) => {
  setAnchorEl(null)
}

const BoxStyled = styled(Box)<{
  cardsizelarge: number // boolean causes console warning
}>(({ cardsizelarge }) => ({
  width: cardsizelarge ? '24px' : '8px',
  height: cardsizelarge ? '24px' : '8px',
  border: '1px solid #E6EAEB',
  borderRadius: cardsizelarge ? '4px' : '1px',
  margin: cardsizelarge ? '2px' : '1px'
}))

interface ThemeCardProps {
  workspace: WorkspaceSchema
  theme: ThemeSchema
  setDocumentForDialogs?: Dispatch<
    SetStateAction<{ document: Document; type: 'design' | 'theme' } | undefined>
  >
  setRemoveThemeDialogOpen?: Dispatch<SetStateAction<boolean>>
  setResetThemeDialogOpen?: Dispatch<SetStateAction<boolean>>
  cardSize?: 'large' | 'small'
  setChangeFolderDialogOpen?: Dispatch<SetStateAction<boolean>>
}

const ThemeCard: React.FC<ThemeCardProps> = ({
  workspace,
  theme,
  setDocumentForDialogs,
  setRemoveThemeDialogOpen,
  setResetThemeDialogOpen,
  cardSize = 'large',
  setChangeFolderDialogOpen
}) => {
  const dispatch = useDispatch()
  const { createTheme } = useThemes(workspace.id)
  const [isHover, setIsHover] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClickCard = () => {
    if (cardSize === 'large') {
      dispatch(setSelectedTheme({ theme }))
    }
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <ThemeMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={() => handleCloseThemeMenu(setAnchorEl)}
        handleThemeDelete={
          setDocumentForDialogs && setRemoveThemeDialogOpen
            ? () =>
                handleThemeDelete(
                  setDocumentForDialogs,
                  setRemoveThemeDialogOpen,
                  theme,
                  setAnchorEl
                )
            : undefined
        }
        handleThemeDuplicate={
          () => handleThemeDuplicate(theme, createTheme, setAnchorEl)
          //handleThemeDuplicate(workspace, theme, firestore, setAnchorEl, dispatch)
        }
        handleThemeChangeFolder={
          setDocumentForDialogs && setChangeFolderDialogOpen
            ? () =>
                handleThemeChangeFolder(
                  setDocumentForDialogs,
                  setChangeFolderDialogOpen,
                  theme,
                  setAnchorEl
                )
            : undefined
        }
        handleResetTheme={
          setDocumentForDialogs && setResetThemeDialogOpen
            ? () =>
                handleThemeChangeFolder(
                  setDocumentForDialogs,
                  setResetThemeDialogOpen,
                  theme,
                  setAnchorEl
                )
            : undefined
        }
      />
      <Card
        onClick={handleClickCard}
        sx={{
          width: cardSize === 'large' ? 276 : 114,
          height: cardSize === 'large' ? 276 : 78,
          cursor: 'pointer'
        }}
      >
        <CardMedia
          sx={{
            position: 'relative',
            height: cardSize === 'large' ? '204px' : '78px',
            backgroundColor: theme.palette.background.default
          }}
        >
          {cardSize === 'large' && (
            <ChipStyled
              color="primary"
              size="small"
              icon={<PaletteIcon />}
              label={
                <Typography variant="body2" color="common.white">
                  Theme
                </Typography>
              }
            />
          )}
          {React.createElement(Icons['TextFieldsRounded'], {
            style: {
              color: theme.palette.text.primary,
              position: 'absolute',
              left: '50%',
              top: '42%',
              transform: 'translate(-50%, -50%)',
              fontSize: cardSize === 'large' ? '100px' : '30px'
            }
          })}
          <Box
            sx={{
              position: 'absolute',
              bottom: cardSize === 'large' ? '42px' : '16px',
              display: 'flex',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            <BoxStyled
              cardsizelarge={cardSize === 'large' ? 1 : 0}
              sx={{ backgroundColor: theme.palette.primary.main }}
            />
            <BoxStyled
              cardsizelarge={cardSize === 'large' ? 1 : 0}
              sx={{ backgroundColor: theme.palette.secondary.main }}
            />
            <BoxStyled
              cardsizelarge={cardSize === 'large' ? 1 : 0}
              sx={{ backgroundColor: theme.palette.text.primary }}
            />
            <BoxStyled
              cardsizelarge={cardSize === 'large' ? 1 : 0}
              sx={{ backgroundColor: theme.palette.background.default }}
            />
          </Box>
        </CardMedia>
        {cardSize === 'large' && (
          <CardContent
            component="div"
            sx={{ padding: '0px !important', borderTop: '1px solid #E6EAEB' }}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={handleClickMenu}
                  sx={{ visibility: isHover ? 'visible' : 'hidden' }}
                  edge="end"
                >
                  <MoreVertIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={theme.title}
                secondary={handleUpdatedAtDate(theme.updatedAt)}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{
                  sx: {
                    color: theme.updatedAt ? theme => theme.palette.text.secondary : 'transparent'
                  }
                }}
              />
            </ListItem>
          </CardContent>
        )}
      </Card>
    </div>
  )
}

export const ThemeMenu: React.FC<{
  open: boolean
  anchorEl: null | HTMLElement
  position?: 'top' | 'bottom'
  handleClose: () => void
  handleThemeDelete?: () => void
  handleThemeDuplicate: () => Promise<void>
  handleThemeChangeFolder?: () => void
  handleResetTheme?: () => void
}> = ({
  open,
  anchorEl,
  position = 'top',
  handleClose,
  handleThemeDelete,
  handleThemeDuplicate,
  handleThemeChangeFolder,
  handleResetTheme
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: position === 'top' ? '-12px' : '80px'
      }}
      anchorOrigin={{
        vertical: position,
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      PaperProps={{
        elevation: 8,
        sx:
          position === 'top'
            ? {
                overflow: 'visible',
                width: 200,
                color: 'white',
                backgroundColor: theme => theme.palette.secondary.dark,
                '&:after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: theme => theme.palette.secondary.dark,
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            : {
                overflow: 'visible',
                width: 200,
                color: 'white',
                backgroundColor: theme => theme.palette.secondary.dark,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: theme => theme.palette.secondary.dark,
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px' }}>
        <MenuItemStyled onClick={handleThemeDelete}>
          <ListItemIconStyled>
            <RemoveIcon />
          </ListItemIconStyled>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={handleThemeDuplicate}>
          <ListItemIconStyled>
            <CopyIcon />
          </ListItemIconStyled>
          <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={handleThemeChangeFolder}>
          <ListItemIconStyled>
            <FolderIcon />
          </ListItemIconStyled>
          <ListItemText primary="Move to folder" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={handleResetTheme}>
          <ListItemIconStyled>
            <ResetIcon />
          </ListItemIconStyled>
          <ListItemText
            primary="Reset to default theme"
            primaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}

export default ThemeCard
