import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import FirebaseInit from 'Components/Layout/FirebaseInit'
import ProtoView from 'Components/Layout/ProtoView'

const App: React.FC = () => {
  let element

  if (process.env.REACT_APP_IS_LOCAL === 'true') {
    element = <FirebaseInit isLocal={true} />
  } else {
    if (window.location.hostname === process.env.REACT_APP_DESIGN_STUDIO_DOMAIN) {
      element = <FirebaseInit isLocal={false} />
    } else {
      element = (
        <Router>
          <Routes>
            <Route path="/:sharelink" element={<ProtoView />} />
          </Routes>
        </Router>
      )
    }
  }

  return element
}
export { App }
export default App
