import React from 'react'
import Icons from 'Components/Common/Icons'
import { UqListItemIcon, UqListItemIconProps } from '@uniqore/wrapper'
import { UqListItemIconPropsExtended } from 'Util/CanvasComponents'

interface UqListItemIconRenderProps {
  componentProps: UqListItemIconProps & UqListItemIconPropsExtended
}

const UqListItemIconRender: React.FC<UqListItemIconRenderProps> = ({ componentProps }) => {
  return (
    <UqListItemIcon>
      {componentProps.icon ? React.createElement(Icons[componentProps.icon]) : null}
    </UqListItemIcon>
  )
}

export default UqListItemIconRender
