import { createTheme } from '@mui/material/styles'
import shadows from '@mui/material/styles/shadows'

const theme = createTheme({
  palette: {
    primary: {
      main: '#43A047',
      light: '#76D275',
      dark: '#00563F'
    },
    secondary: {
      main: '#0060AB',
      light: '#3096E5',
      dark: '#002C38'
    },
    error: {
      main: '#EA4274',
      light: '#FF97B6',
      dark: '#AC234C'
    },
    info: {
      main: '#44ADFF',
      light: '#4FC3F7',
      dark: '#0B73C3'
    },
    warning: {
      main: '#FF993F',
      light: '#FFC593',
      dark: '#DF6901'
    },
    success: {
      main: '#76D275',
      light: '#99F994',
      dark: '#43A047'
    },
    common: {
      black: '#212121',
      white: '#FFFFFF'
    },
    text: {
      primary: '#1A414C',
      secondary: '#80959B',
      disabled: '#B3C0C3'
    }
  },
  typography: {
    h4: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 400
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '0.75rem'
    },
    button: {
      textTransform: 'none'
    }
  },
  shadows: {
    ...shadows,
    '8': '0px 8px 12px rgba(0, 44, 56, 0.08)',
    '24': '0px 36px 46px rgba(0, 44, 56, 0.12)'
  },
  props: {
    navBarHeight: '56px',
    leftSidebar: '316px'
  },
  custom: {
    borderColor: '#E6EAEB',
    iconColor: '#E6ECEE',
    desertNight: '#004558',
    appleGreen: {
      '40': '#F7FBF8',
      '100': '#ECF6ED',
      '300': '#C7E3C8',
      '750': '#72B875',
      '900': '#56A959'
    },
    nightBlue: {
      '40': '#F5F7F7'
    },
    pastelGreen: {
      '750': '#98DD97'
    }
  }
})

declare module '@mui/material/styles' {
  interface Theme {
    props: {
      navBarHeight: string
      leftSidebar: string
    }
    custom: {
      borderColor: string
      iconColor: string
      desertNight: string
      appleGreen: {
        '40': string
        '100': string
        '300': string
        '750': string
        '900': string
      }
      nightBlue: {
        '40': string
      }
      pastelGreen: {
        '750': string
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    props: {
      navBarHeight: string
      leftSidebar: string
    }
    custom: {
      borderColor: string
      iconColor: string
      desertNight: string
      appleGreen: {
        '40': string
        '100': string
        '300': string
        '750': string
        '900': string
      }
      nightBlue: {
        '40': string
      }
      pastelGreen: {
        '750': string
      }
    }
  }
}

export default theme
