import React, { useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { doc, onSnapshot } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { updateTheme } from 'Features/firebaseDocuments'
import { ThemeSchema } from 'types/firebase'

interface ThemesSubscriptionProps {
  themeId: string
  workspaceId: string
}

const ThemesSubscription: React.FC<ThemesSubscriptionProps> = ({ themeId, workspaceId }) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, 'Workspaces', workspaceId, 'Themes', themeId),
      theme => {
        if (theme.metadata.hasPendingWrites) return
        if (!theme.data()) return
        if (theme.data()?.deleted) return
        const handleTheme: ThemeSchema = {
          id: theme.id,
          title: theme.data()?.title || '',
          folder: theme.data()?.folder || '',
          createdAt: theme.data()?.createdAt
            ? moment(theme.data()?.createdAt.toDate()).toISOString(true)
            : undefined,
          updatedAt: theme.data()?.updatedAt
            ? moment(theme.data()?.updatedAt.toDate()).toISOString(true)
            : undefined,
          palette: theme.data()?.palette,
          typography: theme.data()?.typography
        }
        dispatch(updateTheme({ theme: handleTheme }))
      }
    )
    return () => unsub()
  }, [])

  return null
}

export default React.memo(ThemesSubscription)
