import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  useTheme
} from '@mui/material'
import Tooltip from 'Components/Common/Tooltip'
import { usePreview } from 'Components/Layout/Preview/Hooks/PreviewHooks'
import CenterBar from './CenterBar'
import UserMenu from './User/UserMenu'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { editLeftSidebar, LeftSidebarSection } from 'Features/layout'
import { setSelectedDesign, setSelectedTheme, editFolderView } from 'Features/workspaceView'
import { openShareDialog } from 'Features/share'
import { selectScreen, selectComponent } from 'Features/canvasScreens'
import { ReactComponent as UniqoreLogo } from 'assets/images/uniqore-logo.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as BoltIcon } from 'assets/icons/bolt.svg'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: '56px',
  width: '56px',
  borderRadius: 0,
  cursor: 'pointer',
  '& > svg > path': {
    fill: theme.custom.iconColor
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& > svg > path': {
      fill: theme.palette.primary.light
    }
  }
}))

const getInitials = (firstName?: string, lastName?: string) => {
  const first = firstName || ''
  const last = lastName || ''
  const firstInitial = first.charAt(0).toUpperCase()
  const lastInitial = last.charAt(0).toUpperCase()
  return `${firstInitial}${lastInitial}`.trim()
}

const NavBar: React.FC<{ setLogoutDialogOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setLogoutDialogOpen
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { openPreview } = usePreview()
  const { firstName, lastName } = useTypedSelector(state => state.loggedInUser)
  const { leftSidebar } = useTypedSelector(state => state.layout)
  const { selectedDesign, workspace } = useTypedSelector(state => state.workspaceView)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const screens = useTypedSelector(state => state.undoables.present.canvasScreens.screens)
  const theme = useTheme()

  const userMenuOpen = Boolean(anchorEl)

  const handleLeftSideIconClick = (type: LeftSidebarSection) => () => {
    const setValue = leftSidebar.section === type ? !leftSidebar.open : true
    dispatch(
      editLeftSidebar({
        stateKey: 'open',
        value: setValue
      })
    )
    dispatch(editLeftSidebar({ stateKey: 'section', value: setValue ? type : '' }))
  }

  const handleClickLogo = () => {
    navigate('/')
    dispatch(editLeftSidebar({ stateKey: 'open', value: false }))
    dispatch(setSelectedTheme({ theme: undefined }))
    dispatch(setSelectedDesign({ design: undefined }))
    dispatch(editFolderView({ folder: '' }))
  }

  const handleClickPreview = () => {
    dispatch(selectScreen({ screenId: null }))
    dispatch(selectComponent({ component: null }))
    dispatch(
      editLeftSidebar({
        stateKey: 'open',
        value: false
      })
    )
    openPreview(screens)
  }

  return (
    <AppBar elevation={0} sx={{ backgroundColor: theme => theme.palette.secondary.dark }}>
      <Toolbar
        disableGutters
        sx={{
          '@media (min-width: 600px)': {
            minHeight: theme => theme.props.navBarHeight
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <UniqoreLogo onClick={handleClickLogo} style={{ cursor: 'pointer' }} />
            <Box sx={{ display: 'flex', borderLeft: '1px solid rgba(255,255,255, 0.12)' }}>
              {selectedDesign && (
                <>
                  <Tooltip title="Add" sx={{ top: '-8px !important' }}>
                    <IconButtonStyled
                      onClick={handleLeftSideIconClick('add')}
                      disableFocusRipple
                      disableTouchRipple
                      sx={
                        leftSidebar.open && leftSidebar.section === 'add'
                          ? {
                              backgroundColor: theme => theme.custom.appleGreen[900],
                              '&:hover': {
                                backgroundColor: theme => theme.custom.appleGreen[900]
                              }
                            }
                          : {}
                      }
                    >
                      <AddIcon />
                    </IconButtonStyled>
                  </Tooltip>
                  <Tooltip title="Edit" sx={{ top: '-8px !important' }}>
                    <IconButtonStyled
                      onClick={handleLeftSideIconClick('edit')}
                      disableFocusRipple
                      disableTouchRipple
                      sx={
                        leftSidebar.open && leftSidebar.section === 'edit'
                          ? {
                              backgroundColor: theme => theme.custom.appleGreen[900],
                              '&:hover': {
                                backgroundColor: theme => theme.custom.appleGreen[900]
                              }
                            }
                          : {}
                      }
                    >
                      <EditIcon />
                    </IconButtonStyled>
                  </Tooltip>
                  <Tooltip title="Prototype" sx={{ top: '-8px !important' }}>
                    <IconButtonStyled
                      onClick={handleLeftSideIconClick('prototype')}
                      disableFocusRipple
                      disableTouchRipple
                      sx={
                        leftSidebar.open && leftSidebar.section === 'prototype'
                          ? {
                              backgroundColor: theme => theme.custom.appleGreen[900],
                              '&:hover': {
                                backgroundColor: theme => theme.custom.appleGreen[900]
                              }
                            }
                          : {}
                      }
                    >
                      <BoltIcon />
                    </IconButtonStyled>
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
          <Box>
            {workspace && selectedDesign && (
              <CenterBar workspace={workspace} selectedDesign={selectedDesign} />
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedDesign && Object.keys(screens).length > 0 && (
              <>
                <Tooltip title="Preview" sx={{ top: '2px !important' }}>
                  <Button
                    sx={{ color: theme.palette.common.white, marginRight: '16px' }}
                    disableFocusRipple
                    disableTouchRipple
                    onClick={handleClickPreview}
                  >
                    Preview
                  </Button>
                </Tooltip>
                <Tooltip title="Share" sx={{ top: '2px !important' }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                      marginRight: '16px',
                      background: theme.palette.secondary.light,
                      '&:hover': {
                        background: theme.palette.secondary.main
                      }
                    }}
                    onClick={() => dispatch(openShareDialog({ value: true }))}
                  >
                    Share
                  </Button>
                </Tooltip>
              </>
            )}

            <Tooltip title="Profile" sx={{ top: '8px !important' }}>
              <Avatar
                component="button"
                onClick={(
                  e: React.BaseSyntheticEvent<
                    MouseEvent,
                    EventTarget & HTMLButtonElement,
                    EventTarget
                  >
                ) => setAnchorEl(e.currentTarget)}
                sx={{
                  cursor: 'pointer',
                  marginRight: '16px',
                  width: 32,
                  height: 32,
                  bgcolor: theme => theme.custom.appleGreen[900],
                  borderColor: 'transparent'
                }}
              >
                {getInitials(firstName, lastName) ? (
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {getInitials(firstName, lastName)}
                  </Typography>
                ) : (
                  <PersonOutlineIcon />
                )}
              </Avatar>
            </Tooltip>
            <UserMenu
              open={userMenuOpen}
              anchorEl={anchorEl}
              handleClose={() => setAnchorEl(null)}
              logoutOnClick={() => setLogoutDialogOpen(true)}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
