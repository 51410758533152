import { useEffect, useRef, useCallback } from 'react'
import { ActionCreators } from 'redux-undo'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { ScreenConnection, ScreenComponent, handleCanvasShortcuts } from 'Features/canvas'
import {
  deleteScreen,
  deleteComponent,
  selectScreen,
  selectComponent,
  deleteScreenConnection
} from 'Features/canvasScreens'
import { setCurrentScreen, togglePreview } from 'Features/preview'
import { useChildComponent } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Components/ChildComponentHooks'

export const useKeyboardShortcuts = () => {
  const dispatch = useDispatch()
  const { deleteChildComponent } = useChildComponent()

  const { showEdges, freeMove } = useTypedSelector(state => state.canvas)
  const {
    selectedEdgeId,
    selectedScreenId,
    selectedComponentId,
    stagedComponentId,
    parentComponent
  } = useTypedSelector(state => state.undoables.present.canvasScreens)

  // Preview related stuff
  const loggedInUserId = useTypedSelector(state => state.loggedInUser.id)
  const protoDocumentData = useTypedSelector(state => state.share.protoDocumentData)
  const canvasScreens = useTypedSelector(state => state.undoables.present.canvasScreens)
  const screens = protoDocumentData ? protoDocumentData.screens : canvasScreens.screens
  const currentScreen = useTypedSelector(state => state.preview.currentScreen)
  let sourceId: string | null = null
  let connections: ScreenConnection[] = []
  if (screens && currentScreen) {
    const cScreen = screens[currentScreen]
    if (cScreen !== undefined) {
      sourceId = screens[currentScreen].sourceId
      connections = screens[currentScreen].connections
    }
  }

  // Refs store the latest data from reducers
  const canvasHandle = useRef<{ showEdges: boolean; freeMove: boolean }>()
  const screenHandle = useRef<{
    selectedEdgeId: string | null
    selectedScreenId: string | null
    selectedComponentId: string | null
    stagedComponentId: string | null
    parentComponent: ScreenComponent | null
  }>()
  const previewHandle = useRef<{
    sourceId: string | null
    connections: ScreenConnection[]
    loggedInUserId: string
  }>()

  // Latest changes from reducer are updated here to ref, this is done so that the functions
  // receive the latest data from refs
  useEffect(() => {
    previewHandle.current = {
      sourceId,
      connections,
      loggedInUserId
    }
  }, [sourceId, connections, loggedInUserId])

  // Latest changes from reducer are updated here to ref, this is done so that the functions
  // receive the latest data from refs
  useEffect(() => {
    canvasHandle.current = {
      showEdges,
      freeMove
    }
  }, [showEdges, freeMove])

  // Latest changes from reducer are updated here to ref, this is done so that the functions
  // receive the latest data from refs
  useEffect(() => {
    screenHandle.current = {
      selectedEdgeId,
      selectedScreenId,
      selectedComponentId,
      stagedComponentId,
      parentComponent
    }
  }, [selectedEdgeId, selectedScreenId, selectedComponentId, stagedComponentId, parentComponent])

  const canvasKeys = useCallback((event: KeyboardEvent) => {
    if (event.shiftKey && (event.key === 'f' || event.key === 'F')) {
      dispatch(handleCanvasShortcuts({ key: 'showEdges', value: !canvasHandle.current?.showEdges }))
    } else if (event.shiftKey && (event.key === 'g' || event.key === 'G')) {
      dispatch(handleCanvasShortcuts({ key: 'freeMove', value: !canvasHandle.current?.freeMove }))
    }
  }, [])

  const undoRedoKeys = useCallback((event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      dispatch(ActionCreators.undo())
    } else if (
      ((event.ctrlKey || event.metaKey) && event.key === 'y') ||
      ((event.ctrlKey || event.metaKey) &&
        event.shiftKey &&
        (event.key === 'Z' || event.key === 'z'))
    ) {
      dispatch(ActionCreators.redo())
    }
  }, [])

  const previewKeys = useCallback((event: KeyboardEvent) => {
    if (previewHandle.current) {
      const { loggedInUserId, sourceId, connections } = previewHandle.current
      switch (event.key) {
        case 'Escape': {
          if (loggedInUserId !== '') {
            dispatch(togglePreview({ togglePreview: false }))
          }
          break
        }
        case 'ArrowLeft': {
          if (sourceId) {
            dispatch(
              setCurrentScreen({
                currentScreen: sourceId
              })
            )
          }
          break
        }
        case 'ArrowRight': {
          if (connections.length > 0) {
            dispatch(
              setCurrentScreen({
                currentScreen: connections[0].target
              })
            )
          }
          break
        }
      }
    }
  }, [])

  const removeKeys = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (screenHandle.current?.selectedEdgeId) {
        dispatch(deleteScreenConnection({ edgeId: screenHandle.current.selectedEdgeId }))
      } else if (
        (screenHandle.current?.selectedScreenId && screenHandle.current.selectedComponentId) ||
        (screenHandle.current?.selectedScreenId && screenHandle.current.stagedComponentId)
      ) {
        const { selectedScreenId, selectedComponentId, stagedComponentId, parentComponent } =
          screenHandle.current
        if (!parentComponent) {
          dispatch(selectComponent({ component: null }))
          dispatch(
            deleteComponent({
              screenId: selectedScreenId,
              componentId: stagedComponentId || (selectedComponentId as string)
            })
          )
        } else {
          dispatch(selectComponent({ component: parentComponent }))
          deleteChildComponent(
            selectedScreenId,
            parentComponent,
            stagedComponentId || (selectedComponentId as string),
            undefined
          )
        }
      } else if (
        screenHandle.current?.selectedScreenId &&
        !screenHandle.current?.selectedComponentId &&
        !screenHandle.current.stagedComponentId
      ) {
        dispatch(selectScreen({ screenId: null }))
        dispatch(selectComponent({ component: null }))
        dispatch(deleteScreen({ screenId: screenHandle.current.selectedScreenId }))
      }
    }
  }, [])

  // Canvas keys
  const listenCanvasKeys = () => {
    document.addEventListener('keydown', canvasKeys)
  }
  const removeListenerCanvasKeys = () => {
    document.removeEventListener('keydown', canvasKeys)
  }

  // Undo/redo keys
  const listenUndoRedoKeys = () => {
    document.addEventListener('keydown', undoRedoKeys)
  }
  const removeListenerUndoRedoKeys = () => {
    document.removeEventListener('keydown', undoRedoKeys)
  }

  // Preview keys
  const listenPreviewKeys = () => {
    document.addEventListener('keydown', previewKeys)
  }
  const removeListenerPreviewKeys = () => {
    document.removeEventListener('keydown', previewKeys)
  }

  // Delete / Backspace keys
  const listenRemoveKeys = () => {
    document.addEventListener('keydown', removeKeys)
  }
  const removeListenerRemoveKeys = () => {
    document.removeEventListener('keydown', removeKeys)
  }

  return {
    listenCanvasKeys,
    removeListenerCanvasKeys,
    listenUndoRedoKeys,
    removeListenerUndoRedoKeys,
    listenPreviewKeys,
    removeListenerPreviewKeys,
    listenRemoveKeys,
    removeListenerRemoveKeys
  }
}
