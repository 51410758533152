import { createSlice } from '@reduxjs/toolkit'

interface Layout {
  leftSidebar: LeftSidebar
  rightSidebar: RightSidebar
}

export type LeftSidebarSection = 'add' | 'navigator' | 'edit' | 'branding' | 'prototype' | ''

interface LeftSidebar {
  open: boolean
  section: LeftSidebarSection
}

interface RightSidebar {
  open: boolean
}

// Actions
interface EditLeftSidebar {
  payload: {
    stateKey: keyof LeftSidebar
    value: boolean | LeftSidebarSection
  }
}

interface EditRightsideBar {
  payload: {
    stateKey: keyof RightSidebar
    value: boolean
  }
}
// Actions end

const initialState: Layout = {
  leftSidebar: {
    open: false,
    section: 'add'
  },
  rightSidebar: {
    open: false
  }
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    editLeftSidebar: (state, { payload }: EditLeftSidebar) => {
      const { stateKey, value } = payload
      if (stateKey === 'open') {
        state.leftSidebar.open = value as boolean
      }
      if (stateKey === 'section') {
        state.leftSidebar.section = value as LeftSidebarSection
      }
    },
    editRightsideBar: (state, { payload }: EditRightsideBar) => {
      const { stateKey, value } = payload
      if (stateKey === 'open') {
        state.rightSidebar.open = value
      }
    }
  }
})

export const { editLeftSidebar, editRightsideBar } = layoutSlice.actions
export default layoutSlice.reducer
