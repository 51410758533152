import React, { useEffect, useCallback, useRef } from 'react'
import debounce from 'lodash.debounce'
import equal from 'fast-deep-equal'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useScreens } from 'Components/Firebase/Hooks/FirebaseHooks'
import { setIsSaveableChangesMade } from 'Features/saveHelper'
import { firebaseDebounceTimer } from 'Util/Helpers'
import { DesignSchema } from 'types/firebase'

interface ScreensListenerProps {
  workspaceId: string
  selectedDesign: DesignSchema
}

const ScreensListener: React.FC<ScreensListenerProps> = ({ workspaceId, selectedDesign }) => {
  const dispatch = useDispatch()
  const listenScreensChanges = useRef(false)
  const isSaveableChangesMade = useTypedSelector(state => state.saveHelper.isSaveableChangesMade)
  const { past, present } = useTypedSelector(state => state.undoables)
  const screens = useTypedSelector(state => state.undoables.present.canvasScreens.screens)
  const selectedDesignScreens = useTypedSelector(
    state => state.firebaseDocuments.selectedDesignScreens
  )
  const { screensFetchedFromFirebase } = useTypedSelector(state => state.helpers)
  const { saveScreens } = useScreens(workspaceId)

  // Handles save-indicator in NavBar/CenterBar
  useEffect(() => {
    if (listenScreensChanges.current && !isSaveableChangesMade) {
      if (past.length > 0) {
        if (!equal(past[past.length - 1].canvasScreens.screens, present.canvasScreens.screens)) {
          dispatch(setIsSaveableChangesMade({ value: true }))
        }
      }
    }
  }, [past, present, screens])

  // Listen to screens in canvasScreen-reducer and when changes occur,
  // save these changes to firebase. Debounce resets timer everytime change happens
  useEffect(() => {
    if (screensFetchedFromFirebase) {
      if (!listenScreensChanges.current) {
        listenScreensChanges.current = true
      } else if (listenScreensChanges.current) {
        saveToFirebase(selectedDesign, screens, selectedDesignScreens)
      }
    }
  }, [screens, screensFetchedFromFirebase])

  useEffect(() => {
    if (!screensFetchedFromFirebase) {
      if (listenScreensChanges.current) {
        listenScreensChanges.current = false
      }
    }
  }, [screensFetchedFromFirebase])

  const saveToFirebase = useCallback(debounce(saveScreens, firebaseDebounceTimer), [])

  return null
}

export default React.memo(ScreensListener)
