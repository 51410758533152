import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqButton, UqButtonProps } from '@uniqore/wrapper'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'

interface UqButtonRenderProps {
  component: ScreenComponent
}

const UqButtonRender: React.FC<UqButtonRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const props = component.props as UqButtonProps

  return (
    <div style={{ ...component.style }}>
      <UqButton
        {...props}
        onClick={() => onClickAction(component.actions)}
        startIcon={props.startIcon ? React.createElement(Icons[props.startIcon as IconType]) : null}
        endIcon={props.endIcon ? React.createElement(Icons[props.endIcon as IconType]) : null}
      />
    </div>
  )
}

export default UqButtonRender
