import React, { useState, useEffect } from 'react'
import merge from 'deepmerge'
import { useTypedSelector } from 'Store'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { ButtonStyled } from '../../WorkspaceView'
import { useDesigns, useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { Document, WorkspaceSchema, DesignSchema, ThemeSchema } from 'types/firebase'
import { PaletteSchema } from 'Util/ThemeValues'

interface RemoveThemeDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentForDialogs: { document: Document; type: 'design' | 'theme' } | undefined
  designs: DesignSchema[]
  workspace: WorkspaceSchema
}

const RemoveThemeDialog: React.FC<RemoveThemeDialogProps> = ({
  open,
  setOpen,
  documentForDialogs,
  designs,
  workspace
}) => {
  const theme = useTheme()
  const { defaultTheme } = useTypedSelector(state => state.firebaseDocuments)
  const { updateDesign } = useDesigns(workspace.id)
  const { removeTheme, themeSaveLoading } = useThemes(workspace.id)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [designsWithThisTheme, setDesignsWithThisTheme] = useState<DesignSchema[]>([])

  useEffect(() => {
    if (open) {
      setDesignsWithThisTheme(designs.filter(v => v.themeId === documentForDialogs?.document.id))
      setDialogOpen(true)
    }
  }, [open])

  const handleRemoveTheme = (theme: ThemeSchema) => {
    if (designsWithThisTheme.length > 0) {
      designsWithThisTheme.forEach(design => {
        const designsLocalPalette = design.themeOverride?.palette || {}
        const designsLocalTypography = design.themeOverride?.typography || {}
        updateDesign(
          design,
          {
            themeId: defaultTheme?.id,
            themeOverride: {
              palette: merge(theme.palette, designsLocalPalette as PaletteSchema),
              typography: merge(theme.typography, designsLocalTypography)
            }
          },
          true
        )
      })
    }
    removeTheme(theme)
    setDialogOpen(false)
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false)
        setOpen(false)
      }}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <InfoIcon sx={{ color: theme => theme.palette.error.main, marginRight: '16px' }} />
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Are you sure?
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText component="div" variant="body1" color="text.secondary">
            Are you sure you want to delete this theme?{' '}
            {designsWithThisTheme.length > 0 && (
              <>
                All the deleted theme settings will be saved to projects as local changes. Following
                projects are linked with this theme:
                <ul>
                  {designsWithThisTheme.map(d => (
                    <li key={d.id} style={{ color: theme.palette.text.primary }}>
                      {d.title}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {themeSaveLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '70px'
              }}
            />
          ) : null}
          <ButtonStyled
            onClick={() => {
              setDialogOpen(false)
              setOpen(false)
            }}
            variant="text"
            color="secondary"
          >
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={() => handleRemoveTheme(documentForDialogs?.document as ThemeSchema)}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={themeSaveLoading}
          >
            Delete theme
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default RemoveThemeDialog
