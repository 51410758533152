import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import TextField from 'Components/Common/TextField'
import Select, { SelectOption } from 'Components/Common/Select'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg'
import { ButtonStyled } from '../../WorkspaceView'
import { serverTimestamp } from 'firebase/firestore'
import { WorkspaceSchema, ThemeSchema } from 'types/firebase'
import { defaultPaletteSimple, defaultTypography, PaletteSchema } from 'Util/ThemeValues'
import { setSelectedTheme } from 'Features/workspaceView'
import { documentTimestampsToString } from 'Components/Layout/WorkspaceView/Helpers/functions'

const IconStyled = styled('div')(({ theme }) => ({
  marginTop: '16px',
  marginBottom: '16px',
  '& > svg': {
    width: 36,
    height: 36
  },
  '& > svg > path': {
    fill: theme.palette.text.secondary
  }
}))

const getFolderOptions = (folders: string[]): SelectOption[] => {
  const folderOptions: SelectOption[] = [
    {
      label: 'All projects',
      value: 'none'
    }
  ]
  folders.forEach(folder => {
    folderOptions.push({
      label: folder,
      value: folder
    })
  })
  return folderOptions
}

interface NewThemeDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  folders: string[]
  workspace: WorkspaceSchema
}

const NewThemeDialog: React.FC<NewThemeDialogProps> = ({ open, setOpen, folders, workspace }) => {
  const dispatch = useDispatch()
  const { createTheme, themeSaveLoading, savedTheme } = useThemes(workspace.id)
  const [newThemeError, setThemeError] = useState<boolean>(false)
  const [newTheme, setTheme] = useState<{ folder: string; title: string }>({
    folder: 'none',
    title: ''
  })

  useEffect(() => {
    if (savedTheme) {
      setOpen(false)
      dispatch(
        setSelectedTheme({
          theme: documentTimestampsToString(savedTheme) as ThemeSchema
        })
      )
    }
  }, [savedTheme])

  const handleNewTheme = async () => {
    if (!newTheme.title) {
      setThemeError(true)
      return
    }
    if (newThemeError) {
      setThemeError(false)
    }
    const createNewTheme: Omit<ThemeSchema, 'id'> = {
      palette: defaultPaletteSimple as PaletteSchema,
      typography: defaultTypography,
      title: newTheme.title,
      folder: newTheme.folder === 'none' ? '' : newTheme.folder,
      updatedAt: serverTimestamp() as unknown as string,
      createdAt: serverTimestamp() as unknown as string
    }
    setTheme({
      folder: 'none',
      title: ''
    })
    createTheme(createNewTheme)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <Box sx={{ textAlign: 'center', marginTop: '24px', marginBottom: '16px' }}>
        <IconStyled>
          <PaletteIcon />
        </IconStyled>
        <Typography
          variant="h4"
          sx={{ color: theme => theme.palette.text.primary, marginTop: '8px' }}
        >
          New theme
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme => theme.palette.text.primary, marginTop: '8px' }}
        >
          Let’s configure some basic settings first.
        </Typography>
        <DialogContent sx={{ padding: '20px 140px' }}>
          <Select
            value={newTheme.folder}
            onChange={e => setTheme({ ...newTheme, folder: e.target.value })}
            label="Folder"
            options={getFolderOptions(folders)}
          />
          <TextField
            value={newTheme.title}
            onChange={e => setTheme({ ...newTheme, title: e.target.value })}
            label="Theme name"
            error={newThemeError}
            helperText={newThemeError ? 'This field cannot be empty.' : ''}
            style={{ paddingTop: '8px', paddingBottom: '8px' }}
          />
        </DialogContent>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {themeSaveLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '50px',
                width: '100%',
                textAlign: 'center'
              }}
            />
          ) : null}
          <ButtonStyled
            onClick={handleNewTheme}
            color="secondary"
            variant="contained"
            disableElevation
            disabled={themeSaveLoading}
          >
            Continue
          </ButtonStyled>
          <ButtonStyled
            onClick={() => setOpen(false)}
            color="secondary"
            variant="text"
            disableElevation
          >
            Cancel
          </ButtonStyled>
        </Box>
      </Box>
    </Dialog>
  )
}

export default NewThemeDialog
