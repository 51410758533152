import { DependencyList, useEffect, useRef } from 'react'

/**
 * This modified useEffect hook won't run on first render
 */
export const useDidMountEffect = (func: () => void, deps: DependencyList) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
  }, deps)
}
