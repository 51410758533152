import React from 'react'
import { styled, SxProps, Theme } from '@mui/material/styles'
import { default as MuiTooltip } from '@mui/material/Tooltip'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

// eslint-disable-next-line react/display-name
const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.custom.desertNight
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.custom.desertNight
  }
}))

interface Props {
  title: TooltipProps['title']
  children: React.ReactElement
  placement?: TooltipProps['placement']
  sx?: SxProps<Theme>
}

const Tooltip: React.FC<Props> = ({ title, children, placement = 'bottom', sx }) => {
  return (
    <TooltipStyled arrow title={title} placement={placement} sx={sx}>
      {children}
    </TooltipStyled>
  )
}

export default Tooltip
