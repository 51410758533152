import React, { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { UqTheme } from '@uniqore/theme'
import PreviewDialog from './PreviewDialog'
import ComponentRender from '../Canvas/ComponentRender'
import { ProtoDocumentData } from 'Components/Layout/ProtoView'
import { Layout } from '@uniqore/module'
import { useTheme, Theme } from '@mui/material'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import { setMode } from 'Features/preview'
import { SCREEN_HEIGHT, SCREEN_WIDTH } from 'Features/canvas'
import {
  CanvasScreen,
  ThemeSchema,
  ThemeOverride,
  DesignSchema,
  defaultLayoutPropValues
} from 'types/firebase'

const FakeMobileContainer = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '10vh',
  width: `${SCREEN_WIDTH}px`,
  minHeight: `${SCREEN_HEIGHT}px`,
  borderRadius: '20px',
  overflowX: 'hidden',
  overflowY: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

export const handleLayoutProps = (screen: CanvasScreen, design?: DesignSchema) => {
  if (screen.useLocalLayoutProps) {
    return screen.layoutProps || defaultLayoutPropValues
  }
  if (design && design.layoutProps) {
    return design.layoutProps
  }
  return defaultLayoutPropValues
}

interface PreviewProps {
  protoDocumentData?: ProtoDocumentData
}

const Preview: React.FC<PreviewProps> = ({ protoDocumentData }) => {
  // Ref is used to set Mui's Dialog-component inside the screen div
  const container = useRef(null)
  const { desktop, currentScreen } = useTypedSelector(state => state.preview)
  const theme = useTheme()
  const dispatch = useDispatch()

  const { themes, defaultTheme } = useTypedSelector(state => state.firebaseDocuments)
  const selectedDesign = useTypedSelector(state => state.workspaceView.selectedDesign)
  const [projectTheme, setProjectTheme] = useState<Theme>(UqTheme)
  const canvasScreens = useTypedSelector(state => state.undoables.present.canvasScreens)

  useEffect(() => {
    if (isMobile) {
      // Layout-component will handle true mobile-view. If desktop is set to false,
      // a 'fake' mobile-like-container is wrapped around Layout,
      // this is unwanted when user is on mobile.
      dispatch(setMode({ desktop: true }))
    }
  }, [isMobile])

  // Set theme
  useEffect(() => {
    let findTheme: ThemeSchema | undefined = undefined
    let themeOverride: ThemeOverride = {}
    if (protoDocumentData) {
      findTheme = protoDocumentData.theme
      themeOverride = protoDocumentData.design.themeOverride || {}
    } else {
      findTheme = [...themes, defaultTheme as ThemeSchema].find(
        t => t.id === selectedDesign?.themeId
      )
      themeOverride = selectedDesign?.themeOverride || {}
    }

    if (findTheme) {
      setProjectTheme(
        createTheme({
          palette: {
            ...findTheme.palette,
            ...themeOverride.palette
          },
          typography: {
            ...findTheme.typography,
            ...themeOverride.typography
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          wrappers: {
            // TODO: This border-radius & box-shadow should be adjustable in settings somewhere.
            uqButton: {
              sx: {
                borderRadius: '100px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }
            }
          }
        })
      )
    }
  }, [selectedDesign, protoDocumentData])

  const screens = protoDocumentData ? protoDocumentData.screens : canvasScreens.screens
  const screen: CanvasScreen = screens[currentScreen]

  const { headers, contents, footers } = screen.components

  return (
    <>
      <ThemeProvider theme={projectTheme}>
        {desktop ? (
          <Layout
            {...handleLayoutProps(
              screen,
              protoDocumentData ? protoDocumentData.design : selectedDesign
            )}
            ref={container}
            useCenteredView={true}
            top={
              headers.length > 0
                ? headers.map((component, index) => (
                    <ComponentRender key={index} screenId={screen.id} component={component} />
                  ))
                : null
            }
            bottom={
              footers.length > 0
                ? footers.map((component, index) => (
                    <ComponentRender key={index} screenId={screen.id} component={component} />
                  ))
                : null
            }
          >
            {contents.map((component, index) => (
              <ComponentRender
                key={index}
                screenId={screen.id}
                component={component}
                container={container}
              />
            ))}
          </Layout>
        ) : (
          // Mobile view (fake)
          <div
            style={{
              backgroundColor: theme.palette.text.primary,
              height: '100%',
              width: '100%'
            }}
          >
            <FakeMobileContainer>
              <Layout
                {...handleLayoutProps(
                  screen,
                  protoDocumentData ? protoDocumentData.design : selectedDesign
                )}
                ref={container}
                useCenteredView={false}
                maxWidth={false}
                paddingTop="24px"
                paddingBottom="42px"
                paddingLeft="16px"
                paddingRight="16px"
                top={
                  headers.length > 0
                    ? headers.map((component, index) => (
                        <ComponentRender key={index} screenId={screen.id} component={component} />
                      ))
                    : null
                }
                bottom={
                  footers.length > 0
                    ? footers.map((component, index) => (
                        <ComponentRender key={index} screenId={screen.id} component={component} />
                      ))
                    : null
                }
              >
                {contents.map((component, index) => (
                  <ComponentRender
                    key={index}
                    screenId={screen.id}
                    component={component}
                    container={container}
                  />
                ))}
              </Layout>
            </FakeMobileContainer>
          </div>
        )}
      </ThemeProvider>
      <PreviewDialog screens={screens} />
    </>
  )
}

export default Preview
