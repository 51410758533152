/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { ApolloLink } from '@apollo/client'

export const analyticsMiddleware = new ApolloLink((operation, forward) => {
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: operation.operationName,
    payload: operation.variables
  })
  return forward(operation)
})
