import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkspaceSchema, DesignSchema, ThemeSchema } from 'types/firebase'

interface WorkspaceViewSchema {
  workspace?: WorkspaceSchema
  folderView: string
  selectedDesign?: DesignSchema
  selectedTheme?: ThemeSchema
  sortBy: SortBy
  showFilter: ShowFilter
}

export type ShowFilter = 'All' | 'Projects' | 'Themes'
export type SortBy = 'Last modified' | 'Alphabetical' | 'Date created'

const initialState: WorkspaceViewSchema = {
  folderView: '',
  sortBy: 'Last modified',
  showFilter: 'All'
}

const workspaceViewSlice = createSlice({
  name: 'workspaceView',
  initialState,
  reducers: {
    editWorkspace: (state, action: PayloadAction<{ workspace: WorkspaceSchema }>) => {
      state.workspace = action.payload.workspace
    },
    editFolderView: (state, action: PayloadAction<{ folder: string }>) => {
      state.folderView = action.payload.folder
    },
    setSelectedDesign: (state, action: PayloadAction<{ design: DesignSchema | undefined }>) => {
      state.selectedDesign = action.payload.design
    },
    setSelectedTheme: (state, action: PayloadAction<{ theme: ThemeSchema | undefined }>) => {
      state.selectedTheme = action.payload.theme
    },
    editSortBy: (state, action: PayloadAction<{ value: SortBy }>) => {
      state.sortBy = action.payload.value
    },
    editShowFilter: (state, action: PayloadAction<{ value: ShowFilter }>) => {
      state.showFilter = action.payload.value
    },
    clearWorkspaceView: () => initialState
  }
})

export const {
  editWorkspace,
  editFolderView,
  setSelectedDesign,
  setSelectedTheme,
  editSortBy,
  editShowFilter,
  clearWorkspaceView
} = workspaceViewSlice.actions
export default workspaceViewSlice.reducer
