import React, { Dispatch, SetStateAction } from 'react'
import moment from 'moment'
import Fuse from 'fuse.js'
import { useTypedSelector } from 'Store'
import { Box, Grid, Typography } from '@mui/material'
import FolderCard from './FolderCard'
import DesignCard from './DesignCard'
import ThemeCard from './ThemeCard'
import { WorkspaceSchema, Document, DesignSchema, ThemeSchema } from 'types/firebase'
import { SortBy } from 'Features/workspaceView'

const filterOnSearchValue = (
  fuse: Fuse<Document> | undefined,
  document: Document,
  searchValue: string
) => {
  let result = false
  if (!searchValue || !fuse) {
    return !result
  }
  const resultValues = fuse.search(searchValue)
  for (const value of resultValues) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (value.score! < 0.5 && value.item.title === document.title) {
      result = true
      break
    }
  }
  return result
}

const handleDateSorting = (aDate: string | undefined, bDate: string | undefined) => {
  let firstDate = new Date(0)
  let secondDate = new Date(0)
  if (aDate) {
    firstDate = moment(aDate, 'YYYY-MM-DDThh:mm:ss').toDate()
  }
  if (bDate) {
    secondDate = moment(bDate, 'YYYY-MM-DDThh:mm:ss').toDate()
  }
  return firstDate.getTime() - secondDate.getTime()
}

const handleSortBy = (document: Document[], sortBy: SortBy) => {
  if (sortBy === 'Alphabetical') {
    return document.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
  } else if (sortBy === 'Last modified') {
    return document.sort((a, b) => handleDateSorting(a.updatedAt, b.updatedAt)).reverse()
  } else if (sortBy === 'Date created') {
    return document.sort((a, b) => handleDateSorting(a.createdAt, b.createdAt)).reverse()
  }
  return document
}

interface ProjectsViewProps {
  workspace: WorkspaceSchema
  searchValue: string
  fuse: Fuse<Document> | undefined
  designs: DesignSchema[]
  themes: ThemeSchema[]
  folders: string[]
  setDocumentForDialogs: Dispatch<
    SetStateAction<{ document: Document; type: 'design' | 'theme' } | undefined>
  >
  setChangeFolderDialogOpen: Dispatch<SetStateAction<boolean>>
  setRemoveDesignDialogOpen: Dispatch<SetStateAction<boolean>>
  setRemoveThemeDialogOpen: Dispatch<SetStateAction<boolean>>
  setResetThemeDialogOpen: Dispatch<SetStateAction<boolean>>
  setThumbnailDialogOpen: Dispatch<SetStateAction<boolean>>
}

const ProjectsView: React.FC<ProjectsViewProps> = ({
  workspace,
  searchValue,
  fuse,
  designs,
  themes,
  folders,
  setDocumentForDialogs,
  setChangeFolderDialogOpen,
  setRemoveDesignDialogOpen,
  setRemoveThemeDialogOpen,
  setResetThemeDialogOpen,
  setThumbnailDialogOpen
}) => {
  const { folderView, sortBy, showFilter } = useTypedSelector(state => state.workspaceView)

  return (
    <>
      <Box sx={{ marginTop: '24px', marginBottom: '24px', position: 'relative' }}>
        <Typography
          sx={{
            position: 'absolute',
            top: '30px',
            visibility: designs.length > 0 && searchValue !== '' ? 'visible' : 'hidden'
          }}
          variant="body2"
          color="text.primary"
        >
          No results. Please try again.
        </Typography>
        <Grid container spacing={4}>
          {/* When inside folder */}
          {folderView ? (
            <>
              {showFilter === 'All' || showFilter === 'Projects'
                ? handleSortBy(JSON.parse(JSON.stringify(designs)), sortBy)
                    .filter(design => filterOnSearchValue(fuse, design, searchValue))
                    .filter(design => design.folder === folderView)
                    .map(design => (
                      <Grid key={design.id} item>
                        <DesignCard
                          workspace={workspace}
                          themes={themes}
                          design={design}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveDesignDialogOpen={setRemoveDesignDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                          setThumbnailDialogOpen={setThumbnailDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
              {showFilter === 'All' || showFilter === 'Themes'
                ? handleSortBy(JSON.parse(JSON.stringify(themes)), sortBy)
                    .filter(theme => filterOnSearchValue(fuse, theme, searchValue))
                    .filter(theme => theme.folder === folderView)
                    .map(theme => (
                      <Grid key={theme.id} item>
                        <ThemeCard
                          workspace={workspace}
                          theme={theme as ThemeSchema}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveThemeDialogOpen={setRemoveThemeDialogOpen}
                          setResetThemeDialogOpen={setResetThemeDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
            </>
          ) : // While searching...
          searchValue ? (
            <>
              {showFilter === 'All' || showFilter === 'Projects'
                ? handleSortBy(JSON.parse(JSON.stringify(designs)), sortBy)
                    .filter(design => filterOnSearchValue(fuse, design, searchValue))
                    .map(design => (
                      <Grid key={design.id} item>
                        <DesignCard
                          workspace={workspace}
                          themes={themes}
                          design={design}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveDesignDialogOpen={setRemoveDesignDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                          setThumbnailDialogOpen={setThumbnailDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
              {showFilter === 'All' || showFilter === 'Themes'
                ? handleSortBy(JSON.parse(JSON.stringify(themes)), sortBy)
                    .filter(theme => filterOnSearchValue(fuse, theme, searchValue))
                    .map(theme => (
                      <Grid key={theme.id} item>
                        <ThemeCard
                          workspace={workspace}
                          theme={theme as ThemeSchema}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveThemeDialogOpen={setRemoveThemeDialogOpen}
                          setResetThemeDialogOpen={setResetThemeDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
            </>
          ) : (
            // When not inside a folder and not searching
            <>
              {showFilter === 'All' &&
                folders?.map(folder => (
                  <Grid key={folder} item>
                    <FolderCard
                      workspace={workspace}
                      designs={designs}
                      themes={themes}
                      folder={folder}
                    />
                  </Grid>
                ))}
              {showFilter === 'All' || showFilter === 'Projects'
                ? handleSortBy(JSON.parse(JSON.stringify(designs)), sortBy)
                    .filter(design => !design.folder)
                    .map(design => (
                      <Grid key={design.id} item>
                        <DesignCard
                          workspace={workspace}
                          themes={themes}
                          design={design}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveDesignDialogOpen={setRemoveDesignDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                          setThumbnailDialogOpen={setThumbnailDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
              {showFilter === 'All' || showFilter === 'Themes'
                ? handleSortBy(JSON.parse(JSON.stringify(themes)), sortBy)
                    .filter(theme => !theme.folder)
                    .map(theme => (
                      <Grid key={theme.id} item>
                        <ThemeCard
                          workspace={workspace}
                          theme={theme as ThemeSchema}
                          setDocumentForDialogs={setDocumentForDialogs}
                          setRemoveThemeDialogOpen={setRemoveThemeDialogOpen}
                          setResetThemeDialogOpen={setResetThemeDialogOpen}
                          setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                        />
                      </Grid>
                    ))
                : null}
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default ProjectsView
