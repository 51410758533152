import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import {
  UqSpeedDial,
  UqSpeedDialProps,
  UqSpeedDialAction,
  UqSpeedDialActionProps
} from '@uniqore/wrapper'
import { UqSpeedDialPropsExtended } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'

interface UqSpeedDialRenderProps {
  component: ScreenComponent
}

const UqSpeedDialRender: React.FC<UqSpeedDialRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const props = component.props as UqSpeedDialProps & UqSpeedDialPropsExtended

  return (
    <UqSpeedDial
      style={{ ...component.style }}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      ariaLabel={props.ariaLabel}
      direction="up"
      icon={props.icon ? React.createElement(Icons[props.icon as IconType]) : null}
      sx={{
        position: 'absolute',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
          bottom: 16,
          right: 16
        },
        '&.MuiSpeedDial-directionDown': {
          top: 16,
          right: 16
        },
        '&.MuiSpeedDial-directionRight': {
          bottom: 16,
          left: 16
        }
      }}
    >
      {props.options && Object.keys(props.options).length > 0
        ? Object.values(props.options).map((option, i) => {
            const props = option.props as UqSpeedDialActionProps
            const actions = option.actions
            return (
              <UqSpeedDialAction
                key={i}
                tooltipOpen
                icon={props.icon ? React.createElement(Icons[props.icon as IconType]) : null}
                tooltipTitle={props.tooltipTitle}
                onClick={() => onClickAction(actions)}
              />
            )
          })
        : null}
    </UqSpeedDial>
  )
}

export default UqSpeedDialRender
