import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'
import { SelectChangeEvent } from '@mui/material'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import Select, { SelectOption } from 'Components/Common/Select'
import TextField from 'Components/Common/TextField'
import { generateFoldersData } from 'Components/Layout/WorkspaceView/WorkspaceView'
import { WorkspaceSchema, DesignSchema, ThemeSchema } from 'types/firebase'

const getFolderOptions = (folders: string[]): SelectOption[] => {
  const folderOptions: SelectOption[] = [
    {
      label: 'All projects (Home)',
      value: 'none'
    }
  ]
  folders.forEach(folder => {
    folderOptions.push({
      label: folder,
      value: folder
    })
  })
  return folderOptions
}

interface ProjectProps {
  workspace: WorkspaceSchema
  selectedDesign: DesignSchema
  designs: DesignSchema[]
  themes: ThemeSchema[]
}

const Project: React.FC<ProjectProps> = ({ workspace, selectedDesign, designs, themes }) => {
  const { updateDesign } = useDesigns(workspace.id)
  const [folders, setFolders] = useState<string[]>([])

  // Generate folders
  useEffect(() => {
    setFolders(generateFoldersData(designs, themes))
  }, [designs, themes])

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    updateDesign(selectedDesign, {
      folder: event.target.value === 'none' ? '' : event.target.value
    })
  }

  const handleProjectNameSave = (projectName: string) => {
    updateDesign(selectedDesign, {
      title: projectName
    })
  }

  const updateProjectName = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleProjectNameSave(e.target.value)

  const debouncedOnChange = useMemo(() => {
    return debounce(updateProjectName, 1500)
  }, [])

  return (
    <>
      <Select
        label="Folder"
        value={selectedDesign?.folder}
        onChange={handleSelectChange}
        options={getFolderOptions(folders)}
      />
      <TextField
        label="Project name"
        defaultValue={selectedDesign?.title}
        // TODO: fix event type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={debouncedOnChange}
        style={{ paddingBottom: '16px' }}
      />
    </>
  )
}

export default Project
