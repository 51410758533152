import React, { useState, useEffect, useReducer } from 'react'
import chroma from 'chroma-js'
import merge from 'deepmerge'
import equal from 'fast-deep-equal'
import { ColorResult } from 'react-color'
import { Box, Collapse, useTheme } from '@mui/material'
import ColorPicker, { colorReducer, setAlphaToHex } from 'Components/Common/ColorPicker'
import Switch from 'Components/Common/Switch'
import SectionArea from '../SectionArea'
import { Subtitle, ButtonStyled } from '../ThemeCreation'
import { PaletteSchema, ColorSchema, BackgroundSchema, TextSchema } from 'Util/ThemeValues'
import { ThemeSchema } from 'types/firebase'

/**
 * @param color: color that is used the create lighter or darker version
 * @param type: which version of the color is created
 */
export const getChromaValue = (color: string, type: 'dark' | 'light'): string => {
  if (type === 'dark') {
    return chroma(color).darken().hex()
  } else {
    return chroma(color).brighten().hex()
  }
}

interface ColorsSectionProps {
  selectedTheme: ThemeSchema
  palette: PaletteSchema
  setPalette: React.Dispatch<React.SetStateAction<PaletteSchema>>
}

const ColorsSection: React.FC<ColorsSectionProps> = ({ selectedTheme, palette, setPalette }) => {
  const theme = useTheme()

  useEffect(() => {
    colorDispatch({ type: 'reset', resetPayload: selectedTheme.palette })
  }, [selectedTheme])

  // These colors are for the round indicator and ColorPicker itself to use.
  // These are updated with SketchPicker's onChange for fluid animation.
  // SketchPicker's onChangeComplete updates whatever state is chosen, in this case 'palette' (above)
  const [colors, colorDispatch] = useReducer(colorReducer, selectedTheme.palette)

  const [generateColorVariations, setGenerateColorVariations] = useState<boolean>(
    selectedTheme.palette.primary.dark ? false : true
  )
  // If theme's palette doesn't have color variations (dark, light, contrastText etc.)
  // and user doesn't want Mui theme to generate them, this will create initial variation values
  // with the same method which Mui is using (chroma)
  useEffect(() => {
    if (generateColorVariations) {
      const paletteBasicValues = {
        primary: { main: palette.primary.main },
        secondary: { main: palette.secondary.main },
        info: { main: palette.info.main },
        success: { main: palette.success.main },
        warning: { main: palette.warning.main },
        error: { main: palette.error.main },
        text: { primary: palette.text.primary },
        background: { default: palette.background.default }
      }
      colorDispatch({ type: 'reset', resetPayload: paletteBasicValues as PaletteSchema })
      setPalette(paletteBasicValues as PaletteSchema)
    }
    if (!generateColorVariations) {
      const paletteVariations = {
        primary: {
          dark: '',
          light: '',
          contrastText: ''
        },
        secondary: {
          dark: '',
          light: '',
          contrastText: ''
        },
        info: {
          dark: '',
          light: '',
          contrastText: ''
        },
        success: {
          dark: '',
          light: '',
          contrastText: ''
        },
        warning: {
          dark: '',
          light: '',
          contrastText: ''
        },
        error: {
          dark: '',
          light: '',
          contrastText: ''
        },
        text: {
          secondary: '',
          disabled: ''
        },
        background: {
          paper: ''
        }
      }
      // Generate dark, light & contrastText variations for primary, secondary etc...
      Object.keys(palette)
        .filter(key => key !== 'text' && key !== 'background')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .forEach((paletteKey: keyof Omit<PaletteSchema, 'text' | 'background'>) => {
          if (!palette[paletteKey].dark) {
            paletteVariations[paletteKey].dark = getChromaValue(palette[paletteKey].main, 'dark')
          } else {
            paletteVariations[paletteKey].dark = palette[paletteKey].dark
          }
          if (!palette[paletteKey].light) {
            paletteVariations[paletteKey].light = getChromaValue(palette[paletteKey].main, 'light')
          } else {
            paletteVariations[paletteKey].light = palette[paletteKey].light
          }
          if (!palette[paletteKey].contrastText) {
            paletteVariations[paletteKey].contrastText = theme.palette.getContrastText(
              palette[paletteKey].main
            )
          } else {
            paletteVariations[paletteKey].contrastText = palette[paletteKey].contrastText
          }
        })

      // Generate variations for text & background
      Object.keys(palette)
        .filter(key => key === 'text' || key === 'background')
        .forEach(paletteKey => {
          if (paletteKey === 'text') {
            if (!palette[paletteKey].secondary) {
              paletteVariations[paletteKey].secondary = chroma(palette[paletteKey].primary)
                .alpha(0.6)
                .hex()
            } else {
              paletteVariations[paletteKey].secondary = palette[paletteKey].secondary
            }
            if (!palette[paletteKey].disabled) {
              paletteVariations[paletteKey].disabled = chroma(palette[paletteKey].primary)
                .alpha(0.38)
                .hex()
            } else {
              paletteVariations[paletteKey].disabled = palette[paletteKey].disabled
            }
          }
          if (paletteKey === 'background') {
            if (!palette[paletteKey].paper) {
              paletteVariations[paletteKey].paper = '#ffffff'
            } else {
              paletteVariations[paletteKey].paper = palette[paletteKey].paper
            }
          }
        })
      setPalette(merge(palette, paletteVariations as PaletteSchema))
    }
  }, [generateColorVariations])

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param key - Palette's types (primary, secondary, background, text etc...)
   * @param colorKey - (main, dark, light, contrastText)
   */
  const updateColor = (
    color: ColorResult,
    stateUpdate: boolean,
    key: keyof PaletteSchema,
    colorKey: keyof ColorSchema
  ) => {
    if (stateUpdate) {
      setPalette({ ...palette, [key]: { ...palette[key], [colorKey]: setAlphaToHex(color) } })
    } else {
      colorDispatch({ type: 'modify', payload: { value: color.rgb, key, colorKey } })
    }
  }

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param backgroundKey - Values that palette's background -type has (default, paper)
   */
  const updateBackground = (
    color: ColorResult,
    stateUpdate: boolean,
    backgroundKey: keyof BackgroundSchema
  ) => {
    if (stateUpdate) {
      setPalette({
        ...palette,
        background: { ...palette.background, [backgroundKey]: setAlphaToHex(color) }
      })
    } else {
      colorDispatch({
        type: 'modify',
        payload: { value: color.rgb, key: 'background', backgroundKey }
      })
    }
  }

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param textKey - Values that palette's text -type has (primary, secondary, disabled)
   */
  const updateText = (color: ColorResult, stateUpdate: boolean, textKey: keyof TextSchema) => {
    if (stateUpdate) {
      setPalette({ ...palette, text: { ...palette.text, [textKey]: setAlphaToHex(color) } })
    } else {
      colorDispatch({ type: 'modify', payload: { value: color.rgb, key: 'text', textKey } })
    }
  }

  const handleResetColors = () => {
    // Checks if theme had already not-generated color variants
    if (!selectedTheme.palette.primary.dark) {
      setGenerateColorVariations(true)
    } else {
      setGenerateColorVariations(false)
    }
    colorDispatch({ type: 'reset', resetPayload: selectedTheme.palette })
    setPalette(selectedTheme.palette)
  }

  return (
    <SectionArea
      title="Colors"
      components={[
        <Box
          key={1}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box component="span">
            <Switch
              label="Generate color variations"
              checked={generateColorVariations}
              onChange={() => setGenerateColorVariations(!generateColorVariations)}
            />
          </Box>
          <ButtonStyled
            variant="text"
            color="error"
            size="small"
            disableFocusRipple
            disabled={equal(palette, selectedTheme.palette)}
            onClick={handleResetColors}
          >
            Discard changes
          </ButtonStyled>
        </Box>
      ]}
    >
      <Box sx={{ display: 'flex' }}>
        <div>
          <Subtitle>PRIMARY</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.primary.main}
            paletteKey="primary"
            colorKey="main"
            updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'primary', 'main')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.primary.dark}
              paletteKey="primary"
              colorKey="dark"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'primary', 'dark')
              }
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.primary.light}
              paletteKey="primary"
              colorKey="light"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'primary', 'light')
              }
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.primary.contrastText}
            paletteKey="primary"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'primary', 'contrastText')
            }
          />
        </div>
        <div>
          <Subtitle>SECONDARY</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.secondary.main}
            paletteKey="secondary"
            colorKey="main"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'secondary', 'main')
            }
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.secondary.dark}
              paletteKey="secondary"
              colorKey="dark"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'secondary', 'dark')
              }
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.secondary.light}
              paletteKey="secondary"
              colorKey="light"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'secondary', 'light')
              }
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.secondary.contrastText}
            paletteKey="secondary"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'secondary', 'contrastText')
            }
          />
        </div>
        <div>
          <Subtitle>BACKGROUND</Subtitle>
          <ColorPicker
            label="Default"
            colors={colors}
            colorFromState={palette.background.default}
            paletteKey="background"
            colorKey="default"
            updateColor={(color, stateUpdate) => updateBackground(color, stateUpdate, 'default')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Paper"
              colors={colors}
              colorFromState={palette.background.paper}
              paletteKey="background"
              colorKey="paper"
              updateColor={(color, stateUpdate) => updateBackground(color, stateUpdate, 'paper')}
            />
          </Collapse>
        </div>
        <div>
          <Subtitle>TEXT</Subtitle>
          <ColorPicker
            label="Primary"
            colors={colors}
            colorFromState={palette.text.primary}
            paletteKey="text"
            colorKey="primary"
            updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'primary')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Secondary"
              colors={colors}
              colorFromState={palette.text.secondary}
              paletteKey="text"
              colorKey="secondary"
              updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'secondary')}
            />
            <ColorPicker
              label="Disabled"
              colors={colors}
              colorFromState={palette.text.disabled}
              paletteKey="text"
              colorKey="disabled"
              updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'disabled')}
            />
          </Collapse>
        </div>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Subtitle>ERROR</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.error.main}
            paletteKey="error"
            colorKey="main"
            updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'error', 'main')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.error.dark}
              paletteKey="error"
              colorKey="dark"
              updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'error', 'dark')}
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.error.light}
              paletteKey="error"
              colorKey="light"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'error', 'light')
              }
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.error.contrastText}
            paletteKey="error"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'error', 'contrastText')
            }
          />
        </div>
        <div>
          <Subtitle>WARNING</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.warning.main}
            paletteKey="warning"
            colorKey="main"
            updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'warning', 'main')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.warning.dark}
              paletteKey="warning"
              colorKey="dark"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'warning', 'dark')
              }
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.warning.light}
              paletteKey="warning"
              colorKey="light"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'warning', 'light')
              }
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.warning.contrastText}
            paletteKey="warning"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'warning', 'contrastText')
            }
          />
        </div>
        <div>
          <Subtitle>INFO</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.info.main}
            paletteKey="info"
            colorKey="main"
            updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'info', 'main')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.info.dark}
              paletteKey="info"
              colorKey="dark"
              updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'info', 'dark')}
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.info.light}
              paletteKey="info"
              colorKey="light"
              updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'info', 'light')}
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.info.contrastText}
            paletteKey="info"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'info', 'contrastText')
            }
          />
        </div>
        <div>
          <Subtitle>SUCCESS</Subtitle>
          <ColorPicker
            label="Main"
            colors={colors}
            colorFromState={palette.success.main}
            paletteKey="success"
            colorKey="main"
            updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'success', 'main')}
          />
          <Collapse in={!generateColorVariations} timeout="auto" unmountOnExit>
            <ColorPicker
              label="Dark"
              colors={colors}
              colorFromState={palette.success.dark}
              paletteKey="success"
              colorKey="dark"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'success', 'dark')
              }
            />
            <ColorPicker
              label="Light"
              colors={colors}
              colorFromState={palette.success.light}
              paletteKey="success"
              colorKey="light"
              updateColor={(color, stateUpdate) =>
                updateColor(color, stateUpdate, 'success', 'light')
              }
            />
          </Collapse>
          <ColorPicker
            label="Contrast text"
            colors={colors}
            colorFromState={palette.success.contrastText}
            paletteKey="success"
            colorKey="contrastText"
            updateColor={(color, stateUpdate) =>
              updateColor(color, stateUpdate, 'success', 'contrastText')
            }
          />
        </div>
      </Box>
    </SectionArea>
  )
}

export default React.memo(ColorsSection)
