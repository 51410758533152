import React from 'react'
import { useTypedSelector } from 'Store'
import { Box, Card, Divider, ListItem, ListItemText, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { BottomSpace } from '../AddSection'
import ScreenEdit from './ScreenEdit/ScreenEdit'
import ComponentEdit from './ComponentEdit/ComponentEdit'

const EditSection: React.FC = () => {
  const { selectedScreenId, selectedComponentId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )

  return (
    <>
      {!selectedScreenId ? (
        <>
          <ListItem>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
            />
          </ListItem>
          <Divider />
          <Card
            elevation={0}
            sx={{
              borderRadius: '4px',
              margin: '16px',
              padding: '16px',
              backgroundColor: theme => theme.custom.appleGreen[100]
            }}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
            >
              <InfoIcon
                sx={{
                  marginTop: '-2px',
                  marginRight: '8px',
                  color: theme => theme.palette.info.dark
                }}
              />
              <Typography
                variant="body2"
                sx={{ flexGrow: 1, color: theme => theme.palette.text.primary }}
              >
                Please select a screen or a<br /> component to activate this edit
                <br /> section.
              </Typography>
            </Box>
          </Card>
        </>
      ) : (
        <>
          {!selectedComponentId ? (
            <ScreenEdit selectedScreenId={selectedScreenId} />
          ) : (
            <ComponentEdit
              selectedScreenId={selectedScreenId}
              selectedComponentId={selectedComponentId}
            />
          )}
        </>
      )}
      <BottomSpace />
    </>
  )
}

export default EditSection
