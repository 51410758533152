import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { useDesigns, useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { ButtonStyled } from '../../WorkspaceView'
import { editSnackbar } from 'Features/snackbar'
import { editFolderView } from 'Features/workspaceView'
import { WorkspaceSchema } from 'types/firebase'

interface RemoveFolderDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  folders: string[]
  setFolders: React.Dispatch<React.SetStateAction<string[]>>
  workspace: WorkspaceSchema
}

const RemoveFolderDialog: React.FC<RemoveFolderDialogProps> = ({
  open,
  setOpen,
  folders,
  setFolders,
  workspace
}) => {
  const dispatch = useDispatch()
  const { updateDesign } = useDesigns(workspace.id)
  const { updateTheme } = useThemes(workspace.id)
  const [loading, setLoading] = useState<boolean>(false)
  const { folderView } = useTypedSelector(state => state.workspaceView)
  const { designs, themes } = useTypedSelector(state => state.firebaseDocuments)

  const handleFolderRemove = async () => {
    try {
      setLoading(true)
      for (const design of designs) {
        if (design.folder === folderView) {
          updateDesign(
            design,
            {
              folder: ''
            },
            true
          )
        }
      }
      for (const theme of themes) {
        if (theme.folder === folderView) {
          updateTheme(theme, {
            folder: ''
          })
        }
      }
      setFolders(folders.filter((f: string) => f !== folderView))
      dispatch(editFolderView({ folder: '' }))
      setLoading(false)
      setOpen(false)
    } catch (error) {
      setLoading(false)
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <InfoIcon sx={{ color: theme => theme.palette.error.main, marginRight: '16px' }} />
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Are you sure?
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText variant="body1" color="text.secondary">
            Are you sure you want to delete{' '}
            <Typography
              variant="body1"
              color="text.secondary"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {folderView}
            </Typography>
            ? Upon deletion the contents of this folder will be moved to All Projects.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {loading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '46px'
              }}
            />
          ) : null}
          <ButtonStyled onClick={() => setOpen(false)} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={handleFolderRemove}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Delete
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default RemoveFolderDialog
