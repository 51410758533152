import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { styled } from '@mui/material/styles'
import { ScreenComponent, SCREEN_WIDTH } from 'Features/canvas'
import { selectComponent } from 'Features/canvasScreens'
import { editHelpers } from 'Features/helpers'
import UqAppBarRender from './Components/UqAppBarRender'
import UqButtonRender from './Components/UqButtonRender'
import UqCheckboxRender from './Components/UqCheckboxRender'
import UqRadioRender from './Components/UqRadioRender'
import UqRatingRender from './Components/UqRatingRender'
import UqSelectRender from './Components/UqSelectRender'
import UqSwitchRender from './Components/UqSwitchRender'
import UqTextFieldRender from './Components/UqTextFieldRender'
import UqChipRender from './Components/UqChipRender'
import UqDividerRender from './Components/UqDividerRender'
import UqAlertRender from './Components/UqAlertRender'
import UqDialogRender from './Components/UqDialogRender'
import UqSnackbarRender from './Components/UqSnackbarRender'
import UqBottomNavigationRender from './Components/UqBottomNavigationRender'
import UqSpeedDialRender from './Components/UqSpeedDialRender'
import UqFabRender from './Components/UqFabRender'
import UqListRender from './Components/UqListRender'
import UqDatePickerRender from './Components/UqDatePickerRender'
import UqTypographyRender from './Components/UqTypographyRender'
import RadioButtonGroupRender from './Components/RadioButtonGroupRender'
import SelectButtonGroupRender from './Components/SelectButtonGroupRender'
import LoginRender from './Components/LoginRender'
import ImageRender from './Components/ImageRender'
import FlowNavRender from './Components/FlowNavRender'
import OnboardingRender from './Components/OnboardingRender'

// used in Checkbox, Radio and Select -components
export const handleOptions = (options: string): string[] => {
  const splitted = options.split('\n')
  if (splitted.length === 1 && splitted[0] === '') {
    return []
  }
  return splitted
}

const ComponentDiv = styled('div')<{
  height: number
}>(({ height }) => ({
  // create a pseudo element so the component has a greater hover area
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    width: SCREEN_WIDTH,
    height
  }
}))

interface ComponentRenderProps {
  component: ScreenComponent
  screenId: string
  container?: React.MutableRefObject<null>
}

const ComponentRender: React.FC<ComponentRenderProps> = ({ component, screenId, container }) => {
  const dispatch = useDispatch()
  const previewOn = useTypedSelector(state => state.preview.previewOn)
  const { selectedScreenId, selectedComponentId, stagedComponentId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  const componentIdHover = useTypedSelector(state => state.helpers.componentIdHover)
  const isNodeDragging = useTypedSelector(state => state.helpers.isNodeDragging)
  const [isHovering, setIsHovering] = useState<boolean>(false)

  // Height is used to set proper hover area for every component in screen
  const [height, setHeight] = useState<number>(0)
  const heightRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (heightRef.current) {
      setHeight(heightRef.current.clientHeight)
    }
  }, [])

  const renderComponent = (c: ScreenComponent) => {
    switch (c.componentName) {
      case 'uqAppBar':
        return <UqAppBarRender component={c} />
      case 'uqButton':
        return <UqButtonRender component={c} />
      case 'uqCheckbox':
        return <UqCheckboxRender component={c} />
      case 'uqRadio':
        return <UqRadioRender component={c} />
      case 'uqRating':
        return <UqRatingRender component={c} />
      case 'uqSelect':
        return <UqSelectRender component={c} />
      case 'uqSwitch':
        return <UqSwitchRender component={c} />
      case 'uqTextField':
        return <UqTextFieldRender component={c} />
      case 'uqChip':
        return <UqChipRender component={c} />
      case 'uqDivider':
        return <UqDividerRender component={c} />
      case 'uqAlert':
        return <UqAlertRender component={c} />
      case 'uqDialog':
        return (
          <UqDialogRender component={c} container={container as React.MutableRefObject<null>} />
        )
      case 'uqSnackbar':
        return <UqSnackbarRender component={c} />
      case 'uqBottomNavigation':
        return <UqBottomNavigationRender component={c} />
      case 'uqSpeedDial':
        return <UqSpeedDialRender component={c} />
      case 'uqFab':
        return <UqFabRender component={c} />
      case 'uqList':
        return <UqListRender screenId={screenId} component={c} />
      case 'uqTypography':
        return <UqTypographyRender component={c} />
      case 'uqDatePicker':
        return <UqDatePickerRender component={c} />
      case 'radioButtonGroup':
        return <RadioButtonGroupRender component={c} />
      case 'selectButtonGroup':
        return <SelectButtonGroupRender component={c} />
      case 'login':
        return <LoginRender component={c} />
      case 'image':
        return <ImageRender component={c} />
      case 'flowNav':
        return <FlowNavRender component={c} />
      case 'onboarding':
        return <OnboardingRender component={c} />
      default:
        return <></>
    }
  }

  const getOutlineWidth = (componentId: string) => {
    if ((screenId === selectedScreenId && isHovering) || componentId === componentIdHover) {
      return 2
    } else if (selectedComponentId === componentId || stagedComponentId === componentId) {
      return 1
    } else {
      return 0
    }
  }

  const handleMouseEnter = (componentId: string) => {
    if (isNodeDragging) return
    if (screenId === selectedScreenId) {
      setIsHovering(true)
      dispatch(editHelpers({ key: 'componentIdHover', value: componentId }))
    }
  }

  const handleMouseLeave = () => {
    if (isNodeDragging) return
    if (screenId === selectedScreenId) {
      setIsHovering(false)
      dispatch(editHelpers({ key: 'componentIdHover', value: '' }))
    }
  }

  const handleSingleClick = (component: ScreenComponent) => {
    if (isNodeDragging) return
    if (screenId === selectedScreenId) {
      dispatch(selectComponent({ stageComponent: true, component }))
    }
  }

  const handleDoubleClick = (component: ScreenComponent) => {
    if (isNodeDragging) return
    if (screenId === selectedScreenId) {
      dispatch(selectComponent({ stageComponent: false, component }))
    }
  }

  return (
    <>
      {!previewOn ? (
        <ComponentDiv
          ref={heightRef}
          height={height}
          onMouseEnter={() => handleMouseEnter(component.id)}
          onMouseLeave={() => handleMouseLeave()}
          onClick={() => handleSingleClick(component)}
          onDoubleClick={() => handleDoubleClick(component)}
          style={{
            outlineStyle: 'solid',
            outlineColor: '#3096E5',
            outlineWidth: getOutlineWidth(component.id)
          }}
        >
          {renderComponent(component)}
        </ComponentDiv>
      ) : (
        renderComponent(component)
      )}
    </>
  )
}

export default React.memo(ComponentRender)
