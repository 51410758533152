/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import useTypedSelector from 'Store'
import ComponentEdit from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/ComponentEdit'
import AddElementsMenu, { ActionType } from 'Components/Common/Editing/AddElementsMenu'
import ContentBox from 'Components/Common/Editing/ContentBox'
import { canvasComponents } from 'Util/CanvasComponents'
import { findFirstFreeInteger } from 'Util/Helpers'
import { DynamicComponentProps } from 'Util/UqComponentsData'
import { ScreenComponent, deepCopyComponent } from 'Features/canvas'

interface DynamicProps {
  label: string
  propName: string
  idPrefix: string
  config: DynamicComponentProps
  onChange: (components: { [componentId: string]: ScreenComponent }) => void
  value: { [componentId: string]: ScreenComponent } | undefined
}

const Dynamic: React.FC<DynamicProps> = ({
  label,
  onChange,
  config,
  propName,
  idPrefix,
  value = {}
}) => {
  const { selectedScreenId, selectedComponentId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  const [components, setComponents] = useState<{ [id: string]: ScreenComponent }>(value)

  if (!selectedScreenId || !selectedComponentId) return <></>

  const componentCount = Object.keys(components).length

  const { componentName, maxLimit, propAllowList } = config

  useEffect(() => {
    onChange(components)
  }, [components])

  const onAdd = () => {
    if (maxLimit && Object.keys(components).length >= maxLimit) return

    const id = findFirstFreeInteger(Object.keys(components))
    if (!id) return

    let props = canvasComponents[componentName].props
    if (propAllowList) {
      const filteredProps: { [key: string]: unknown } = {}
      Object.keys(props).forEach(key => {
        // @ts-ignore Typescript doesn't recognize that `props[key]` will exist.
        if (propAllowList.includes(key)) filteredProps[key] = props[key]
      })
      props = filteredProps
    }
    const newComponent: ScreenComponent = {
      id: `${idPrefix}_${propName}_${id}`,
      componentName,
      props
    }
    setComponents({ ...components, [id]: newComponent })
  }

  const onDelete = (id: string) => {
    const state = { ...components }
    delete state[id]
    setComponents(state)
  }

  const clearComponents = () => setComponents({})

  const onPropChange = (componentId: string, propName: string, value: unknown) => {
    const current = components[componentId]
    if (!current) return
    const updated: ScreenComponent = deepCopyComponent(current)
    // @ts-ignore
    updated.props[propName] = value
    setComponents({
      ...components,
      [componentId]: updated
    })
  }

  const useInlineView = maxLimit === 1

  let menuAction: ActionType | undefined
  // If only one child is allowed, the menu action toggles between `+` and `-`
  if (useInlineView && componentCount === 1) {
    menuAction = { type: 'remove', onClick: clearComponents }
  } else if (maxLimit && componentCount >= maxLimit) {
    // Button is hidden if limit is reached
    menuAction = undefined
  } else menuAction = { type: 'add', onClick: onAdd }

  return (
    <>
      <AddElementsMenu label={label} action={menuAction} />
      {Object.entries(components).map(([id, component]) =>
        useInlineView ? (
          <ComponentEdit
            key={id}
            selectedScreenId={selectedScreenId}
            selectedComponentId={selectedComponentId}
            hideHeader
            disableCategories
            component={component}
            onPropChange={(propName, newValue) => onPropChange(id, propName, newValue)}
          />
        ) : (
          <ContentBox
            key={id}
            primaryText={`${label} ${id}`}
            collapsible
            onClickRemove={() => onDelete(id)}
          >
            <ComponentEdit
              selectedScreenId={selectedScreenId}
              selectedComponentId={selectedComponentId}
              hideHeader
              disableCategories
              propAllowList={propAllowList}
              component={component}
              onPropChange={(propName, newValue) => onPropChange(id, propName, newValue)}
            />
          </ContentBox>
        )
      )}
    </>
  )
}

export default Dynamic
