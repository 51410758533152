import React from 'react'
import { Box, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

interface Props {
  text: string
  onClick: () => void
}

const AddElement: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: '60px',
        marginTop: '8px',
        border: theme => `1px dashed ${theme.custom.appleGreen[300]}`,
        borderRadius: '4px',
        backgroundColor: theme => theme.custom.appleGreen[40]
      }}
    >
      <ListItem onClick={onClick}>
        <ListItemIcon>
          <AddIcon sx={{ color: theme => theme.custom.appleGreen[750] }} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            variant: 'body2',
            sx: { color: theme => theme.custom.appleGreen[750] }
          }}
        />
      </ListItem>
    </Box>
  )
}

export default AddElement
