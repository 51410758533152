import React from 'react'
import {
  UqBottomNavigation,
  UqBottomNavigationProps,
  UqBottomNavigationActionProps
} from '@uniqore/wrapper'
import { UqBottomNavigationPropsExtended } from 'Util/CanvasComponents'
import UqBottomNavigationActionRender from './SubComponents/UqBottomNavigationActionRender'
import { ScreenComponent } from 'Features/canvas'

interface UqBottomNavigationRenderProps {
  component: ScreenComponent
}

const UqBottomNavigationRender: React.FC<UqBottomNavigationRenderProps> = ({ component }) => {
  const props = component.props as UqBottomNavigationProps & UqBottomNavigationPropsExtended

  return (
    <UqBottomNavigation showLabels={props.showLabels} style={{ ...component.style }}>
      {props.tabs && Object.keys(props.tabs).length > 0
        ? Object.values(props.tabs).map((tab, i) => (
            <UqBottomNavigationActionRender
              key={i}
              componentProps={tab.props as UqBottomNavigationActionProps}
              showLabel={props.showLabels ? true : false}
            />
          ))
        : null}
    </UqBottomNavigation>
  )
}

export default UqBottomNavigationRender
