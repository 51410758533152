import { ScreenComponent } from 'Features/canvas'
import { CanvasScreen } from 'types/firebase'
import { OnboardingCarouselItemsCustom, OnboardingPropsCustom } from 'Util/CanvasComponents'

const onboardingCarouselItemSubtitleToBody = (
  screenComponent: ScreenComponent
): ScreenComponent => {
  return {
    ...screenComponent,
    props: {
      ...(screenComponent.props as OnboardingPropsCustom),
      carouselItems: (screenComponent.props as OnboardingPropsCustom).carouselItems.map(
        ({ subtitle, ...rest }: OnboardingCarouselItemsCustom & { subtitle?: string }) => ({
          ...rest,
          body: subtitle || ''
        })
      )
    }
  }
}

export const updateOnboardingModule = (screen: CanvasScreen): CanvasScreen => {
  return {
    ...screen,
    components: {
      ...screen.components,
      contents: screen.components.contents.map(v =>
        v.componentName === 'onboarding' ? onboardingCarouselItemSubtitleToBody(v) : v
      ),
      headers: screen.components.headers.map(v =>
        v.componentName === 'onboarding' ? onboardingCarouselItemSubtitleToBody(v) : v
      ),
      footers: screen.components.footers.map(v =>
        v.componentName === 'onboarding' ? onboardingCarouselItemSubtitleToBody(v) : v
      )
    }
  }
}
