import React from 'react'
import { useTypedSelector } from 'Store'
import { MarginPixelAmount } from '@uniqore/theme'
import { UqTextField } from '@uniqore/wrapper'
import { UqTextFieldPropsCustom, UqInputAdornmentPropsCustom } from 'Util/CanvasComponents'
import UqInputAdornmentRender from './SubComponents/UqInputAdornmentRender'
import { ScreenComponent } from 'Features/canvas'

interface UqTextFieldRenderProps {
  component: ScreenComponent
  marginVertical?: MarginPixelAmount
}

const UqTextFieldRender: React.FC<UqTextFieldRenderProps> = ({ component, marginVertical }) => {
  const { previewOn } = useTypedSelector(state => state.preview)
  // Removes 'prefix' and 'suffix' from props since they are not recognized props.
  // Also removed 'value' since TextField is changed between controlled and
  // uncontrolled if Preview is on/off. (Allow user to type if Preview is on).
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { prefix, suffix, value, ...props } = component.props as UqTextFieldPropsCustom
  const componentProps = component.props as UqTextFieldPropsCustom

  return (
    <UqTextField
      {...props}
      style={{ ...component.style }}
      value={!previewOn ? componentProps.value : undefined}
      defaultValue={previewOn ? componentProps.value : undefined}
      marginVertical={marginVertical || componentProps.marginVertical}
      marginHorizontal={componentProps.marginHorizontal}
      marginTop={componentProps.marginTop}
      marginBottom={componentProps.marginBottom}
      marginLeft={componentProps.marginLeft}
      marginRight={componentProps.marginRight}
      multiline={(props.rows as number) > 0}
      InputProps={{
        startAdornment:
          componentProps.prefix && Object.keys(componentProps.prefix).length > 0
            ? Object.values(componentProps.prefix).map((prefix, i) => (
                <UqInputAdornmentRender
                  key={i}
                  componentProps={prefix.props as UqInputAdornmentPropsCustom}
                  position="start"
                />
              ))
            : null,
        endAdornment:
          componentProps.suffix && Object.keys(componentProps.suffix).length > 0
            ? Object.values(componentProps.suffix).map((suffix, i) => (
                <UqInputAdornmentRender
                  key={i}
                  componentProps={suffix.props as UqInputAdornmentPropsCustom}
                  position="end"
                />
              ))
            : null
      }}
    />
  )
}

export default UqTextFieldRender
