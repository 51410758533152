/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * This file is heavily influenced by material-ui/docs/data/components/material-icons/searchIcons.js
 * that is licensed under the MIT-license.
 * Source code: https://github.com/mui/material-ui/blob/master/docs/data/material/components/material-icons/SearchIcons.js
 * TODO: This file needs further improving
 */
import React, { useState, useEffect, useCallback } from 'react'
import { Index as FlexSearchIndex, IndexSearchResult } from 'flexsearch'
import synonyms from 'Util/IconSynonyms'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Button, Dialog, IconButton, Typography, SvgIcon } from '@mui/material'
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg'
import Icons, { IconType } from 'Components/Common/Icons'
import Select from 'Components/Common/Select'
import TextField from 'Components/Common/TextField'
import ClearIcon from '@mui/icons-material/Clear'

const searchIndex = new FlexSearchIndex({
  tokenize: 'full'
})

const StyledIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  color: theme.palette.text.secondary,
  margin: '0 4px',
  '& > div': {
    display: 'flex'
  },
  '& > div > *': {
    flexGrow: 1,
    fontSize: '.6rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 0
  }
}))

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  boxSizing: 'content-box',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  margin: theme.spacing(0.5, 0)
}))

export interface IconSchema {
  importName: string
  name: string
  theme: string
  Component: unknown
}

interface MuiIconsProps {
  icons: IconSchema[]
  onClickIcon?: (icon: IconType) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MuiIcons: React.FC<MuiIconsProps> = React.memo(({ icons, onClickIcon, setOpen }) => {
  return (
    <div>
      {icons.map(icon => {
        return (
          <StyledIcon key={icon.importName}>
            <StyledSvgIcon
              // @ts-ignore
              component={icon.Component}
              fontSize="large"
              tabIndex={-1}
              onClick={() => {
                if (onClickIcon) {
                  onClickIcon(icon.importName as IconType)
                  setOpen(false)
                }
              }}
            />
            <div>
              <div>{icon.importName}</div>
            </div>
          </StyledIcon>
        )
      })}
    </div>
  )
})
MuiIcons.displayName

const allIconsMap = {}
const allIcons = Object.keys(Icons)
  .sort()
  .map(importName => {
    let theme
    if (importName.indexOf('Outlined') !== -1) {
      theme = 'Outlined'
    } else if (importName.indexOf('TwoTone') !== -1) {
      theme = 'Two tone'
    } else if (importName.indexOf('Rounded') !== -1) {
      theme = 'Rounded'
    } else if (importName.indexOf('Sharp') !== -1) {
      theme = 'Sharp'
    } else {
      theme = 'Filled'
    }

    const name = importName.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '')
    let searchable = name
    // @ts-ignore
    if (synonyms[searchable]) {
      // @ts-ignore
      searchable += ` ${synonyms[searchable]}`
    }
    searchIndex.addAsync(importName, searchable)

    const icon: IconSchema = {
      importName,
      name,
      theme,
      // @ts-ignore
      Component: Icons[importName]
    }
    // @ts-ignore
    allIconsMap[importName] = icon
    return icon
  })

const RemoveIconStyled = styled(IconButton)(({ theme }) => ({
  cursor: 'pointer',
  marginRight: '4px',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '& > svg': {
    width: 20,
    height: 20
  },
  '& > svg > path': {
    fill: theme.palette.error.main
  }
}))

export interface ButtonIconProps {
  icon?: IconType
  onClickIcon?: (icon: IconType) => void
  onClickRemove?: (icon: '') => void
  label?: string
  disablePadding?: boolean
  buttonHeight?: number
  style?: React.CSSProperties
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  onClickIcon,
  onClickRemove,
  label,
  disablePadding,
  buttonHeight,
  style
}) => {
  const theme = useTheme()
  const [isHover, setIsHover] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={style}
        sx={disablePadding ? {} : { padding: '4px 16px' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {label && (
            <Typography variant="body2" sx={{ color: theme => theme.palette.text.primary }}>
              {label}
            </Typography>
          )}
          <Box>
            <RemoveIconStyled
              onClick={onClickRemove ? () => onClickRemove('') : undefined}
              style={{ visibility: isHover && icon ? 'visible' : 'hidden' }}
            >
              <RemoveIcon />
            </RemoveIconStyled>
            <Button
              onClick={() => setDialogOpen(true)}
              variant="outlined"
              disableElevation
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                backgroundColor: 'white',
                height: `${buttonHeight}px` || '36px',
                border: `1px solid ${theme.custom.borderColor}`,
                '&:hover': {
                  border: `1px solid ${theme.custom.borderColor}`
                }
              }}
            >
              {icon ? (
                React.createElement(Icons[icon], {
                  style: { color: theme.palette.text.primary }
                })
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ paddingTop: '2px', paddingBottom: '2px' }}
                >
                  None
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
      <IconSearchDialog
        icon={icon as IconType}
        open={dialogOpen}
        setOpen={setDialogOpen}
        onClickIcon={onClickIcon}
      />
    </>
  )
}

export default ButtonIcon

interface IconSearchDialogProps {
  icon: IconType
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClickIcon?: (icon: IconType) => void
}

const IconSearchDialog: React.FC<IconSearchDialogProps> = ({
  icon,
  open,
  setOpen,
  onClickIcon
}) => {
  const [keys, setKeys] = useState<IndexSearchResult | null>(null)
  const [searchValue, setSearchValue] = useState<string>(icon)
  const [iconVariant, setIconVariant] = useState<string>('Filled')

  const searchUpdate = useCallback((value: string) => {
    if (value === '') {
      setKeys(null)
    } else {
      searchIndex.searchAsync(value).then(results => {
        setKeys(results)
      })
    }
  }, [])

  useEffect(() => {
    searchUpdate(searchValue)
  }, [searchValue])

  const icons = React.useMemo(
    () =>
      // @ts-ignore
      (keys === null ? allIcons : keys.map(key => allIconsMap[key])).filter(
        icon => iconVariant === icon.theme
      ),
    [iconVariant, keys]
  )

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <Box
        sx={{
          height: '96px',
          backgroundColor: theme => theme.palette.text.primary,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 0px 8px 8px'
        }}
      >
        <TextField
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          label="Search term"
          autoFocus
          style={{ flexGrow: 2 }}
        />
        <Select
          value={iconVariant}
          onChange={e => setIconVariant(e.target.value)}
          options={['Filled', 'Outlined', 'Rounded', 'Two tone', 'Sharp'].map(variant => ({
            label: variant,
            value: variant
          }))}
          label="Type"
          disablePadding
          style={{ flexGrow: 4 }}
        />
        <IconButton
          onClick={() => setOpen(false)}
          style={{ flexGrow: 1 }}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <ClearIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      <Box sx={{ height: '386px', overflow: 'auto', padding: '24px 0px 16px 24px' }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${icons.length} matching results`}</Typography>
        <MuiIcons icons={icons} onClickIcon={onClickIcon} setOpen={setOpen} />
      </Box>
    </Dialog>
  )
}
