import React from 'react'
import { SelectButtonGroup, SelectButtonGroupProps, SelectButtonGroupSchema } from '@uniqore/module'
import { ScreenComponent } from 'Features/canvas'

interface SelectButtonGroupRenderProps {
  component: ScreenComponent
}

const SelectButtonGroupRender: React.FC<SelectButtonGroupRenderProps> = ({ component }) => {
  const props = component.props as SelectButtonGroupProps

  return (
    <SelectButtonGroup
      selectButtons={props.selectButtons?.map(v => {
        const selectButton = v as SelectButtonGroupSchema
        const color = selectButton.color as SelectButtonGroupSchema['color'] & 'default'
        return {
          ...selectButton,
          color: color !== 'default' ? color : undefined
        }
      })}
      buttonsColor={props.buttonsColor}
      variant={props.variant || 'elevated'}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    />
  )
}

export default SelectButtonGroupRender
