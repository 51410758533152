import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SaveHelperState {
  isCurrentlySaving: boolean
  isSaveableChangesMade: boolean
  isSaveSuccessful: boolean
}

const initialState: SaveHelperState = {
  isCurrentlySaving: false,
  isSaveableChangesMade: false,
  isSaveSuccessful: true
}

const saveHelperSlice = createSlice({
  name: 'saveHelper',
  initialState,
  reducers: {
    setCurrentlySaving: (
      state,
      action: PayloadAction<{ isSaving: boolean; isSaveSuccessful?: boolean }>
    ) => {
      const { isSaving, isSaveSuccessful } = action.payload
      state.isCurrentlySaving = isSaving
      if (isSaveSuccessful !== undefined) {
        state.isSaveSuccessful = isSaveSuccessful
      }
    },
    setIsSaveableChangesMade: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isSaveableChangesMade = action.payload.value
    },
    clearSaveHelper: () => initialState
  }
})

export const { setCurrentlySaving, setIsSaveableChangesMade, clearSaveHelper } =
  saveHelperSlice.actions
export default saveHelperSlice.reducer
