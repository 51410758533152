import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import SpinnerMui from 'Components/Common/SpinnerMui'
import Select, { SelectOption } from 'Components/Common/Select'
import { ButtonStyled } from '../../WorkspaceView'
import { doc, updateDoc, DocumentReference, serverTimestamp } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { WorkspaceSchema, Document, ThemeSchema } from 'types/firebase'
import { editSnackbar } from 'Features/snackbar'
import { setSelectedTheme } from 'Features/workspaceView'

const getFolderOptions = (folders: string[]): SelectOption[] => {
  const folderOptions: SelectOption[] = [
    {
      label: 'All projects (Home)',
      value: 'none'
    }
  ]
  folders.forEach(folder => {
    folderOptions.push({
      label: folder,
      value: folder
    })
  })
  return folderOptions
}

interface MoveFolderDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentForDialogs: { document: Document; type: 'design' | 'theme' } | undefined
  folders: string[]
  workspace: WorkspaceSchema
}

const MoveFolderDialog: React.FC<MoveFolderDialogProps> = ({
  open,
  setOpen,
  documentForDialogs,
  folders,
  workspace
}) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const selectedTheme = useTypedSelector(state => state.workspaceView.selectedTheme)
  const [loading, setLoading] = useState<boolean>(false)
  const [changeToFolder, setChangeToFolder] = useState<string>('')
  const [docRef, setDocRef] = useState<DocumentReference>()

  useEffect(() => {
    if (open && documentForDialogs) {
      setChangeToFolder(documentForDialogs.document.folder || 'none')
      if (documentForDialogs.type === 'design') {
        setDocRef(
          doc(firestore, 'Workspaces', workspace.id, 'Designs', documentForDialogs.document.id)
        )
      }
      if (documentForDialogs.type === 'theme') {
        setDocRef(
          doc(firestore, 'Workspaces', workspace.id, 'Themes', documentForDialogs.document.id)
        )
      }
    }
  }, [open, documentForDialogs])

  const handleFolderMove = async () => {
    if (docRef) {
      try {
        setLoading(true)
        const folder = changeToFolder === 'none' ? '' : changeToFolder
        await updateDoc(docRef, { folder: folder, updatedAt: serverTimestamp() })
        setLoading(false)
        setOpen(false)
        // If user is in ThemeCreation view and updates the folder where theme is located,
        // update also selectedTheme in workspaceView-reducer
        if (selectedTheme && documentForDialogs && documentForDialogs.type === 'theme') {
          dispatch(
            setSelectedTheme({
              theme: {
                ...documentForDialogs.document,
                folder: changeToFolder === 'none' ? '' : changeToFolder
              } as ThemeSchema
            })
          )
        }
      } catch (error) {
        setLoading(false)
        dispatch(
          editSnackbar({
            message: `Error. ${error}`,
            type: 'error'
          })
        )
      }
    } else {
      dispatch(
        editSnackbar({
          message: `Something went wrong, please try again.`,
          type: 'error'
        })
      )
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Move to folder
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText variant="body1" color="text.secondary">
            Choose the folder for{' '}
            <Typography
              variant="body1"
              color="text.secondary"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {documentForDialogs?.document.title}
            </Typography>
            .
          </DialogContentText>
          <Select
            value={changeToFolder}
            onChange={e => setChangeToFolder(e.target.value)}
            label="Folder"
            options={getFolderOptions(folders)}
            disabled={folders.length === 0}
          />
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {loading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '43px'
              }}
            />
          ) : null}
          <ButtonStyled onClick={() => setOpen(false)} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={handleFolderMove}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            Move
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default MoveFolderDialog
