import React, { useCallback } from 'react'
import { useTypedSelector } from 'Store'
import { styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'

const Draggable = styled('div')(() => ({
  width: '10px',
  cursor: 'ew-resize',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  zIndex: 9000
}))

interface RightSidebarProps {
  rightSidebarWidth: number
  setRightSidebarWidth: React.Dispatch<React.SetStateAction<number>>
}

const RightSidebar: React.FC<RightSidebarProps> = ({ rightSidebarWidth, setRightSidebarWidth }) => {
  const { rightSidebar } = useTypedSelector(state => state.layout)

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback(e => {
    const screenWidth = document.body.clientWidth
    const newWidth = screenWidth - e.clientX
    const widthPercent = (newWidth / screenWidth) * 100
    if (widthPercent > 20 && widthPercent < 75) {
      setRightSidebarWidth(newWidth)
    }
  }, [])

  return (
    <Drawer
      open={rightSidebar.open}
      variant="persistent"
      anchor="right"
      PaperProps={{
        sx: {
          width: rightSidebarWidth,
          height: theme => `calc(100% - ${theme.props.navBarHeight})`,
          top: theme => theme.props.navBarHeight
        }
      }}
    >
      <Draggable onMouseDown={handleMouseDown} />
    </Drawer>
  )
}

export default RightSidebar
