import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ScreenId } from 'Features/canvas'
import { DesignSchema, ThemeSchema, CanvasScreen } from 'types/firebase'

export interface ProtoDocumentData {
  screens: Record<ScreenId, CanvasScreen>
  theme: ThemeSchema
  design: DesignSchema
}

interface Share {
  shareDialogOpen: boolean
  previewDialogOpen: boolean
  protoDocumentData: ProtoDocumentData | null
}

const initialState: Share = {
  shareDialogOpen: false,
  previewDialogOpen: false,
  protoDocumentData: null
}

const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    openShareDialog: (state, action: PayloadAction<{ value: boolean }>) => {
      state.shareDialogOpen = action.payload.value
    },
    openPreviewDialog: (state, action: PayloadAction<{ value: boolean }>) => {
      state.previewDialogOpen = action.payload.value
    },
    setProtoDocumentData: (
      state,
      action: PayloadAction<{ protoDocumentData: ProtoDocumentData | null }>
    ) => {
      state.protoDocumentData = action.payload.protoDocumentData
    },
    clearShare: () => initialState
  }
})

export const { openShareDialog, openPreviewDialog, setProtoDocumentData, clearShare } =
  shareSlice.actions
export default shareSlice.reducer
