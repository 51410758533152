import React from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { ButtonStyled } from '../../WorkspaceView'
import { useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { Document, WorkspaceSchema, ThemeSchema } from 'types/firebase'
import { PaletteSchema, defaultPaletteSimple, defaultTypography } from 'Util/ThemeValues'

interface ResetThemeDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentForDialogs: { document: Document; type: 'design' | 'theme' } | undefined
  workspace: WorkspaceSchema
}

const ResetThemeDialog: React.FC<ResetThemeDialogProps> = ({
  open,
  setOpen,
  documentForDialogs,
  workspace
}) => {
  const { updateTheme, themeSaveLoading } = useThemes(workspace.id)

  const handleResetTheme = (theme: ThemeSchema) => {
    updateTheme(theme, {
      palette: defaultPaletteSimple as PaletteSchema,
      typography: defaultTypography
    })
    setOpen(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <InfoIcon sx={{ color: theme => theme.palette.error.main, marginRight: '16px' }} />
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Are you sure?
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText component="div" variant="body1" color="text.secondary">
            Are you sure you want to reset to default theme? This can&apos;t be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {themeSaveLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '70px'
              }}
            />
          ) : null}
          <ButtonStyled onClick={() => setOpen(false)} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={() => handleResetTheme(documentForDialogs?.document as ThemeSchema)}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={themeSaveLoading}
          >
            Reset theme
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default ResetThemeDialog
