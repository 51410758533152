import React from 'react'
import { UqListItem, UqListItemProps, UqListItemIconProps } from '@uniqore/wrapper'
import { AddProps } from '@uniqore/module'
import {
  UqListItemPropsExtended,
  UqListItemIconPropsExtended,
  UqAvatarPropsCustom,
  UqListItemSecondaryActionPropsCustom
} from 'Util/CanvasComponents'
import UqListItemAvatarRender from './UqListItemAvatarRender'
import UqListItemIconRender from './UqListItemIconRender'
import UqListItemTextRender from './UqListItemTextRender'
import UqListItemSecondaryActionRender from './UqListItemSecondaryActionRender'
import UqListItemSecondaryActionAddRender from './UqListItemSecondaryActionAddRender'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'

interface UqListItemRenderProps {
  component: ScreenComponent
}

const UqListItemRender: React.FC<UqListItemRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()

  const props = component.props as UqListItemProps & UqListItemPropsExtended

  return (
    <UqListItem
      onClick={() => onClickAction(component.actions)}
      alignItems={props.alignItems}
      dense={props.dense}
      disableGutters={props.disableGutters}
      disablePadding={props.disablePadding}
      divider={props.divider}
    >
      {/* Avatar */}
      {props.listItemAvatars &&
        Object.values(props.listItemAvatars).map((listItemAvatar, i) => (
          <UqListItemAvatarRender
            key={i}
            componentProps={listItemAvatar.props as UqAvatarPropsCustom}
          />
        ))}
      {/* Icon */}
      {props.listItemIcons &&
        Object.values(props.listItemIcons).map((listItemIcon, i) => (
          <UqListItemIconRender
            key={i}
            componentProps={listItemIcon.props as UqListItemIconProps & UqListItemIconPropsExtended}
          />
        ))}
      {/* Primary & Secondary texts */}
      {props.primary || props.secondary ? (
        <UqListItemTextRender primary={props.primary} secondary={props.secondary} />
      ) : null}
      {/* Secondary action.  Do not show if 'Add'-module is added */}
      {props.listItemSecondaryActions && Object.keys(props?.add || {}).length < 1
        ? Object.values(props.listItemSecondaryActions).map((action, i) => (
            <UqListItemSecondaryActionRender
              key={i}
              componentProps={action.props as UqListItemSecondaryActionPropsCustom}
            />
          ))
        : null}
      {/* Add -module */}
      {props.add &&
        Object.values(props.add).map((add, i) => (
          <UqListItemSecondaryActionAddRender key={i} componentProps={add.props as AddProps} />
        ))}
    </UqListItem>
  )
}

export default UqListItemRender
