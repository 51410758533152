import React from 'react'
import { Property } from 'csstype'
import equal from 'fast-deep-equal'
import { styled } from '@mui/material/styles'
import { Box, Divider, SelectChangeEvent, Typography } from '@mui/material'
import TypographyStylePicker from 'Components/Common/TypographyStylePicker'
import SectionArea from '../SectionArea'
import { ButtonStyled } from '../ThemeCreation'
import { TypographyVariant, TypographySchema } from 'Util/ThemeValues'
import { ThemeSchema } from 'types/firebase'

export const TypographyContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const TypographyStyle = styled(Typography)<{
  typographystyles: object
}>(({ typographystyles }) => ({
  ...typographystyles
}))

interface TypographySectionProps {
  selectedTheme: ThemeSchema
  typography: TypographySchema
  setTypography: React.Dispatch<React.SetStateAction<TypographySchema>>
}

const TypographySection: React.FC<TypographySectionProps> = ({
  selectedTheme,
  typography,
  setTypography
}) => {
  const updateFontWeight = (event: SelectChangeEvent<string>, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], fontWeight: event.target.value }
    })
  }

  const updateFontStyle = (isItalic: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], fontStyle: isItalic ? 'italic' : 'normal' }
    })
  }

  const updateTextDecoration = (isUnderline: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], textDecoration: isUnderline ? 'underline' : 'initial' }
    })
  }

  const updateTextTransform = (isUpperCase: boolean, variant: TypographyVariant) => {
    setTypography({
      ...typography,
      [variant]: { ...typography[variant], textTransform: isUpperCase ? 'uppercase' : 'none' }
    })
  }

  const handleResetTypography = () => {
    setTypography(selectedTheme.typography)
  }

  return (
    <SectionArea
      title="Typography"
      components={[
        <ButtonStyled
          key={1}
          variant="text"
          color="error"
          size="small"
          disableFocusRipple
          disabled={equal(typography, selectedTheme.typography)}
          onClick={handleResetTypography}
        >
          Discard changes
        </ButtonStyled>
      ]}
    >
      <Box>
        <TypographyContainer>
          <TypographyStyle typographystyles={typography.h5} color="text.primary">
            Headline
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.h5.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'h5')}
            fontStyle={typography.h5.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'h5')}
            textDecoration={typography.h5.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'h5')}
            textTransform={typography.h5.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'h5')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.h6} color="text.primary">
            Title (H6)
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.h6.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'h6')}
            fontStyle={typography.h6.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'h6')}
            textDecoration={typography.h6.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'h6')}
            textTransform={typography.h6.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'h6')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.subtitle1} color="text.primary">
            Subtitle 1
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.subtitle1.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'subtitle1')}
            fontStyle={typography.subtitle1.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'subtitle1')}
            textDecoration={typography.subtitle1.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'subtitle1')}
            textTransform={typography.subtitle1.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'subtitle1')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.subtitle2} color="text.primary">
            Subtitle 2
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.subtitle2.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'subtitle2')}
            fontStyle={typography.subtitle2.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'subtitle2')}
            textDecoration={typography.subtitle2.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'subtitle2')}
            textTransform={typography.subtitle2.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'subtitle2')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.body1} color="text.primary">
            Body 1
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.body1.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'body1')}
            fontStyle={typography.body1.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'body1')}
            textDecoration={typography.body1.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'body1')}
            textTransform={typography.body1.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'body1')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.body2} color="text.primary">
            Body 2
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.body2.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'body2')}
            fontStyle={typography.body2.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'body2')}
            textDecoration={typography.body2.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'body2')}
            textTransform={typography.body2.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'body2')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.button} color="text.primary">
            Button
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.button.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'button')}
            fontStyle={typography.button.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'button')}
            textDecoration={typography.button.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'button')}
            textTransform={typography.button.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'button')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.caption} color="text.primary">
            Caption
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.caption.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'caption')}
            fontStyle={typography.caption.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'caption')}
            textDecoration={typography.caption.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'caption')}
            textTransform={typography.caption.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'caption')}
          />
        </TypographyContainer>
        <Divider />

        <TypographyContainer>
          <TypographyStyle typographystyles={typography.overline} color="text.primary">
            Overline
          </TypographyStyle>
          <TypographyStylePicker
            fontWeight={typography.overline.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'overline')}
            fontStyle={typography.overline.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'overline')}
            textDecoration={typography.overline.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'overline')}
            textTransform={typography.overline.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'overline')}
          />
        </TypographyContainer>
      </Box>
    </SectionArea>
  )
}

export default React.memo(TypographySection)
