import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { UqListProps } from '@uniqore/wrapper'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragStart,
  DragUpdate,
  DropResult
} from 'react-beautiful-dnd'
import { IconType } from 'Components/Common/Icons'
import AddElementsMenu from 'Components/Common/Editing/AddElementsMenu'
import ComponentBox from 'Components/Common/ComponentBox'
import CollapseMenu from 'Components/Common/CollapseMenu'
import Switch from 'Components/Common/Switch'
import { Divider, Box } from '@mui/material'
import { ScreenComponent, deepCopyComponent } from 'Features/canvas'
import { selectComponent, updateComponent, deleteComponent } from 'Features/canvasScreens'
import {
  UqListPropsExtended,
  UqListItemPropsExtended,
  UqListItemIconPropsExtended
} from 'Util/CanvasComponents'
import { useChildComponent } from './ChildComponentHooks'

const queryAttr = 'data-rbd-drag-handle-draggable-id'

const onDragStart = (
  event: DragStart,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>,
  setDraggingId: Dispatch<SetStateAction<string>>
) => {
  const draggableId = event.draggableId
  const domQuery = `[${queryAttr}='${draggableId}']`
  const draggedDOM = document.querySelector(domQuery)

  if (!draggedDOM) return
  const { clientHeight, clientWidth, parentNode } = draggedDOM
  if (!parentNode) return

  const sourceIndex = event.source.index
  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    [...parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const style = curr.currentStyle || window.getComputedStyle(curr)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(parentNode as Element).paddingLeft)
  })

  setDraggingId(event.draggableId)
}

const onDragUpdate = (
  update: DragUpdate,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>
) => {
  if (!update.destination) return
  const draggableId = update.draggableId
  const destinationIndex = update.destination.index

  const domQuery = `[${queryAttr}='${draggableId}']`
  const draggedDOM = document.querySelector(domQuery)

  if (!draggedDOM) return
  const { clientHeight, clientWidth, parentNode } = draggedDOM
  if (!parentNode) return

  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    [...parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const style = curr.currentStyle || window.getComputedStyle(curr)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(parentNode as Element).paddingLeft)
  })
}

const onDragEnd = (
  result: DropResult,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>,
  setDraggingId: Dispatch<SetStateAction<string>>,
  dispatch: Dispatch<unknown>,
  screenId: string,
  parentComponent: ScreenComponent,
  childrenComponentsIds: ScreenComponent[]
) => {
  setPlaceholder(undefined)
  setDraggingId('')

  if (result.destination) {
    const items = Array.from(childrenComponentsIds.map(c => c.id))
    const [reOrderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reOrderedItem)
    const component = deepCopyComponent(parentComponent)
    component.props.childrenComponents = items
    dispatch(updateComponent({ screenId, component, section: 'contents' }))
  }
}

interface PlaceholderSchema {
  clientY: number
  clientX: number
  clientHeight: number
  clientWidth: number
}

const getValue = (childComponent: ScreenComponent, propName: string): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return childComponent.props[propName] || ''
}

const getIcon = (childComponent: ScreenComponent): IconType | undefined => {
  let icon = undefined
  const props = childComponent.props as UqListItemPropsExtended
  if (props.listItemIcons) {
    Object.values(props.listItemIcons).forEach(value => {
      const iconProps = value.props as UqListItemIconPropsExtended
      icon = iconProps.icon
    })
  }
  return icon
}

interface UqListEditProps {
  setComponentProps: React.Dispatch<unknown>
  componentToEdit: ScreenComponent
  selectedScreenId: string
}

const UqListEdit: React.FC<UqListEditProps> = ({
  setComponentProps,
  componentToEdit,
  selectedScreenId
}) => {
  const dispatch = useDispatch()
  const contents = useTypedSelector(
    state => state.undoables.present.canvasScreens.screens[selectedScreenId].components.contents
  )
  const { addChildComponent, deleteChildComponent } = useChildComponent()

  const [childrenComponents, setChildrenComponents] = useState<ScreenComponent[]>([])
  const [placeholder, setPlaceholder] = useState<PlaceholderSchema>()
  const [draggingId, setDraggingId] = useState<string>('')
  const [props, setProps] = useState<UqListProps & UqListPropsExtended>(
    componentToEdit.props as UqListProps & UqListPropsExtended
  )

  useEffect(() => {
    if (componentToEdit.props) {
      setProps(componentToEdit.props as UqListProps & UqListPropsExtended)
    }
  }, [componentToEdit.props])

  // TODO: this useEffect can be deleted. There was a bug that added
  // multiple ListItems without proper parent List-component.
  // Checks parentless components and delete them
  useEffect(() => {
    // ListItems that have List's id as their parentComponent,
    // but if this List doesn't have ListItem's id in its 'childrenComponents'-array,
    // add them to parentless-array and remove them
    const parentless: ScreenComponent[] = []
    const listItems: ScreenComponent[] = []
    const childrenIds = props.childrenComponents || []
    contents
      .filter(c => c.parentComponentId)
      .forEach(content => {
        if (content.parentComponentId === componentToEdit.id) {
          listItems.push(content)
        }
      })
    listItems.forEach(c => {
      if (!childrenIds.includes(c.id)) {
        parentless.push(c)
      }
    })
    // If any parentless ListItems are found, delete them
    parentless.forEach(c => {
      dispatch(
        deleteComponent({ screenId: selectedScreenId, componentId: c.id, section: 'contents' })
      )
    })
  }, [])

  useEffect(() => {
    // ListItems that this List has within its 'childrenComponents'-array
    const children: ScreenComponent[] = []
    const childrenIds = props.childrenComponents || []
    childrenIds.forEach(childId => {
      contents
        .filter(c => c.parentComponentId)
        .forEach(content => {
          if (content.id === childId) {
            children.push(content)
          }
        })
    })

    setChildrenComponents(children)
  }, [contents, props.childrenComponents])

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propName: 'dense' | 'disablePadding'
  ) => {
    setProps({ ...props, [propName]: event.target.checked })
    setComponentProps({ ...props, [propName]: event.target.checked })
  }

  const handleSingleClick = (
    _e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    dispatch(
      selectComponent({
        stageComponent: true,
        component,
        parentComponent: componentToEdit
      })
    )
  }

  const handleDoubleClick = (
    _e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    dispatch(
      selectComponent({
        stageComponent: false,
        component,
        parentComponent: componentToEdit
      })
    )
  }

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation()
    deleteChildComponent(selectedScreenId, componentToEdit, id, 'contents')
  }

  const handleCopy = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    e.stopPropagation()
    addChildComponent(selectedScreenId, componentToEdit, 'contents', {
      componentName: 'uqListItem',
      props: component.props
    })
  }

  return (
    <>
      <CollapseMenu text="CONTENT">
        <AddElementsMenu
          label="List items"
          action={{
            type: 'add',
            onClick: () =>
              addChildComponent(selectedScreenId, componentToEdit, 'contents', {
                componentName: 'uqListItem'
              })
          }}
        />
        <div style={{ margin: '16px', marginTop: '8px' }}>
          <DragDropContext
            onDragStart={event => onDragStart(event, setPlaceholder, setDraggingId)}
            onDragUpdate={event => onDragUpdate(event, setPlaceholder)}
            onDragEnd={event =>
              onDragEnd(
                event,
                setPlaceholder,
                setDraggingId,
                dispatch,
                selectedScreenId,
                componentToEdit,
                childrenComponents
              )
            }
          >
            <Droppable droppableId="listItems">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ position: 'relative', marginTop: '8px' }}
                >
                  {childrenComponents.map((childComponent, index) => (
                    <Draggable
                      key={childComponent.id}
                      draggableId={childComponent.id}
                      index={index}
                    >
                      {provided => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={{
                            marginTop: '0px',
                            marginBottom: '8px',
                            ...provided.draggableProps.style
                          }}
                        >
                          <ComponentBox
                            componentId={childComponent.id}
                            isDragging={draggingId === childComponent.id}
                            primary={getValue(childComponent, 'primary')}
                            secondary={getValue(childComponent, 'secondary')}
                            icon={getIcon(childComponent)}
                            secondaryIcon="Remove"
                            onClick={e => handleSingleClick(e, childComponent)}
                            onDoubleClick={e => handleDoubleClick(e, childComponent)}
                            onClickIcon={e => handleRemove(e, childComponent.id)}
                            onClickCopy={e => handleCopy(e, childComponent)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {placeholder && snapshot.isDraggingOver && (
                    <Box
                      sx={{
                        position: 'absolute',
                        borderRadius: '4px',
                        border: theme => `1px dashed ${theme.palette.text.disabled}`
                      }}
                      style={{
                        top: placeholder?.clientY,
                        left: placeholder?.clientX,
                        height: placeholder?.clientHeight,
                        width: placeholder?.clientWidth
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CollapseMenu>
      <Divider />
      <CollapseMenu text="STYLE">
        <Switch
          label="Dense"
          checked={props.dense}
          onChange={e => handleSwitchChange(e, 'dense')}
        />
        <Switch
          label="Disable Padding"
          checked={props.disablePadding}
          onChange={e => handleSwitchChange(e, 'disablePadding')}
        />
      </CollapseMenu>
      <Divider />
    </>
  )
}

export default UqListEdit
