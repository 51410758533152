import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ReactComponent as LoginIcon } from 'assets/images/login.svg'
import { changeAuth } from 'Features/auth'

const Content = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.palette.secondary.dark,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const InputWrapper = styled('div')(() => ({
  backgroundColor: 'white',
  borderRadius: '5px',
  marginTop: 24,
  minWidth: 300
}))

const GoBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[400],
  textDecoration: 'underline',
  '& .MuiButton-text': {
    color: theme.palette.grey[400],
    fontWeight: 400
  },
  '&:hover': {
    background: 'none'
  }
}))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset(
    $email: EmailAddress!
    $type: LoginType
    $organization: ID
    $lang: String
  ) {
    requestPasswordReset(email: $email, type: $type, organization: $organization, lang: $lang) {
      error
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      error
    }
  }
`

const PasswordReset: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const token = query.get('token')
  const [isEmailRequest] = useState<boolean>(token === null)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const [requestPasswordReset, { loading: requestPasswordResetLoading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onCompleted(result) {
        if (result.requestPasswordReset.error) {
          setError(result.requestPasswordReset.error)
        } else {
          setSuccess('Password reset email is on the way!')
        }
      },
      onError(error) {
        if (error) {
          setError(`Error: ${error}`)
        }
      }
    }
  )

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(RESET_PASSWORD, {
    onCompleted(result) {
      if (result.resetPassword.error) {
        setError(result.resetPassword.error)
      } else {
        setPassword('')
        setPasswordRepeat('')
        dispatch(changeAuth({ value: true, key: 'passwordResetDone' }))
        navigate('/login')
      }
    },
    onError(error) {
      if (error) {
        setError(`Error: ${error}`)
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    setError('')
    if (isEmailRequest) {
      if (email === '') {
        setError('Email cannot be empty.')
      } else {
        requestPasswordReset({
          variables: {
            email: email,
            type: 'ORGUSER',
            organization: 'designstudio',
            lang: 'fi'
          }
        })
      }
    } else {
      if (password === '' || password !== passwordRepeat) {
        setError('Password cannot be empty or passwords do not match')
      } else {
        resetPassword({
          variables: {
            token: token,
            newPassword: password
          }
        })
      }
    }
  }

  return (
    <Content>
      <form style={{ textAlign: 'center' }} onSubmit={submit}>
        <Box sx={{ paddingBottom: '8px' }}>
          <LoginIcon />
        </Box>
        <Typography style={{ color: '#FAFAFA', paddingBottom: '16px' }} variant="h5">
          Reset your password
        </Typography>
        {success ? (
          <>
            <Typography sx={{ color: 'white' }}>{success}</Typography>
            <Typography
              sx={{ color: 'white', padding: '24px 16px 8px 16px' }}
              variant="caption"
              component="div"
            >
              If you haven&apos;t received the reset password email, check the email and
              organization that you provided in the previous page and try again.
            </Typography>
            <GoBackButton variant="text" onClick={() => setSuccess('')}>
              Go back
            </GoBackButton>
          </>
        ) : (
          <>
            {isEmailRequest ? (
              <>
                <InputWrapper>
                  <TextField
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    placeholder="Email"
                    fullWidth
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                  />
                </InputWrapper>
              </>
            ) : (
              <>
                <InputWrapper>
                  <TextField
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    placeholder="Password"
                    fullWidth
                    autoComplete="false"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextField
                    value={passwordRepeat}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPasswordRepeat(e.target.value)
                    }
                    placeholder="Password again"
                    fullWidth
                    autoComplete="false"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!error}
                    helperText={error}
                  />
                </InputWrapper>
              </>
            )}
            <>
              <Box sx={{ position: 'relative' }}>
                {requestPasswordResetLoading || resetPasswordLoading ? (
                  <SpinnerMui
                    color="white"
                    size={30}
                    style={{
                      position: 'absolute',
                      bottom: '23px',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  />
                ) : null}
                <Button
                  sx={{
                    margin: '24px 0px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                    '&:hover': {
                      background: theme => theme.palette.secondary.light
                    }
                  }}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={requestPasswordResetLoading || resetPasswordLoading}
                >
                  {isEmailRequest ? 'RESET PASSWORD' : 'CHANGE PASSWORD'}
                </Button>
              </Box>
              {isEmailRequest && (
                <GoBackButton variant="text" onClick={() => navigate('/login')}>
                  Go back
                </GoBackButton>
              )}
            </>
          </>
        )}
      </form>
    </Content>
  )
}

export default PasswordReset
