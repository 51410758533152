import React from 'react'
import { UqAvatar, UqChipProps } from '@uniqore/wrapper'
import { UqAvatarPropsCustom } from 'Util/CanvasComponents'

interface UqAvatarRenderProps {
  componentProps: UqAvatarPropsCustom
  chipSize?: UqChipProps['size']
}

const UqAvatarRender: React.FC<UqAvatarRenderProps> = ({ componentProps, chipSize }) => {
  return (
    <UqAvatar
      src={componentProps.type === 'image' ? componentProps.image : undefined}
      variant={componentProps.variant}
      sx={
        chipSize === 'medium'
          ? { marginLeft: '4px', width: 24, height: 24 }
          : chipSize === 'small'
          ? { marginLeft: '2px', width: 18, height: 18 }
          : {}
      }
    >
      {componentProps.type === 'text' ? componentProps.text : null}
    </UqAvatar>
  )
}

export default UqAvatarRender
