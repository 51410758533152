import React, { useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { doc, onSnapshot } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { updateDesign, setDesignId, removeDesign } from 'Features/firebaseDocuments'
import { DesignSchema } from 'types/firebase'

interface DesignsSubscriptionProps {
  designId: string
  workspaceId: string
}

const DesignsSubscription: React.FC<DesignsSubscriptionProps> = ({ designId, workspaceId }) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()

  const removeDesignFromReducer = (id: string) => {
    dispatch(removeDesign({ id }))
    // Remove designs' id from the subscription list (LayoutSection-component)
    dispatch(setDesignId({ removeId: id }))
  }

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, 'Workspaces', workspaceId, 'Designs', designId),
      design => {
        if (design.metadata.hasPendingWrites) return
        if (!design.data()) return
        if (design.data()?.deleted) {
          removeDesignFromReducer(design.id)
          return
        }
        const handleDesign: DesignSchema = {
          id: design.id,
          title: design.data()?.title || '',
          folder: design.data()?.folder || '',
          deleted: design.data()?.deleted,
          icon: design.data()?.icon || 'StarBorder',
          createdAt: design.data()?.createdAt
            ? moment(design.data()?.createdAt.toDate()).toISOString(true)
            : undefined,
          updatedAt: design.data()?.updatedAt
            ? moment(design.data()?.updatedAt.toDate()).toISOString(true)
            : undefined,
          thumbnailColor: design.data()?.thumbnailColor,
          themeId: design.data()?.themeId,
          themeOverride: design.data()?.themeOverride,
          modifiedBy: design.data()?.modifiedBy,
          layoutProps: design.data()?.layoutProps
        }
        dispatch(updateDesign({ design: handleDesign }))
      }
    )
    return () => unsub()
  }, [])

  return null
}

export default React.memo(DesignsSubscription)
