import React from 'react'
import { Image, ImageProps } from '@uniqore/module'
import ImagePlaceholder from 'assets/images/image-module-placeholder.svg'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'

interface ImageRenderProps {
  component: ScreenComponent
}

const ImageRender: React.FC<ImageRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const props = component.props as ImageProps

  return (
    <Image
      onClick={() => onClickAction(component.actions)}
      src={props.src || ImagePlaceholder}
      alt="Check image source"
      width={props.width}
      center={props.center}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    />
  )
}

export default ImageRender
