import React from 'react'
import ColorPicker from 'Components/Common/ColorPicker'
import { TextColorProps } from '../ColorsAdvanced'

const TextColor: React.FC<TextColorProps> = ({ colors, palette, updateText }) => {
  return (
    <div style={{ marginLeft: '16px' }}>
      <ColorPicker
        label="Primary"
        colors={colors}
        colorFromState={palette.text.primary}
        paletteKey="text"
        colorKey="primary"
        updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'primary')}
      />
      <ColorPicker
        label="Secondary"
        colors={colors}
        colorFromState={palette.text.secondary}
        paletteKey="text"
        colorKey="secondary"
        updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'secondary')}
      />
      <ColorPicker
        label="Disabled"
        colors={colors}
        colorFromState={palette.text.disabled}
        paletteKey="text"
        colorKey="disabled"
        updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'disabled')}
      />
    </div>
  )
}

export default TextColor
