import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Box } from '@mui/material'
import LayoutSections from './LayoutSections'
import WorkspaceFetch from './WorkspaceView/WorkspaceFetch'
import NavBar from 'Components/Layout/NavBar'
import PreviewNavBar from 'Components/Layout/Preview/PreviewNavBar'
import LoginDialog from 'Components/Auth/LoginDialog'
import LogoutDialog from 'Components/Auth/LogoutDialog'
import LoggedInUserHandler from 'Components/Auth/LoggedInUserHandler'
import { useWorkspaces } from 'Components/Firebase/Hooks/FirebaseHooks'
import { editWorkspace } from 'Features/workspaceView'

const Layout: React.FC = () => {
  const dispatch = useDispatch()
  const [logoutDialogOpen, setLogoutDialogOpen] = useState<boolean>(false)
  const { workspace } = useTypedSelector(state => state.workspaceView)
  const { previewOn } = useTypedSelector(state => state.preview)
  const loggedInUser = useTypedSelector(state => state.loggedInUser)
  const { workspacesData, workspacesLoading, refetchWorkSpaces } = useWorkspaces()

  // Get workspaces
  useEffect(() => {
    // TODO: add possibility to change the workspace
    if (workspacesData) {
      dispatch(editWorkspace({ workspace: workspacesData.designStudioWorkspaces[0] }))
    }
  }, [workspacesData])

  // Refetch workspaces if session was expired
  useEffect(() => {
    if (loggedInUser.id && !workspace) {
      refetchWorkSpaces()
    }
  }, [loggedInUser.id, workspace])

  return (
    <Box sx={{ position: 'relative', height: '100vh' }}>
      <LoggedInUserHandler />
      {previewOn ? <PreviewNavBar /> : <NavBar setLogoutDialogOpen={setLogoutDialogOpen} />}
      <LoginDialog />
      <LogoutDialog open={logoutDialogOpen} setOpen={setLogoutDialogOpen} />
      {!workspace && loggedInUser.id ? (
        <WorkspaceFetch workspacesLoading={workspacesLoading} />
      ) : null}
      {workspace && loggedInUser.id ? <LayoutSections workspace={workspace} /> : null}
    </Box>
  )
}

export default Layout
