import React from 'react'
import Icons from 'Components/Common/Icons'
import { UqFab, UqFabProps } from '@uniqore/wrapper'
import { UqFabPropsExtended } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'

interface UqFabRenderProps {
  component: ScreenComponent
}

const UqFabRender: React.FC<UqFabRenderProps> = ({ component }) => {
  const props = component.props as UqFabProps & UqFabPropsExtended

  return (
    <UqFab
      color={props.color}
      variant={props.variant}
      size={props.size}
      style={{ ...component.style }}
    >
      {props.icon
        ? React.createElement(Icons[props.icon], {
            style: props.label ? { marginRight: '4px' } : {}
          })
        : null}
      {props.label ? props.label : null}
    </UqFab>
  )
}

export default UqFabRender
