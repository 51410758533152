/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LayoutProps } from '@uniqore/module'
import { WritableDraft } from 'immer/dist/internal'
import { XYPosition } from 'react-flow-renderer'
import {
  GRID_WIDTH,
  GRID_HEIGHT,
  ScreenId,
  ComponentId,
  ScreenConnectionType,
  ScreenComponent,
  ComponentSections
} from 'Features/canvas'
import { CanvasScreen } from 'types/firebase'
import { latestScreenVersion } from 'Components/Firebase/Version/ScreenVersionHandler'

export enum ConnectionDir {
  Up,
  Right,
  Down
}

export interface ConnectionPosition {
  position: XYPosition
  direction: ConnectionDir
}

const connPosition = (source: CanvasScreen, index: number): ConnectionPosition => {
  let cy = 0
  let dir = ConnectionDir.Right
  const cx = source.geometry.x + GRID_WIDTH
  // If we have 0 conections, position the new screen on the right
  // If we have odd number of connections, position down right
  // Else (even connections), position up right
  if (index === 0) {
    cy = source.geometry.y
    dir = ConnectionDir.Right
  } else if (index % 2 === 1) {
    // 1 => 1, 3 => 2, 5 => 3 etc
    const multplier = Math.floor(index / 2) + 1
    cy = source.geometry.y + GRID_HEIGHT * multplier
    dir = ConnectionDir.Down
  } else {
    // 2 => 1, 4 => 2, 6 => 3 etc
    const multplier = Math.floor(index / 2)
    cy = source.geometry.y - GRID_HEIGHT * multplier
    dir = ConnectionDir.Up
  }

  return { position: { x: cx, y: cy }, direction: dir }
}

export const findConnectionPosition = (
  screens: Record<string, CanvasScreen>,
  source: CanvasScreen
): ConnectionPosition => {
  // y needs to be found with the simple analysis
  let tx = source.geometry.x + GRID_WIDTH // tx doesn't currenlty change
  let ty = -1
  let dir: ConnectionDir = ConnectionDir.Right
  let conIdx = 0

  for (conIdx = 0; conIdx < source.connections.length; conIdx++) {
    const conScreen = screens[source.connections[conIdx].target]
    const cx = conScreen.geometry.x
    const cy = conScreen.geometry.y
    const ch = conScreen.geometry.h
    const cw = conScreen.geometry.w
    const conPos = connPosition(source, conIdx)
    const testY = conPos.position.y

    // Check if the connection screen doesn't overlap with the test coordinates
    if (!(testY >= cy && testY <= cy + ch && tx >= cx && tx <= cx + cw)) {
      ty = testY
      tx = conPos.position.x
      dir = conPos.direction
      break
    }
  }

  // If we didn't find a suitable space, calculate the next one
  if (ty === -1) {
    const conPos = connPosition(source, conIdx)
    tx = conPos.position.x
    ty = conPos.position.y
    dir = conPos.direction
  }

  return { position: { x: tx, y: ty }, direction: dir }
}

export interface CanvasScreenState {
  screens: Record<ScreenId, CanvasScreen> // All of the screens
  selectedEdgeId: string | null // Selected line between the screens
  selectedScreenId: ScreenId | null // Selected screen
  selectedComponentId: string | null // Selected component in a screen, for example a button. (Opens prop-edit, requires doubleClick)
  stagedComponentId: string | null // When component is clicked, but not yet selected (prop-edit is not yet open, but component is outlined)
  parentComponent: ScreenComponent | null // If for example ListItem is selected, its parent is List-component
}

const initialState: CanvasScreenState = {
  screens: {},
  selectedEdgeId: null,
  selectedScreenId: null,
  selectedComponentId: null,
  stagedComponentId: null,
  parentComponent: null
}

const canvasScreensSlice = createSlice({
  name: 'canvasScreen',
  initialState,
  reducers: {
    setScreens: (state, action: PayloadAction<{ screens: CanvasScreen[] }>) => {
      const allScreens: Record<ScreenId, CanvasScreen> = {}
      Object.values(action.payload.screens).forEach(screen => {
        allScreens[screen.id] = { ...screen }
      })
      state.screens = allScreens
    },
    addScreen: (
      state,
      action: PayloadAction<{ id: string; screen: CanvasScreen; source?: CanvasScreen }>
    ) => {
      const { id, screen, source } = action.payload
      state.screens[id] = screen
      screen.version = latestScreenVersion
      if (source) {
        state.screens[source.id].connections.push({
          target: id,
          // TODO: optionally set type and name
          type: ScreenConnectionType.Normal,
          name: 'New Connection'
        })
        state.screens[id].sourceId = source.id
      }
      // Set as selected screen
      state.selectedScreenId = id
    },
    changeFlowStartScreen: (state, action: PayloadAction<{ screenId: string }>) => {
      // There should be only one screen that has 'flowStartPoint' value as true
      const currentStartingScreens = Object.values(state.screens).filter(s => s.flowStartPoint)
      for (const screen of currentStartingScreens) {
        screen.flowStartPoint = false
      }
      state.screens[action.payload.screenId].flowStartPoint = true
    },
    addScreenConnection: (
      state,
      action: PayloadAction<{ sourceScreenId: string; targetScreenId: string }>
    ) => {
      const { sourceScreenId, targetScreenId } = action.payload
      if (sourceScreenId === targetScreenId) return
      const sourceScreen = state.screens[sourceScreenId]
      const targetScreen = state.screens[targetScreenId]
      if (sourceScreen && targetScreen) {
        // Checks if source screen already has this connection
        if (!sourceScreen.connections.find(c => c.target === targetScreen.id)) {
          sourceScreen.connections.push({
            target: targetScreenId,
            // TODO: optionally set type and name
            type: ScreenConnectionType.Normal,
            name: 'New Connection'
          })
          const previousSourceId = targetScreen.sourceId || ''
          const previousSourceScreen = state.screens[previousSourceId]
          if (previousSourceScreen) {
            previousSourceScreen.connections = previousSourceScreen.connections.filter(
              c => c.target !== targetScreen.id
            )
          }
          // Change target screen's source id
          targetScreen.sourceId = sourceScreenId
        }
      }
    },
    deleteScreenConnection: (state, action: PayloadAction<{ edgeId: string }>) => {
      const { edgeId } = action.payload
      state.selectedEdgeId = null
      // Edge id consist both source-screen-id & target-screen-id separated with "_".
      const splitted = edgeId.split('_')
      if (splitted.length === 2) {
        const sourceScreen = state.screens[splitted[0]]
        const targetScreen = state.screens[splitted[1]]
        if (sourceScreen && targetScreen) {
          // Remove target connection from source screen
          sourceScreen.connections = sourceScreen.connections.filter(
            c => c.target !== targetScreen.id
          )
          // Remove sourceId from target screen
          targetScreen.sourceId = null
        }
      }
    },
    editScreenLabel: (state, action: PayloadAction<{ screenId: string; value: string }>) => {
      const { screenId, value } = action.payload
      state.screens[screenId].label = value
    },
    editScreenLayoutProps: (
      state,
      action: PayloadAction<{
        screenId: string
        layoutProps: LayoutProps
      }>
    ) => {
      const { screenId, layoutProps } = action.payload
      state.screens[screenId].layoutProps = layoutProps
    },
    editScreenValue: (
      state,
      action: PayloadAction<{ screenId: string; key: 'useLocalLayoutProps'; value: boolean }>
    ) => {
      const { screenId, key, value } = action.payload
      state.screens[screenId][key] = value
    },
    updateScreenPosition: (state, action: PayloadAction<{ id: string; position: XYPosition }>) => {
      const id = action.payload.id
      const position = action.payload.position
      if (state.screens[id]) {
        state.screens[id].geometry.x = position.x
        state.screens[id].geometry.y = position.y
      }
    },
    deleteScreen: (state, action: PayloadAction<{ screenId: ScreenId }>) => {
      const screen = state.screens[action.payload.screenId]
      // Remove the connection from source if any
      if (screen.sourceId) {
        // Check that sourceScreen actually still exists
        const sourceScreen = state.screens[screen.sourceId]
        if (sourceScreen) {
          const sourceCons = sourceScreen.connections.filter(con => con.target !== screen.id)
          state.screens[screen.sourceId].connections = sourceCons
        }
      }
      // Unlink the source
      screen.connections.forEach(con => (state.screens[con.target].sourceId = null))
      // Then you can safely delete the screen
      delete state.screens[screen.id]
    },
    addComponent: (
      state,
      action: PayloadAction<{
        screenId: ScreenId
        component: ScreenComponent
        section: keyof ComponentSections
      }>
    ) => {
      const { screenId, component, section } = action.payload
      if (state.screens[screenId]) {
        if (!state.screens[screenId].components) {
          state.screens[screenId].components[section] = [component]
        } else {
          state.screens[screenId].components[section].push(component)
        }
      }
    },
    updateComponent: (
      state,
      action: PayloadAction<{
        screenId: ScreenId
        component: ScreenComponent
        section?: keyof ComponentSections
      }>
    ) => {
      const { screenId, component, section } = action.payload
      let sectionKey = section
      if (!sectionKey) {
        const headers = Object.values(state.screens[screenId].components.headers)
        const contents = Object.values(state.screens[screenId].components.contents)
        const footers = Object.values(state.screens[screenId].components.footers)
        if (headers.find(c => c.id === component.id)) sectionKey = 'headers'
        else if (contents.find(c => c.id === component.id)) sectionKey = 'contents'
        else if (footers.find(c => c.id === component.id)) sectionKey = 'footers'
      }
      if (sectionKey) {
        state.screens[screenId].components[sectionKey] = state.screens[screenId].components[
          sectionKey
        ].map(c => (c.id !== component.id ? c : component))
      }
    },
    deleteComponent: (
      state,
      action: PayloadAction<{
        screenId: ScreenId
        componentId: string
        section?: keyof ComponentSections
      }>
    ) => {
      const { screenId, componentId, section } = action.payload
      let sectionKey = section
      if (!sectionKey) {
        const headers = Object.values(state.screens[screenId].components.headers)
        const contents = Object.values(state.screens[screenId].components.contents)
        const footers = Object.values(state.screens[screenId].components.footers)
        if (headers.find(c => c.id === componentId)) sectionKey = 'headers'
        else if (contents.find(c => c.id === componentId)) sectionKey = 'contents'
        else if (footers.find(c => c.id === componentId)) sectionKey = 'footers'
      }
      if (sectionKey) {
        let ids = [componentId]
        const componentToDelete = state.screens[screenId].components[sectionKey].find(
          c => c.id === componentId
        )
        if (componentToDelete) {
          // Checks child components
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ids = ids.concat(componentToDelete.props.childrenComponents || [])
        }
        for (const id of ids) {
          state.screens[screenId].components[sectionKey] = state.screens[screenId].components[
            sectionKey
          ].filter(c => c.id !== id)
        }
      }
    },
    updateComponentsOrder: (
      state,
      action: PayloadAction<{
        screenId: string
        components: ScreenComponent[]
        section: keyof ComponentSections
      }>
    ) => {
      const { screenId, components, section } = action.payload
      state.screens[screenId].components[section] = components
    },
    selectScreen: (state, action: PayloadAction<{ screenId: ScreenId | null }>) => {
      const { screenId } = action.payload
      // Do not select empty canvas indicator
      if (screenId === 'empty-screen') return
      state.selectedScreenId = screenId
    },
    selectComponent: (
      state,
      action: PayloadAction<{
        stageComponent?: boolean
        component?: ScreenComponent | null
        screenId?: string | null
        componentId?: ComponentId | null
        parentComponent?: ScreenComponent
      }>
    ) => {
      const { stageComponent, component, screenId, componentId, parentComponent } = action.payload
      // set component either by providing the component itself
      if (component === null) {
        state.stagedComponentId = null
        state.selectedComponentId = null
      } else if (component) {
        if (stageComponent) state.stagedComponentId = component.id
        else {
          state.stagedComponentId = null
          state.selectedComponentId = component.id
        }
      }
      // or find it by providing screen id and component id
      else if (screenId && componentId) {
        const headers = Object.values(state.screens[screenId].components.headers)
        const contents = Object.values(state.screens[screenId].components.contents)
        const footers = Object.values(state.screens[screenId].components.footers)
        const screenComponent = [...headers, ...contents, ...footers].find(
          c => c.id === componentId
        )
        if (screenComponent) {
          if (stageComponent) state.stagedComponentId = screenComponent.id
          else {
            state.stagedComponentId = null
            state.selectedComponentId = screenComponent.id
          }
        }
      }
      state.parentComponent = parentComponent || null
    },
    selectEdge: (state, action: PayloadAction<{ edgeId: string | null }>) => {
      state.selectedEdgeId = action.payload.edgeId
    },
    clearCanvasScreens: () => initialState
  }
})

export const nextConnectionPosition = (
  state: WritableDraft<CanvasScreenState>,
  screen: CanvasScreen
): ConnectionPosition => {
  return findConnectionPosition(state.screens, screen)
}

export const {
  setScreens,
  addScreen,
  addScreenConnection,
  deleteScreenConnection,
  changeFlowStartScreen,
  editScreenLabel,
  editScreenLayoutProps,
  editScreenValue,
  updateScreenPosition,
  deleteScreen,
  addComponent,
  updateComponent,
  deleteComponent,
  updateComponentsOrder,
  selectScreen,
  selectComponent,
  selectEdge,
  clearCanvasScreens
} = canvasScreensSlice.actions
export default canvasScreensSlice.reducer
