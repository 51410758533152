import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Box, Card, Divider, ListItem, ListItemText, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { BottomSpace } from '../AddSection'
import LayoutStyleMenu from './LayoutStyleMenu'
import CollapseMenu from 'Components/Common/CollapseMenu'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import { changeFlowStartScreen } from 'Features/canvasScreens'
import { handleCanvasShortcuts } from 'Features/canvas'

const PrototypeSection: React.FC = () => {
  const dispatch = useDispatch()
  const workspace = useTypedSelector(state => state.workspaceView.workspace)
  const screens = useTypedSelector(state => state.undoables.present.canvasScreens.screens)

  useEffect(() => {
    dispatch(handleCanvasShortcuts({ key: 'showEdges', value: true }))
    return () => {
      dispatch(handleCanvasShortcuts({ key: 'showEdges', value: false }))
    }
  }, [])

  return (
    <>
      <ListItem>
        <ListItemText
          primary="Prototype"
          primaryTypographyProps={{ variant: 'subtitle1', color: 'text.secondary' }}
        />
      </ListItem>
      <Divider />
      <CollapseMenu text="Prototype">
        <SmallSelect
          label="Flow starting point"
          value={Object.values(screens).find(s => s.flowStartPoint)?.id}
          options={Object.values(screens).map(s => ({ value: s.id, label: s.label }))}
          onChange={e => dispatch(changeFlowStartScreen({ screenId: e.target.value }))}
          doNotFormatValue
          style={{ marginBottom: '8px' }}
        />
      </CollapseMenu>
      <Divider />
      <CollapseMenu text="Layout style">
        <LayoutStyleMenu workspace={workspace} />
      </CollapseMenu>
      <Box sx={{ margin: '16px' }}>
        <Card
          elevation={0}
          sx={{
            borderRadius: '4px',
            padding: '16px',
            backgroundColor: theme => theme.custom.appleGreen[100]
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <InfoIcon
              sx={{
                marginTop: '-2px',
                marginRight: '8px',
                color: theme => theme.palette.info.dark
              }}
            />
            <Typography
              variant="body2"
              sx={{ flexGrow: 1, color: theme => theme.palette.text.primary }}
            >
              Layout is visible on larger devices
              <br /> such as desktop or tablets.
            </Typography>
          </Box>
        </Card>
      </Box>
      <BottomSpace />
    </>
  )
}

export default PrototypeSection
