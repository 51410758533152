import { combineReducers } from '@reduxjs/toolkit'
import undoable from 'redux-undo'

import auth from 'Features/auth'
import canvas from 'Features/canvas'
import canvasScreens from 'Features/canvasScreens'
import firebaseDocuments from 'Features/firebaseDocuments'
import helpers from 'Features/helpers'
import layout from 'Features/layout'
import loggedInUser from 'Features/loggedInUser'
import saveHelper from 'Features/saveHelper'
import snackbar from 'Features/snackbar'
import workspaceView from 'Features/workspaceView'
import preview from 'Features/preview'
import share from 'Features/share'

const rootReducer = combineReducers({
  auth,
  canvas,
  firebaseDocuments,
  helpers,
  layout,
  loggedInUser,
  saveHelper,
  snackbar,
  workspaceView,
  preview,
  share,
  undoables: undoable(
    combineReducers({
      canvasScreens
    })
  )
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
