import React from 'react'
import { useTypedSelector } from 'Store'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqRating, UqRatingProps } from '@uniqore/wrapper'
import { ScreenComponent } from 'Features/canvas'
import { UqRatingPropsExtended } from 'Util/CanvasComponents'

interface UqRatingRenderProps {
  component: ScreenComponent
}

const UqRatingRender: React.FC<UqRatingRenderProps> = ({ component }) => {
  const { previewOn } = useTypedSelector(state => state.preview)
  // Removed 'value' since Rating is changed between controlled and
  // uncontrolled if Preview is on/off. (Allow user to change value if Preview is on).
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, ...props } = component.props as UqRatingProps
  const componentProps = component.props as UqRatingProps & UqRatingPropsExtended

  return (
    <div style={{ ...component.style, textAlign: componentProps.align || 'left' }}>
      <UqRating
        {...props}
        value={!previewOn ? componentProps.value : undefined}
        defaultValue={(previewOn ? componentProps.value : undefined) as number | undefined}
        icon={
          componentProps.icon
            ? React.createElement(Icons[componentProps.icon as IconType], {
                fontSize: componentProps.size
              })
            : null
        }
        emptyIcon={
          componentProps.icon
            ? React.createElement(Icons[componentProps.icon as IconType], {
                fontSize: componentProps.size
              })
            : null
        }
      />
    </div>
  )
}

export default UqRatingRender
