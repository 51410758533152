import { Dispatch, SetStateAction } from 'react'
import equal from 'fast-deep-equal'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { findComponent } from '../ComponentEdit'
import { ScreenComponent } from 'Features/canvas'
import { updateComponent } from 'Features/canvasScreens'
import { setCurrentScreen } from 'Features/preview'
import { ComponentActionSchema } from '../Sections/ActionSection'
import { CanvasScreen } from 'types/firebase'

export interface ModuleValues {
  // For example 'actionComponents' or 'radioButtons'
  // (= props in Flownav or RadioButtonGroup -modules)
  propName: string
  // Values of the prop
  propValues: object
  // 'actionComponents' or 'radioButtons' is an array, index is needed to find
  // the correct prop when updating the props
  index: number
}

/**
 * Checks if updated actions and component's current actions are different
 * @returns true if equal
 */
const checkActionEqual = (
  updatedActions: ComponentActionSchema[],
  component?: ScreenComponent
): boolean => {
  return equal(updatedActions, component?.actions)
}

/**
 * Update actions in child component (for example Button under Dialog)
 * @returns Child component with updated props
 */
const handleChildProp = (
  actions: ComponentActionSchema[],
  componentToEdit: ScreenComponent,
  screens: Record<string, CanvasScreen>,
  screenId: string,
  selectedComponentId: string
): ScreenComponent | null => {
  // 'findParent' = for example 'Dialog'
  const findParent = findComponent(screens, screenId, selectedComponentId)
  if (findParent) {
    // 'props' = parent's props
    const props = JSON.parse(JSON.stringify(findParent.props))
    for (const [propName, propValue] of Object.entries(props)) {
      // If 'propValue' is object, it includes child components
      if (typeof propValue === 'object') {
        // 'findChild' = for example 'Button' under Dialog's props
        const findChild = Object.values(propValue as object).find(c => c.id === componentToEdit.id)
        if (findChild && !checkActionEqual(actions, findChild)) {
          const childComponent = { ...findChild, actions }
          const updateProps = {} as Record<string, unknown>
          Object.entries(propValue as object).forEach(([id, child]) => {
            updateProps[id] = child.id === childComponent.id ? childComponent : child
          })
          props[propName] = updateProps
          const component = {
            ...findParent,
            props: props
          }
          return component
        }
      }
    }
  }
  return null
} // handleChildProp

/**
 * Update actions in UqModule-components (for example Flownav 'actionComponents' prop)
 * @returns UqModule with updated props
 */
const handleModuleProp = (
  actions: ComponentActionSchema[],
  componentToEdit: ScreenComponent,
  moduleValues: ModuleValues
) => {
  const { propName, propValues, index } = moduleValues
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const prop = componentToEdit.props[propName][index]
  if (prop && !checkActionEqual(actions, prop)) {
    const propValue = { ...propValues, actions }
    const props = JSON.parse(JSON.stringify(componentToEdit.props))
    props[propName][index] = propValue
    const component = {
      ...componentToEdit,
      props: props
    }
    return component
  }
  return null
} // handleModuleProp

export const useActions = () => {
  const dispatch = useDispatch()
  const screens = useTypedSelector(state => state.undoables.present.canvasScreens.screens)
  const protoDocumentData = useTypedSelector(state => state.share.protoDocumentData)
  const previewOn = useTypedSelector(state => state.preview.previewOn)

  const updateActions = (
    method: 'add' | 'remove' | 'edit',
    action: ComponentActionSchema,
    setActions: Dispatch<SetStateAction<ComponentActionSchema[]>>
  ) => {
    switch (method) {
      case 'add':
        setActions(actions => [...actions, action])
        break
      case 'remove':
        // TODO: so far only one action can be added/removed.
        // When multiple actions are supported, uncomment filter-function
        //setActions(actions => actions.filter(a => a.id !== action.id))
        setActions([])
        break
      case 'edit':
        setActions(actions => actions.map(a => (a.id === action.id ? action : a)))
        break
    }
  } // updateActions

  const dispatchActionsToReducer = (
    actions: ComponentActionSchema[],
    componentToEdit: ScreenComponent,
    screenId: string,
    selectedComponentId?: string,
    moduleValues?: ModuleValues
  ) => {
    // Handle UqModules:
    // 'moduleValues' handles UqModule-component props. 'moduleValues' has
    // for example a prop-name 'actionComponents', which holds prop values:
    // (ie. 'flownav'-module's flownav's buttons). Actions are added under this prop.
    if (moduleValues) {
      const component = handleModuleProp(actions, componentToEdit, moduleValues)
      if (component) {
        dispatch(updateComponent({ screenId, component }))
      }
    }

    // Handle child components:
    // 'componentToEdit.id' can be different from 'selectedComponentId'.
    // If for example Dialog is selected, 'selectedComponentId' is Dialog's id,
    // and Buttons are its children (props) (= 'componentToEdit').
    else if (componentToEdit.id !== selectedComponentId) {
      const component = handleChildProp(
        actions,
        componentToEdit,
        screens,
        screenId,
        selectedComponentId || ''
      )
      if (component) {
        dispatch(updateComponent({ screenId, component }))
      }
    }
    // If 'moduleValues' is undefined and 'componentToEdit.id' and
    // 'selectedComponentId' are similar, it means that component is not a
    // UqModule nor a child component
    else if (!checkActionEqual(actions, componentToEdit)) {
      const component = {
        ...componentToEdit,
        actions
      }
      dispatch(updateComponent({ screenId, component }))
    }
  } // dispatchActionsToReducer

  const onClickAction = (actions?: ComponentActionSchema[]) => {
    if (!previewOn) return
    for (const action of actions || []) {
      switch (action.type) {
        case 'NAVIGATION': {
          if (action.navigateTo) {
            const checkScreens = protoDocumentData?.screens || screens
            let navigateScreenFound = false
            for (const screen of Object.values(checkScreens)) {
              if (screen.id === action.navigateTo) {
                navigateScreenFound = true
                break
              }
            }
            if (navigateScreenFound) {
              dispatch(setCurrentScreen({ currentScreen: action.navigateTo }))
            }
          }
          break
        }
      }
    }
  } // onClickAction

  return { updateActions, dispatchActionsToReducer, onClickAction, screens }
}
