import React from 'react'
import Icons from 'Components/Common/Icons'
import { UqIconButton, UqIconButtonProps } from '@uniqore/wrapper'
import { UqIconButtonPropsExtended } from 'Util/CanvasComponents'
import { SxProps, Theme } from '@mui/material'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'

interface UqIconButtonRenderProps {
  component: ScreenComponent
  iconColor?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  edge?: UqIconButtonProps['edge']
  sx?: SxProps<Theme>
}

const UqIconButtonRender: React.FC<UqIconButtonRenderProps> = ({
  component,
  iconColor,
  edge,
  sx
}) => {
  const { onClickAction } = useActions()
  const props = component.props as UqIconButtonProps & UqIconButtonPropsExtended

  return (
    <UqIconButton
      sx={sx}
      edge={edge}
      color={props.color === 'default' ? iconColor : props.color}
      size={props.size}
      onClick={() => onClickAction(component.actions)}
    >
      {props.icon
        ? React.createElement(Icons[props.icon], {
            fontSize: props.size
          })
        : null}
      {!props.icon && props.children ? props.children : null}
    </UqIconButton>
  )
}

export default UqIconButtonRender
