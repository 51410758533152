import { gql } from '@apollo/client'

export const SHARED_DESIGN_STUDIO_DOCUMENT = gql`
  query sharedDesignStudioDocument($shareKey: ID!) {
    sharedDesignStudioDocument(shareKey: $shareKey)
  }
`

export const DESIGN_STUDIO_DOCUMENT_SHARES = gql`
  query designStudioDocumentShares($workspace: ID!, $document: ID) {
    designStudioDocumentShares(workspace: $workspace, document: $document) {
      id
      document {
        id
      }
    }
  }
`

export const SHARE_DESIGN_STUDIO_DOCUMENT = gql`
  mutation shareDesignStudioDocument($workspace: ID!, $design: ID!) {
    shareDesignStudioDocument(workspace: $workspace, design: $design) {
      id
    }
  }
`
export const UNSHARE_DESIGN_STUDIO_DOCUMENT = gql`
  mutation unshareDesignStudioDocument($shareKey: ID!) {
    unshareDesignStudioDocument(shareKey: $shareKey)
  }
`
