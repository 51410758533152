import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { useLogOut } from 'Components/Auth/Hooks/AuthHooks'

const ButtonStyled = styled(Button)(() => ({
  paddingLeft: '32px',
  paddingRight: '32px',
  textTransform: 'none',
  borderRadius: '100px'
}))

const LogoutDialog: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ open, setOpen }) => {
  const logOut = useLogOut()

  const handleLogout = () => {
    setOpen(false)
    logOut('/login')
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={{
            padding: '16px 16px 0px 24px',
            color: theme => theme.palette.text.primary
          }}
        >
          Are you sure you want to log out?
        </Typography>
        <DialogContent></DialogContent>
        <DialogActions
          sx={{
            padding: '0px 24px 24px 0px'
          }}
        >
          <ButtonStyled
            variant="text"
            color="secondary"
            size="large"
            onClick={() => setOpen(false)}
          >
            Cancel and go back
          </ButtonStyled>
          <ButtonStyled
            disableElevation
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleLogout}
          >
            Logout
          </ButtonStyled>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default LogoutDialog
