/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { MarginProps } from '@uniqore/theme'
import { Divider } from '@mui/material'
import PaddingMenu from 'Components/Common/Editing/PaddingMenu'
import SmallTextField from 'Components/Common/Editing/SmallTextField'

interface PaddingSectionProps {
  componentProps: unknown
  setComponentProps: React.Dispatch<unknown>
  hideHeader: boolean
}

const PaddingSection: React.FC<PaddingSectionProps> = ({
  componentProps,
  setComponentProps,
  hideHeader
}) => {
  const marginProps = componentProps as MarginProps

  const handlePaddingChange = (event: React.ChangeEvent<HTMLTextAreaElement>, propName: string) => {
    // @ts-ignore
    setComponentProps({ ...componentProps, [propName]: Number(event.target.value) })
  }

  /**
   * Determines if only Vertical & Horizontal are shown.
   * If component has props marginTop, it means that also margin bottom, -left and -right
   * also exist, therefore show all those values
   */
  const handlePaddingSettings = (marginProps: MarginProps) => {
    const props = { ...marginProps }
    if (props.marginTop === undefined) {
      props.marginTop = props.marginVertical
      props.marginBottom = props.marginVertical
      props.marginLeft = props.marginHorizontal
      props.marginRight = props.marginHorizontal
    } else {
      props.marginVertical = props.marginTop
      props.marginHorizontal = props.marginLeft
      delete props.marginTop
      delete props.marginBottom
      delete props.marginLeft
      delete props.marginRight
    }
    setComponentProps({ ...props })
  }

  return (
    <>
      <PaddingMenu
        label="Padding"
        action={
          marginProps.marginTop
            ? {
                type: 'link',
                onClick: () => handlePaddingSettings(marginProps)
              }
            : {
                type: 'break',
                onClick: () => handlePaddingSettings(marginProps)
              }
        }
        style={hideHeader ? { display: 'none' } : {}}
      >
        {marginProps.marginTop === undefined ? (
          <div style={{ marginBottom: '8px' }}>
            <SmallTextField
              value={marginProps.marginVertical}
              onChange={e => handlePaddingChange(e, 'marginVertical')}
              label="Vertical"
              type="number"
              helperText="Top, bottom"
              suffixText="px"
            />
            <SmallTextField
              value={marginProps.marginHorizontal}
              onChange={e => handlePaddingChange(e, 'marginHorizontal')}
              label="Horizontal"
              type="number"
              helperText="Left, right"
              suffixText="px"
            />
          </div>
        ) : (
          <div style={{ marginBottom: '8px' }}>
            <SmallTextField
              value={marginProps.marginTop}
              onChange={e => handlePaddingChange(e, 'marginTop')}
              label="Top"
              type="number"
              suffixText="px"
            />
            <SmallTextField
              value={marginProps.marginBottom}
              onChange={e => handlePaddingChange(e, 'marginBottom')}
              label="Bottom"
              type="number"
              suffixText="px"
            />
            <SmallTextField
              value={marginProps.marginLeft}
              onChange={e => handlePaddingChange(e, 'marginLeft')}
              label="Left"
              type="number"
              suffixText="px"
            />
            <SmallTextField
              value={marginProps.marginRight}
              onChange={e => handlePaddingChange(e, 'marginRight')}
              label="Right"
              type="number"
              suffixText="px"
            />
          </div>
        )}
        <Divider />
      </PaddingMenu>
    </>
  )
}

export default PaddingSection
