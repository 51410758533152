import React from 'react'
import { styled } from '@mui/material/styles'
import { Login, LoginProps } from '@uniqore/module'
import UqTypographyRender from './UqTypographyRender'
import UqTextFieldRender from './UqTextFieldRender'
import UqButtonRender from './UqButtonRender'
import { ScreenComponent } from 'Features/canvas'

// Add margin top only to first additional element
const Div = styled('div')<{
  index: number
}>(({ index }) => ({
  ...(index && {
    marginTop: '32px'
  })
}))

interface LoginRenderProps {
  component: ScreenComponent
}

const LoginRender: React.FC<LoginRenderProps> = ({ component }) => {
  const props = component.props as LoginProps

  return (
    <Login
      style={{ ...component.style }}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      headingComponents={
        props.headingComponents &&
        Object.values(props.headingComponents).map(component => (
          <UqTypographyRender key={component.id} component={component} />
        ))
      }
      contentComponents={
        props.contentComponents &&
        Object.values(props.contentComponents).map(component => (
          <UqTextFieldRender key={component.id} component={component} marginVertical={4} />
        ))
      }
      actionComponents={
        props.actionComponents &&
        Object.values(props.actionComponents).map(component => (
          <UqButtonRender key={component.id} component={component} />
        ))
      }
      additionalComponents={
        props.additionalComponents &&
        Object.values(props.additionalComponents).map((component, index) => (
          <Div key={component.id} index={index === 0 ? 1 : 0}>
            <UqTypographyRender component={component} />
          </Div>
        ))
      }
    />
  )
}

export default LoginRender
