import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import ComponentBox from 'Components/Common/ComponentBox'
import AddElementsMenu from 'Components/Common/Editing/AddElementsMenu'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'
import { NavigationActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection/NavigationAction'
import { ScreenComponent, genComponentId } from 'Features/canvas'
import {
  addComponent,
  deleteComponent,
  selectComponent,
  updateComponent
} from 'Features/canvasScreens'
import uqComponentsData from 'Util/UqComponentsData'
import { canvasComponents } from 'Util/CanvasComponents'

const Header: React.FC = () => {
  const dispatch = useDispatch()
  const { screens, selectedScreenId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  if (!selectedScreenId || Object.keys(screens).length === 0) return <></>

  const appBarComponent: ScreenComponent | undefined =
    screens[selectedScreenId].components.headers[0]

  const handleAddComponent = () => {
    const props = JSON.parse(JSON.stringify(canvasComponents.uqAppBar.props))
    const thisScreen = screens[selectedScreenId]
    // Add action to go back if sourceId exists
    if (thisScreen && thisScreen.sourceId) {
      const action: ComponentActionSchema & NavigationActionSchema = {
        id: uuidv4(),
        type: 'NAVIGATION',
        navigateTo: thisScreen.sourceId
      }
      if (props.prefix && props.prefix[1]) {
        props.prefix[1].actions = [action]
      }
    }

    dispatch(
      addComponent({
        screenId: selectedScreenId,
        component: {
          id: genComponentId(),
          componentName: 'uqAppBar',
          props: props
        },
        section: 'headers'
      })
    )
  }

  const handleRemoveComponent = (componentId: string) => {
    dispatch(deleteComponent({ screenId: selectedScreenId, componentId, section: 'headers' }))
  }

  const handleSingleClick = (component: ScreenComponent) => {
    dispatch(
      selectComponent({
        stageComponent: true,
        screenId: selectedScreenId,
        componentId: component.id
      })
    )
  }

  const handleDoubleClick = (component: ScreenComponent) => {
    dispatch(
      selectComponent({
        stageComponent: false,
        screenId: selectedScreenId,
        componentId: component.id
      })
    )
  }

  const handleVisibility = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    e.stopPropagation()
    const isVisible = component.style ? component.style.display !== 'none' : true
    const editedComponent: ScreenComponent = {
      ...component,
      style: isVisible ? { display: 'none' } : {}
    }
    dispatch(
      updateComponent({
        screenId: selectedScreenId,
        component: editedComponent,
        section: 'headers'
      })
    )
  }

  return (
    <AddElementsMenu
      label="Header"
      action={
        !appBarComponent
          ? { type: 'add', onClick: handleAddComponent }
          : { type: 'remove', onClick: () => handleRemoveComponent(appBarComponent.id) }
      }
    >
      {appBarComponent && (
        <div style={{ margin: '16px', marginTop: '8px' }}>
          <ComponentBox
            componentId={appBarComponent.id}
            primary={uqComponentsData.uqAppBar.name}
            icon={uqComponentsData.uqAppBar.editIcon || 'FiberManualRecord'}
            secondaryIcon={
              appBarComponent.style
                ? appBarComponent.style.display !== 'none'
                  ? 'Visibility'
                  : 'VisibilityOff'
                : 'Visibility'
            }
            isVisible={appBarComponent.style ? appBarComponent.style.display !== 'none' : true}
            onClick={() => handleSingleClick(appBarComponent)}
            onDoubleClick={() => handleDoubleClick(appBarComponent)}
            onClickIcon={e => handleVisibility(e, appBarComponent)}
            notDraggable
          />
        </div>
      )}
    </AddElementsMenu>
  )
}

export default Header
