import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

const TitleArea = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.custom.borderColor}`,
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const ContentArea = styled('div')(() => ({
  padding: '16px 24px'
}))

interface SectionAreaProps {
  title: string
  components?: React.ReactElement[]
  children?: React.ReactNode
}

const SectionArea: React.FC<SectionAreaProps> = ({ title, components, children }) => {
  return (
    <Box
      sx={{
        border: theme => `1px solid ${theme.custom.borderColor}`,
        borderRadius: '4px',
        backgroundColor: 'white',
        marginTop: '8px',
        marginBottom: '8px'
      }}
    >
      <TitleArea>
        <Typography variant="subtitle1" color="text.primary">
          {title}
        </Typography>
        <Box>{components}</Box>
      </TitleArea>
      <ContentArea>{children}</ContentArea>
    </Box>
  )
}

export default SectionArea
