import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

const SpinnerMui: React.FC<{
  color?: string
  size?: string | number
  backdrop?: boolean
  style?: React.CSSProperties
  className?: string
}> = ({ color = 'rgba(0, 0, 0, 0.87)', size = 40, backdrop = false, style, className }) => {
  return (
    <div style={style} className={className}>
      <CircularProgress
        style={{ color }}
        size={size}
        sx={{
          position: 'relative',
          zIndex: theme => theme.zIndex.tooltip + 1
        }}
      />
      {backdrop && (
        <Backdrop
          sx={{
            zIndex: theme => theme.zIndex.tooltip
          }}
          open
        />
      )}
    </div>
  )
}

export default SpinnerMui
