import React, { useState, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragStart,
  DragUpdate,
  DropResult
} from 'react-beautiful-dnd'
import { Box } from '@mui/material'
import ComponentBox from 'Components/Common/ComponentBox'
import { ScreenComponent, ComponentSections } from 'Features/canvas'
import { updateComponent, updateComponentsOrder, selectComponent } from 'Features/canvasScreens'
import uqComponentsData from 'Util/UqComponentsData'

const queryAttr = 'data-rbd-drag-handle-draggable-id'

const onDragStart = (
  event: DragStart,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>,
  setDraggingId: Dispatch<SetStateAction<string>>
) => {
  const draggableId = event.draggableId
  const domQuery = `[${queryAttr}='${draggableId}']`
  const draggedDOM = document.querySelector(domQuery)

  if (!draggedDOM) return
  const { clientHeight, clientWidth, parentNode } = draggedDOM
  if (!parentNode) return

  const sourceIndex = event.source.index
  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    [...parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const style = curr.currentStyle || window.getComputedStyle(curr)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(parentNode as Element).paddingLeft)
  })

  setDraggingId(event.draggableId)
}

const onDragUpdate = (
  update: DragUpdate,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>
) => {
  if (!update.destination) return
  const draggableId = update.draggableId
  const destinationIndex = update.destination.index

  const domQuery = `[${queryAttr}='${draggableId}']`
  const draggedDOM = document.querySelector(domQuery)

  if (!draggedDOM) return
  const { clientHeight, clientWidth, parentNode } = draggedDOM
  if (!parentNode) return

  const clientY =
    parseFloat(window.getComputedStyle(parentNode as Element).paddingTop) +
    [...parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const style = curr.currentStyle || window.getComputedStyle(curr)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

  setPlaceholder({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(parentNode as Element).paddingLeft)
  })
}

/**
 * Gets list of all components in current screen. Child components are separated since
 * when sorting components in the screen, we only want to sort main components and not
 * child components. When the sorting is done, we add child-components back to the list of
 * components
 * @param components
 * @returns
 */
const separateChildComponents = (components: ScreenComponent[]) => {
  const mainComponents = components.filter(c => !c.parentComponentId)
  const childComponents = components.filter(c => c.parentComponentId)
  return {
    mainComponents,
    childComponents
  }
}

const onDragEnd = (
  result: DropResult,
  setPlaceholder: Dispatch<SetStateAction<PlaceholderSchema | undefined>>,
  setDraggingId: Dispatch<SetStateAction<string>>,
  dispatch: Dispatch<unknown>,
  screenId: string,
  section: keyof ComponentSections,
  components: ScreenComponent[]
) => {
  setPlaceholder(undefined)
  setDraggingId('')

  if (result.destination) {
    const { mainComponents, childComponents } = separateChildComponents(components)
    const items = Array.from(mainComponents)
    const [reOrderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reOrderedItem)
    dispatch(
      updateComponentsOrder({ screenId, components: items.concat(childComponents), section })
    )
  }
}

interface PlaceholderSchema {
  clientY: number
  clientX: number
  clientHeight: number
  clientWidth: number
}

interface DragDropHandlerProps {
  components: ScreenComponent[]
  screenId: string
  section: keyof ComponentSections
}

const DragDropHandler: React.FC<DragDropHandlerProps> = ({ components, screenId, section }) => {
  const dispatch = useDispatch()
  const { selectedScreenId } = useTypedSelector(state => state.undoables.present.canvasScreens)
  const [placeholder, setPlaceholder] = useState<PlaceholderSchema>()
  const [draggingId, setDraggingId] = useState<string>('')

  const handleSingleClick = (_e: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string) => {
    dispatch(selectComponent({ stageComponent: true, screenId: selectedScreenId, componentId: id }))
  }

  const handleDoubleClick = (_e: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string) => {
    dispatch(
      selectComponent({ stageComponent: false, screenId: selectedScreenId, componentId: id })
    )
  }

  const handleVisibility = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    component: ScreenComponent
  ) => {
    e.stopPropagation()
    const isVisible = component.style ? component.style.display !== 'none' : true
    const editedComponent: ScreenComponent = {
      ...component,
      style: isVisible ? { display: 'none' } : {}
    }
    dispatch(updateComponent({ screenId, component: editedComponent, section }))
  }

  return (
    <DragDropContext
      onDragStart={event => onDragStart(event, setPlaceholder, setDraggingId)}
      onDragUpdate={event => onDragUpdate(event, setPlaceholder)}
      onDragEnd={event =>
        onDragEnd(event, setPlaceholder, setDraggingId, dispatch, screenId, section, components)
      }
    >
      <Droppable droppableId={section}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ position: 'relative', marginTop: '8px' }}
          >
            {components
              .filter(c => c.componentName !== 'uqListItem')
              .map((component, index) => (
                <Draggable key={component.id} draggableId={component.id} index={index}>
                  {provided => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={{
                        marginTop: '0px',
                        marginBottom: '8px',
                        ...provided.draggableProps.style
                      }}
                    >
                      <ComponentBox
                        componentId={component.id}
                        isDragging={draggingId === component.id}
                        primary={uqComponentsData[component.componentName].name}
                        secondary={uqComponentsData[component.componentName].name}
                        icon={
                          uqComponentsData[component.componentName].editIcon || 'FiberManualRecord'
                        }
                        isVisible={component.style ? component.style.display !== 'none' : true}
                        secondaryIcon={
                          component.style
                            ? component.style.display !== 'none'
                              ? 'Visibility'
                              : 'VisibilityOff'
                            : 'Visibility'
                        }
                        onClick={e => handleSingleClick(e, component.id)}
                        onDoubleClick={e => handleDoubleClick(e, component.id)}
                        onClickIcon={e => handleVisibility(e, component)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
            {placeholder && snapshot.isDraggingOver && (
              <Box
                sx={{
                  position: 'absolute',
                  borderRadius: '4px',
                  border: theme => `1px dashed ${theme.palette.text.disabled}`
                }}
                style={{
                  top: placeholder?.clientY,
                  left: placeholder?.clientX,
                  height: placeholder?.clientHeight,
                  width: placeholder?.clientWidth
                }}
              />
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragDropHandler
