import React, { useState } from 'react'
import { Collapse } from '@mui/material'
import { SketchPicker, ColorResult } from 'react-color'
import TextField from 'Components/Common/TextField'
import {
  ColorContainer,
  IndicatorContainer,
  RoundColorIndicator,
  InputContainer,
  convertRgbObjectToString,
  removeAlphaFromHex
} from './ColorPicker'

interface SingleColorPickerProps {
  label: string
  color: string
  updateColor: (color: ColorResult, stateUpdate: boolean) => void
  style?: React.CSSProperties
}

const SingleColorPicker: React.FC<SingleColorPickerProps> = ({
  label,
  color,
  updateColor,
  style
}) => {
  const [expand, setExpand] = useState<boolean>(false)

  return (
    <ColorContainer style={style}>
      <IndicatorContainer>
        <RoundColorIndicator
          onClick={() => setExpand(!expand)}
          style={{ backgroundColor: convertRgbObjectToString(color) }}
        />
      </IndicatorContainer>
      <InputContainer>
        <TextField
          label={label}
          value={removeAlphaFromHex(color || '')}
          onClick={() => setExpand(!expand)}
          readOnly
          disablePadding
          suffixIcon={expand ? 'ExpandLess' : 'ExpandMore'}
        />
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <SketchPicker
            color={color}
            onChange={color => updateColor(color, false)}
            onChangeComplete={color => updateColor(color, true)}
          />
        </Collapse>
      </InputContainer>
    </ColorContainer>
  )
}

export default SingleColorPicker
