import React, { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { ReactComponent as WaveHandImage } from 'assets/images/wave-hand-pale.svg'
import { Box, Button, Typography, Dialog, DialogActions } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { openPreviewDialog } from 'Features/share'
import { setCurrentScreen } from 'Features/preview'
import { CanvasScreen } from 'types/firebase'

interface PreviewDialogProps {
  screens: Record<string, CanvasScreen>
}

const PreviewDialog: React.FC<PreviewDialogProps> = ({ screens }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const previewDialogOpen = useTypedSelector(state => state.share.previewDialogOpen)
  const currentScreen = useTypedSelector(state => state.preview.currentScreen)

  const handleClose = () => {
    dispatch(openPreviewDialog({ value: false }))
  }

  const handleRestart = () => {
    // Find starting point screen
    for (const screen of Object.values(screens)) {
      if (screen.flowStartPoint) {
        dispatch(setCurrentScreen({ currentScreen: screen.id }))
        break
      }
    }
    handleClose()
  }

  const checkRestartDisabled = useCallback((currentScreenId: string): boolean => {
    if (screens[currentScreenId] && screens[currentScreenId].flowStartPoint) {
      return true
    }
    return false
  }, [])

  const restartButtonDisabled = useMemo(
    () => checkRestartDisabled(currentScreen),
    [previewDialogOpen]
  )

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={previewDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: '8px', backgroundColor: theme.palette.secondary.dark }
      }}
      transitionDuration={{ enter: 0, exit: theme.transitions.duration.leavingScreen }}
    >
      <Box sx={{ padding: '24px', display: 'flex', alignItems: 'center' }}>
        <WaveHandImage />
        <Typography variant="h6" sx={{ color: '#fff', marginLeft: '16px' }}>
          Hi friend!
        </Typography>
      </Box>
      <Box sx={{ padding: '0px 24px' }}>
        <Typography variant="body1" sx={{ color: '#fff' }}>
          This prototype is only for demo purposes, but it could be transformed into an actual
          application in days. See more:{' '}
          <Typography
            component="span"
            variant="body1"
            sx={{ color: '#3096E5', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => window.open('https://www.uniqore.com/', '_blank')}
          >
            Uniqore.com
          </Typography>
        </Typography>
      </Box>
      <Box sx={{ padding: '24px' }}>
        <Typography variant="body2" sx={{ color: '#B3C0C3' }}>
          How to navigate? Use like a normal application and move between screens by using
          navigation buttons (tob or bottom) or other active content elements.
        </Typography>
      </Box>
      <DialogActions sx={{ padding: '24px' }}>
        <Button
          onClick={handleRestart}
          disabled={restartButtonDisabled}
          variant="text"
          color="secondary"
          sx={{
            borderRadius: '100px',
            color: restartButtonDisabled
              ? 'rgba(255, 255, 255, 0.26) !important'
              : theme => theme.palette.secondary.light,
            marginRight: '8px'
          }}
        >
          Restart
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          disableElevation
          sx={{
            borderRadius: '100px',
            backgroundColor: theme => theme.palette.secondary.light,
            '&:hover': {
              backgroundColor: theme => theme.palette.secondary.main
            }
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewDialog
