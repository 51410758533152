import React from 'react'
import { ColorResult } from 'react-color'
import { useTheme } from '@mui/material/styles'
import equal from 'fast-deep-equal'
import {
  Box,
  Button,
  Card,
  Drawer,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SpinnerMui from 'Components/Common/SpinnerMui'
import CollapseMenu from 'Components/Common/CollapseMenu'
import Switch from 'Components/Common/Switch'
import TypographyButton from 'Components/Common/TypographyButton'
import AdvancedColor from './Advanced/AdvancedColor'
import BackgroundColor from './Advanced/BackgroundColor'
import TextColor from './Advanced/TextColor'
import { PaletteSchema, ColorSchema, TextSchema, BackgroundSchema } from 'Util/ThemeValues'
import { DesignSchema } from 'types/firebase'

export interface ColorProps {
  colors: PaletteSchema
  palette: PaletteSchema
  updateColor: (
    color: ColorResult,
    stateUpdate: boolean,
    key: keyof PaletteSchema,
    colorKey: keyof ColorSchema
  ) => void
}

export interface BackgroundColorProps {
  colors: PaletteSchema
  palette: PaletteSchema
  updateBackground: (
    color: ColorResult,
    stateUpdate: boolean,
    backgroundKey: keyof BackgroundSchema
  ) => void
}

export interface TextColorProps {
  colors: PaletteSchema
  palette: PaletteSchema
  updateText: (color: ColorResult, stateUpdate: boolean, textKey: keyof TextSchema) => void
}

interface ColorsAdvancedProps {
  selectedDesign: DesignSchema
  isCurrentlySaving: boolean
  showAdvanced: boolean
  setShowAdvanced: React.Dispatch<React.SetStateAction<boolean>>
  combinedPalette: PaletteSchema
  colors: PaletteSchema
  palette: PaletteSchema
  updateColor: (
    color: ColorResult,
    stateUpdate: boolean,
    key: keyof PaletteSchema,
    colorKey: keyof ColorSchema
  ) => void
  updateBackground: (
    color: ColorResult,
    stateUpdate: boolean,
    backgroundKey: keyof BackgroundSchema
  ) => void
  updateText: (color: ColorResult, stateUpdate: boolean, textKey: keyof TextSchema) => void
  generateColorVariations: boolean
  setGenerateColorVariations: React.Dispatch<React.SetStateAction<boolean>>
  handleSaveColors: () => void
  handleResetColors: () => void
}

const ColorsAdvanced: React.FC<ColorsAdvancedProps> = ({
  selectedDesign,
  isCurrentlySaving,
  showAdvanced,
  setShowAdvanced,
  combinedPalette,
  colors,
  palette,
  updateColor,
  updateBackground,
  updateText,
  generateColorVariations,
  setGenerateColorVariations,
  handleSaveColors,
  handleResetColors
}) => {
  const theme = useTheme()

  return (
    <>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            whiteSpace: 'nowrap',
            backgroundColor: 'background.paper',
            height: theme => `calc(100% - ${theme.props.navBarHeight})`,
            top: theme => theme.props.navBarHeight,
            overflowX: 'hidden',
            transition: 'width 250ms ease-in-out',
            width: theme => (showAdvanced ? theme.props.leftSidebar : 0),
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: '11px', right: '0px' }}>
            {isCurrentlySaving ? (
              <SpinnerMui
                color="white"
                size={25}
                style={{
                  position: 'absolute',
                  right: '55px',
                  top: '3px'
                }}
              />
            ) : null}
            <Button
              onClick={handleSaveColors}
              disabled={equal(palette, combinedPalette)}
              color="primary"
              variant="contained"
              disableElevation
              sx={{
                marginBottom: '16px',
                marginRight: '16px',
                borderRadius: '100px',
                zIndex: 2,
                backgroundColor: 'primary.light'
              }}
              size="small"
            >
              Save changes
            </Button>
          </div>
          <ListItem disableGutters sx={{ paddingLeft: '8px', paddingRight: '16px', zIndex: 1 }}>
            <IconButton onClick={() => setShowAdvanced(false)}>
              <ArrowBackIcon />
            </IconButton>
            <ListItemText
              primary="Colors"
              primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
              sx={{ marginLeft: '8px' }}
            />
          </ListItem>
          <Divider />
          <CollapseMenu text="General">
            <Switch
              label="Generate color variations"
              checked={generateColorVariations}
              onChange={() => setGenerateColorVariations(!generateColorVariations)}
            />
            <Card
              elevation={0}
              sx={{
                borderRadius: '4px',
                margin: '16px',
                padding: '16px',
                backgroundColor: theme => theme.custom.appleGreen[100]
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
              >
                <InfoIcon
                  sx={{
                    marginTop: '-2px',
                    marginRight: '8px',
                    color: theme => theme.palette.info.dark
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ flexGrow: 1, color: theme => theme.palette.text.primary }}
                >
                  Color variations will be calculated
                  <br /> automatically based on “Main”
                  <br /> color. Switch off to manually
                  <br /> modify variations.
                </Typography>
              </Box>
            </Card>
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Primary">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="primary"
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Secondary">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="secondary"
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Background">
            <BackgroundColor
              colors={colors}
              palette={palette}
              updateBackground={updateBackground}
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Text">
            <TextColor colors={colors} palette={palette} updateText={updateText} />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Error">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="error"
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Warning">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="warning"
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Info">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="info"
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Success">
            <AdvancedColor
              colors={colors}
              palette={palette}
              updateColor={updateColor}
              type="success"
            />
          </CollapseMenu>
          <Divider />
          <Box sx={{ margin: '16px' }}>
            <TypographyButton
              text="Reset to theme"
              disabled={!selectedDesign.themeOverride?.palette || isCurrentlySaving}
              onClick={handleResetColors}
              color={theme.palette.error.main}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default ColorsAdvanced
