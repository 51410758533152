import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Box, Divider, ListItem, ListItemText, IconButton, Collapse } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import TextField from 'Components/Common/TextField'
import CollapseMenu from 'Components/Common/CollapseMenu'
import Switch from 'Components/Common/Switch'
import Header from './ComponentsMenu/Header'
import Content from './ComponentsMenu/Content'
import Footer from './ComponentsMenu/Footer'
import LayoutStyleMenu from '../../PrototypeSection/LayoutStyleMenu'
import { editScreenLabel, editScreenValue } from 'Features/canvasScreens'

interface ScreenEditProps {
  selectedScreenId: string
}

const ScreenEdit: React.FC<ScreenEditProps> = ({ selectedScreenId }) => {
  const dispatch = useDispatch()
  const [editIconVisible, setEditIconVisible] = useState<boolean>(false)
  const [editNameOpen, setEditNameOpen] = useState<boolean>(false)

  const screen = useTypedSelector(
    state => state.undoables.present.canvasScreens.screens[selectedScreenId]
  )

  return (
    <>
      <ListItem
        sx={{ cursor: 'pointer' }}
        onMouseEnter={() => setEditIconVisible(true)}
        onMouseLeave={() => setEditIconVisible(false)}
        onClick={() => setEditNameOpen(!editNameOpen)}
        secondaryAction={
          <IconButton edge="end">
            {editNameOpen ? (
              <DoneIcon sx={{ color: theme => theme.palette.success.main }} />
            ) : (
              <EditIcon
                sx={{
                  visibility: editIconVisible ? 'visible' : 'hidden',
                  color: theme => theme.palette.text.secondary
                }}
              />
            )}
          </IconButton>
        }
      >
        <ListItemText
          primary={screen.label}
          primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
        />
      </ListItem>
      <Box>
        <Collapse in={editNameOpen} unmountOnExit>
          <TextField
            label="Name"
            value={screen.label}
            onChange={e =>
              dispatch(editScreenLabel({ screenId: screen.id, value: e.target.value }))
            }
            style={{ paddingBottom: '16px' }}
          />
        </Collapse>
      </Box>
      <Divider />
      <Header />
      <Divider />
      <Content />
      <Divider />
      <Footer />
      <Divider />
      {screen && (
        <CollapseMenu text="Styling" defaultOpen={false}>
          <Switch
            label="Use global layout styles"
            checked={!screen.useLocalLayoutProps}
            onChange={e =>
              dispatch(
                editScreenValue({
                  screenId: screen.id,
                  key: 'useLocalLayoutProps',
                  value: !e.target.checked
                })
              )
            }
          />
          <Collapse in={screen.useLocalLayoutProps} timeout="auto" unmountOnExit>
            <LayoutStyleMenu screen={screen} />
          </Collapse>
        </CollapseMenu>
      )}
    </>
  )
}

export default ScreenEdit
