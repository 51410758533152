/* eslint-disable @typescript-eslint/ban-ts-comment */
import { onError } from '@apollo/client/link/error'
import { changeAuth } from 'Features/auth'
import { store } from 'Store'

export const errorLink = onError(({ networkError }) => {
  // @ts-ignore
  if (networkError && networkError.statusCode === 401) {
    store.dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
  }
})
