import React, { useState } from 'react'
import { styled, Theme } from '@mui/material/styles'
import { Collapse, List, ListItem, ListItemText, SxProps } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'

const ExpandMoreStyled = styled(ExpandMore)<{
  open?: number
}>(({ theme, open }) => ({
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  ...(open && {
    transform: 'rotate(180deg)'
  })
}))

interface CollapseMenuProps {
  text: string
  children?: NonNullable<React.ReactNode>
  defaultOpen?: boolean
  sx?: SxProps<Theme>
  style?: React.CSSProperties
}

const CollapseMenu: React.FC<CollapseMenuProps> = ({
  text,
  children,
  defaultOpen = true,
  sx,
  style
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)
  const [isHovering, setIsHovering] = useState<boolean>(false)

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={style}
    >
      <List disablePadding sx={sx} style={{ backgroundColor: 'white' }}>
        <ListItem
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            cursor: 'pointer'
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: theme => theme.palette.text.primary,
              textTransform: 'uppercase'
            }}
          />
          {isHovering ? (
            <ExpandMoreStyled
              open={isOpen ? 1 : 0} // boolean causes console warning
              sx={{
                color: theme => theme.palette.text.secondary
              }}
            />
          ) : null}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </List>
    </div>
  )
}

export default CollapseMenu
