/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Node, XYPosition } from 'react-flow-renderer'
import { CanvasComponentName } from 'Util/CanvasComponents'
import short from 'short-uuid'
import { CanvasScreen } from 'types/firebase'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'

export const SCREEN_WIDTH = 360
export const SCREEN_HEIGHT = 640
export const SCREEN_MARGIN = 72
export const GRID_WIDTH = SCREEN_WIDTH + SCREEN_MARGIN
export const GRID_HEIGHT = SCREEN_HEIGHT + SCREEN_MARGIN
// To make subgrid ("free movement") compatible with the normal grid
// we need to make sure that the subgrid divides evenly with the normal grid.
// Biggest common denominator between SCREEN_WITH(432) and SCREEN_HEIGHT(712) is 8
export const SUBGRID_SIZE = 8

export const genScreenId = (): ScreenId => {
  return short.generate()
}

export const genComponentId = (): ComponentId => {
  return short.generate()
}

export type ScreenId = string
export type ComponentId = string

export interface GridRect {
  /** x coordinate on canvas */
  x: number
  /** y coordinate on canvas */
  y: number
  /** width on the canvas */
  w: number
  /** height on the canvas */
  h: number
}

/**
 * A directed connection between two screens.
 *
 * A connection can have one of the following types:
 *
 *  - Normal: this connection leads to the natural "next" screen
 *  - Error: this connection should be followed in error conditions
 *  - Alternate: this connection leads to an alternative flow (e.g.
 *    an extra set of screens to for filling in optional details.)
 *
 * There can be more than one of each connection type, in which case
 * a name can (and should) be assigned to the connection.
 */
export interface ScreenConnection {
  target: ScreenId
  type: ScreenConnectionType
  name: string
}

export enum ScreenConnectionType {
  Normal = 0,
  Error = 1,
  Alternate = 2
}

export interface ScreenComponent {
  id: ComponentId
  componentName: CanvasComponentName
  props: unknown
  actions?: ComponentActionSchema[]
  parentComponentId?: string
  style?: React.CSSProperties
}

// Screen's components
export interface ComponentSections {
  headers: ScreenComponent[]
  contents: ScreenComponent[]
  footers: ScreenComponent[]
}

/**
 * The top level Document object
 */
export interface Document {
  screens: CanvasScreen[]
}

/**
 * Convert GridRect to a XYPosition that `react-flow-renderer` understsands
 */
export const gridToXYPosition = (rect: GridRect): XYPosition => {
  return { x: rect.x, y: rect.y }
}

export const gridFromXYPosition = (pos: XYPosition): GridRect => {
  return {
    x: pos.x,
    y: pos.y,
    w: SCREEN_WIDTH,
    h: SCREEN_HEIGHT
  }
}

export const deepCopyComponent = (component: ScreenComponent) => {
  return JSON.parse(JSON.stringify(component))
}

const copyComponentAndChangeId = (component: ScreenComponent) => {
  const copiedComponent: ScreenComponent = deepCopyComponent(component)
  copiedComponent.id = genComponentId()
  return copiedComponent
}

const handleScreenComponentsCopy = (components: ScreenComponent[]) => {
  const modifiedComponents: ScreenComponent[] = []
  components.forEach(component => {
    // @ts-ignore
    const childIds: string[] | undefined = component.props.childrenComponents
    const copiedComponent: ScreenComponent = copyComponentAndChangeId(component)
    if (childIds) {
      // If component has child components, handle them here
      // @ts-ignore
      copiedComponent.props.childrenComponents = []
      childIds.forEach(childId => {
        const findChild = components.find(c => c.id === childId)
        if (findChild) {
          const copiedChild = copyComponentAndChangeId(findChild)
          copiedChild.parentComponentId = copiedComponent.id
          // @ts-ignore
          copiedComponent.props.childrenComponents.push(copiedChild.id)
          modifiedComponents.push(copiedChild)
        }
      })
    }
    // Components that have parentComponent are added above (they are included in childIds)
    if (!copiedComponent.parentComponentId) {
      modifiedComponents.push(copiedComponent)
    }
  })
  return modifiedComponents
}

/**
 * Helper function for transfroming react-flow nodes into CanvasScreens
 */
export const nodeIntoScreen = (
  node: Node<unknown>,
  copiedScreenComponents?: ComponentSections
): CanvasScreen => {
  const components: ComponentSections = {
    headers: [],
    contents: [],
    footers: []
  }

  if (copiedScreenComponents) {
    const headers = handleScreenComponentsCopy(copiedScreenComponents.headers)
    const footers = handleScreenComponentsCopy(copiedScreenComponents.footers)
    const contents = handleScreenComponentsCopy(copiedScreenComponents.contents)
    components.headers = headers
    components.footers = footers
    components.contents = contents
  }

  return {
    id: node.id,
    geometry: gridFromXYPosition(node.position),
    connections: [],
    label: 'New Screen',
    sourceId: null,
    components: components
  }
}

// TODO: Better snap to grid detection.
//       Especially clicking the empty space dection is bad
export function snapToGrid(pendingX: number, pendingY: number, freeMove?: boolean): XYPosition {
  const width = freeMove ? SUBGRID_SIZE : GRID_WIDTH
  const height = freeMove ? SUBGRID_SIZE : GRID_HEIGHT

  // x cannot fall below 0, the circle "add new screen" button is before the 0
  let x = Math.round(pendingX / width) * width
  const y = Math.round(pendingY / height) * height
  if (x < 0) x = 0

  return { x, y }
}

export enum AddScreenDir {
  Top,
  Bottom
}

export interface AddScreenData {
  sourceId: ScreenId
  direction: AddScreenDir
  copiedScreenComponents?: ComponentSections
}

export enum RemoveSpaceAction {
  AddScreen,
  RemoveSpace
}

export type CanvasId = string | null

export interface CanvasIndicatorData {
  hidden: boolean
  onEmptySpace: boolean
}

export interface Canvas {
  // indicate where the node is moved
  indicator: CanvasIndicatorData
  // Temp value to communicate between the main screen and child buttons
  stagingScreen: AddScreenData | null
  // Staged space that we want to remove
  removableSpace: { pos: XYPosition; action: RemoveSpaceAction } | null
  showEdges: boolean
  freeMove: boolean
}

const initialState: Canvas = {
  indicator: { hidden: true, onEmptySpace: true },
  stagingScreen: null,
  removableSpace: null,
  showEdges: false,
  freeMove: false
}

const canvasSlice = createSlice({
  name: 'canvasState',
  initialState,
  reducers: {
    updateIndicator: (state, action: PayloadAction<CanvasIndicatorData>) => {
      state.indicator = action.payload
    },
    stageScreen: (state, action: PayloadAction<AddScreenData | null>) => {
      state.stagingScreen = action.payload
    },
    setRemovableSpace: (
      state,
      action: PayloadAction<{ pos: XYPosition; action: RemoveSpaceAction } | null>
    ) => {
      state.removableSpace = action.payload
    },
    handleCanvasShortcuts: (
      state,
      action: PayloadAction<{ key: 'showEdges' | 'freeMove'; value: boolean }>
    ) => {
      const { key, value } = action.payload
      state[key] = value
    }
  }
})

export const { updateIndicator, stageScreen, setRemovableSpace, handleCanvasShortcuts } =
  canvasSlice.actions
export default canvasSlice.reducer
