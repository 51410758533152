import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Fab, Menu, MenuList, MenuItem, ListItemText, Typography } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: '20px',
  '&:hover': {
    backgroundColor: theme.custom.desertNight
  }
}))

const TypographyStyled = styled(Typography)(() => ({
  fontWeight: 500,
  lineHeight: '14px',
  fontSize: '10px'
}))

const CanvasFab: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <FabMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
      <Fab
        onClick={handleClick}
        size="medium"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: theme => theme.zIndex.speedDial,
          boxShadow: 'none',
          color: 'white',
          backgroundColor: open
            ? theme => theme.palette.secondary.dark
            : theme => theme.custom.desertNight,
          '&:hover': {
            backgroundColor: theme => theme.palette.secondary.dark
          },
          '& .MuiSvgIcon-root': {
            fontSize: '16px'
          }
        }}
      >
        <QuestionMarkIcon />
      </Fab>
    </>
  )
}

const FabMenu: React.FC<{
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
}> = ({ open, anchorEl, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '-12px'
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      PaperProps={{
        elevation: 8,
        sx: {
          overflow: 'visible',
          width: 200,
          color: 'white',
          backgroundColor: theme => theme.palette.secondary.dark,
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 19,
            width: 10,
            height: 10,
            bgcolor: theme => theme.palette.secondary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px' }}>
        <MenuItemStyled>
          <ListItemText primary="Undo" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Ctrl/⌘ Z</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Redo" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Ctrl/⌘ ⇧ Z | Ctrl/⌘ Y</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">DEL | Backspace</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Pan" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Space + Drag</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Zoom" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Space/Ctrl/⌘ Scroll/Pinch</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Show the flow" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Shift F</TypographyStyled>
        </MenuItemStyled>
        <MenuItemStyled>
          <ListItemText primary="Snap to grid" primaryTypographyProps={{ variant: 'caption' }} />
          <TypographyStyled color="text.disabled">Shift G</TypographyStyled>
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}

export default CanvasFab
