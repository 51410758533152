/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useDispatch } from 'react-redux'
import equal from 'fast-deep-equal'
import { ScreenComponent } from 'Features/canvas'
import { updateComponent } from 'Features/canvasScreens'

export const useProps = () => {
  const dispatch = useDispatch()

  const updateProps = (
    componentProps: unknown,
    componentToEdit: ScreenComponent,
    screenId: string
  ) => {
    if (!equal(componentProps, componentToEdit.props)) {
      const component = {
        ...componentToEdit,
        props: {
          // @ts-ignore
          ...componentProps
        }
      }
      dispatch(updateComponent({ screenId, component }))
    }
  }

  return {
    updateProps
  }
}
