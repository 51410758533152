import React from 'react'
import { UqDivider, UqDividerProps } from '@uniqore/wrapper'
import { ScreenComponent } from 'Features/canvas'

interface UqDividerRenderProps {
  component: ScreenComponent
}

const UqDividerRender: React.FC<UqDividerRenderProps> = ({ component }) => {
  const props = component.props as UqDividerProps

  return <UqDivider {...props} style={{ ...component.style }} />
}

export default UqDividerRender
