import React from 'react'
import { Navigate } from 'react-router-dom'

const isLogin = () => {
  return localStorage.getItem('token')
}

const PrivateRoute: React.FC<{ children: React.ReactElement; redirectTo: string }> = ({
  children,
  redirectTo
}) => {
  return isLogin() ? children : <Navigate to={redirectTo} />
}

export default PrivateRoute
