import React, { useState } from 'react'
import { List, ListItem, ListItemText, IconButton } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'

export interface PaddingMenuActionType {
  type: 'link' | 'break'
  onClick?: () => void
}

export interface PaddingMenuProps {
  label: string
  action?: PaddingMenuActionType
  children?: NonNullable<React.ReactNode>
  style?: React.CSSProperties
}

const PaddingMenu: React.FC<PaddingMenuProps> = ({ label, action, children, style }) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)

  return (
    <div
      style={style}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <List disablePadding sx={{ backgroundColor: 'white' }}>
        <ListItem
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: theme => theme.palette.text.primary,
              textTransform: 'uppercase'
            }}
          />
          {action?.type === 'link' ? (
            <IconButton
              onClick={action?.onClick}
              sx={{ padding: '0px', cursor: 'pointer' }}
              style={{ visibility: isHovering ? 'visible' : 'hidden' }}
            >
              <LinkIcon
                sx={{
                  color: theme => theme.custom.appleGreen[750]
                }}
              />
            </IconButton>
          ) : action?.type === 'break' ? (
            <IconButton
              onClick={action?.onClick}
              sx={{ padding: '0px', cursor: 'pointer' }}
              style={{ visibility: isHovering ? 'visible' : 'hidden' }}
            >
              <LinkOffIcon
                sx={{
                  color: theme => theme.custom.appleGreen[750]
                }}
              />
            </IconButton>
          ) : null}
        </ListItem>
        {children}
      </List>
    </div>
  )
}

export default PaddingMenu
