import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqChip, UqChipProps } from '@uniqore/wrapper'
import { UqChipPropsCustom, UqAvatarPropsCustom } from 'Util/CanvasComponents'
import UqAvatarRender from './SubComponents/UqAvatarRender'
import { ScreenComponent } from 'Features/canvas'

interface UqChipRenderProps {
  component: ScreenComponent
}

const UqChipRender: React.FC<UqChipRenderProps> = ({ component }) => {
  // Removes 'removable' & 'icon' & 'avatar' since they are customized props now
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { removable, icon, avatar, ...props } = component.props as UqChipPropsCustom
  const componentProps = component.props as UqChipPropsCustom

  return (
    <UqChip
      style={{ ...component.style }}
      {...props}
      avatar={
        componentProps.avatar && Object.keys(componentProps.avatar).length > 0 ? (
          <UqAvatarRender
            componentProps={Object.values(componentProps.avatar)[0].props as UqAvatarPropsCustom}
            chipSize={componentProps.size as UqChipProps['size']}
          />
        ) : undefined
      }
      icon={
        (componentProps.avatar && Object.keys(componentProps.avatar).length === 0) ||
        !componentProps.avatar
          ? componentProps.icon
            ? React.createElement(Icons[componentProps.icon as IconType])
            : undefined
          : undefined
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onDelete={componentProps.removable ? () => {} : undefined} // onDelete must be defined for 'deleteIcon' to be shown
    />
  )
}

export default UqChipRender
