import React, { useEffect, useRef, useCallback } from 'react'
import { useDidMountEffect } from 'Components/Common/Hooks/CommonHooks'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import ReactFlow, {
  useKeyPress,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  XYPosition
} from 'react-flow-renderer'
import CanvasScreenComponent from '../Canvas/CanvasScreen'
import CanvasIndicator from '../Canvas/CanvasIndicator'
import CanvasEmptySpace from '../Canvas/CanvasEmptySpace'
import CustomEdge from '../Canvas/CustomEdge'
import {
  stageScreen,
  genScreenId,
  gridToXYPosition,
  snapToGrid,
  updateIndicator,
  nodeIntoScreen,
  ComponentSections,
  GRID_WIDTH
} from 'Features/canvas'
import {
  addScreen,
  updateScreenPosition,
  addScreenConnection,
  findConnectionPosition,
  selectScreen,
  selectEdge
} from 'Features/canvasScreens'
import { editHelpers } from 'Features/helpers'
import { CanvasScreen } from 'types/firebase'
import { MarkerDefinition } from './MarkerDefinition'

const nodeTypes = {
  screen: CanvasScreenComponent,
  indicator: CanvasIndicator,
  emptySpace: CanvasEmptySpace
}

const edgeTypes = {
  custom: CustomEdge
}

/**
 * initialNodes contains nodes that are always needed on the canvas.
 * Note that the canvas assumes that indicator is always in the index 0
 */
const initialNodes: Node[] = [
  {
    id: 'indicator',
    type: 'indicator',
    data: {},
    position: { x: 0, y: 0 },
    draggable: false
  }
]

const createNewScreenNode = (position: XYPosition): Node => {
  const id = genScreenId()
  return {
    id,
    type: 'screen',
    position,
    data: { id }
  }
}

/**
 * Unwrap the CanvasScreens and connections to `react-flow-renderer` nodes & edges
 */
const screensToNodesAndEges = (
  screens: Record<string, CanvasScreen>,
  showEdges: boolean,
  selectedEdgeId: string | null
) => {
  const nodes: Node[] = []
  const edges: Edge[] = []
  // the helper elements need to be under the screens
  nodes.push(initialNodes[0])

  for (const screen of Object.values(screens)) {
    nodes.push({
      id: screen.id,
      type: 'screen',
      dragHandle: '.drag-handle',
      data: screen.id,
      position: gridToXYPosition(screen.geometry)
    })

    if (showEdges) {
      screen.connections.forEach(connection => {
        const edgeId = `${screen.id}_${connection.target}`
        edges.push({
          id: edgeId,
          source: screen.id,
          target: connection.target,
          type: 'custom',
          zIndex: 2,
          markerEnd: 'custom-marker',
          style:
            edgeId === selectedEdgeId
              ? { stroke: '#3096E5', strokeWidth: 2 }
              : { stroke: 'rgba(48, 151, 229, 0.5)', strokeWidth: 1 }
        })
      })
    }
  }

  // Add screen adding button if there are no screens
  if (nodes.length < 2) {
    nodes.push({
      id: 'empty-screen', // note, if id is updated here, also selectScreen action must be updated in canvas-reducer
      type: 'emptySpace',
      data: {},
      position: { x: 0, y: 0 },
      draggable: false
    })
  }

  return {
    nodes,
    edges
  }
}

const Canvas: React.FC = () => {
  const dispatch = useDispatch()
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const spacePressed = useKeyPress('Space')
  const { screens, selectedEdgeId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  const { stagingScreen, showEdges, freeMove } = useTypedSelector(state => state.canvas)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  useDidMountEffect(() => {
    if (spacePressed) {
      document.body.style.cursor = 'grabbing'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [spacePressed])

  useEffect(() => {
    const { nodes, edges } = screensToNodesAndEges(screens, showEdges, selectedEdgeId)
    setNodes(nodes)
    setEdges(edges)
  }, [screens, showEdges, selectedEdgeId])

  useEffect(() => {
    // TODO: replace the staging screen redux logic with a native callback function
    //       onAddScreen or something like that
    if (stagingScreen === null) {
      return
    }

    const sourceNode = screens[stagingScreen.sourceId]

    // TODO: how to handle this error?
    if (!sourceNode) {
      return
    }

    const pos = findConnectionPosition(screens, sourceNode).position
    const newNode = createNewScreenNode(pos)
    updateAndShiftScreen(newNode)
    dispatchNodeToScreen(newNode, sourceNode, stagingScreen.copiedScreenComponents)

    // After we have set the screen from staging, we can unset it
    dispatch(stageScreen(null))
  }, [stagingScreen]) // useEffect

  const dispatchNodeToScreen = (
    node: Node,
    source?: CanvasScreen,
    copiedScreenComponents?: ComponentSections
  ) => {
    dispatch(
      addScreen({
        id: node.id,
        screen: nodeIntoScreen(node, copiedScreenComponents),
        source
      })
    )
  } // dispatchNodeToScreen

  /**
   * Show indicator in the relevant position
   * Define the nodeId when showing the indicator based on a existing node
   */
  const showIndicator = (pos: XYPosition, nodeId?: string) => {
    const onEmptySpace =
      Object.values(screens).find(screen => {
        const ex = screen.geometry.x
        const ey = screen.geometry.y
        const tx = pos.x
        const ty = pos.y

        return ex === tx && ey === ty && screen.id !== nodeId
      }) === undefined

    // Tell react-flow the position of the indicator
    setNodes(nds =>
      nds.map(node => {
        if (node.id === initialNodes[0].id) {
          node.position = pos
        }
        return node
      })
    )
    dispatch(updateIndicator({ hidden: false, onEmptySpace: onEmptySpace }))
  } // showIndicator

  const shiftNodeRight = (
    oldNode: CanvasScreen,
    targetNode: CanvasScreen | Node,
    newPos: XYPosition
  ) => {
    const rowScreens = Object.values(screens).filter(screen => {
      const nx = screen.geometry.x
      const ny = screen.geometry.y
      // Get all except the current node
      // We're going to update current node's position later
      return nx >= newPos.x && newPos.y === ny && screen.id !== targetNode.id
    })

    rowScreens.sort((aNode, bNode) => aNode.geometry.x - bNode.geometry.x)

    let currentX = oldNode.geometry.x
    for (const screen of rowScreens) {
      const isNeighbor = currentX === screen.geometry.x
      if (!isNeighbor) {
        break
      }
      currentX = screen.geometry.x + GRID_WIDTH

      // Avoid the "read-only" rerror
      const oldPos = screen.geometry
      const newPos = { x: oldPos.x + GRID_WIDTH, y: oldPos.y }
      dispatch(updateScreenPosition({ id: screen.id, position: newPos }))
    }
  } // shiftNodeRight

  /**
   * Update screen position on the grid based on the new node value
   * and shift all the blocking screens to right if the target position
   * contains a screen
   */
  const updateAndShiftScreen = (screen: Node) => {
    // Snap the new potential position to grid
    const newPos = snapToGrid(screen.position.x, screen.position.y, freeMove)

    // First move the other nodes if there node exists in thew new position
    const oldSceen = Object.values(screens).find(scr => {
      const nx = scr.geometry.x
      const ny = scr.geometry.y

      return newPos.x === nx && newPos.y === ny && screen.id !== scr.id
    }) as CanvasScreen | undefined

    if (oldSceen !== undefined) {
      shiftNodeRight(oldSceen, screen, newPos)
    }

    // After old ones are moved, we can safely set the new position for the screen
    dispatch(updateScreenPosition({ id: screen.id, position: newPos }))
  } // updateAndShiftScreen

  const onNodeDragStart = () => {
    dispatch(editHelpers({ key: 'isNodeDragging', value: true }))
  }

  const onNodeDrag = (_e: React.MouseEvent, node: Node) => {
    const targetPos = snapToGrid(node.position.x, node.position.y, freeMove)
    showIndicator(targetPos, node.id)
  }

  const onNodeDragStop = (_e: React.MouseEvent, node: Node) => {
    dispatch(editHelpers({ key: 'isNodeDragging', value: false }))
    // The indicator should aways get hidden when we stop dragging
    dispatch(updateIndicator({ hidden: true, onEmptySpace: false }))

    // Only snap the screens to canvas
    if (node.type === 'screen') {
      updateAndShiftScreen(node)
      dispatch(selectScreen({ screenId: node.id }))
    }
  }

  const onConnect = useCallback(
    connection => {
      dispatch(
        addScreenConnection({
          sourceScreenId: connection.source,
          targetScreenId: connection.target
        })
      )
    },
    [setEdges]
  )

  const onPaneClick = useCallback(() => {
    dispatch(selectScreen({ screenId: null }))
    dispatch(selectEdge({ edgeId: null }))
  }, [])

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        height: '100%',
        backgroundColor: '#FAFAFA'
      }}
    >
      <div style={{ flexGrow: 1, height: '100%' }} ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={() => dispatch(editHelpers({ key: 'edgeConnecting', value: true }))}
          onConnectEnd={() => dispatch(editHelpers({ key: 'edgeConnecting', value: false }))}
          onEdgeClick={(_e, edge) => dispatch(selectEdge({ edgeId: edge.id }))}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          maxZoom={2}
          minZoom={0.1}
          onNodeClick={(_e, node) => dispatch(selectScreen({ screenId: node.id }))}
          onPaneClick={onPaneClick}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          nodesDraggable={true}
          // Canvas handling
          panOnScroll={true}
          zoomActivationKeyCode={'Space' || 'Meta'}
          panOnDrag={spacePressed}
        >
          <MarkerDefinition id="custom-marker" color="#3096E5" />
        </ReactFlow>
      </div>
    </div>
  )
}

export default Canvas
