import React from 'react'

interface Props {
  children: React.ReactNode
  if: boolean
  with: (children: React.ReactNode) => JSX.Element
}

/**
 * Takes a condition and a JSX wrapper. If the condition passes, the children are wrapped in the
 * wrapper. If it fails, they are returned without a wrapper.
 */
export const ConditionalWrapper = ({ if: condition, with: wrapper, children }: Props) => {
  return condition ? wrapper(children) : <>{children}</>
}
