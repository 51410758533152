import { CanvasScreen } from 'types/firebase'
import { updateOnboardingModule } from './ScreenVersionHelpers'

export const latestScreenVersion = 1

export const updateSchemaScreens = (screens: CanvasScreen[]): CanvasScreen[] => {
  return screens.map(nextVersion)
}

const nextVersion = (screen: CanvasScreen): CanvasScreen => {
  switch (screen.version) {
    case 0:
      return setToVersion1(screen)
    default:
      return screen
  }
}

const setToVersion1 = (screen: CanvasScreen): CanvasScreen => {
  const updatedScreen = updateOnboardingModule(screen)
  updatedScreen.version = 1
  return nextVersion(updatedScreen)
}
