import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material/styles'
import { Menu, MenuList, MenuItem, ListItemText } from '@mui/material'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: '20px',
  '&:hover': {
    backgroundColor: theme.custom.desertNight
  }
}))

interface ModeMenuProps {
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
  options: { name: string; logo: React.ReactNode; action: () => void }[]
  setSelectedIndex: Dispatch<SetStateAction<number>>
}

const ModeMenu: React.FC<ModeMenuProps> = ({
  open,
  anchorEl,
  handleClose,
  options,
  setSelectedIndex
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '12px',
        marginLeft: '12px',
        top: '9px'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible',
          width: 200,
          color: 'white',
          backgroundColor: theme => theme.palette.secondary.dark,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 25,
            width: 10,
            height: 10,
            bgcolor: theme => theme.palette.secondary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px' }}>
        {options.map((option, i) => (
          <MenuItemStyled
            key={option.name}
            onClick={() => {
              setSelectedIndex(i)
              option.action()
              handleClose()
            }}
          >
            {option.logo}
            <ListItemText primary={option.name} primaryTypographyProps={{ variant: 'caption' }} />
          </MenuItemStyled>
        ))}
      </MenuList>
    </Menu>
  )
}

export default ModeMenu
