import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import Fuse from 'fuse.js'
import { signInWithCustomToken, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { FirestoreProvider, useFirebaseApp, AuthProvider } from 'reactfire'
import LoginPage from 'Components/Auth/LoginPage'
import PasswordReset from 'Components/Auth/PasswordReset'
import PrivateRoute from 'Components/Auth/PrivateRoute'
import Layout from 'Components/Layout'
import Snackbar from 'Components/Common/Snackbar'
import { editSnackbar } from 'Features/snackbar'
import { changeAuth } from 'Features/auth'
import uqComponentsData, { UqComponentsDataValues } from 'Util/UqComponentsData'
import ProtoView from 'Components/Layout/ProtoView'

// Adds components' data for fuse search data
const componentsForFuse: UqComponentsDataValues[] = []
Object.values(uqComponentsData).forEach(c => componentsForFuse.push(c))

export const fuse = new Fuse(componentsForFuse, {
  keys: ['name', 'forSearch'], // which keys are searched
  includeScore: true
})

const FirebaseInit: React.FC<{ isLocal: boolean }> = ({ isLocal }) => {
  const dispatch = useDispatch()
  const app = useFirebaseApp()
  const firestoreInstance = getFirestore(app)
  const auth = getAuth(app)
  const { firebaseToken } = useTypedSelector(state => state.loggedInUser)

  useEffect(() => {
    if (firebaseToken) {
      signInWithCustomToken(auth, firebaseToken).catch(error => {
        dispatch(
          editSnackbar({
            message: `Error. ${error.message}`,
            type: 'error'
          })
        )
        // If user cannot be authenticated, logout
        dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
      })
    }
  }, [firebaseToken])

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <Router>
          <Routes>
            {isLocal && <Route path="/share/:sharelink" element={<ProtoView />} />}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route
              path="/"
              element={
                <PrivateRoute redirectTo="/login">
                  <Layout />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
        <Snackbar />
      </FirestoreProvider>
    </AuthProvider>
  )
}

export default FirebaseInit
