import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LoggedInUser {
  id: string
  firstName: string
  lastName: string
  email: string
  organization: string
  firebaseToken?: string
}

const initialState: LoggedInUser = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  organization: ''
}

const loggedInUserSlice = createSlice({
  name: 'loggedInUser',
  initialState,
  reducers: {
    initLoggedInUser: (
      state,
      action: PayloadAction<{
        value: {
          id: string
          firstName: string
          lastName: string
          email: string
          organization: {
            name: string
          }
        }
      }>
    ) => {
      const { value } = action.payload
      if (value) {
        state.id = value.id
        state.firstName = value.firstName || ''
        state.lastName = value.lastName || ''
        state.email = value.email
        state.organization = value.organization.name
      }
    },
    addFirebaseToken: (state, action: PayloadAction<{ value: string }>) => {
      state.firebaseToken = action.payload.value
    },
    clearLoggedInUser: () => initialState
  }
})

export const { initLoggedInUser, addFirebaseToken, clearLoggedInUser } = loggedInUserSlice.actions
export default loggedInUserSlice.reducer
