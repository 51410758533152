import React, { useEffect, useState } from 'react'
import merge from 'deepmerge'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { Divider, IconButton, ListItem, ListItemText } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import CollapseMenu from 'Components/Common/CollapseMenu'
import { BottomSpace } from '../AddSection'
import { editLeftSidebar } from 'Features/layout'
import Project from './Project'
import Theme from './Theme'
import Colors from './Colors'
import Typographys from './Typographys'
import {
  PaletteSchema,
  TypographySchema,
  defaultPaletteSimple,
  defaultTypography
} from 'Util/ThemeValues'

const BrandingSection: React.FC = () => {
  const dispatch = useDispatch()
  const { designs, themes, defaultTheme } = useTypedSelector(state => state.firebaseDocuments)
  const { workspace, selectedDesign } = useTypedSelector(state => state.workspaceView)
  // Combined palette & typography combines themeOverride values with the theme that design has (design's themeId -value)
  const [combinedPalette, setCombinedPalette] = useState<PaletteSchema>()
  const [combinedTypography, setCombinedTypography] = useState<TypographySchema>()

  if (!defaultTheme || !selectedDesign) return <></>

  // TODO: these could be separated under the Colors and Typographys -components
  useEffect(() => {
    // Find the theme based on design's theme id value
    const findTheme = [...themes, defaultTheme].find(v => v.id === selectedDesign.themeId)
    const themeOverride = selectedDesign?.themeOverride

    // Set palette
    let themePalette: PaletteSchema = {} as PaletteSchema
    let themeOverridePalette: PaletteSchema = {} as PaletteSchema
    if (findTheme) {
      themePalette = findTheme.palette
    } else {
      themePalette = defaultPaletteSimple as PaletteSchema
    }
    if (themeOverride && themeOverride.palette) {
      // if design has any theme overrides, overwrite theme values with those values
      themeOverridePalette = themeOverride.palette
    }

    // Set typography
    let themeTypography: TypographySchema = {} as TypographySchema
    let themeOverrideTypography: TypographySchema = {} as TypographySchema
    if (findTheme) {
      themeTypography = findTheme.typography
    } else {
      themeTypography = defaultTypography
    }
    if (themeOverride && themeOverride.typography) {
      // if design has any theme overrides, overwrite theme values with those values
      themeOverrideTypography = themeOverride.typography
    }

    setCombinedPalette(merge(themePalette, themeOverridePalette))
    setCombinedTypography(merge(themeTypography, themeOverrideTypography))
  }, [selectedDesign])

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton
            onClick={() => dispatch(editLeftSidebar({ stateKey: 'open', value: false }))}
            edge="end"
          >
            <ClearIcon />
          </IconButton>
        }
      >
        <ListItemText
          primary="Global settings"
          primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
        />
      </ListItem>
      {workspace && selectedDesign && combinedPalette && combinedTypography ? (
        <>
          <Divider />
          <CollapseMenu text="Project">
            <Project
              workspace={workspace}
              selectedDesign={selectedDesign}
              designs={designs}
              themes={themes}
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Theme">
            <Theme workspace={workspace} selectedDesign={selectedDesign} themes={themes} />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Colors">
            <Colors
              workspace={workspace}
              selectedDesign={selectedDesign}
              combinedPalette={combinedPalette}
            />
          </CollapseMenu>
          <Divider />
          <CollapseMenu text="Typography">
            <Typographys
              workspace={workspace}
              selectedDesign={selectedDesign}
              combinedTypography={combinedTypography}
            />
          </CollapseMenu>
          <Divider />
        </>
      ) : null}
      <BottomSpace />
    </>
  )
}

export default BrandingSection
