import React from 'react'
import { getBezierPath, Position } from 'react-flow-renderer'
import { styled } from '@mui/material/styles'

const PathSelector = styled('path')(() => ({
  '&:hover + path': {
    stroke: '#3097e5 !important',
    strokeWidth: '2 !important'
  }
}))

const PathEdge = styled('path')(() => ({
  '&:hover': {
    cursor: 'pointer',
    stroke: '#3097e5 !important',
    strokeWidth: '2 !important'
  }
}))

interface CustomEdge {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  style: React.CSSProperties
  markerEnd: string
}

const CustomEdge: React.FC<CustomEdge> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition
  })

  return (
    <>
      <PathSelector
        style={style}
        className="react-flow__edge-path-selector"
        d={edgePath}
        fillRule="evenodd"
      />
      <PathEdge
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        fillRule="evenodd"
        markerEnd={markerEnd}
      />
    </>
  )
}

export default CustomEdge
