import { UqComponentsDataValues } from './UqComponentsData'
import Alert from 'assets/component-icons/Alert.svg'
import AppBar from 'assets/component-icons/AppBar.svg'
import BottomNavigation from 'assets/component-icons/BottomNavigation.svg'
import Button from 'assets/component-icons/Button.svg'
import Checkbox from 'assets/component-icons/Checkbox.svg'
import Chip from 'assets/component-icons/Chip.svg'
import DatePicker from 'assets/component-icons/DatePicker.svg'
import Dialog from 'assets/component-icons/Dialog.svg'
import Divider from 'assets/component-icons/Divider.svg'
import Fab from 'assets/component-icons/Fab.svg'
import Image from 'assets/component-icons/Image.svg'
import List from 'assets/component-icons/List.svg'
import UqModule from 'assets/component-icons/UqModule.svg'
import RadioButtonGroup from 'assets/component-icons/RadioButtonGroup.svg'
import Rating from 'assets/component-icons/Rating.svg'
import Select from 'assets/component-icons/Select.svg'
import SelectButtonGroup from 'assets/component-icons/SelectButtonGroup.svg'
import Snackbar from 'assets/component-icons/Snackbar.svg'
import SpeedDial from 'assets/component-icons/SpeedDial.svg'
import Switch from 'assets/component-icons/Switch.svg'
import TextField from 'assets/component-icons/TextField.svg'
import Typography from 'assets/component-icons/Typography.svg'

export const uqButton: UqComponentsDataValues = {
  name: 'Button',
  component: 'uqButton',
  icon: Button,
  editIcon: 'ArrowRightAltRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    children: {
      type: 'node',
      section: 'CONTENT',
      label: 'Label'
    },
    startIcon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Prefix icon'
    },
    endIcon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Suffix icon'
    },
    variant: {
      type: ['contained', 'outlined', 'text'],
      section: 'STYLE'
    },
    size: {
      type: ['small', 'medium', 'large'],
      section: 'STYLE'
    },
    color: {
      type: ['primary', 'secondary', 'success', 'error', 'info', 'warning'],
      section: 'STYLE'
    },
    fullWidth: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqCheckbox: UqComponentsDataValues = {
  name: 'Checkbox',
  component: 'uqCheckbox',
  icon: Checkbox,
  editIcon: 'CheckBoxRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    options: {
      type: 'string',
      section: 'CONTENT',
      customProperties: {
        textField: {
          helperText: 'Add one option per line',
          multiline: true
        }
      }
    },
    size: {
      type: ['medium', 'small'],
      section: 'STYLE'
    },
    color: {
      type: ['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    checked: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqRadio: UqComponentsDataValues = {
  name: 'Radio',
  component: 'uqRadio',
  icon: Checkbox,
  editIcon: 'RadioButtonCheckedRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    options: {
      type: 'string',
      section: 'CONTENT',
      customProperties: {
        textField: {
          helperText: 'Add one option per line (min. 2)',
          multiline: true
        }
      }
    },
    size: {
      type: ['medium', 'small'],
      section: 'STYLE'
    },
    color: {
      type: ['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    checked: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqRating: UqComponentsDataValues = {
  name: 'Rating',
  component: 'uqRating',
  icon: Rating,
  editIcon: 'StarRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    size: {
      type: ['small', 'medium', 'large'],
      section: 'STYLE'
    },
    value: {
      type: 'number',
      section: 'STYLE'
    },
    max: {
      type: 'number',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    },
    align: {
      type: ['center', 'left', 'right'],
      section: 'STYLE'
    }
  }
}

export const uqSelect: UqComponentsDataValues = {
  name: 'Select',
  component: 'uqSelect',
  icon: Select,
  editIcon: 'ArrowDropDownCircleRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    label: {
      type: 'node',
      section: 'CONTENT'
    },
    helperText: {
      type: 'string',
      section: 'CONTENT'
    },
    options: {
      type: 'string',
      section: 'CONTENT',
      label: 'Add options',
      customProperties: {
        textField: {
          helperText: 'Add one option per line',
          multiline: true
        }
      }
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    variant: {
      type: ['filled', 'outlined', 'standard'],
      section: 'STYLE'
    },
    fullWidth: {
      type: 'bool',
      section: 'STYLE'
    },
    multiple: {
      type: 'bool',
      section: 'STYLE',
      label: 'Multiple selection'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqSwitch: UqComponentsDataValues = {
  name: 'Switch',
  component: 'uqSwitch',
  icon: Switch,
  editIcon: 'ToggleOnRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    label: {
      type: 'string',
      section: 'CONTENT'
    },
    size: {
      type: ['medium', 'small'],
      section: 'STYLE'
    },
    color: {
      type: ['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    checked: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqTextField: UqComponentsDataValues = {
  name: 'Text field',
  component: 'uqTextField',
  icon: TextField,
  editIcon: 'TextFieldsRounded',
  category: 'content',
  forSearch: ['content', 'input'],
  props: {
    label: {
      type: 'node',
      section: 'CONTENT'
    },
    value: {
      type: 'string',
      section: 'CONTENT'
    },
    placeholder: {
      type: 'string',
      section: 'CONTENT'
    },
    helperText: {
      type: 'node',
      section: 'CONTENT'
    },
    variant: {
      type: ['filled', 'outlined', 'standard'],
      section: 'STYLE'
    },
    size: {
      type: ['medium', 'small'],
      section: 'STYLE'
    },
    color: {
      type: ['primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    rows: {
      type: 'number',
      section: 'STYLE'
    },
    fullWidth: {
      type: 'bool',
      section: 'STYLE'
    },
    error: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    },
    required: {
      type: 'bool',
      section: 'STYLE'
    },
    prefix: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqInputAdornment',
          propAllowList: ['type', 'icon', 'text'],
          maxLimit: 1
        }
      }
    },
    suffix: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqInputAdornment',
          propAllowList: ['type', 'icon', 'text'],
          maxLimit: 1
        }
      }
    }
  }
}

export const uqChip: UqComponentsDataValues = {
  name: 'Chip',
  component: 'uqChip',
  icon: Chip,
  editIcon: 'EditAttributesRounded',
  category: 'content',
  props: {
    label: {
      type: 'node',
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    variant: {
      type: ['filled', 'outlined'],
      section: 'STYLE'
    },
    size: {
      type: ['medium', 'small'],
      section: 'STYLE'
    },
    color: {
      type: ['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    clickable: {
      type: 'bool',
      section: 'STYLE'
    },
    removable: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    },
    avatar: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqAvatar',
          propAllowList: ['type', 'image', 'text', 'variant'],
          maxLimit: 1
        }
      }
    }
  }
}

export const uqDivider: UqComponentsDataValues = {
  name: 'Divider',
  component: 'uqDivider',
  icon: Divider,
  editIcon: 'RemoveRounded',
  category: 'content',
  props: {
    variant: {
      type: ['fullWidth', 'inset', 'middle'],
      section: 'STYLE'
    },
    light: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqAlert: UqComponentsDataValues = {
  name: 'Alert',
  component: 'uqAlert',
  icon: Alert,
  editIcon: 'NotificationsRounded',
  category: 'content',
  props: {
    title: {
      type: 'string',
      section: 'CONTENT'
    },
    body: {
      type: 'string',
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Prefix icon'
    },
    variant: {
      type: ['filled', 'outlined', 'standard'],
      section: 'STYLE'
    },
    color: {
      type: ['error', 'info', 'success', 'warning'],
      section: 'STYLE'
    }
  }
}

export const uqDialog: UqComponentsDataValues = {
  name: 'Dialog',
  component: 'uqDialog',
  icon: Dialog,
  editIcon: 'NotificationsRounded',
  category: 'content',
  props: {
    title: {
      type: 'string',
      section: 'CONTENT'
    },
    body: {
      type: 'string',
      section: 'CONTENT'
    },
    buttons: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqButton',
          maxLimit: 2,
          propAllowList: ['children', 'startIcon', 'endIcon', 'variant']
        }
      }
    },
    fullWidth: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqSnackbar: UqComponentsDataValues = {
  name: 'Snackbar',
  component: 'uqSnackbar',
  icon: Snackbar,
  editIcon: 'NotificationsRounded',
  category: 'content',
  props: {
    title: {
      type: 'string',
      section: 'CONTENT'
    },
    body: {
      type: 'string',
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Prefix icon'
    },
    color: {
      type: ['error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    originHorizontal: {
      type: ['center', 'left', 'right'],
      section: 'STYLE'
    },
    originVertical: {
      type: ['bottom', 'top'],
      section: 'STYLE'
    }
  }
}

export const uqBottomNavigation: UqComponentsDataValues = {
  name: 'Bottom Navigation',
  component: 'uqBottomNavigation',
  icon: BottomNavigation,
  editIcon: 'CallToAction',
  category: 'content',
  forSearch: ['header', 'navigation'],
  props: {
    tabs: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqBottomNavigationAction',
          propAllowList: ['label', 'icon']
        }
      }
    },
    showLabels: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqSpeedDial: UqComponentsDataValues = {
  name: 'Speed dial',
  component: 'uqSpeedDial',
  icon: SpeedDial,
  editIcon: 'CallToAction',
  category: 'content',
  forSearch: ['header', 'navigation'],
  props: {
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    options: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqSpeedDialAction',
          propAllowList: ['tooltipTitle', 'icon']
        }
      }
    }
  }
}

export const uqFab: UqComponentsDataValues = {
  name: 'Fab',
  component: 'uqFab',
  icon: Fab,
  editIcon: 'CallToAction',
  category: 'content',
  forSearch: ['header', 'navigation'],
  props: {
    label: {
      type: 'string',
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    variant: {
      type: ['circular', 'extended'],
      section: 'STYLE'
    },
    size: {
      type: ['small', 'medium', 'large'],
      section: 'STYLE'
    },
    color: {
      type: ['default', 'primary', 'secondary'],
      section: 'STYLE'
    }
  }
}

export const uqList: UqComponentsDataValues = {
  name: 'List',
  component: 'uqList',
  icon: List,
  editIcon: 'ListRounded',
  category: 'content',
  props: {
    dense: {
      type: 'bool',
      section: 'STYLE'
    },
    disablePadding: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqTypography: UqComponentsDataValues = {
  name: 'Typography',
  component: 'uqTypography',
  icon: Typography,
  editIcon: 'TextFieldsRounded',
  category: 'content',
  props: {
    children: {
      type: 'node',
      section: 'CONTENT',
      label: 'Text'
    },
    variant: {
      type: [
        'h5',
        'h6',
        'body1',
        'body2',
        'subtitle1',
        'subtitle2',
        'button',
        'caption',
        'overline'
      ],
      section: 'STYLE'
    },
    color: {
      type: ['primary', 'secondary', 'disabled'],
      section: 'STYLE'
    },
    align: {
      type: ['center', 'left', 'right'],
      section: 'STYLE'
    },
    gutterBottom: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const radioButtonGroup: UqComponentsDataValues = {
  name: 'Radio group',
  component: 'radioButtonGroup',
  icon: RadioButtonGroup,
  editIcon: 'RadioButtonCheckedRounded',
  category: 'content',
  props: {
    radioButtons: {
      type: 'module',
      section: 'CONTENT',
      label: 'Option',
      moduleProps: {
        object: {
          text: 'string',
          startIcon: 'button',
          metaText: 'smallTextField',
          color: [
            'default',
            'inherit',
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning'
          ]
        },
        primaryTextKey: 'text',
        secondaryTextKey: 'metaText'
      }
    },
    variant: {
      type: ['outlined', 'elevated'],
      section: 'STYLE'
    },
    buttonsColor: {
      type: ['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'],
      section: 'STYLE'
    }
  }
}

export const selectButtonGroup: UqComponentsDataValues = {
  name: 'Checkbox group',
  component: 'selectButtonGroup',
  icon: SelectButtonGroup,
  editIcon: 'CheckBoxRounded',
  category: 'content',
  props: {
    selectButtons: {
      type: 'module',
      section: 'CONTENT',
      label: 'Option',
      moduleProps: {
        object: {
          text: 'string',
          startIcon: 'button',
          metaText: 'smallTextField',
          color: [
            'default',
            'inherit',
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning'
          ]
        },
        primaryTextKey: 'text',
        secondaryTextKey: 'metaText'
      }
    },
    variant: {
      type: ['outlined', 'elevated'],
      section: 'STYLE'
    },
    buttonsColor: {
      type: ['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'],
      section: 'STYLE'
    }
  }
}

export const login: UqComponentsDataValues = {
  name: 'Login',
  component: 'login',
  icon: UqModule,
  editIcon: 'AutoAwesomeRounded',
  category: 'screenAndModules',
  props: {
    headingComponents: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Heading',
      customProperties: {
        dynamic: {
          componentName: 'uqTypography',
          propAllowList: ['children', 'variant', 'color', 'align', 'gutterBottom']
        }
      }
    },
    contentComponents: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Content',
      customProperties: {
        dynamic: {
          componentName: 'uqTextField',
          propAllowList: [
            'label',
            'value',
            'placeholder',
            'helperText',
            'variant',
            'size',
            'color',
            'fullWidth',
            'error',
            'disabled',
            'prefix',
            'suffix'
          ]
        }
      }
    },
    actionComponents: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Action',
      customProperties: {
        dynamic: {
          componentName: 'uqButton'
        }
      }
    },
    additionalComponents: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Additional',
      customProperties: {
        dynamic: {
          componentName: 'uqTypography',
          propAllowList: ['children', 'variant', 'color', 'align', 'gutterBottom']
        }
      }
    }
  }
}

export const image: UqComponentsDataValues = {
  name: 'Image',
  component: 'image',
  icon: Image,
  editIcon: 'ImageRounded',
  category: 'content',
  props: {
    src: {
      type: 'string',
      section: 'CONTENT',
      label: 'Image source',
      customProperties: {
        textField: {
          multiline: false
        }
      }
    },
    width: {
      type: 'number',
      section: 'STYLE',
      label: 'Width (%)'
    },
    center: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const onboarding: UqComponentsDataValues = {
  name: 'Onboarding',
  component: 'onboarding',
  icon: UqModule,
  editIcon: 'AutoAwesomeRounded',
  category: 'screenAndModules',
  props: {
    carouselItems: {
      label: 'Items',
      type: 'module',
      section: 'CONTENT',
      moduleProps: {
        object: {
          imageWidth: 'number',
          imageSource: 'string',
          headline: 'string',
          body: 'string'
        },
        primaryTextKey: 'headline',
        secondaryTextKey: 'body'
      }
    },
    actionComponents: {
      type: 'module',
      section: 'CONTENT',
      label: 'Buttons',
      moduleProps: {
        object: {
          text: 'string',
          variant: ['contained', 'outlined', 'text'],
          size: ['small', 'medium', 'large'],
          color: [
            'default',
            'inherit',
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning'
          ],
          fullWidth: 'bool',
          disabled: 'bool',
          startIcon: 'button',
          endIcon: 'button'
        },
        primaryTextKey: 'text'
      }
    },
    buttonsColor: {
      type: ['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'],
      section: 'STYLE'
    },
    actionDirection: {
      type: ['row', 'row-reverse', 'column', 'column-reverse'],
      section: 'STYLE',
      label: 'Direction'
    }
  }
}

export const uqDatePicker: UqComponentsDataValues = {
  name: 'Date picker',
  component: 'uqDatePicker',
  icon: DatePicker,
  editIcon: 'EventRounded',
  category: 'content',
  props: {
    label: {
      type: 'string',
      section: 'CONTENT'
    },
    variation: {
      type: ['inline', 'textfield'],
      section: 'STYLE'
    },
    localization: {
      type: ['fi', 'en-uk', 'en-us'],
      section: 'STYLE',
      customProperties: { smallSelect: { doNotFormatValue: true } }
    },
    disablePast: {
      type: 'bool',
      section: 'STYLE'
    },
    disabled: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

// These components below are not shown in AddSection and cannot be dragged to canvas.
// However they are used inside other components in component render (SubComponents)

export const uqAppBar: UqComponentsDataValues = {
  name: 'Appbar',
  component: 'uqAppBar',
  icon: AppBar,
  editIcon: 'WebAssetRounded',
  props: {
    title: {
      type: 'string',
      section: 'CONTENT'
    },
    prefix: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqIconButton',
          propAllowList: ['icon', 'children', 'size', 'color']
        }
      }
    },
    toolbar: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqIconButton',
          propAllowList: ['icon', 'children', 'size', 'color']
        }
      }
    },
    progress: {
      type: 'dynamic',
      section: 'CONTENT',
      customProperties: {
        dynamic: {
          componentName: 'uqLinearProgress',
          propAllowList: ['value', 'variant', 'color'],
          maxLimit: 1
        }
      }
    },
    color: {
      type: ['default', 'inherit', 'primary', 'secondary', 'transparent'],
      section: 'STYLE'
    },
    iconColor: {
      type: ['default', 'inherit', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    titleVariant: {
      type: [
        'h5',
        'h6',
        'body1',
        'body2',
        'subtitle1',
        'subtitle2',
        'button',
        'caption',
        'overline'
      ],
      section: 'STYLE'
    }
  }
}

export const flowNav: UqComponentsDataValues = {
  name: 'Flow nav',
  component: 'flowNav',
  editIcon: 'CallToAction',
  props: {
    actionComponents: {
      type: 'module',
      section: 'CONTENT',
      label: 'Buttons',
      moduleProps: {
        object: {
          text: 'string',
          variant: ['contained', 'outlined', 'text'],
          size: ['small', 'medium', 'large'],
          color: [
            'default',
            'inherit',
            'primary',
            'secondary',
            'success',
            'error',
            'info',
            'warning'
          ],
          fullWidth: 'bool',
          disabled: 'bool',
          startIcon: 'button',
          endIcon: 'button'
        },
        primaryTextKey: 'text'
      }
    },
    buttonsColor: {
      type: ['inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning'],
      section: 'STYLE'
    },
    actionDirection: {
      type: ['row', 'row-reverse', 'column', 'column-reverse'],
      section: 'STYLE',
      label: 'Direction'
    }
  }
}

export const uqInputAdornment: UqComponentsDataValues = {
  name: 'InputAdorment',
  component: 'uqInputAdornment',
  props: {
    type: {
      type: ['icon', 'text'],
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    text: {
      type: 'smallTextField',
      section: 'CONTENT',
      customProperties: {
        smallTextField: {
          type: 'text'
        }
      }
    }
  }
}

export const uqBottomNavigationAction: UqComponentsDataValues = {
  name: 'BottomNavigationAction',
  component: 'uqBottomNavigationAction',
  props: {
    label: {
      type: 'string',
      section: 'CONTENT'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    }
  }
}

export const uqSpeedDialAction: UqComponentsDataValues = {
  name: 'SpeedDialAction',
  component: 'uqSpeedDialAction',
  props: {
    tooltipTitle: {
      type: 'string',
      section: 'CONTENT',
      label: 'Label'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    }
  }
}

export const uqAvatar: UqComponentsDataValues = {
  name: 'Avatar',
  component: 'uqAvatar',
  props: {
    type: {
      type: ['image', 'text'],
      section: 'CONTENT'
    },
    image: {
      type: 'smallTextField',
      section: 'CONTENT',
      label: 'Image url'
    },
    text: {
      type: 'smallTextField',
      section: 'CONTENT'
    },
    variant: {
      type: ['circular', 'rounded', 'square'],
      section: 'STYLE'
    }
  }
}

export const uqIconButton: UqComponentsDataValues = {
  name: 'IconButton',
  component: 'uqIconButton',
  props: {
    children: {
      type: 'string',
      section: 'CONTENT',
      label: 'Label'
    },
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    color: {
      type: ['default', 'inherit', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'STYLE'
    },
    size: {
      type: ['small', 'medium', 'large'],
      section: 'STYLE'
    }
  }
}

export const uqLinearProgress: UqComponentsDataValues = {
  name: 'LinearProgress',
  component: 'uqLinearProgress',
  props: {
    value: {
      type: 'number',
      section: 'CONTENT'
    },
    variant: {
      type: ['determinate', 'indeterminate'],
      section: 'STYLE'
    },
    color: {
      type: ['inherit', 'primary', 'secondary'],
      section: 'STYLE'
    }
  }
}

export const uqListItem: UqComponentsDataValues = {
  name: 'ListItem',
  component: 'uqListItem',
  props: {
    primary: {
      type: 'string',
      section: 'CONTENT'
    },
    secondary: {
      type: 'string',
      section: 'CONTENT',
      customProperties: {
        textField: {
          helperText: 'Add one option per line',
          multiline: true
        }
      }
    },
    listItemAvatars: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Prefix Avatar',
      customProperties: {
        dynamic: {
          componentName: 'uqListItemAvatar',
          maxLimit: 1
        }
      }
    },
    listItemIcons: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Prefix Icon',
      customProperties: {
        dynamic: {
          componentName: 'uqListItemIcon',
          maxLimit: 1
        }
      }
    },
    listItemSecondaryActions: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Suffix',
      customProperties: {
        dynamic: {
          componentName: 'uqListItemSecondaryAction',
          maxLimit: 1
        }
      }
    },
    add: {
      type: 'dynamic',
      section: 'CONTENT',
      label: 'Suffix add',
      customProperties: {
        dynamic: {
          componentName: 'add',
          maxLimit: 1
        }
      }
    },
    alignItems: {
      type: ['center', 'flex-start'],
      section: 'STYLE'
    },
    divider: {
      type: 'bool',
      section: 'STYLE'
    },
    dense: {
      type: 'bool',
      section: 'STYLE'
    },
    disableGutters: {
      type: 'bool',
      section: 'STYLE'
    },
    disablePadding: {
      type: 'bool',
      section: 'STYLE'
    }
  }
}

export const uqListItemIcon: UqComponentsDataValues = {
  name: 'List item icon',
  component: 'uqListItemIcon',
  props: {
    icon: {
      type: 'button',
      section: 'CONTENT'
    }
  }
}

export const uqListItemAvatar: UqComponentsDataValues = {
  name: 'List item avatar',
  component: 'uqListItemAvatar',
  props: {
    type: {
      type: ['image', 'text'],
      section: 'CONTENT'
    },
    image: {
      type: 'smallTextField',
      section: 'CONTENT',
      label: 'Image url'
    },
    text: {
      type: 'smallTextField',
      section: 'CONTENT'
    },
    variant: {
      type: ['circular', 'rounded', 'square'],
      section: 'STYLE'
    }
  }
}

export const uqListItemSecondaryAction: UqComponentsDataValues = {
  name: 'List item secondary action',
  component: 'uqListItemSecondaryAction',
  props: {
    icon: {
      type: 'button',
      section: 'CONTENT'
    },
    component: {
      type: ['none', 'uqCheckbox', 'uqRadio', 'uqSwitch'],
      section: 'CONTENT'
    },
    color: {
      type: ['default', 'inherit', 'primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'CONTENT'
    },
    edge: {
      type: ['start', 'end'],
      section: 'CONTENT',
      label: 'align'
    }
  }
}

export const add: UqComponentsDataValues = {
  name: 'Add',
  component: 'add',
  props: {
    variant: {
      type: ['single', 'multi'],
      section: 'CONTENT'
    },
    color: {
      type: ['primary', 'secondary', 'error', 'info', 'success', 'warning'],
      section: 'CONTENT'
    },
    edge: {
      type: ['start', 'end'],
      section: 'CONTENT',
      label: 'align'
    },
    icon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Icon default'
    },
    checkedIcon: {
      type: 'button',
      section: 'CONTENT',
      label: 'Icon selected'
    },
    quantity: {
      type: 'number',
      section: 'CONTENT'
    },
    unit: {
      type: 'smallTextField',
      section: 'CONTENT'
    },
    checked: {
      type: 'bool',
      section: 'CONTENT'
    }
  }
}
