import React, { useEffect } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { ButtonStyled } from '../../WorkspaceView'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import { Document, WorkspaceSchema } from 'types/firebase'

interface RemoveDesignDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  documentForDialogs: { document: Document; type: 'design' | 'theme' } | undefined
  workspace: WorkspaceSchema
}

const RemoveDesignDialog: React.FC<RemoveDesignDialogProps> = ({
  open,
  setOpen,
  documentForDialogs,
  workspace
}) => {
  const { deleteDesign, deleteDesignData, deleteDesignLoading } = useDesigns(workspace.id)

  useEffect(() => {
    if (deleteDesignData && deleteDesignData.deleteDesignStudioDocument) {
      setOpen(false)
    }
  }, [deleteDesignData])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <InfoIcon sx={{ color: theme => theme.palette.error.main, marginRight: '16px' }} />
          <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
            Are you sure?
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText variant="body1" color="text.secondary">
            Are you sure you want to delete{' '}
            <Typography
              variant="body1"
              color="text.secondary"
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {documentForDialogs?.document.title}
            </Typography>
            ? This can&apos;t be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'relative',
            padding: '0px 24px 24px 0px'
          }}
        >
          {deleteDesignLoading ? (
            <SpinnerMui
              color="white"
              size={30}
              style={{
                position: 'absolute',
                bottom: '26px',
                right: '46px'
              }}
            />
          ) : null}
          <ButtonStyled onClick={() => setOpen(false)} variant="text" color="secondary">
            Cancel
          </ButtonStyled>
          <ButtonStyled
            onClick={() => deleteDesign(documentForDialogs?.document.id as string)}
            disableElevation
            color="secondary"
            variant="contained"
            disabled={deleteDesignLoading}
          >
            Delete
          </ButtonStyled>
        </DialogActions>
      </>
    </Dialog>
  )
}

export default RemoveDesignDialog
