/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Document } from 'types/firebase'
import moment from 'moment'

// Sets Timestamps to string (if they exist) to prevent setting non-serializable values in redux-state
export const documentTimestampsToString = (document: Document) => {
  return {
    ...document,
    createdAt: document.createdAt
      ? // @ts-ignore // value coming from firebase is not a string, although type createAt is typed as a string
        moment(document.createdAt.toDate()).toISOString(true)
      : undefined,
    updatedAt: document.updatedAt
      ? // @ts-ignore // value coming from firebase is not a string, although type createAt is typed as a string
        moment(document.updatedAt.toDate()).toISOString(true)
      : undefined
  }
}
