import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import ShareDialog from 'Components/Layout/Share/ShareDialog'
import WorkspaceView from 'Components/Layout/WorkspaceView'
import LeftSidebar from 'Components/Layout/LeftSidebar'
import RightSidebar from 'Components/Layout/RightSidebar'
import Canvas from 'Components/Layout/Canvas'
import CanvasFab from 'Components/Layout/CanvasFab'
import { useDesigns, useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import { collection, getDocs, serverTimestamp } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { documentTimestampsToString } from 'Components/Layout/WorkspaceView/Helpers/functions'
import { editSnackbar } from 'Features/snackbar'
import { setDefaultTheme, setDesignId, setThemeId } from 'Features/firebaseDocuments'
import { PaletteSchema, defaultPaletteSimple, defaultTypography } from 'Util/ThemeValues'
import { WorkspaceSchema, Document, ThemeSchema, DesignSchema } from 'types/firebase'
import Subscribe from './WorkspaceView/Helpers/FirebaseDocuments/Subscribe'
import DesignScreensHandler from './WorkspaceView/Helpers/FirebaseDocuments/DesignScreensHandler'
import CanvasClickListener from './Canvas/CanvasClickListener'
import ScreensListener from 'Components/Firebase/ScreensListener'
import Preview from './Preview'
import ScreenSyncNotification from 'Components/Firebase/ScreenSyncNotification'

interface LayoutSectionsProps {
  workspace: WorkspaceSchema
}

const LayoutSections: React.FC<LayoutSectionsProps> = ({ workspace }) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const { getDesigns, designsData, designsLoading } = useDesigns(workspace.id)
  const { createTheme, savedTheme } = useThemes(workspace.id)
  const { previewOn } = useTypedSelector(state => state.preview)

  // Set initial rightSidebarWidth to be 25% of the whole screen width
  const [rightSidebarWidth, setRightSidebarWidth] = useState<number>(
    document.body.clientWidth * 0.25
  )
  const { defaultTheme, designIds, themeIds } = useTypedSelector(state => state.firebaseDocuments)
  const selectedDesign = useTypedSelector(state => state.workspaceView.selectedDesign)

  const [themesStatus, setThemesStatus] = useState<'error' | 'success' | 'loading'>('loading')
  const themesRef = collection(firestore, 'Workspaces', workspace.id, 'Themes')

  // Fetch designs only if default theme exists.
  // If default theme doesn't exist, it is created
  useEffect(() => {
    if (defaultTheme) {
      getDesigns()
    }
  }, [defaultTheme])

  useEffect(() => {
    if (designsData && designsData.designStudioDocuments) {
      const designs: DesignSchema[] = designsData.designStudioDocuments
      const handleDesignIds: string[] = []
      for (const design of designs) {
        if (design.deleted) {
          continue
        }
        handleDesignIds.push(design.id)
      }
      dispatch(setDesignId({ allIds: handleDesignIds }))
    }
  }, [designsData])

  // Fetch themes
  useEffect(() => {
    const fetchThemes = async () => {
      try {
        setThemesStatus('loading')
        const querySnapShot = await getDocs(themesRef)
        const handleThemeIds: string[] = []
        querySnapShot.forEach(theme => {
          if (theme.data().defaultTheme) {
            // Firebase has a default theme that all the new created Designs are using.
            // Do not show this with other themes, but save it to reducer
            dispatch(
              setDefaultTheme({
                defaultTheme: documentTimestampsToString({
                  id: theme.id,
                  ...theme.data()
                } as Document) as ThemeSchema
              })
            )
            return
          }
          handleThemeIds.push(theme.id)
        })
        dispatch(setThemeId({ allIds: handleThemeIds }))
        setThemesStatus('success')
      } catch (error) {
        setThemesStatus('error')
        dispatch(
          editSnackbar({
            message: `Error. ${error}`,
            type: 'error'
          })
        )
      }
    }
    fetchThemes()
  }, [])

  // If default theme doesn't exist, create one.
  // TODO: now we create the default theme in front-end if this workspace
  // doesn't have one. Should this be done somewhere else,
  // since Design must have at least one theme (default theme if other theme is not specified).
  useEffect(() => {
    if (themesStatus === 'success' && !defaultTheme) {
      const createDefaultTheme: Omit<ThemeSchema, 'id'> = {
        palette: defaultPaletteSimple as PaletteSchema,
        typography: defaultTypography,
        title: 'Default',
        folder: '',
        updatedAt: serverTimestamp() as unknown as string,
        createdAt: serverTimestamp() as unknown as string,
        defaultTheme: true
      }
      createTheme(createDefaultTheme)
    }
  }, [themesStatus, defaultTheme])

  // Set created default theme to reducer
  useEffect(() => {
    if (savedTheme) {
      dispatch(
        setDefaultTheme({
          defaultTheme: documentTimestampsToString({
            ...savedTheme
          } as Document) as ThemeSchema
        })
      )
    }
  }, [savedTheme])

  return (
    <>
      {!selectedDesign && (
        <WorkspaceView
          workspace={workspace}
          designsLoading={designsLoading}
          themesStatus={themesStatus}
        />
      )}
      {selectedDesign && (
        <>
          {previewOn ? (
            <Preview />
          ) : (
            <>
              <LeftSidebar />
              <RightSidebar
                rightSidebarWidth={rightSidebarWidth}
                setRightSidebarWidth={setRightSidebarWidth}
              />
              <CanvasFab />
              <Canvas />
            </>
          )}
          <ShareDialog workspaceId={workspace.id} selectedDesignId={selectedDesign.id} />
          <CanvasClickListener />
          <ScreenSyncNotification workspaceId={workspace.id} selectedDesignId={selectedDesign.id} />
          <DesignScreensHandler workspaceId={workspace.id} selectedDesignId={selectedDesign.id} />
          <ScreensListener workspaceId={workspace.id} selectedDesign={selectedDesign} />
        </>
      )}
      {<Subscribe documentType="designs" designIds={designIds} />}
      {<Subscribe documentType="themes" themeIds={themeIds} />}
    </>
  )
}

export default LayoutSections
