import React from 'react'
import { ListItem, ListItemText } from '@mui/material'
import { default as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material/Switch'

interface SwitchProps {
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  label?: string
  color?: MuiSwitchProps['color']
  disablePadding?: boolean
  style?: React.CSSProperties
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  label,
  color,
  disablePadding,
  style
}) => {
  return (
    <ListItem
      disablePadding={disablePadding}
      style={style}
      sx={{ paddingTop: '4px', paddingBottom: '4px' }}
    >
      <ListItemText
        primary={label}
        primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
      />
      <MuiSwitch checked={checked} onChange={onChange} color={color} edge="end" />
    </ListItem>
  )
}

export default Switch
