import React, { useEffect, useState } from 'react'
import equal from 'fast-deep-equal'
import { useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import SpinnerMui from 'Components/Common/SpinnerMui'
import { styled } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'
import TitleSection from './Sections/TitleSection'
import ColorsSection from './Sections/ColorsSection'
import TypographySection from './Sections/TypographySection'
import { ThemeSchema, WorkspaceSchema } from 'types/firebase'
import { PaletteSchema, TypographySchema } from 'Util/ThemeValues'

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '19px',
  letterSpacing: '0px',
  color: theme.palette.text.primary,
  marginTop: '8px',
  marginBottom: '8px'
}))

export const ButtonStyled = styled(Button)(() => ({
  marginLeft: '8px',
  borderRadius: '100px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}))

interface ThemeCreationProps {
  workspace: WorkspaceSchema
  selectedTheme: ThemeSchema
}

const ThemeCreation: React.FC<ThemeCreationProps> = ({ workspace, selectedTheme }) => {
  // When changing values, these states are updated, since we are comparing the values to the already saved
  // theme values (selectedTheme). Any changes that are done here, can be then reseted to back to 'selectedTheme' values.
  const [title, setTitle] = useState<string>(selectedTheme.title)
  const [palette, setPalette] = useState<PaletteSchema>(selectedTheme.palette)
  const [typography, setTypography] = useState<TypographySchema>(selectedTheme.typography)

  const { updateTheme, themeSaveLoading } = useThemes(workspace.id)

  useEffect(() => {
    setPalette(selectedTheme.palette)
    setTypography(selectedTheme.typography)
  }, [selectedTheme])

  const handleSaveTheme = () => {
    updateTheme(selectedTheme as ThemeSchema, {
      title,
      typography,
      palette
    })
  }

  return (
    <Box sx={{ marginTop: '24px', marginBottom: '24px', position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: '-79px', right: '60px' }}>
        {themeSaveLoading ? (
          <SpinnerMui
            color="white"
            size={30}
            style={{
              position: 'absolute',
              bottom: '-1px',
              left: '45px'
            }}
          />
        ) : null}
        <Button
          onClick={handleSaveTheme}
          variant="contained"
          disabled={
            themeSaveLoading ||
            (equal(title, selectedTheme.title) &&
              equal(palette, selectedTheme.palette) &&
              equal(typography, selectedTheme.typography))
          }
          disableElevation
        >
          Save changes
        </Button>
      </Box>
      <TitleSection selectedTheme={selectedTheme} title={title} setTitle={setTitle} />
      <ColorsSection selectedTheme={selectedTheme} palette={palette} setPalette={setPalette} />
      <TypographySection
        selectedTheme={selectedTheme}
        typography={typography}
        setTypography={setTypography}
      />
    </Box>
  )
}

export default ThemeCreation
