import React from 'react'
import { useDispatch } from 'react-redux'
import { Node, NodeProps } from 'react-flow-renderer'
import { Typography } from '@mui/material'
import { genScreenId, SCREEN_HEIGHT, SCREEN_WIDTH, nodeIntoScreen } from 'Features/canvas'
import { addScreen } from 'Features/canvasScreens'

const CanvasEmptySpace: React.FC<NodeProps> = () => {
  const dispatch = useDispatch()

  const onAdd = () => {
    // At this point, there is no screens on the canvas
    // so we can just add a new screen to 0,0 position
    const screen = nodeIntoScreen({
      id: genScreenId(),
      type: 'screen',
      position: { x: 0, y: 0 }
    } as Node)

    dispatch(addScreen({ id: screen.id, screen }))
  }

  return (
    <div
      style={{
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT
      }}
    >
      <div
        onClick={() => onAdd()}
        style={{
          position: 'absolute',
          width: '209px',
          height: '209px',
          top: '72.5px',
          left: '75.5px',
          borderRadius: '14px',
          border: '2px dashed #D9E3E6',
          background: '#F5F7F7'
        }}
      >
        <p
          style={{
            position: 'absolute',
            fontSize: '65.33px',
            width: '65.33px',
            height: '65.33px',
            top: '0px',
            left: '88px',
            color: '#dde6e8'
          }}
        >
          +
        </p>
        <Typography
          style={{
            top: '218px',
            left: '37px',
            position: 'absolute'
          }}
          variant="caption"
          align="center"
        >
          Click to add new screen
        </Typography>
      </div>
    </div>
  )
}
export default CanvasEmptySpace
