import React, { useState, useEffect, useRef } from 'react'
import { Layout } from '@uniqore/module'
import { useDrop } from 'react-dnd'
import { ItemTypes } from 'Util/DnDItemTypes'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import { Handle, NodeProps, Position } from 'react-flow-renderer'
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'
import { Box, Theme, Typography } from '@mui/material'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { UqTheme } from '@uniqore/theme'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import AddBottom from 'assets/icons/AddBottom.svg'
import AddBottomHover from 'assets/icons/AddBottomHover.svg'
import AddDefault from 'assets/icons/AddDefault.svg'
import AddDefaultHover from 'assets/icons/AddDefaultHover.svg'
import AddTop from 'assets/icons/AddTop.svg'
import AddTopHover from 'assets/icons/AddTopHover.svg'
import CopyScreen from 'assets/icons/CopyScreen.svg'
import CopyScreenHover from 'assets/icons/CopyScreenHover.svg'
import ComponentRender from './ComponentRender'
import { handleLayoutProps } from 'Components/Layout/Preview'
import { canvasComponents, CanvasComponentsSchema } from 'Util/CanvasComponents'
import {
  AddScreenDir,
  stageScreen,
  genComponentId,
  ComponentSections,
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
  SCREEN_MARGIN
} from 'Features/canvas'
import {
  addComponent,
  selectScreen,
  nextConnectionPosition,
  ConnectionDir
} from 'Features/canvasScreens'
import { editLeftSidebar } from 'Features/layout'
import { CanvasScreen, ThemeSchema } from 'types/firebase'
import { useChildComponent } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Components/ChildComponentHooks'

const ScreenDiv = styled('div')(() => ({
  minHeight: SCREEN_HEIGHT,
  width: SCREEN_WIDTH,
  border: `1px solid #E6EAEB`,
  background: '#ffffff',
  cursor: 'default',
  // create a pseudo element so the div has a greater hover area
  '&:before': {
    content: '""', // future ref: pseudo element needs content to be "rendered"
    position: 'absolute',
    minHeight: SCREEN_HEIGHT + SCREEN_MARGIN / 2,
    width: SCREEN_WIDTH + SCREEN_MARGIN / 2
  }
}))

interface HandleProps {
  edgeconnecting: boolean
}

const ScreenSizeHandle = styled(Handle, {
  name: 'ScreenSizeHandle',
  shouldForwardProp: prop => prop !== 'edgeconnecting'
})<HandleProps>(props => ({
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  background: 'transparent',
  borderColor: 'transparent',
  borderRadius: 0,
  zIndex: props.edgeconnecting ? 2 : 0,
  '&:hover': {
    outline: `2px solid ${props.edgeconnecting ? '#3096E5' : 'transparent'}`
  }
}))

const HandleStyled = styled(Handle, {
  name: 'HandleStyled',
  shouldForwardProp: prop => prop !== 'edgeconnecting'
})<HandleProps>(props => ({
  outline: `2px solid ${props.edgeconnecting ? '#3097e5' : 'rgba(48, 151, 229, 0.5)'}`,
  '&:hover': {
    outline: '2px solid #3097e5'
  }
}))

// In which section (headers, contents, footers) component is placed
export const getComponentSection = (
  type: keyof CanvasComponentsSchema
): keyof ComponentSections => {
  let result: keyof ComponentSections = 'contents'
  if (type === 'uqAppBar') {
    result = 'headers'
  } else if (type === 'flowNav') {
    result = 'footers'
  } else {
    result = 'contents'
  }
  return result
}

const screenToIcon = (direction: ConnectionDir, hovering: boolean): string => {
  if (direction === ConnectionDir.Right) {
    if (hovering) {
      return AddDefaultHover
    } else {
      return AddDefault
    }
  } else if (direction === ConnectionDir.Down) {
    if (hovering) {
      return AddBottomHover
    } else {
      return AddBottom
    }
  } else {
    if (direction === ConnectionDir.Up) {
      return AddTopHover
    } else {
      return AddTop
    }
  }
}

const ScreenAddButton: React.FC<{
  dir: AddScreenDir
  screen: CanvasScreen
  components: ComponentSections
}> = ({ dir, screen, components }) => {
  const dispatch = useDispatch()
  const direction = useTypedSelector(state =>
    nextConnectionPosition(state.undoables.present.canvasScreens, screen)
  )
  const [topHover, setTopHover] = useState<boolean>(false)
  const [bottomHover, setBottomHover] = useState<boolean>(false)

  if (!screen) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '265px',
        left: SCREEN_WIDTH + 16
      }}
    >
      <div
        onClick={() => dispatch(stageScreen({ direction: dir, sourceId: screen.id }))}
        onMouseEnter={() => setTopHover(true)}
        onMouseLeave={() => setTopHover(false)}
        style={{
          height: 40,
          width: 40,
          marginBottom: '32px',
          cursor: 'pointer'
        }}
      >
        <img src={screenToIcon(direction.direction, topHover)}></img>
      </div>
      <div
        onClick={() =>
          dispatch(
            stageScreen({ direction: dir, sourceId: screen.id, copiedScreenComponents: components })
          )
        }
        onMouseEnter={() => setBottomHover(true)}
        onMouseLeave={() => setBottomHover(false)}
        style={{
          height: 40,
          width: 40,
          cursor: 'pointer'
        }}
      >
        <img src={bottomHover ? CopyScreenHover : CopyScreen}></img>
      </div>
    </div>
  )
}

interface ScreenLabelProps {
  screenLabel: string
  hovering: boolean
}

const ScreenLabel: React.FC<ScreenLabelProps> = ({ screenLabel, hovering }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '-20px',
        left: '-16px'
      }}
    >
      <div
        className="drag-handle"
        style={{ display: 'flex', alignItems: 'center', cursor: 'grab' }}
      >
        <DragIndicatorRoundedIcon
          style={{ visibility: hovering ? 'visible' : 'hidden' }}
          sx={{
            fontSize: '16px',
            marginRight: '2px',
            color: '#3096E5'
          }}
        />
        <Typography
          variant="subtitle2"
          sx={{
            color: hovering ? '#3096E5' : '#80959B'
          }}
        >
          {screenLabel}
        </Typography>
      </div>
    </div>
  )
}

interface FlowStartIndicator {
  screen: CanvasScreen
  showEdges: boolean
}

const FlowStartIndicator: React.FC<FlowStartIndicator> = ({ screen, showEdges }) => {
  const dispatch = useDispatch()
  const { leftSidebar } = useTypedSelector(state => state.layout)

  const handleClick = () => {
    dispatch(
      editLeftSidebar({
        stateKey: 'open',
        value: leftSidebar.section === 'prototype' ? !leftSidebar.open : true
      })
    )
    dispatch(editLeftSidebar({ stateKey: 'section', value: 'prototype' }))
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        position: 'absolute',
        left: '-40px',
        height: '40px',
        width: '40px',
        backgroundColor: '#3096E5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      style={screen.flowStartPoint && showEdges ? {} : { display: 'none' }}
    >
      <PlayIcon />
    </Box>
  )
}

// Screen should contain all the components / modules
const CanvasScreenComponent: React.FC<NodeProps> = ({ id }) => {
  // Ref is used to set Mui's Dialog-component inside the screen div
  const container = useRef(null)
  const dispatch = useDispatch()
  const [hovering, setHovering] = useState<boolean>(false)
  const edgeConnecting = useTypedSelector(state => state.helpers.edgeConnecting)
  const showEdges = useTypedSelector(state => state.canvas.showEdges)
  const { themes, defaultTheme } = useTypedSelector(state => state.firebaseDocuments)
  const selectedDesign = useTypedSelector(state => state.workspaceView.selectedDesign)
  const screen = useTypedSelector(state => state.undoables.present.canvasScreens.screens[id])
  const selectedScreenId = useTypedSelector(
    state => state.undoables.present.canvasScreens.selectedScreenId
  )
  const { addChildComponent } = useChildComponent()
  const [projectTheme, setProjectTheme] = useState<Theme>(UqTheme)

  useEffect(() => {
    const findTheme = [...themes, defaultTheme as ThemeSchema].find(
      t => t.id === selectedDesign?.themeId
    )
    if (findTheme && selectedDesign) {
      setProjectTheme(
        createTheme({
          palette: {
            ...findTheme.palette,
            ...selectedDesign.themeOverride?.palette
          },
          typography: {
            ...findTheme.typography,
            ...selectedDesign.themeOverride?.typography
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          wrappers: {
            // TODO: This border-radius & box-shadow should be adjustable in settings somewhere.
            uqButton: {
              sx: {
                borderRadius: '100px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }
            }
          }
        })
      )
    }
  }, [selectedDesign])

  // Is used when dragging components from AddSection (sidepanel) to screen
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    collect: monitor => ({
      isOver: !!monitor.isOver()
    }),
    drop: (item: { type: keyof CanvasComponentsSchema }) => addComponentToScreen(item.type)
  })

  if (!screen) return <></>
  const { headers, contents, footers } = screen.components

  const addComponentToScreen = (type: keyof CanvasComponentsSchema) => {
    const component = {
      id: genComponentId(),
      componentName: type,
      props: canvasComponents[type].props
    }
    dispatch(
      addComponent({
        screenId: screen.id,
        component: component,
        section: getComponentSection(type)
      })
    )
    dispatch(selectScreen({ screenId: id }))
    if (component.componentName === 'uqList' && selectedScreenId) {
      addChildComponent(selectedScreenId, component, 'contents', {
        componentName: 'uqListItem'
      })
    }
  }

  const getOutlineWidth = (isOver: boolean) => {
    if (hovering || isOver) {
      return 2
    } else if (id === selectedScreenId) {
      return 1
    } else {
      return 0
    }
  }

  return (
    <ThemeProvider theme={projectTheme}>
      {/* Target-Handle that covers the whole screen */}
      <ScreenSizeHandle edgeconnecting={edgeConnecting} type="target" position={Position.Left} />
      {/* Round source-Handle on the right side of the screen */}
      <HandleStyled
        edgeconnecting={edgeConnecting}
        type="source"
        position={Position.Right}
        style={{
          width: '16px',
          height: '16px',
          background: 'white',
          border: 'none',
          right: '-9px',
          zIndex: 2,
          visibility: showEdges ? 'visible' : 'hidden'
        }}
      />
      <FlowStartIndicator screen={screen} showEdges={showEdges} />
      <ScreenLabel screenLabel={screen.label} hovering={selectedScreenId === id || hovering} />
      <div ref={drop}>
        <ScreenDiv
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => dispatch(selectScreen({ screenId: id }))}
          style={{
            outlineWidth: getOutlineWidth(isOver),
            outlineStyle: 'solid',
            outlineColor: '#3096E5'
          }}
        >
          <Layout
            {...handleLayoutProps(screen, selectedDesign)}
            ref={container}
            useCenteredView={false}
            maxWidth={false}
            borderWidth="0px"
            paddingTop="24px"
            paddingBottom="42px"
            paddingLeft="16px"
            paddingRight="16px"
            top={
              headers.length > 0
                ? headers.map(component => (
                    <div
                      key={component.id}
                      // style={{
                      //   pointerEvents: isShiftPressed ? 'all' : 'none'
                      // }}
                    >
                      <ComponentRender screenId={id} component={component} />
                    </div>
                  ))
                : null
            }
            bottom={
              footers.length > 0
                ? footers.map(component => (
                    <div
                      key={component.id}
                      // style={{
                      //   pointerEvents: isShiftPressed ? 'all' : 'none'
                      // }}
                    >
                      <ComponentRender screenId={id} component={component} />
                    </div>
                  ))
                : null
            }
          >
            {contents.map(component => (
              <div
                key={component.id}
                // style={{
                //   pointerEvents: isShiftPressed ? 'all' : 'none'
                // }}
              >
                <ComponentRender screenId={id} component={component} container={container} />
              </div>
            ))}
          </Layout>
          {(selectedScreenId === id || hovering) && (
            <ScreenAddButton
              screen={screen}
              dir={AddScreenDir.Top}
              components={screen.components}
            />
          )}
        </ScreenDiv>
      </div>
    </ThemeProvider>
  )
}

export default React.memo(CanvasScreenComponent)
