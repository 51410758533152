import React, { useState, useEffect, useReducer } from 'react'
import { useTypedSelector } from 'Store'
import chroma from 'chroma-js'
import merge from 'deepmerge'
import equal from 'fast-deep-equal'
import { ColorResult } from 'react-color'
import { Box, Button, useTheme } from '@mui/material'
import ColorBasic from './ColorsBasic'
import ColorsAdvanced from './ColorsAdvanced'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import SpinnerMui from 'Components/Common/SpinnerMui'
import TypographyButton from 'Components/Common/TypographyButton'
import { colorReducer, setAlphaToHex } from 'Components/Common/ColorPicker'
import { PaletteSchema, ColorSchema, BackgroundSchema, TextSchema } from 'Util/ThemeValues'
import { WorkspaceSchema, DesignSchema } from 'types/firebase'
import { getChromaValue } from 'Components/Layout/WorkspaceView/WorkspaceComponents/ThemeCreation/Sections/ColorsSection'

interface ColorsProps {
  workspace: WorkspaceSchema
  selectedDesign: DesignSchema
  combinedPalette: PaletteSchema
}

const Colors: React.FC<ColorsProps> = ({ workspace, selectedDesign, combinedPalette }) => {
  const theme = useTheme()
  const isCurrentlySaving = useTypedSelector(state => state.saveHelper.isCurrentlySaving)
  const { updateDesign } = useDesigns(workspace.id)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [generateColorVariations, setGenerateColorVariations] = useState<boolean>(
    combinedPalette.primary.dark ? false : true
  )
  const [palette, setPalette] = useState<PaletteSchema>(combinedPalette)

  // These colors are for the round indicator and ColorPicker itself to use.
  // These are updated with SketchPicker's onChange for fluid animation.
  // SketchPicker's onChangeComplete updates whatever state is chosen, in this case 'palette' (above)
  const [colors, colorDispatch] = useReducer(colorReducer, combinedPalette)

  useEffect(() => {
    setPalette(combinedPalette)
    colorDispatch({ type: 'reset', resetPayload: combinedPalette })
  }, [combinedPalette])

  // TODO: this code is copy-pasted from 'ColorsSection'-component, make a hook?
  // If theme's palette doesn't have color variations (dark, light, contrastText etc.)
  // and user doesn't want Mui theme to generate them, this will create initial variation values
  // with the same method which Mui is using (chroma)
  useEffect(() => {
    if (generateColorVariations) {
      const paletteBasicValues = {
        primary: { main: palette.primary.main },
        secondary: { main: palette.secondary.main },
        info: { main: palette.info.main },
        success: { main: palette.success.main },
        warning: { main: palette.warning.main },
        error: { main: palette.error.main },
        text: { primary: palette.text.primary },
        background: { default: palette.background.default }
      }
      colorDispatch({ type: 'reset', resetPayload: paletteBasicValues as PaletteSchema })
      setPalette(paletteBasicValues as PaletteSchema)
    }
    if (!generateColorVariations) {
      const paletteVariations = {
        primary: {
          dark: '',
          light: '',
          contrastText: ''
        },
        secondary: {
          dark: '',
          light: '',
          contrastText: ''
        },
        info: {
          dark: '',
          light: '',
          contrastText: ''
        },
        success: {
          dark: '',
          light: '',
          contrastText: ''
        },
        warning: {
          dark: '',
          light: '',
          contrastText: ''
        },
        error: {
          dark: '',
          light: '',
          contrastText: ''
        },
        text: {
          secondary: '',
          disabled: ''
        },
        background: {
          paper: ''
        }
      }
      // Generate dark, light & contrastText variations for primary, secondary etc...
      Object.keys(palette)
        .filter(key => key !== 'text' && key !== 'background')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .forEach((paletteKey: keyof Omit<PaletteSchema, 'text' | 'background'>) => {
          if (!palette[paletteKey].dark) {
            paletteVariations[paletteKey].dark = getChromaValue(palette[paletteKey].main, 'dark')
          } else {
            paletteVariations[paletteKey].dark = palette[paletteKey].dark
          }
          if (!palette[paletteKey].light) {
            paletteVariations[paletteKey].light = getChromaValue(palette[paletteKey].main, 'light')
          } else {
            paletteVariations[paletteKey].light = palette[paletteKey].light
          }
          if (!palette[paletteKey].contrastText) {
            paletteVariations[paletteKey].contrastText = theme.palette.getContrastText(
              palette[paletteKey].main
            )
          } else {
            paletteVariations[paletteKey].contrastText = palette[paletteKey].contrastText
          }
        })

      // Generate variations for text & background
      Object.keys(palette)
        .filter(key => key === 'text' || key === 'background')
        .forEach(paletteKey => {
          if (paletteKey === 'text') {
            if (!palette[paletteKey].secondary) {
              paletteVariations[paletteKey].secondary = chroma(palette[paletteKey].primary)
                .alpha(0.6)
                .hex()
            } else {
              paletteVariations[paletteKey].secondary = palette[paletteKey].secondary
            }
            if (!palette[paletteKey].disabled) {
              paletteVariations[paletteKey].disabled = chroma(palette[paletteKey].primary)
                .alpha(0.38)
                .hex()
            } else {
              paletteVariations[paletteKey].disabled = palette[paletteKey].disabled
            }
          }
          if (paletteKey === 'background') {
            if (!palette[paletteKey].paper) {
              paletteVariations[paletteKey].paper = '#ffffff'
            } else {
              paletteVariations[paletteKey].paper = palette[paletteKey].paper
            }
          }
        })
      setPalette(merge(palette, paletteVariations as PaletteSchema))
    }
  }, [generateColorVariations])

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param key - Palette's types (primary, secondary, background, text etc...)
   * @param colorKey - (main, dark, light, contrastText)
   */
  const updateColor = (
    color: ColorResult,
    stateUpdate: boolean,
    key: keyof PaletteSchema,
    colorKey: keyof ColorSchema
  ) => {
    if (stateUpdate) {
      setPalette({ ...palette, [key]: { ...palette[key], [colorKey]: setAlphaToHex(color) } })
    } else {
      colorDispatch({ type: 'modify', payload: { value: color.rgb, key, colorKey } })
    }
  }

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param backgroundKey - Values that palette's background -type has (default, paper)
   */
  const updateBackground = (
    color: ColorResult,
    stateUpdate: boolean,
    backgroundKey: keyof BackgroundSchema
  ) => {
    if (stateUpdate) {
      setPalette({
        ...palette,
        background: { ...palette.background, [backgroundKey]: setAlphaToHex(color) }
      })
    } else {
      colorDispatch({
        type: 'modify',
        payload: { value: color.rgb, key: 'background', backgroundKey }
      })
    }
  }

  /**
   * @param color - An object that SketchPicker returns. Includes for example hex and rgb colors.
   * @param stateUpdate - Updates whatever state is chosen, true only when SketchPicker's onChangeComplete updates
   * @param textKey - Values that palette's text -type has (primary, secondary, disabled)
   */
  const updateText = (color: ColorResult, stateUpdate: boolean, textKey: keyof TextSchema) => {
    if (stateUpdate) {
      setPalette({ ...palette, text: { ...palette.text, [textKey]: setAlphaToHex(color) } })
    } else {
      colorDispatch({ type: 'modify', payload: { value: color.rgb, key: 'text', textKey } })
    }
  }

  const handleSaveColors = () => {
    updateDesign(selectedDesign, {
      themeOverride: {
        ...selectedDesign.themeOverride,
        palette
      }
    })
  }

  const handleResetColors = () => {
    // Checks if theme had already not-generated color variants
    if (!combinedPalette.primary.dark) {
      setGenerateColorVariations(true)
    } else {
      setGenerateColorVariations(false)
    }
    colorDispatch({ type: 'reset', resetPayload: combinedPalette })
    const updatedThemeOverride = JSON.parse(JSON.stringify(selectedDesign.themeOverride))
    delete updatedThemeOverride.palette
    updateDesign(selectedDesign, {
      themeOverride: updatedThemeOverride
    })
  }

  return (
    <div style={{ marginLeft: '16px', marginBottom: '16px', position: 'relative' }}>
      <div style={{ position: 'absolute', right: '16px', top: '-36px', background: 'white' }}>
        {isCurrentlySaving ? (
          <SpinnerMui
            color="white"
            size={25}
            style={{
              position: 'absolute',
              right: '40px',
              top: '3px'
            }}
          />
        ) : null}
        <Button
          onClick={handleSaveColors}
          disabled={equal(palette, combinedPalette)}
          color="primary"
          variant="contained"
          disableElevation
          sx={{ borderRadius: '100px', backgroundColor: 'primary.light' }}
          size="small"
        >
          Save changes
        </Button>
      </div>
      <ColorBasic
        colors={colors}
        palette={palette}
        updateColor={updateColor}
        updateBackground={updateBackground}
        updateText={updateText}
      />
      <ColorsAdvanced
        selectedDesign={selectedDesign}
        isCurrentlySaving={isCurrentlySaving}
        showAdvanced={showAdvanced}
        setShowAdvanced={setShowAdvanced}
        combinedPalette={combinedPalette}
        colors={colors}
        palette={palette}
        updateColor={updateColor}
        updateBackground={updateBackground}
        updateText={updateText}
        generateColorVariations={generateColorVariations}
        setGenerateColorVariations={setGenerateColorVariations}
        handleSaveColors={handleSaveColors}
        handleResetColors={handleResetColors}
      />
      <Box>
        <TypographyButton
          text="Advanced settings"
          onClick={() => setShowAdvanced(true)}
          color={theme.palette.secondary.main}
          style={{ marginBottom: '8px' }}
        />
        <TypographyButton
          text="Reset to theme"
          disabled={!selectedDesign.themeOverride?.palette || isCurrentlySaving}
          onClick={handleResetColors}
          color={theme.palette.error.main}
        />
      </Box>
    </div>
  )
}

export default Colors
