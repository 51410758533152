import React, { Dispatch, SetStateAction } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { SelectOption } from 'Components/Common/Select'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import { ComponentActionSchema } from './ActionSection'
import { useActions } from '../../Hooks/ActionHooks'
import { CanvasScreen } from 'types/firebase'

export interface NavigationActionSchema {
  navigateTo?: string
}

const handleOption = (
  type: 'next' | 'prev' | 'screens',
  selectedScreen: CanvasScreen | undefined,
  screens: Record<string, CanvasScreen>,
  screen?: CanvasScreen
): { value: string; label: string; disabled: boolean } => {
  switch (type) {
    case 'next': {
      if (selectedScreen && selectedScreen.connections.length > 0) {
        const label =
          screens[selectedScreen.connections[0].target].label ||
          selectedScreen.connections[0].target
        return {
          value: selectedScreen.connections[0].target,
          label: `Next (${label})`,
          disabled: false
        }
      }
      return { value: '', label: 'Next (not set)', disabled: true }
    }
    case 'prev': {
      if (selectedScreen && selectedScreen.sourceId) {
        const label = screens[selectedScreen.sourceId].label || selectedScreen.sourceId
        return {
          value: selectedScreen.sourceId,
          label: `Previous (${label})`,
          disabled: false
        }
      }
      return { value: '', label: 'Previous (not set)', disabled: true }
    }
    case 'screens': {
      if (screen) {
        return { value: screen.id, label: screen.label, disabled: false }
      }
      return { value: '', label: '', disabled: true }
    }
    default:
      return { value: '', label: '', disabled: true }
  }
}

const navigateToOptions = (
  selectedScreen: CanvasScreen | undefined,
  screens: Record<string, CanvasScreen>
): SelectOption[] => {
  const options = [] as SelectOption[]
  // Add next value
  const next = handleOption('next', selectedScreen, screens)
  options.push({ value: next.value, label: next.label, disabled: next.disabled })

  // Add prev value
  const prev = handleOption('prev', selectedScreen, screens)
  options.push({ value: prev.value, label: prev.label, disabled: prev.disabled })

  // Add other screens
  Object.values(screens)
    .filter(s => s.id !== selectedScreen?.id)
    .filter(s => s.id !== next.value)
    .filter(s => s.id !== prev.value)
    .forEach(screen => {
      options.push({ ...handleOption('screens', selectedScreen, screens, screen) })
    })

  return options
}

// Set default navigation-action values
export const handleNavigationDefaultValues = (
  action: ComponentActionSchema,
  selectedScreen: CanvasScreen,
  screens: Record<string, CanvasScreen>
) => {
  if (selectedScreen.connections && selectedScreen.connections.length > 0) {
    // Select next if target specified
    action.navigateTo = selectedScreen.connections[0].target
  } else {
    // Else select random screen
    const screenArr = Object.values(screens).filter(s => s.id !== selectedScreen.id)
    if (screenArr.length === 0) {
      action.navigateTo = ''
    } else {
      const randomInt = Math.floor(Math.random() * (screenArr.length - 0)) + 0
      action.navigateTo = screenArr[randomInt].id
    }
  }
}

interface NavigationActionProps {
  action: NavigationActionSchema
  setActions: Dispatch<SetStateAction<ComponentActionSchema[]>>
  selectedScreen: CanvasScreen
}

const NavigationAction: React.FC<NavigationActionProps> = ({
  action,
  setActions,
  selectedScreen
}) => {
  const { updateActions, screens } = useActions()

  const handleNavigateTo = (e: SelectChangeEvent, action: NavigationActionSchema) => {
    const navigateTo = e.target.value
    const updateAction = { ...action, navigateTo }
    updateActions('edit', updateAction as ComponentActionSchema, setActions)
  }

  return (
    <>
      <SmallSelect
        label="Screen"
        value={action.navigateTo}
        onChange={e => handleNavigateTo(e, action)}
        options={navigateToOptions(selectedScreen, screens)}
        doNotFormatValue
      />
    </>
  )
}

export default NavigationAction
