import { useDispatch } from 'react-redux'
import { clearPreview, togglePreview, setCurrentScreen } from 'Features/preview'
import { changeFlowStartScreen } from 'Features/canvasScreens'
import { editSnackbar } from 'Features/snackbar'
import { CanvasScreen } from 'types/firebase'

export const usePreview = (protoView?: boolean) => {
  const dispatch = useDispatch()

  const openPreview = (screens: Record<string, CanvasScreen>) => {
    const screensArr = Object.values(screens)
    let screenId = null

    // Find starting point screen
    for (const screen of screensArr) {
      if (screen.flowStartPoint) {
        screenId = screen.id
        break
      }
    }

    // If starting point screen is not yet defined, find first screen
    // that has sourceId null and set is as starting point screen
    if (screenId === null) {
      for (const screen of screensArr) {
        if (screen.sourceId === null) {
          if (!protoView) {
            dispatch(changeFlowStartScreen({ screenId: screen.id }))
          }
          screenId = screen.id
          break
        }
      }
    }

    // If all screens have sourceId, set random screen as starting point
    if (screenId === null) {
      if (screensArr.length > 0) {
        if (!protoView) {
          dispatch(changeFlowStartScreen({ screenId: screensArr[0].id }))
        }
        screenId = screensArr[0].id
      }
    }

    // Open preview only when screen id is not null to prevent errors in preview-mode
    if (screenId !== null) {
      dispatch(clearPreview())
      dispatch(togglePreview({ togglePreview: true }))
      dispatch(setCurrentScreen({ currentScreen: screenId }))
    } else {
      dispatch(
        editSnackbar({
          message: 'Flow starting point screen is not defined.',
          type: 'error'
        })
      )
    }
  }

  return {
    openPreview
  }
}
