import React, { useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { fi, enGB, enUS } from 'date-fns/locale'
import { UqDatePicker, UqStaticDatePicker, UqTextField, UqBox } from '@uniqore/wrapper'
import { UqDatePickerPropsCustom } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'

const localeMap = {
  fi: fi,
  'en-uk': enGB,
  'en-us': enUS
}

interface UqDatePickerRenderProps {
  component: ScreenComponent
}

const UqDatePickerRender: React.FC<UqDatePickerRenderProps> = ({ component }) => {
  const props = component.props as UqDatePickerPropsCustom

  const [value, setValue] = useState<Date | null>(null)

  return (
    <UqBox
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[props.localization || 'en-uk']}
      >
        {props.variation === 'inline' ? (
          <UqStaticDatePicker
            {...props}
            value={value}
            onChange={newValue => {
              setValue(newValue)
            }}
            renderInput={params => <UqTextField {...params} fullWidth />}
            disableMaskedInput
            openTo="day"
            displayStaticWrapperAs="desktop"
          />
        ) : (
          <UqDatePicker
            {...props}
            value={value}
            onChange={newValue => {
              setValue(newValue)
            }}
            renderInput={params => <UqTextField {...params} fullWidth />}
            disableMaskedInput
          />
        )}
      </LocalizationProvider>
    </UqBox>
  )
}

export default UqDatePickerRender
