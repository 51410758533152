import React from 'react'
import equal from 'fast-deep-equal'
import { Property } from 'csstype'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  Drawer,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  SelectChangeEvent
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SpinnerMui from 'Components/Common/SpinnerMui'
import TypographyButton from 'Components/Common/TypographyButton'
import TypographyCard from './TypographyCard'
import { TypographyVariant, TypographySchema } from 'Util/ThemeValues'
import { DesignSchema } from 'types/firebase'

interface TypographysAdvancedProps {
  selectedDesign: DesignSchema
  isCurrentlySaving: boolean
  typography: TypographySchema
  combinedTypography: TypographySchema
  updateFontWeight: (event: SelectChangeEvent<string>, variant: TypographyVariant) => void
  updateFontStyle: (isItalic: boolean, variant: TypographyVariant) => void
  updateTextDecoration: (isUnderline: boolean, variant: TypographyVariant) => void
  updateTextTransform: (isUpperCase: boolean, variant: TypographyVariant) => void
  showAdvanced: boolean
  setShowAdvanced: React.Dispatch<React.SetStateAction<boolean>>
  handleSaveTypographys: () => void
  handleResetTypography: () => void
}

const TypographysAdvanced: React.FC<TypographysAdvancedProps> = ({
  selectedDesign,
  isCurrentlySaving,
  typography,
  combinedTypography,
  updateFontWeight,
  updateFontStyle,
  updateTextDecoration,
  updateTextTransform,
  showAdvanced,
  setShowAdvanced,
  handleSaveTypographys,
  handleResetTypography
}) => {
  const theme = useTheme()

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          whiteSpace: 'nowrap',
          backgroundColor: 'background.paper',
          height: theme => `calc(100% - ${theme.props.navBarHeight})`,
          top: theme => theme.props.navBarHeight,
          overflowX: 'hidden',
          transition: 'width 250ms ease-in-out',
          width: theme => (showAdvanced ? theme.props.leftSidebar : 0),
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
    >
      <Box>
        <div style={{ position: 'absolute', top: '11px', right: '0px' }}>
          {isCurrentlySaving ? (
            <SpinnerMui
              color="white"
              size={25}
              style={{
                position: 'absolute',
                right: '55px',
                top: '3px'
              }}
            />
          ) : null}
          <Button
            onClick={handleSaveTypographys}
            disabled={equal(typography, combinedTypography)}
            color="primary"
            variant="contained"
            disableElevation
            sx={{
              marginBottom: '16px',
              marginRight: '16px',
              borderRadius: '100px',
              zIndex: 2,
              backgroundColor: 'primary.light'
            }}
            size="small"
          >
            Save changes
          </Button>
        </div>
        <ListItem disableGutters sx={{ paddingLeft: '8px', paddingRight: '16px' }}>
          <IconButton onClick={() => setShowAdvanced(false)}>
            <ArrowBackIcon />
          </IconButton>
          <ListItemText
            primary="Typography"
            primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
            sx={{ marginLeft: '8px' }}
          />
        </ListItem>
        <Divider />
        <Box sx={{ margin: '12px 16px' }}>
          <TypographyCard
            text="Headline"
            fontSize={typography.h5.fontSize as Property.FontSize}
            fontWeight={typography.h5.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'h5')}
            fontStyle={typography.h5.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'h5')}
            textDecoration={typography.h5.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'h5')}
            textTransform={typography.h5.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'h5')}
          />
          <TypographyCard
            text="Title"
            fontSize={typography.h6.fontSize as Property.FontSize}
            fontWeight={typography.h6.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'h6')}
            fontStyle={typography.h6.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'h6')}
            textDecoration={typography.h6.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'h6')}
            textTransform={typography.h6.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'h6')}
          />
          <TypographyCard
            text="Subtitle 1"
            fontSize={typography.subtitle1.fontSize as Property.FontSize}
            fontWeight={typography.subtitle1.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'subtitle1')}
            fontStyle={typography.subtitle1.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'subtitle1')}
            textDecoration={typography.subtitle1.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'subtitle1')}
            textTransform={typography.subtitle1.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'subtitle1')}
          />
          <TypographyCard
            text="Subtitle 2"
            fontSize={typography.subtitle2.fontSize as Property.FontSize}
            fontWeight={typography.subtitle2.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'subtitle2')}
            fontStyle={typography.subtitle2.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'subtitle2')}
            textDecoration={typography.subtitle2.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'subtitle2')}
            textTransform={typography.subtitle2.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'subtitle2')}
          />
          <TypographyCard
            text="Body 1"
            fontSize={typography.body1.fontSize as Property.FontSize}
            fontWeight={typography.body1.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'body1')}
            fontStyle={typography.body1.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'body1')}
            textDecoration={typography.body1.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'body1')}
            textTransform={typography.body1.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'body1')}
          />
          <TypographyCard
            text="Body 2"
            fontSize={typography.body2.fontSize as Property.FontSize}
            fontWeight={typography.body2.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'body2')}
            fontStyle={typography.body2.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'body2')}
            textDecoration={typography.body2.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'body2')}
            textTransform={typography.body2.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'body2')}
          />
          <TypographyCard
            text="Button"
            fontSize={typography.button.fontSize as Property.FontSize}
            fontWeight={typography.button.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'button')}
            fontStyle={typography.button.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'button')}
            textDecoration={typography.button.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'button')}
            textTransform={typography.button.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'button')}
          />
          <TypographyCard
            text="Caption"
            fontSize={typography.caption.fontSize as Property.FontSize}
            fontWeight={typography.caption.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'caption')}
            fontStyle={typography.caption.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'caption')}
            textDecoration={typography.caption.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'caption')}
            textTransform={typography.caption.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'caption')}
          />
          <TypographyCard
            text="Overline"
            fontSize={typography.overline.fontSize as Property.FontSize}
            fontWeight={typography.overline.fontWeight}
            fontWeightOnChange={value => updateFontWeight(value, 'overline')}
            fontStyle={typography.overline.fontStyle}
            fontStyleOnChange={value => updateFontStyle(value, 'overline')}
            textDecoration={typography.overline.textDecoration as Property.TextDecoration}
            textDecorationOnChange={value => updateTextDecoration(value, 'overline')}
            textTransform={typography.overline.textTransform}
            textTransformOnChange={value => updateTextTransform(value, 'overline')}
          />
        </Box>
        <Box sx={{ margin: '16px' }}>
          <TypographyButton
            text="Reset to theme"
            disabled={!selectedDesign.themeOverride?.typography || isCurrentlySaving}
            onClick={handleResetTypography}
            color={theme.palette.error.main}
          />
        </Box>
      </Box>
    </Drawer>
  )
}

export default TypographysAdvanced
