import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqInputAdornment, UqInputAdornmentProps } from '@uniqore/wrapper'
import { UqInputAdornmentPropsCustom } from 'Util/CanvasComponents'

interface UqInputAdornmentRenderProps {
  componentProps: UqInputAdornmentPropsCustom
  position: UqInputAdornmentProps['position']
}

const UqInputAdornmentRender: React.FC<UqInputAdornmentRenderProps> = ({
  componentProps,
  position
}) => {
  return (
    <UqInputAdornment position={position}>
      {componentProps.type === 'icon' && componentProps.icon
        ? React.createElement(Icons[componentProps.icon as IconType])
        : componentProps.type === 'text'
        ? componentProps.text
        : null}
    </UqInputAdornment>
  )
}

export default UqInputAdornmentRender
