import React, { useState, useEffect } from 'react'
import { useTheme, Theme } from '@mui/material/styles'
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton
} from '@mui/material'
import Icons, { IconType } from 'Components/Common/Icons'
import RemoveIcon from '@mui/icons-material/Remove'

const childrenStyles = (theme: Theme) => {
  return {
    border: `1px solid ${theme.custom.borderColor}`,
    borderTop: '0px',
    borderRadius: '4px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: theme.custom.nightBlue[40]
  }
}

interface ContentBoxProps {
  primaryText?: string
  secondaryText?: string
  icon?: IconType
  onClickRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  collapsible?: boolean
  open?: boolean
  disablePadding?: boolean
  children?: NonNullable<React.ReactNode>
  style?: React.CSSProperties
}

const ContentBox: React.FC<ContentBoxProps> = ({
  primaryText,
  secondaryText,
  icon,
  onClickRemove,
  open,
  collapsible = false,
  disablePadding,
  children,
  style
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isHover, setIsHover] = useState<boolean>(false)

  useEffect(() => {
    setIsOpen(open ? true : false)
  }, [open])

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={style}
      sx={disablePadding ? {} : { padding: '4px 16px' }}
    >
      <List disablePadding>
        <ListItem
          onClick={() => setIsOpen(!isOpen)}
          secondaryAction={
            onClickRemove ? (
              <IconButton
                onClick={onClickRemove}
                sx={isHover ? {} : { display: 'none' }}
                edge="end"
              >
                <RemoveIcon sx={{ color: theme => theme.palette.text.secondary }} />
              </IconButton>
            ) : null
          }
          sx={{
            cursor: 'pointer',
            height: '56px',
            border: theme => `1px solid ${theme.custom.borderColor}`,
            borderRadius: '4px',
            borderBottomLeftRadius: !isOpen ? '4px' : '0px',
            borderBottomRightRadius: !isOpen ? '4px' : '0px',
            background: isHover ? '#FAFAFA' : theme => theme.custom.nightBlue[40]
          }}
        >
          {icon && (
            <ListItemIcon>
              {React.createElement(Icons[icon], {
                style: { color: theme.palette.text.secondary }
              })}
            </ListItemIcon>
          )}
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            primaryTypographyProps={{
              variant: 'body2',
              sx: { color: theme => theme.palette.text.primary }
            }}
            secondaryTypographyProps={{
              sx: { color: theme => theme.palette.text.secondary }
            }}
          />
        </ListItem>
        {collapsible ? (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <div style={childrenStyles(theme)}>{children}</div>
          </Collapse>
        ) : (
          <div style={childrenStyles(theme)}> {children}</div>
        )}
      </List>
    </Box>
  )
}

export default ContentBox
