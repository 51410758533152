import React, { useState, useEffect } from 'react'
import { useTypedSelector } from 'Store'
import { UqList, UqListProps } from '@uniqore/wrapper'
import { UqListPropsExtended } from 'Util/CanvasComponents'
import UqListItemRender from './SubComponents/UqListItemRender'
import { ScreenComponent } from 'Features/canvas'

interface UqListRenderProps {
  screenId: string
  component: ScreenComponent
}

const UqListRender: React.FC<UqListRenderProps> = ({ screenId, component }) => {
  const protoDocumentData = useTypedSelector(state => state.share.protoDocumentData)
  const screens = useTypedSelector(state => state.undoables.present.canvasScreens.screens)
  const [contents, setContents] = useState<ScreenComponent[]>([])
  const [listItems, setListItems] = useState<ScreenComponent[]>([])

  useEffect(() => {
    if (protoDocumentData) {
      setContents(protoDocumentData.screens[screenId].components.contents)
    } else if (screens && screens[screenId]) {
      setContents(screens[screenId].components.contents)
    }
  }, [protoDocumentData, screens, screenId])

  const props = component.props as UqListProps & UqListPropsExtended

  useEffect(() => {
    const children: ScreenComponent[] = []
    const childrenIds = props.childrenComponents || []
    childrenIds.forEach(childId => {
      contents
        .filter(c => c.parentComponentId)
        .forEach(content => {
          if (content.id === childId) {
            children.push(content)
          }
        })
    })
    setListItems(children)
  }, [contents, props.childrenComponents])

  return (
    <UqList
      style={{ ...component.style }}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      dense={props.dense}
      disablePadding={props.disablePadding}
    >
      {listItems.map(listItem => (
        <UqListItemRender key={listItem.id} component={listItem} />
      ))}
    </UqList>
  )
}

export default UqListRender
