import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PreviewSchema {
  previewOn: boolean
  desktop: boolean
  currentScreen: string
}

const initialState: PreviewSchema = {
  previewOn: false,
  desktop: true,
  currentScreen: ''
}

const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    togglePreview: (state, action: PayloadAction<{ togglePreview: boolean }>) => {
      state.previewOn = action.payload.togglePreview
      // Reset currentScreen if preview is toggled on
      if (action.payload.togglePreview) {
        state.currentScreen = ''
      }
    },
    setMode: (state, action: PayloadAction<{ desktop: boolean }>) => {
      state.desktop = action.payload.desktop
    },
    setCurrentScreen: (state, action: PayloadAction<{ currentScreen: string }>) => {
      state.currentScreen = action.payload.currentScreen
    },
    clearPreview: () => initialState
  }
})

export const { togglePreview, clearPreview, setMode, setCurrentScreen } = previewSlice.actions
export default previewSlice.reducer
