import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SelectChangeEvent, useTheme } from '@mui/material'
import AddElementsMenu from 'Components/Common/Editing/AddElementsMenu'
import { SelectOption } from 'Components/Common/Select'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import NavigationAction, {
  NavigationActionSchema,
  handleNavigationDefaultValues
} from './NavigationAction'
import { useActions, ModuleValues } from '../../Hooks/ActionHooks'
import { ScreenComponent } from 'Features/canvas'

// Different action types
const componentActionTypes = ['NAVIGATION'] as const
type ComponentActionType = typeof componentActionTypes[number]

type ActionSchemas = NavigationActionSchema

export interface ComponentActionSchema extends ActionSchemas {
  id: string
  type: ComponentActionType
}

// Sets options for action-type select
const actionOptions = (): SelectOption[] => {
  return componentActionTypes.map(type => ({
    label: type.charAt(0) + type.substring(1).toLowerCase(),
    value: type
  }))
}

const setInitialActions = (
  component: ScreenComponent,
  moduleValues?: ModuleValues
): ComponentActionSchema[] => {
  if (moduleValues) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return moduleValues.propValues.actions || []
  } else {
    return component.actions || []
  }
}

interface ActionSection {
  component: ScreenComponent
  screenId: string
  selectedComponentId?: string
  moduleValues?: ModuleValues
}

const ActionSection: React.FC<ActionSection> = ({
  component,
  screenId,
  selectedComponentId,
  moduleValues
}) => {
  const theme = useTheme()
  const { updateActions, dispatchActionsToReducer, screens } = useActions()
  const selectedScreen = screens[screenId]

  const [actions, setActions] = useState<ComponentActionSchema[]>(
    setInitialActions(component, moduleValues)
  )

  useEffect(() => {
    dispatchActionsToReducer(actions, component, screenId, selectedComponentId, moduleValues)
  }, [actions])

  const handleAddAction = () => {
    const newAction = {
      id: uuidv4(),
      type: 'NAVIGATION'
    } as ComponentActionSchema
    handleNavigationDefaultValues(newAction, selectedScreen, screens)
    updateActions('add', newAction, setActions)
  }

  const handleRemoveAction = () => {
    // TODO: so far only one action can be added / removed.
    // When multiple actions are supported, provide deletable action as second parameter
    updateActions('remove', {} as ComponentActionSchema, setActions)
  }

  const handleTypeChange = (e: SelectChangeEvent, action: ComponentActionSchema) => {
    const type = e.target.value as ComponentActionType
    const updateAction = { id: action.id, type } as ComponentActionSchema
    switch (type) {
      case 'NAVIGATION':
        handleNavigationDefaultValues(updateAction, selectedScreen, screens)
        break
    }
    updateActions('edit', updateAction, setActions)
  }

  return (
    <>
      <AddElementsMenu
        label="Actions"
        action={
          actions.length > 0
            ? { type: 'remove', onClick: handleRemoveAction }
            : { type: 'add', onClick: handleAddAction }
        }
        backgroundColor={
          component.id !== selectedComponentId ? theme.custom.nightBlue[40] : undefined
        }
      />
      {actions.map(action => (
        <div key={action.id}>
          <SmallSelect
            label="Type"
            value={action.type}
            options={actionOptions()}
            onChange={e => handleTypeChange(e, action)}
            disabled={actions.length < 2}
            doNotFormatValue
          />
          {action.type === 'NAVIGATION' && (
            <NavigationAction
              action={action}
              setActions={setActions}
              selectedScreen={selectedScreen}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default ActionSection
