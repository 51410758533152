import React, { useState, useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import { ReactComponent as LogoWithText } from 'assets/images/logo-with-text.svg'
import { ReactComponent as ArrowDown } from 'assets/share/share-arrow-down.svg'
import { ReactComponent as ArrowUpSmall } from 'assets/share/share-arrow-up-small.svg'
import { ReactComponent as Info } from 'assets/share/share-info.svg'
import { styled } from '@mui/material/styles'
import {
  AppBar,
  Box,
  Fab,
  Toolbar,
  IconButton,
  ButtonGroup,
  Button,
  Typography,
  Slide,
  ClickAwayListener
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { KeyboardArrowDown } from '@mui/icons-material'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import Tooltip from 'Components/Common/Tooltip'
import { ProtoDocumentData } from 'Components/Layout/ProtoView'
import ModeMenu from './ModeMenu'
import { setCurrentScreen, setMode, togglePreview } from 'Features/preview'
import { openPreviewDialog } from 'Features/share'

const ArrowButtonBottom = styled(IconButton)(() => ({
  height: '36px',
  width: '36px',
  borderRadius: 0,
  backgroundColor: '#002C38 !important',
  '&:hover': {
    backgroundColor: '#002C38 !important'
  },
  '&:disabled': {
    backgroundColor: '#002C38 !important'
  }
}))

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: '56px',
  width: '56px',
  borderRadius: 0,
  cursor: 'pointer',
  '& > svg > path': {
    fill: theme.custom.iconColor
  },
  '&.Mui-disabled > svg > path': {
    fill: theme.palette.text.secondary
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& > svg > path': {
      fill: theme.palette.primary.light
    }
  }
}))

const PreviewNavBar: React.FC<{ protoDocumentData?: ProtoDocumentData }> = ({
  protoDocumentData
}) => {
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [modeAnchorEl, setModeAnchorEl] = useState<HTMLButtonElement | null>(null)
  const modeMenuOpen = Boolean(modeAnchorEl)
  const [showNavBar, setShowNavBar] = useState<boolean>(true)
  const selectedDesign = useTypedSelector(state => state.workspaceView.selectedDesign)
  const currentScreen = useTypedSelector(state => state.preview.currentScreen)
  const canvasScreens = useTypedSelector(state => state.undoables.present.canvasScreens)
  const previewDialogOpen = useTypedSelector(state => state.share.previewDialogOpen)
  const [listenerOn, setListenerOn] = useState<boolean>(false)

  // Preview navbar should stay open when preview-dialog is closed.
  // Timeout prevents 'ClickAwayListener' closing the navbar immediately when
  // Preview's Continue-button is clicked
  useEffect(() => {
    if (!previewDialogOpen) {
      setTimeout(() => {
        setListenerOn(true)
      }, 200)
    }
    if (previewDialogOpen) {
      setListenerOn(false)
    }
  }, [previewDialogOpen])

  const options = React.useMemo(
    () => [
      {
        name: 'Desktop',
        logo: (
          <DesktopWindowsIcon
            sx={{ height: '13px', color: theme => theme.palette.text.disabled }}
          />
        ),
        action: () => dispatch(setMode({ desktop: true }))
      },
      {
        name: 'Mobile',
        logo: (
          <SmartphoneIcon sx={{ height: '13px', color: theme => theme.palette.text.disabled }} />
        ),
        action: () => dispatch(setMode({ desktop: false }))
      }
    ],
    []
  )

  const screens = protoDocumentData ? protoDocumentData.screens : canvasScreens.screens
  const sourceId = screens[currentScreen].sourceId
  const connections = screens[currentScreen].connections

  const backArrowDisabled = sourceId === null
  const forwardArrowDisabled = connections.length === 0

  const handlePreviousScreen = () => {
    if (sourceId) {
      dispatch(
        setCurrentScreen({
          currentScreen: sourceId
        })
      )
    }
  }

  const handleNextScreen = () => {
    if (connections.length > 0) {
      dispatch(
        setCurrentScreen({
          currentScreen: connections[0].target
        })
      )
    }
  }

  return (
    <>
      {/* Desktop view */}
      {!isMobile ? (
        <>
          <ClickAwayListener
            onClickAway={() => {
              if (listenerOn) {
                setShowNavBar(false)
              }
            }}
          >
            <div>
              {/* Desktop navbar (on top) */}
              <Box
                sx={{
                  position: 'absolute',
                  height: '56px',
                  zIndex: 99,
                  width: '100%'
                }}
                onMouseEnter={() => setShowNavBar(true)}
              >
                <Slide direction="down" in={showNavBar} mountOnEnter unmountOnExit>
                  <AppBar
                    elevation={0}
                    position="relative"
                    sx={{ backgroundColor: theme => theme.palette.secondary.dark }}
                  >
                    <Toolbar
                      disableGutters
                      sx={{
                        position: 'relative',
                        '@media (min-width: 600px)': {
                          minHeight: theme => theme.props.navBarHeight
                        }
                      }}
                    >
                      {/* Close button / Logo */}
                      <Box>
                        {protoDocumentData ? (
                          <div style={{ marginTop: '6px', marginLeft: '16px' }}>
                            <LogoWithText />
                          </div>
                        ) : (
                          <Tooltip title="Close preview" sx={{ top: '-8px !important' }}>
                            <IconButtonStyled
                              onClick={() => dispatch(togglePreview({ togglePreview: false }))}
                              disableFocusRipple
                              disableTouchRipple
                            >
                              <CloseIcon />
                            </IconButtonStyled>
                          </Tooltip>
                        )}
                      </Box>
                      {/* Design name */}
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)'
                        }}
                      >
                        <Typography variant="subtitle2">
                          {protoDocumentData
                            ? protoDocumentData.design.title
                            : selectedDesign?.title}
                        </Typography>
                      </Box>
                      {/* Desktop / Mobile -menu  */}
                      <Box sx={{ position: 'absolute', right: '16px' }}>
                        <ButtonGroup variant="text" ref={anchorRef}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: 'white',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {options[selectedIndex].name}
                          </Typography>
                          <Button
                            size="small"
                            onClick={e => setModeAnchorEl(e.currentTarget)}
                            sx={{ border: 0, fontSize: 12, color: 'white' }}
                          >
                            <KeyboardArrowDown />
                          </Button>
                        </ButtonGroup>
                        <div onMouseLeave={() => setModeAnchorEl(null)}>
                          <ModeMenu
                            open={modeMenuOpen}
                            anchorEl={modeAnchorEl}
                            options={options}
                            setSelectedIndex={setSelectedIndex}
                            handleClose={() => setModeAnchorEl(null)}
                          />
                        </div>
                      </Box>
                    </Toolbar>
                  </AppBar>
                </Slide>
              </Box>
              {/* Bottom arrows */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                  bottom: '50px',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
              >
                <Slide direction="up" in={showNavBar} mountOnEnter unmountOnExit>
                  <div>
                    <ArrowButtonBottom
                      disabled={backArrowDisabled}
                      onClick={handlePreviousScreen}
                      sx={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                    >
                      <ArrowBackIosNewIcon
                        fontSize="small"
                        sx={{ color: backArrowDisabled ? '#80959B' : '#fff' }}
                      />
                    </ArrowButtonBottom>
                    <ArrowButtonBottom
                      disabled={forwardArrowDisabled}
                      onClick={handleNextScreen}
                      sx={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{ color: forwardArrowDisabled ? '#80959B' : '#fff' }}
                      />
                    </ArrowButtonBottom>
                  </div>
                </Slide>
              </Box>
            </div>
          </ClickAwayListener>
          {/* Bottom fab */}
          <Fab
            onClick={() => dispatch(openPreviewDialog({ value: true }))}
            size="medium"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme => theme.zIndex.speedDial,
              boxShadow: 'none',
              color: 'white',
              backgroundColor: theme => theme.palette.secondary.dark,
              '&:hover': {
                backgroundColor: theme => theme.palette.secondary.dark
              },
              '& .MuiSvgIcon-root': {
                fontSize: '16px'
              }
            }}
          >
            <QuestionMarkIcon />
          </Fab>
        </>
      ) : (
        // Mobile view (on bottom)
        <ClickAwayListener
          onClickAway={() => {
            if (listenerOn) {
              setShowNavBar(false)
            }
          }}
        >
          <Box>
            <Box
              style={{ bottom: 0 }}
              sx={{
                position: 'fixed',
                height: '56px',
                zIndex: 99,
                width: '100%'
              }}
            >
              <Slide direction="up" in={showNavBar} mountOnEnter unmountOnExit>
                <AppBar
                  elevation={0}
                  position="relative"
                  sx={{ backgroundColor: theme => theme.palette.secondary.dark }}
                >
                  <Toolbar
                    disableGutters
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      '@media (min-width: 600px)': {
                        minHeight: theme => theme.props.navBarHeight
                      }
                    }}
                  >
                    {/* Question mark */}
                    <Box
                      style={{ marginLeft: '16px' }}
                      sx={
                        previewDialogOpen
                          ? {
                              '& > svg > path': {
                                fill: '#76D275'
                              },
                              '& > svg > rect': {
                                stroke: '#76D275'
                              }
                            }
                          : {}
                      }
                    >
                      <Info
                        onClick={() => dispatch(openPreviewDialog({ value: true }))}
                        style={{ cursor: 'pointer' }}
                      />
                    </Box>
                    {/* Arrows */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <IconButtonStyled
                        sx={{ width: '45px' }}
                        disabled={backArrowDisabled}
                        onClick={handlePreviousScreen}
                      >
                        <ArrowBackIosNewIcon sx={{ fontSize: '20px' }} />
                      </IconButtonStyled>
                      <IconButtonStyled
                        sx={{ width: '45px' }}
                        disabled={forwardArrowDisabled}
                        onClick={handleNextScreen}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
                      </IconButtonStyled>
                    </Box>
                    {/* Arrow down */}
                    <Box sx={{ marginRight: '16px' }}>
                      <ArrowDown
                        onClick={() => setShowNavBar(false)}
                        style={{ cursor: 'pointer' }}
                      />
                    </Box>
                  </Toolbar>
                </AppBar>
              </Slide>
            </Box>

            {/* Small arrow up */}
            <Box
              sx={{
                position: 'absolute',
                right: '16px',
                bottom: '16px',
                visibility: showNavBar ? 'hidden' : 'visible',
                zIndex: 9999
              }}
            >
              <ArrowUpSmall onClick={() => setShowNavBar(true)} style={{ cursor: 'pointer' }} />
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </>
  )
}

export default PreviewNavBar
