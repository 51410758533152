import { LayoutProps } from '@uniqore/module'
import { IconType } from 'Components/Common/Icons'
import { Timestamp } from 'firebase/firestore'
import { PaletteSchema, TypographySchema } from 'Util/ThemeValues'
import { ScreenId, GridRect, ScreenConnection, ComponentSections } from 'Features/canvas'

export interface WorkspaceSchema {
  id: string
  title: string
  owner: string
  deleted?: Timestamp
}

export interface Document {
  id: string
  title: string
  folder: string
  createdAt?: string // TimeStamp is saved as a string to reducer to prevent setting non-serializable values in redux-state
  updatedAt?: string // TimeStamp is saved as a string to reducer to prevent setting non-serializable values in redux-state
}

export interface ThemeOverride {
  palette?: PaletteSchema
  typography?: TypographySchema
}

export interface DesignSchema extends Document {
  icon?: IconType
  deleted?: boolean
  thumbnailColor?: string
  themeId?: string
  themeOverride?: ThemeOverride
  modifiedBy?: string // id of the org user
  layoutProps?: LayoutProps
}

export interface ThemeSchema extends Document {
  palette: PaletteSchema
  typography: TypographySchema
  defaultTheme?: boolean
}

/**
 * A screen represents a single page in the document/app.
 * Named CanvasScreen since Screen is reserved
 * https://developer.mozilla.org/en-US/docs/Web/API/Screen
 */
export interface CanvasScreen {
  // The screen's unique identifier
  id: ScreenId
  // Position and size on grid (in grid units)
  geometry: GridRect
  // The screen that is connected to this
  sourceId: ScreenId | null
  // Connections to other screens
  connections: ScreenConnection[]
  // The screen's label (shown in Design Studio view)
  label: string
  // Screen's components
  components: ComponentSections
  // First screen to view on preview, there should only be only one screen that has this value as true
  flowStartPoint?: boolean
  // Props for the Layout
  layoutProps?: LayoutProps
  // If true, uses global layout props (from DesignSchema)
  useLocalLayoutProps?: boolean
  // Current version number of the screen
  version?: number
}

export const defaultLayoutPropValues = {
  containerBackground: '#B3C0C3',
  borderColor: '#000000',
  borderWidth: '0px',
  borderRadius: '20px',
  useElevation: true
}
