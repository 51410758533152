/**
 * Gets the first positive, nonzero integer not included in {@link series}.
 * @param series Array of entries to test against
 * @param maxTries Highest number to try.
 * @returns Positive, nonzero integer not included in {@link series} or null if {@link maxTries} is
 *   encountered first.
 */
export const findFirstFreeInteger = (series: string[], maxTries = 100): string | null => {
  for (let i = 1; i < maxTries; i++) {
    if (!series.includes(i.toString())) return i.toString()
  }
  return null
}

export const firebaseDebounceTimer = 15000
