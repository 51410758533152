import React from 'react'
import { FlowNav, FlowNavProps, FlowNavButtonProps } from '@uniqore/module'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'

interface FlowNavRenderProps {
  component: ScreenComponent
}

const FlowNavRender: React.FC<FlowNavRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const props = component.props as FlowNavProps

  return (
    <FlowNav
      actionComponents={props.actionComponents?.map(v => {
        const actionProps = v as FlowNavButtonProps & { actions?: ComponentActionSchema[] }
        const color = actionProps.color as FlowNavButtonProps['color'] & 'default'
        return {
          ...actionProps,
          color: color !== 'default' ? color : undefined,
          onClick: () => onClickAction(actionProps.actions)
        }
      })}
      actionDirection={props.actionDirection}
      buttonsColor={props.buttonsColor}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    />
  )
}

export default FlowNavRender
