import React from 'react'
import { UqListItemAvatar } from '@uniqore/wrapper'
import { UqAvatarPropsCustom } from 'Util/CanvasComponents'
import UqAvatarRender from './UqAvatarRender'

interface UqListItemAvatarRenderProps {
  componentProps: UqAvatarPropsCustom
}

const UqListItemAvatarRender: React.FC<UqListItemAvatarRenderProps> = ({ componentProps }) => {
  return (
    <UqListItemAvatar>
      <UqAvatarRender componentProps={componentProps} />
    </UqListItemAvatar>
  )
}

export default UqListItemAvatarRender
