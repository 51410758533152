import React from 'react'
import { Typography } from '@mui/material'

interface TypographyButtonProps {
  text?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  color?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const TypographyButton: React.FC<TypographyButtonProps> = ({
  text,
  onClick,
  color,
  disabled,
  style
}) => {
  return (
    <Typography
      onClick={disabled ? undefined : onClick}
      variant="button"
      component="div"
      style={style}
      sx={{
        color: disabled ? theme => theme.palette.text.disabled : color,
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': {
          textDecoration: disabled ? 'none' : 'underline'
        }
      }}
    >
      {text}
    </Typography>
  )
}

export default TypographyButton
