import React from 'react'
import { Box, Select, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import { SelectOption } from 'Components/Common/Select'

export interface SmallSelectProps {
  value?: string
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
  options?: SelectOption[]
  label?: string
  disabled?: boolean
  disablePadding?: boolean
  size?: 'small' | 'medium'
  style?: React.CSSProperties
  doNotFormatValue?: boolean
}

const SmallSelect: React.FC<SmallSelectProps> = ({
  value = '',
  onChange,
  options = [],
  label,
  disabled,
  disablePadding,
  size = 'small',
  style,
  doNotFormatValue = false
}) => {
  return (
    <Box style={style} sx={disablePadding ? {} : { padding: '4px 16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {label && (
          <Typography variant="body2" sx={{ color: theme => theme.palette.text.primary }}>
            {label}
          </Typography>
        )}
        <Select
          value={value}
          onChange={onChange}
          disabled={disabled}
          size="small"
          displayEmpty
          renderValue={
            !doNotFormatValue
              ? value => {
                  return (
                    <Typography
                      variant="body2"
                      sx={{
                        color: value
                          ? theme => theme.palette.text.primary
                          : theme => theme.palette.text.secondary
                      }}
                    >
                      {value
                        ? value.toString().charAt(0).toUpperCase() + value.toString().slice(1)
                        : 'None'}
                    </Typography>
                  )
                }
              : undefined
          }
          sx={{
            height: size === 'medium' ? '40px !important' : '36px !important',
            '& .MuiSelect-select': {
              paddingRight: '48px !important',
              minHeight: '0px !important',
              backgroundColor: 'white'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: theme => `1px solid ${theme.custom.borderColor} !important`
            }
          }}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value} disabled={option.disabled} sx={{ minHeight: 0 }}>
              <Typography variant="body2" sx={{ color: theme => theme.palette.text.primary }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

export default SmallSelect
