import React from 'react'
import * as UqWrappers from '@uniqore/wrapper'
import * as UqModules from '@uniqore/module'
import { IconType } from 'Components/Common/Icons'
import { ScreenComponent } from 'Features/canvas'

//export type ComponentsProps = UqWrappers.UqButtonProps
// | UqWrappers.UqAppBarProps
// | UqWrappers.UqAlertProps
// | UqWrappers.UqDialogProps
// | UqWrappers.UqChipProps
// | UqWrappers.UqDividerProps
// | UqWrappers.UqListProps
// | UqWrappers.UqRatingProps
// | UqWrappers.UqSelectProps
// | UqWrappers.UqSnackbarProps
// | UqWrappers.UqSwitchProps
// | UqWrappers.UqCheckboxProps
// | UqWrappers.UqBottomNavigationProps
// | UqWrappers.UqFabProps
// | UqWrappers.UqSpeedDialProps
// | UqWrappers.UqTextFieldProps

export interface CanvasComponentsSchema {
  uqButton: {
    component: React.FC
    props: UqWrappers.UqButtonProps
  }
  uqAppBar: {
    component: React.FC
    props: UqAppBarPropsCustom
  }
  uqAlert: {
    component: React.FC
    props: UqWrappers.UqAlertProps & UqAlertPropsExtended
  }
  uqDialog: {
    component: React.FC<UqWrappers.UqDialogProps>
    props: UqWrappers.UqDialogProps & UqDialogPropsExtended
  }
  uqChip: {
    component: React.FC
    props: UqChipPropsCustom
  }
  uqDivider: {
    component: React.FC
    props: UqWrappers.UqDividerProps
  }
  uqRating: {
    component: React.FC
    props: UqWrappers.UqRatingProps & UqRatingPropsExtended
  }
  uqSelect: {
    component: React.FC
    props: UqWrappers.UqSelectProps & UqSelectPropsExtended
  }
  uqSnackbar: {
    component: React.FC
    props: UqWrappers.UqSnackbarProps & UqSnackbarPropsExtended
  }
  uqSwitch: {
    component: React.FC
    props: UqWrappers.UqSwitchProps & UqSwitchPropsExtended
  }
  uqCheckbox: {
    component: React.FC
    props: UqWrappers.UqCheckboxProps & UqCheckboxPropsExtended
  }
  uqRadio: {
    component: React.FC
    props: UqWrappers.UqCheckboxProps & UqRadioPropsExtended
  }
  uqBottomNavigation: {
    component: React.FC
    props: UqWrappers.UqBottomNavigationProps & UqBottomNavigationPropsExtended
  }
  uqSpeedDial: {
    component: React.FC<UqWrappers.UqSpeedDialProps>
    props: UqWrappers.UqSpeedDialProps & UqSpeedDialPropsExtended
  }
  uqTextField: {
    component: React.FC
    props: UqTextFieldPropsCustom
  }
  uqFab: {
    component: React.FC
    props: UqWrappers.UqFabProps & UqFabPropsExtended
  }
  uqList: {
    component: React.FC
    props: UqWrappers.UqListProps & UqListPropsExtended
  }
  uqTypography: {
    component: React.FC
    props: UqWrappers.UqTypographyProps & UqTypographyPropsExtended
  }
  uqDatePicker: {
    component: React.FC<UqWrappers.UqDatePickerProps>
    props: UqDatePickerPropsCustom
  }
  radioButtonGroup: {
    component: React.FC
    props: UqModules.RadioButtonGroupProps
  }
  selectButtonGroup: {
    component: React.FC
    props: UqModules.SelectButtonGroupProps
  }
  login: {
    component: React.FC
    props: LoginPropsCustom
  }
  image: {
    component: React.FC
    props: UqModules.ImageProps
  }
  flowNav: {
    component: React.FC
    props: UqModules.FlowNavProps
  }
  onboarding: {
    component: React.FC
    props: OnboardingPropsCustom
  }
  // These components below are not shown in AddSection and cannot be dragged to canvas.
  // However they are used inside other components in component render (SubComponents)
  uqInputAdornment: {
    component: React.FC<UqWrappers.UqInputAdornmentProps>
    props: UqInputAdornmentPropsCustom
  }
  uqBottomNavigationAction: {
    component: React.FC
    props: UqWrappers.UqBottomNavigationActionProps
  }
  uqSpeedDialAction: {
    component: React.FC
    props: UqWrappers.UqSpeedDialActionProps
  }
  uqAvatar: {
    component: React.FC
    props: UqAvatarPropsCustom
  }
  uqIconButton: {
    component: React.FC
    props: UqWrappers.UqIconButtonProps & UqIconButtonPropsExtended
  }
  uqLinearProgress: {
    component: React.FC
    props: UqWrappers.UqLinearProgressProps
  }
  uqListItem: {
    component: React.FC
    props: UqWrappers.UqListItemProps & UqListItemPropsExtended
  }
  uqListItemIcon: {
    component: React.FC
    props: UqWrappers.UqListItemIconProps & UqListItemIconPropsExtended
  }
  uqListItemAvatar: {
    component: React.FC
    props: UqAvatarPropsCustom
  }
  uqListItemSecondaryAction: {
    component: React.FC
    props: UqListItemSecondaryActionPropsCustom
  }
  add: {
    component: React.FC
    props: AddPropsCustom
  }
}

// Extend existing props to make prop changing simpler
// NOTE: icons are typed: "IconType | ''".
// Using empty string instead of 'undefined' prevents the following react warning:
// (A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen)
export type UqCheckboxPropsExtended = { options: string }
export type UqRadioPropsExtended = { options: string }
export type UqSwitchPropsExtended = { label: string }
export type UqSelectPropsExtended = { helperText: string; options: string; icon: IconType | '' }
export type UqRatingPropsExtended = { align?: 'center' | 'left' | 'right' }
export type UqAlertPropsExtended = {
  body: string
}
export type UqDialogPropsExtended = {
  title: string
  body: string
  buttons?: { [componentId: string]: ScreenComponent }
}
export type UqSnackbarPropsExtended = {
  originHorizontal: 'center' | 'left' | 'right'
  originVertical: 'bottom' | 'top'
  title: string
  body: string
  icon: IconType | ''
  color: 'error' | 'info' | 'success' | 'warning'
}
export interface UqAppBarPropsCustom extends Omit<UqWrappers.UqAppBarProps, 'prefix'> {
  title: string
  titleVariant: UqWrappers.UqTypographyProps['variant']
  prefix?: { [componentId: string]: ScreenComponent }
  toolbar?: { [componentId: string]: ScreenComponent }
  progress?: { [componentId: string]: ScreenComponent }
  iconColor?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
}
export type UqBottomNavigationPropsExtended = {
  tabs?: { [componentId: string]: ScreenComponent }
}
export type UqSpeedDialPropsExtended = {
  options?: { [componentId: string]: ScreenComponent }
}
export type UqFabPropsExtended = {
  label?: string
  icon?: IconType | ''
}
export type UqIconButtonPropsExtended = {
  icon?: IconType | ''
}
export type UqListPropsExtended = {
  childrenComponents?: string[]
}
export type UqListItemPropsExtended = {
  listItemAvatars?: { [componentId: string]: ScreenComponent }
  listItemIcons?: { [componentId: string]: ScreenComponent }
  listItemTexts?: { [componentId: string]: ScreenComponent }
  listItemSecondaryActions?: { [componentId: string]: ScreenComponent }
  add?: { [componentId: string]: ScreenComponent }
  primary?: string
  secondary?: string
}
export type UqListItemIconPropsExtended = {
  icon?: IconType | ''
}
export type UqTypographyPropsExtended = {
  color?: string
}
// Extend props end

// Create custom prop types to make prop changing simpler
export interface UqDatePickerPropsCustom
  extends Omit<UqWrappers.UqDatePickerProps, 'value' | 'onChange' | 'renderInput'> {
  variation?: 'inline' | 'textfield'
  localization?: 'fi' | 'en-uk' | 'en-us'
}
export interface UqTextFieldPropsCustom extends Omit<UqWrappers.UqTextFieldProps, 'prefix'> {
  prefix?: { [componentId: string]: ScreenComponent }
  suffix?: { [componentId: string]: ScreenComponent }
}
export interface UqChipPropsCustom extends Omit<UqWrappers.UqChipProps, 'icon' | 'avatar'> {
  removable: boolean
  icon: IconType | ''
  avatar?: { [componentId: string]: ScreenComponent }
}
export interface UqInputAdornmentPropsCustom
  extends Omit<UqWrappers.UqInputAdornmentProps, 'position'> {
  type: 'icon' | 'text'
  icon: IconType | ''
  text: string
}
export interface UqAvatarPropsCustom extends UqWrappers.UqAvatarProps {
  type?: 'image' | 'text'
  image: string
  text: string
}
export interface UqListItemSecondaryActionPropsCustom
  extends UqWrappers.UqListItemSecondaryActionProps {
  icon?: IconType | ''
  component?: keyof CanvasComponentsSchema | 'none'
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  edge?: 'start' | 'end'
}
export interface LoginPropsCustom extends UqModules.LoginProps {
  headingComponents?: { [componentId: string]: ScreenComponent }
  contentComponents?: { [componentId: string]: ScreenComponent }
  actionComponents?: { [componentId: string]: ScreenComponent }
  additionalComponents?: { [componentId: string]: ScreenComponent }
}
export interface OnboardingCarouselItemsCustom extends UqModules.CarouselItemProps {
  imageSource?: string
  imageWidth?: number
}
export interface OnboardingPropsCustom extends UqModules.OnboardingProps {
  carouselItems: OnboardingCarouselItemsCustom[]
}
export interface AddPropsCustom extends Omit<UqModules.AddProps, 'icon' | 'checkedIcon'> {
  icon: IconType | null
  checkedIcon: IconType | null
}
// Custom prop types end

export const canvasComponents: CanvasComponentsSchema = {
  uqButton: {
    component: UqWrappers.UqButton,
    props: {
      children: 'Button',
      startIcon: '',
      endIcon: '',
      variant: 'contained',
      size: 'large',
      color: 'primary',
      fullWidth: true,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqCheckbox: {
    component: UqWrappers.UqCheckbox,
    props: {
      options: 'Checkbox 1',
      size: 'medium',
      color: 'primary',
      checked: false,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqRadio: {
    component: UqWrappers.UqRadio,
    props: {
      options: 'Radio 1\nRadio 2',
      size: 'medium',
      color: 'primary',
      checked: false,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqRating: {
    component: UqWrappers.UqRating,
    props: {
      icon: 'Star',
      size: 'large',
      value: 3,
      max: 5,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0,
      align: 'center'
    }
  },
  uqSelect: {
    component: UqWrappers.UqSelect,
    props: {
      label: 'Select',
      helperText: '',
      options: '',
      icon: '',
      variant: 'outlined',
      fullWidth: true,
      multiple: false,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqSwitch: {
    component: UqWrappers.UqSwitch,
    props: {
      label: 'Switch',
      size: 'medium',
      color: 'primary',
      checked: true,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqTextField: {
    component: UqWrappers.UqTextField,
    props: {
      label: 'Text field',
      value: '',
      placeholder: '',
      helperText: '',
      variant: 'outlined',
      size: 'medium',
      color: 'primary',
      rows: 0,
      fullWidth: true,
      error: false,
      disabled: false,
      required: false,
      marginHorizontal: 0,
      marginVertical: 8
    }
  },
  uqChip: {
    component: UqWrappers.UqChip,
    props: {
      label: 'Chip',
      icon: '',
      variant: 'filled',
      size: 'medium',
      color: 'secondary',
      clickable: false,
      removable: false,
      disabled: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqDivider: {
    component: UqWrappers.UqDivider,
    props: {
      variant: 'fullWidth',
      light: false,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqAlert: {
    component: UqWrappers.UqAlert,
    props: {
      title: '',
      body: 'Alert body',
      icon: '',
      color: 'success',
      variant: 'standard',
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqDialog: {
    component: UqWrappers.UqDialog,
    props: {
      open: true,
      title: 'Title',
      body: 'Title body',
      fullWidth: true,
      buttons: {
        1: {
          id: 'default1',
          componentName: 'uqButton',
          props: {
            variant: 'text',
            children: 'Cancel',
            startIcon: '',
            endIcon: ''
          }
        },
        2: {
          id: 'default2',
          componentName: 'uqButton',
          props: {
            variant: 'text',
            children: 'Confirm',
            startIcon: '',
            endIcon: ''
          }
        }
      }
    }
  },
  uqSnackbar: {
    component: UqWrappers.UqSnackbar,
    props: {
      title: '',
      body: 'Snackbar body',
      icon: '',
      color: 'success',
      originHorizontal: 'left',
      originVertical: 'bottom'
    }
  },
  uqAppBar: {
    component: UqWrappers.UqAppBar,
    props: {
      title: 'Back',
      color: 'transparent',
      iconColor: 'default',
      titleVariant: 'subtitle1',
      prefix: {
        1: {
          id: 'prefix1',
          componentName: 'uqIconButton',
          props: {
            icon: 'ArrowBackIosNewRounded',
            color: 'default',
            children: '',
            size: 'medium'
          }
        }
      }
    }
  },
  uqBottomNavigation: {
    component: UqWrappers.UqBottomNavigation,
    props: {
      showLabels: true,
      marginVertical: 0,
      marginHorizontal: 0,
      tabs: {
        1: {
          id: 'tab1',
          componentName: 'uqBottomNavigationAction',
          props: {
            label: 'First',
            icon: 'NotificationsNoneOutlined'
          }
        },
        2: {
          id: 'tab2',
          componentName: 'uqBottomNavigationAction',
          props: {
            label: 'Second',
            icon: 'NotificationsNoneOutlined'
          }
        },
        3: {
          id: 'tab3',
          componentName: 'uqBottomNavigationAction',
          props: {
            label: 'Third',
            icon: 'NotificationsNoneOutlined'
          }
        }
      }
    }
  },
  uqSpeedDial: {
    component: UqWrappers.UqSpeedDial,
    props: {
      ariaLabel: 'label', // is required
      icon: 'Add',
      marginVertical: 0,
      marginHorizontal: 0,
      options: {
        1: {
          id: 'option1',
          componentName: 'uqSpeedDialAction',
          props: {
            tooltipTitle: 'Help',
            icon: 'HelpOutlineOutlined'
          }
        }
      }
    }
  },
  uqFab: {
    component: UqWrappers.UqFab,
    props: {
      label: 'Add',
      icon: 'Add',
      variant: 'extended',
      color: 'primary',
      size: 'medium',
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqList: {
    component: UqWrappers.UqList,
    props: {
      dense: false,
      disablePadding: false,
      marginVertical: 0,
      marginHorizontal: 0,
      childrenComponents: []
    }
  },
  uqTypography: {
    component: UqWrappers.UqTypography,
    props: {
      children: 'Text',
      variant: 'h6',
      align: 'center',
      gutterBottom: false,
      color: 'primary',
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  uqDatePicker: {
    component: UqWrappers.UqDatePicker,
    props: {
      label: 'Pick a date',
      disablePast: true,
      disabled: false,
      variation: 'inline',
      localization: 'en-uk',
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  radioButtonGroup: {
    component: UqModules.RadioButtonGroup,
    props: {
      marginVertical: 4,
      marginHorizontal: 0,
      radioButtons: [
        {
          text: 'Radio button 1',
          value: 'radio1',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.RadioButtonGroupSchema['color']
        },
        {
          text: 'Radio button 2',
          value: 'radio2',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.RadioButtonGroupSchema['color']
        },
        {
          text: 'Radio button 3',
          value: 'radio3',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.RadioButtonGroupSchema['color']
        }
      ],
      buttonsColor: 'secondary',
      variant: 'outlined'
    }
  },
  selectButtonGroup: {
    component: UqModules.SelectButtonGroup,
    props: {
      marginVertical: 4,
      marginHorizontal: 0,
      selectButtons: [
        {
          text: 'Select button 1',
          value: 'select1',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.SelectButtonGroupSchema['color']
        },
        {
          text: 'Select button 2',
          value: 'select2',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.SelectButtonGroupSchema['color']
        },
        {
          text: 'Select button 3',
          value: 'select3',
          startIcon: '',
          metaText: '',
          color: 'default' as UqModules.SelectButtonGroupSchema['color']
        }
      ],
      buttonsColor: 'secondary',
      variant: 'outlined'
    }
  },
  login: {
    component: UqModules.Login,
    props: {
      marginVertical: 0,
      marginHorizontal: 0,
      headingComponents: {
        1: {
          id: 'typography1',
          componentName: 'uqTypography',
          props: {
            children: 'Login',
            variant: 'h5',
            color: 'primary',
            align: 'center',
            gutterBottom: false
          }
        },
        2: {
          id: 'typography2',
          componentName: 'uqTypography',
          props: {
            children: 'Add email and password',
            variant: 'subtitle1',
            color: 'primary',
            align: 'center',
            gutterBottom: true
          }
        }
      },
      contentComponents: {
        1: {
          id: 'textfield1',
          componentName: 'uqTextField',
          props: {
            label: 'Email',
            value: '',
            placeholder: 'Your email',
            helperText: '',
            variant: 'outlined',
            size: 'medium',
            color: 'primary',
            rows: 0,
            fullWidth: true,
            error: false,
            disabled: false,
            required: false,
            prefix: {
              1: {
                id: 'prefix1',
                componentName: 'uqInputAdornment',
                props: {
                  type: 'icon',
                  icon: 'EmailOutlined',
                  text: ''
                }
              }
            },
            suffix: {}
          }
        },
        2: {
          id: 'textfield2',
          componentName: 'uqTextField',
          props: {
            label: 'Password',
            value: '',
            placeholder: 'Your password',
            helperText: '',
            variant: 'outlined',
            size: 'medium',
            color: 'primary',
            rows: 0,
            fullWidth: true,
            error: false,
            disabled: false,
            required: false,
            prefix: {
              1: {
                id: 'prefix1',
                componentName: 'uqInputAdornment',
                props: {
                  type: 'icon',
                  icon: 'LockOutlined',
                  text: ''
                }
              }
            },
            suffix: {
              1: {
                id: 'suffix1',
                componentName: 'uqInputAdornment',
                props: {
                  type: 'icon',
                  icon: 'LockOutlined',
                  text: 'Visibility'
                }
              }
            }
          }
        }
      },
      actionComponents: {
        1: {
          id: 'button1',
          componentName: 'uqButton',
          props: {
            children: 'Sign in',
            startIcon: '',
            endIcon: '',
            variant: 'contained',
            size: 'large',
            color: 'primary',
            fullWidth: true,
            disabled: false
          }
        }
      },
      additionalComponents: {}
    }
  },
  image: {
    component: UqModules.Image,
    props: {
      src: '',
      width: 100,
      center: true,
      marginVertical: 0,
      marginHorizontal: 0
    }
  },
  flowNav: {
    component: UqModules.FlowNav,
    props: {
      marginVertical: 0,
      marginHorizontal: 0,
      actionComponents: [
        {
          text: 'Back',
          variant: 'text',
          size: 'large',
          color: 'default',
          fullWidth: true,
          disabled: false,
          startIcon: '',
          endIcon: ''
        },
        {
          text: 'Next',
          variant: 'contained',
          size: 'large',
          color: 'default',
          fullWidth: true,
          disabled: false,
          startIcon: '',
          endIcon: ''
        }
      ],
      buttonsColor: 'primary',
      actionDirection: 'row'
    }
  },
  onboarding: {
    component: UqModules.Onboarding,
    props: {
      marginVertical: 0,
      marginHorizontal: 0,
      actionComponents: [
        {
          text: 'Get started',
          variant: 'contained',
          size: 'large',
          color: 'default',
          fullWidth: true,
          disabled: false,
          startIcon: '',
          endIcon: ''
        }
      ],
      carouselItems: [
        {
          headline: 'Title',
          body: 'Body',
          imageSource: '',
          imageWidth: 100
        }
      ],
      buttonsColor: 'primary',
      actionDirection: 'column'
    }
  },
  // These components below are not shown in AddSection and cannot be dragged to canvas.
  // However they are used inside other components in component render (SubComponents)
  uqInputAdornment: {
    component: UqWrappers.UqInputAdornment,
    props: {
      type: 'icon',
      icon: 'NotificationsNoneOutlined',
      text: 'Meta'
    }
  },
  uqBottomNavigationAction: {
    component: UqWrappers.UqBottomNavigationAction,
    props: {
      label: 'Label',
      icon: 'NotificationsNoneOutlined'
    }
  },
  uqSpeedDialAction: {
    component: UqWrappers.UqSpeedDialAction,
    props: {
      icon: 'HelpOutlineOutlined',
      tooltipTitle: 'Help'
    }
  },
  uqAvatar: {
    component: UqWrappers.UqAvatar,
    props: {
      type: 'image',
      image: '',
      text: '',
      variant: 'circular'
    }
  },
  uqIconButton: {
    component: UqWrappers.UqIconButton,
    props: {
      icon: 'Menu',
      color: 'default',
      children: '',
      size: 'medium'
    }
  },
  uqLinearProgress: {
    component: UqWrappers.UqLinearProgress,
    props: {
      value: 50,
      variant: 'determinate',
      color: 'primary'
    }
  },
  uqListItem: {
    component: UqWrappers.UqListItem,
    props: {
      alignItems: 'center',
      dense: false,
      disableGutters: false,
      disablePadding: false,
      divider: false,
      primary: 'List item',
      secondary: 'Secondary text'
      // listItemIcons: {
      //   1: {
      //     id: 'icon1',
      //     componentName: 'uqListItemIcon',
      //     props: {
      //       icon: 'NotificationsNoneOutlined'
      //     }
      //   }
      // }
    }
  },
  uqListItemIcon: {
    component: UqWrappers.UqListItemIcon,
    props: {
      icon: 'NotificationsNoneOutlined'
    }
  },
  uqListItemAvatar: {
    component: UqWrappers.UqListItemAvatar,
    props: {
      type: 'image',
      text: '',
      image: '',
      variant: 'circular'
    }
  },
  uqListItemSecondaryAction: {
    component: UqWrappers.UqListItemSecondaryAction,
    props: {
      icon: 'MessageRounded',
      component: 'none',
      color: 'primary',
      edge: 'end'
    }
  },
  add: {
    component: UqModules.Add,
    props: {
      variant: 'single',
      color: 'primary',
      edge: 'end',
      icon: null, // uses default icon
      checkedIcon: null, // uses default checkecIcon
      quantity: 0,
      unit: '',
      checked: false
    }
  }
}

export type CanvasComponentName = keyof typeof canvasComponents
// screen is a reserved word
export type CanvasComponentTp = 'screen' | keyof typeof canvasComponents
