import React from 'react'
import DesignsSubscription from './DesignsSubscription'
import ThemesSubscription from './ThemesSubscription'
import { useTypedSelector } from 'Store'

interface SubscribeProps {
  documentType: 'designs' | 'themes'
  designIds?: string[]
  themeIds?: string[]
}

const Subscribe: React.FC<SubscribeProps> = ({ documentType, designIds, themeIds }) => {
  const workspace = useTypedSelector(state => state.workspaceView.workspace)
  if (!workspace) return <></>

  return (
    <>
      {documentType === 'designs' &&
        designIds &&
        designIds.map((designId, i) => (
          <DesignsSubscription key={i} designId={designId} workspaceId={workspace.id} />
        ))}
      {documentType === 'themes' &&
        themeIds &&
        themeIds.map((themeId, i) => (
          <ThemesSubscription key={i} themeId={themeId} workspaceId={workspace.id} />
        ))}
    </>
  )
}

export default React.memo(Subscribe)
