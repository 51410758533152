import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import {
  SelectChangeEvent,
  Box,
  Button,
  Card,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import Select, { SelectOption } from 'Components/Common/Select'
import { WorkspaceSchema, DesignSchema, ThemeSchema } from 'types/firebase'
import { setSelectedDesign } from 'Features/workspaceView'
import { editHelpers } from 'Features/helpers'
import { ButtonStyled } from 'Components/Layout/WorkspaceView'

const getThemeOptions = (
  themes: ThemeSchema[],
  defaultTheme: ThemeSchema | undefined
): SelectOption[] => {
  const themeOptions: SelectOption[] = []
  if (defaultTheme) {
    themeOptions.push({
      label: defaultTheme.title,
      value: defaultTheme.id
    })
  }
  themes.forEach(theme => {
    themeOptions.push({
      label: theme.title,
      value: theme.id
    })
  })
  return themeOptions
}

interface ThemeProps {
  workspace: WorkspaceSchema
  selectedDesign: DesignSchema
  themes: ThemeSchema[]
}

const Theme: React.FC<ThemeProps> = ({ workspace, selectedDesign, themes }) => {
  const dispatch = useDispatch()
  const { updateDesign } = useDesigns(workspace.id)
  const { defaultTheme } = useTypedSelector(state => state.firebaseDocuments)
  const [changeThemeDialogOpen, setChangeThemeDialogOpen] = useState<boolean>(false)
  const [selectValue, setSelectValue] = useState<string>()

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    if (selectedDesign.themeOverride && Object.keys(selectedDesign.themeOverride).length !== 0) {
      setChangeThemeDialogOpen(true)
      setSelectValue(event.target.value)
    } else {
      handleDesignUpdate(event.target.value)
    }
  }

  const handleDesignUpdate = (value?: string) => {
    updateDesign(selectedDesign as DesignSchema, {
      themeId: value || selectValue,
      themeOverride: null
    })
    setChangeThemeDialogOpen(false)
    setSelectValue(undefined)
  }

  return (
    <>
      <Select
        label="Theme"
        value={selectedDesign?.themeId}
        onChange={handleSelectChange}
        options={getThemeOptions(themes, defaultTheme)}
      />
      <div>
        <Button
          onClick={() => {
            dispatch(editHelpers({ key: 'openNewThemeDialog', value: true }))
            dispatch(setSelectedDesign({ design: undefined }))
          }}
          color="primary"
          variant="text"
          sx={{ marginLeft: '16px', borderRadius: '100px' }}
        >
          + Create new theme
        </Button>
      </div>
      <Card
        elevation={0}
        sx={{
          borderRadius: '4px',
          margin: '16px',
          padding: '16px',
          backgroundColor: theme => theme.custom.appleGreen[100]
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <InfoIcon
            sx={{
              marginTop: '-2px',
              marginRight: '8px',
              color: theme => theme.palette.info.dark
            }}
          />
          <Typography
            variant="body2"
            sx={{ flexGrow: 1, color: theme => theme.palette.text.primary }}
          >
            All the changes made in the
            <br /> project are managed as local
            <br /> changes with no impact on the
            <br /> original theme.
          </Typography>
        </Box>
      </Card>
      {/* Change theme dialog */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={changeThemeDialogOpen}
        onClose={() => setChangeThemeDialogOpen(false)}
        PaperProps={{
          sx: { borderRadius: '8px' }
        }}
      >
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
            <InfoIcon sx={{ color: theme => theme.palette.error.main, marginRight: '16px' }} />
            <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
              Are you sure?
            </Typography>
          </Box>
          <DialogContent>
            <DialogContentText variant="body1" color="text.secondary">
              All the local theme settings will be lost and changed to new theme settings. This
              can&apos;t be undone and will affect immediately.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              position: 'relative',
              padding: '0px 24px 24px 0px'
            }}
          >
            <ButtonStyled
              onClick={() => setChangeThemeDialogOpen(false)}
              size="large"
              variant="text"
              color="secondary"
            >
              Cancel
            </ButtonStyled>
            <ButtonStyled
              onClick={() => handleDesignUpdate()}
              disableElevation
              color="secondary"
              variant="contained"
            >
              Change theme
            </ButtonStyled>
          </DialogActions>
        </>
      </Dialog>
    </>
  )
}

export default Theme
