import React from 'react'
import equal from 'fast-deep-equal'
import TextField from 'Components/Common/TextField'
import SectionArea from '../SectionArea'
import { ButtonStyled } from '../ThemeCreation'
import { ThemeSchema } from 'types/firebase'

interface TitleSectionProps {
  selectedTheme: ThemeSchema
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const TitleSection: React.FC<TitleSectionProps> = ({ selectedTheme, title, setTitle }) => {
  const handleResetTitle = () => {
    setTitle(selectedTheme.title)
  }

  return (
    <SectionArea
      title="Theme title"
      components={[
        <ButtonStyled
          key={1}
          variant="text"
          color="error"
          size="small"
          disableFocusRipple
          disabled={equal(title, selectedTheme.title)}
          onClick={handleResetTitle}
        >
          Discard changes
        </ButtonStyled>
      ]}
    >
      <div>
        <TextField
          value={title}
          onChange={e => setTitle(e.target.value)}
          label="Theme title"
          disablePadding
        />
      </div>
    </SectionArea>
  )
}

export default React.memo(TitleSection)
