import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { styled, useTheme } from '@mui/material/styles'
import {
  Card,
  CardMedia,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Menu,
  MenuList,
  MenuItem
} from '@mui/material'
import Icons, { IconType } from 'Components/Common/Icons'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg'
import { ReactComponent as PaletteIcon } from 'assets/icons/palette.svg'
import { useDesigns, useScreens } from 'Components/Firebase/Hooks/FirebaseHooks'
import { DesignSchema, Document, ThemeSchema, WorkspaceSchema } from 'types/firebase'
import { editSnackbar } from 'Features/snackbar'

export const handleUpdatedAtDate = (value: string | undefined) => {
  let text = 'Edited '
  if (value) {
    text += moment(value, 'YYYY-MM-DDThh:mm:ss').fromNow()
  }
  return text
}

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: '20px',
  '&:hover': {
    backgroundColor: theme.custom.desertNight
  }
}))

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  mixWidth: '26px',
  '& > svg': {
    width: 14,
    height: 14
  },
  '& > svg > path': {
    fill: theme.palette.text.disabled
  }
}))

interface DesignCardProps {
  workspace: WorkspaceSchema
  themes: ThemeSchema[]
  design: DesignSchema
  setDocumentForDialogs?: Dispatch<
    SetStateAction<{ document: Document; type: 'design' | 'theme' } | undefined>
  >
  cardSize?: 'large' | 'small'
  setRemoveDesignDialogOpen?: Dispatch<SetStateAction<boolean>>
  setChangeFolderDialogOpen?: Dispatch<SetStateAction<boolean>>
  setThumbnailDialogOpen?: Dispatch<SetStateAction<boolean>>
}

const DesignCard: React.FC<DesignCardProps> = ({
  workspace,
  themes,
  design,
  setDocumentForDialogs,
  cardSize = 'large',
  setRemoveDesignDialogOpen,
  setChangeFolderDialogOpen,
  setThumbnailDialogOpen
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { getScreensForDuplicatedDesign, saveScreens } = useScreens(workspace.id)
  const { createDesign, openDesign, updateDesign, createDesignData, deleteDesign } = useDesigns(
    workspace.id
  )
  const designs = useTypedSelector(state => state.firebaseDocuments.designs)
  const [createdDesignId, setCreatedDesignId] = useState<string>()
  const [isHover, setIsHover] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // When the design is duplicated, store its id to 'createdDesignId'-state.
  // This id is then used in useEffect below.
  useEffect(() => {
    if (createDesignData) {
      setCreatedDesignId(createDesignData.createDesignStudioDocument.id)
    }
  }, [createDesignData])

  // When duplicated design's id is stored in useEffect above,
  // this id is used to find the freshly duplicated design in redux-store,
  // and is then passed to 'updateDesign'-function, which updates screens etc.
  // to the duplicated design.
  useEffect(() => {
    if (createdDesignId) {
      const findCreatedDesignInStore = designs.find(d => d.id === createdDesignId)
      if (findCreatedDesignInStore) {
        handleScreenDuplication(findCreatedDesignInStore)
      }
    }
  }, [designs, createdDesignId])

  const handleScreenDuplication = async (duplicatedDesign: DesignSchema) => {
    setCreatedDesignId(undefined)
    const { screens, error } = await getScreensForDuplicatedDesign(design.id)
    if (error) {
      removeDuplicatedDesign(duplicatedDesign)
    } else {
      const { error } = await saveScreens(duplicatedDesign, screens, {}, true)
      if (error) {
        removeDuplicatedDesign(duplicatedDesign)
      } else {
        updateDesign(
          duplicatedDesign,
          {
            themeId: design.themeId,
            themeOverride: design.themeOverride || {},
            icon: design.icon || 'StarBorder',
            thumbnailColor: design.thumbnailColor || theme.palette.secondary.dark
          },
          true
        )
      }
    }
  }

  // If something goes wrong when duplicating a design, remove it
  const removeDuplicatedDesign = (duplicatedDesign: DesignSchema) => {
    deleteDesign(duplicatedDesign.id)
    dispatch(
      editSnackbar({
        message: `Error. Something went wrong, please try again.`,
        type: 'error'
      })
    )
  }

  const handleClickCard = () => {
    if (cardSize === 'large') {
      openDesign(design)
    }
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDesignDelete = () => {
    if (setDocumentForDialogs && setRemoveDesignDialogOpen) {
      setDocumentForDialogs({ document: design, type: 'design' })
      setRemoveDesignDialogOpen(true)
      handleCloseMenu()
    }
  }

  const handleDesignDuplicate = () => {
    createDesign(`${design.title} copy`, design.folder)
    handleCloseMenu()
  }

  const handleDesignChangeFolder = () => {
    if (setDocumentForDialogs && setChangeFolderDialogOpen) {
      setDocumentForDialogs({ document: design, type: 'design' })
      setChangeFolderDialogOpen(true)
      handleCloseMenu()
    }
  }

  const handleDesignThumbnail = () => {
    if (setDocumentForDialogs && setThumbnailDialogOpen) {
      setDocumentForDialogs({ document: design, type: 'design' })
      setThumbnailDialogOpen(true)
      handleCloseMenu()
    }
  }

  return (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <CardMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleDesignDelete={handleDesignDelete}
        handleDesignDuplicate={handleDesignDuplicate}
        handleDesignChangeFolder={handleDesignChangeFolder}
        handleDesignThumbnail={handleDesignThumbnail}
      />
      <Card
        onClick={handleClickCard}
        sx={{
          width: cardSize === 'large' ? 276 : 114,
          height: cardSize === 'large' ? 276 : 78,
          cursor: 'pointer'
        }}
      >
        <CardMedia
          sx={{
            position: 'relative',
            height: cardSize === 'large' ? '204px' : '78px',
            backgroundColor:
              design.thumbnailColor ||
              themes.find(v => v.id === design.themeId)?.palette.primary.main ||
              theme.palette.secondary.dark
          }}
        >
          {React.createElement(Icons[design.icon as IconType], {
            style: {
              color:
                themes.find(v => v.id === design.themeId)?.palette.primary.contrastText || 'white',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: cardSize === 'large' ? '100px' : '30px'
            }
          })}
        </CardMedia>
        {cardSize === 'large' && (
          <CardContent
            component="div"
            sx={{ padding: '0px !important', borderTop: '1px solid #E6EAEB' }}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={handleClickMenu}
                  sx={{ visibility: isHover ? 'visible' : 'hidden' }}
                  edge="end"
                >
                  <MoreVertIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={design.title}
                secondary={handleUpdatedAtDate(design.updatedAt)}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{
                  sx: {
                    color: design.updatedAt ? theme => theme.palette.text.secondary : 'transparent'
                  }
                }}
              />
            </ListItem>
          </CardContent>
        )}
      </Card>
    </div>
  )
}

const CardMenu: React.FC<{
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
  handleDesignDelete: () => void
  handleDesignDuplicate: () => void
  handleDesignChangeFolder: () => void
  handleDesignThumbnail: () => void
}> = ({
  open,
  anchorEl,
  handleClose,
  handleDesignDelete,
  handleDesignDuplicate,
  handleDesignChangeFolder,
  handleDesignThumbnail
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '-12px'
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      PaperProps={{
        elevation: 8,
        sx: {
          overflow: 'visible',
          width: 200,
          color: 'white',
          backgroundColor: theme => theme.palette.secondary.dark,
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 15,
            width: 10,
            height: 10,
            bgcolor: theme => theme.palette.secondary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px', paddingBottom: '8px' }}>
        <MenuItemStyled onClick={handleDesignDelete}>
          <ListItemIconStyled>
            <RemoveIcon />
          </ListItemIconStyled>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={handleDesignDuplicate}>
          <ListItemIconStyled>
            <CopyIcon />
          </ListItemIconStyled>
          <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={handleDesignChangeFolder}>
          <ListItemIconStyled>
            <FolderIcon />
          </ListItemIconStyled>
          <ListItemText primary="Move to folder" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
      </MenuList>
      <Divider sx={{ backgroundColor: theme => theme.custom.desertNight }} />
      <MenuList disablePadding sx={{ padding: '0px', paddingTop: '8px' }}>
        <MenuItemStyled onClick={handleDesignThumbnail}>
          <ListItemIconStyled>
            <PaletteIcon />
          </ListItemIconStyled>
          <ListItemText primary="Thumbnail" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}

export default DesignCard
