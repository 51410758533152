import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main
  }
}))

const options = ['New project', 'New theme']

interface NewDocumentButtonProps {
  setNewDesignDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  setNewThemeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  style?: React.CSSProperties
}

const NewDocumentButton: React.FC<NewDocumentButtonProps> = ({
  setNewDesignDialogOpen,
  setNewThemeDialogOpen,
  style
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleClick = () => {
    if (selectedIndex === 0) {
      setNewDesignDialogOpen(true)
    }
    if (selectedIndex === 1) {
      setNewThemeDialogOpen(true)
    }
  }

  const handleMenuItemClick = (_e: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <div style={style}>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <ButtonStyled onClick={handleClick} color="secondary">
          {options[selectedIndex]}
        </ButtonStyled>
        <ButtonStyled
          size="small"
          color="secondary"
          onClick={handleToggle}
          sx={{
            width: '36px'
          }}
        >
          <ArrowDropDownIcon />
        </ButtonStyled>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default NewDocumentButton
