import React from 'react'
import { UqTypography, UqTypographyProps } from '@uniqore/wrapper'
import { UqTypographyPropsExtended } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'

interface UqTypographyRenderProps {
  component: ScreenComponent
}

const UqTypographyRender: React.FC<UqTypographyRenderProps> = ({ component }) => {
  const props = component.props as UqTypographyProps & UqTypographyPropsExtended

  return (
    <UqTypography
      component="div"
      align={props.align}
      variant={props.variant}
      gutterBottom={props.gutterBottom || false}
      color={`text.${props.color || 'primary'}`}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    >
      {props.children}
    </UqTypography>
  )
}

export default UqTypographyRender
