import React from 'react'
import Icons from 'Components/Common/Icons'
import { UqListItemSecondaryAction, UqIconButton } from '@uniqore/wrapper'
import { UqListItemSecondaryActionPropsCustom, canvasComponents } from 'Util/CanvasComponents'

interface UqListItemSecondaryActionRenderProps {
  componentProps: UqListItemSecondaryActionPropsCustom
}

const UqListItemSecondaryActionRender: React.FC<UqListItemSecondaryActionRenderProps> = ({
  componentProps
}) => {
  return (
    <UqListItemSecondaryAction>
      {componentProps && componentProps.icon ? (
        <UqIconButton color={componentProps.color || 'primary'} edge={componentProps.edge || 'end'}>
          {React.createElement(Icons[componentProps.icon])}
        </UqIconButton>
      ) : null}
      {componentProps && componentProps.component && componentProps.component !== 'none'
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          React.createElement(canvasComponents[componentProps.component].component, {
            color: componentProps.color || 'primary',
            edge: componentProps.edge || 'end'
          })
        : null}
    </UqListItemSecondaryAction>
  )
}

export default UqListItemSecondaryActionRender
