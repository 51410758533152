import React from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { styled } from '@mui/material/styles'
import { Box, Typography, IconButton, CircularProgress } from '@mui/material'
import Tooltip from 'Components/Common/Tooltip'
import { useScreens } from 'Components/Firebase/Hooks/FirebaseHooks'
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { editLeftSidebar } from 'Features/layout'
import { WorkspaceSchema, DesignSchema } from 'types/firebase'

const IconButtonStyled = styled(IconButton)<{
  error?: number
}>(({ theme, error }) => ({
  height: '36px',
  width: '36px',
  cursor: 'pointer',
  ...(!error && {
    '& > svg > path': {
      fill: theme.palette.text.secondary
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& > svg > path': {
        fill: theme.palette.primary.light
      }
    }
  }),
  ...(error && {
    '& > svg > path': {
      fill: theme.palette.error.main
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& > svg > path': {
        fill: theme.palette.error.light
      }
    }
  })
}))

interface CenterBarProps {
  workspace: WorkspaceSchema
  selectedDesign: DesignSchema
}

const CenterBar: React.FC<CenterBarProps> = ({ workspace, selectedDesign }) => {
  const dispatch = useDispatch()
  const { leftSidebar } = useTypedSelector(state => state.layout)
  const { isCurrentlySaving, isSaveSuccessful, isSaveableChangesMade } = useTypedSelector(
    state => state.saveHelper
  )
  const { screens } = useTypedSelector(state => state.undoables.present.canvasScreens)
  const { selectedDesignScreens } = useTypedSelector(state => state.firebaseDocuments)
  const { saveScreens } = useScreens(workspace.id)

  const handleServiceClick = () => {
    dispatch(editLeftSidebar({ stateKey: 'section', value: 'branding' }))
    dispatch(
      editLeftSidebar({
        stateKey: 'open',
        value: leftSidebar.section !== 'branding' ? true : !leftSidebar.open
      })
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        borderRadius: '4px',
        paddingLeft: '16px',
        paddingRight: '4px',
        backgroundColor:
          leftSidebar.section === 'branding' && leftSidebar.open
            ? theme => theme.custom.desertNight
            : theme => theme.palette.secondary.dark
      }}
    >
      <Typography
        onClick={handleServiceClick}
        component="span"
        variant="subtitle2"
        sx={{
          cursor: 'pointer',
          color:
            leftSidebar.section === 'branding' && leftSidebar.open
              ? theme => theme.palette.success.light
              : theme => theme.palette.common.white,
          '&:hover': {
            color: theme => theme.palette.success.light
          }
        }}
      >
        {selectedDesign.title}
      </Typography>
      {isCurrentlySaving ? (
        <CircularProgress
          size={20}
          sx={{
            color: 'text.secondary',
            marginLeft: '8px',
            marginRight: '8px'
          }}
        />
      ) : (
        <>
          {isSaveableChangesMade ? (
            <Tooltip
              title={isSaveSuccessful ? 'Unsaved changes' : 'Save was not successful, try again.'}
            >
              <IconButtonStyled
                onClick={() => saveScreens(selectedDesign, screens, selectedDesignScreens)}
                error={!isSaveSuccessful ? 1 : 0}
              >
                <SaveIcon />
              </IconButtonStyled>
            </Tooltip>
          ) : (
            <Tooltip title="Up to date">
              <IconButtonStyled>
                <CheckIcon />
              </IconButtonStyled>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  )
}

export default CenterBar
