import { createSlice } from '@reduxjs/toolkit'

type SnackbarType = 'error' | 'success'

interface SnacbarSchema {
  open: boolean
  message: string
  type?: SnackbarType
}

interface EditSnackbarAction {
  payload: Omit<SnacbarSchema, 'open'>
}

const initialState: SnacbarSchema = {
  open: false,
  message: ''
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    editSnackbar: (state, { payload }: EditSnackbarAction) => {
      const { message, type } = payload
      if (message) {
        state.open = true
        state.message = message
        state.type = type
      } else {
        state.open = false
        state.message = message
      }
    }
  }
})

export const { editSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer
