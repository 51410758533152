import React from 'react'
import { useTypedSelector } from 'Store'
import { Drawer } from '@mui/material'
import AddSection from 'Components/Layout/LeftSidebar/AddSection'
import EditSection from 'Components/Layout/LeftSidebar/EditSection'
import PrototypeSection from 'Components/Layout/LeftSidebar/PrototypeSection'
import BrandingSection from 'Components/Layout/LeftSidebar/BrandingSection'
import NavigatorSection from 'Components/Layout/LeftSidebar/NavigatorSection'

const LeftSidebar: React.FC = () => {
  const { leftSidebar } = useTypedSelector(state => state.layout)

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          whiteSpace: 'nowrap',
          backgroundColor: 'background.paper',
          height: theme => `calc(100% - ${theme.props.navBarHeight})`,
          top: theme => theme.props.navBarHeight,
          overflowX: 'hidden',
          transition: 'width 250ms ease-in-out',
          width: theme => (leftSidebar.open ? theme.props.leftSidebar : 0),
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
    >
      {leftSidebar.section === 'add' && <AddSection />}
      {leftSidebar.section === 'edit' && <EditSection />}
      {leftSidebar.section === 'prototype' && <PrototypeSection />}
      {leftSidebar.section === 'branding' && <BrandingSection />}
      {leftSidebar.section === 'navigator' && <NavigatorSection />}
    </Drawer>
  )
}

export default LeftSidebar
