import React, { useState, Fragment } from 'react'
import { fuse } from 'Components/Layout/FirebaseInit'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useDrag } from 'react-dnd'
import { ItemTypes } from 'Util/DnDItemTypes'
import { Box, Divider, Grid, Typography } from '@mui/material'
import CollapseMenu from 'Components/Common/CollapseMenu'
import { getComponentSection } from 'Components/Layout/Canvas/CanvasScreen'
import { genComponentId } from 'Features/canvas'
import { addComponent } from 'Features/canvasScreens'
import uqComponentsData, { Categories, UqComponentsDataValues } from 'Util/UqComponentsData'
import { canvasComponents } from 'Util/CanvasComponents'
import { useChildComponent } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Components/ChildComponentHooks'

interface AddSectionBoxProps {
  componentData: UqComponentsDataValues
}

const AddSectionBox: React.FC<AddSectionBoxProps> = ({ componentData }) => {
  const dispatch = useDispatch()
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const selectedScreenId = useTypedSelector(
    state => state.undoables.present.canvasScreens.selectedScreenId
  )
  const { addChildComponent } = useChildComponent()

  const [, drag] = useDrag({
    type: ItemTypes.COMPONENT,
    item: () => ({ type: componentData.component })
  })

  const handleComponentClick = () => {
    if (selectedScreenId) {
      const component = {
        id: genComponentId(),
        componentName: componentData.component,
        props: canvasComponents[componentData.component].props
      }
      dispatch(
        addComponent({
          screenId: selectedScreenId,
          component: component,
          section: getComponentSection(componentData.component)
        })
      )
      if (component.componentName === 'uqList') {
        addChildComponent(selectedScreenId, component, 'contents', {
          componentName: 'uqListItem'
        })
      }
    }
  }

  return (
    <Box
      ref={drag}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleComponentClick}
      sx={{
        backgroundColor: isHovering ? '#F5F7F7' : '#FAFAFA',
        cursor: 'pointer',
        margin: '3px',
        width: '88px',
        height: '88px',
        borderRadius: '4px',
        border: theme => `1px solid ${theme.custom.borderColor}`,
        outline: isHovering ? theme => `1px solid ${theme.palette.secondary.light}` : ''
      }}
    >
      <div
        style={{
          width: '100%',
          height: '60px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <img src={componentData.icon} width={80} />
      </div>
      <div style={{ width: '100%', margin: '4px 0 4px 0' }}>
        <Typography
          align="center"
          variant="body2"
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '14px'
          }}
          sx={{
            color: 'text.primary',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {componentData.name}
        </Typography>
      </div>
    </Box>
  )
}

const filterOnSearchValue = (componentData: UqComponentsDataValues, searchValue: string) => {
  let result = false
  if (!searchValue) {
    return !result
  }
  const resultValues = fuse.search(searchValue)
  for (const value of resultValues) {
    if (value.score! < 0.5 && value.item.name === componentData.name) {
      result = true
      break
    }
  }
  return result
}

const getComponentsAmount = (
  dataValues: UqComponentsDataValues[],
  category: Categories,
  searchValue: string
) => {
  return dataValues
    .filter(v => v.category === category)
    .filter(c => filterOnSearchValue(c, searchValue)).length
}

const getComponentsByCategory = (
  dataValues: UqComponentsDataValues[],
  category: Categories,
  searchValue: string
) => {
  return dataValues
    .filter(v => v.category === category)
    .filter(c => filterOnSearchValue(c, searchValue))
    .map((c, i) => <AddSectionBox key={i} componentData={c} />)
}

const categoryNameHandler = (name: string) => {
  const value = name.replace(/([a-z0-9])([A-Z])/g, '$1 $2') // camelCase to Camel Case
  return value.toLowerCase().replace('and', '&')
}

const generateCategories = (dataValues: UqComponentsDataValues[]) => {
  const categories: Categories[] = []
  dataValues.forEach(({ category }) => {
    if (!category) return
    let nameExists = false
    for (const cat of categories) {
      if (cat === category) {
        nameExists = true
      }
    }
    if (!nameExists) {
      categories.push(category)
    }
  })
  return categories
}

const Components: React.FC<{ searchValue: string }> = ({ searchValue }) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          position: 'absolute',
          top: '48px',
          color: theme => theme.palette.text.secondary,
          padding: '16px'
        }}
      >
        No results.
      </Typography>
      {generateCategories(Object.values(uqComponentsData)).map(category => (
        <Fragment key={category}>
          <CollapseMenu
            text={`${categoryNameHandler(category)} (${getComponentsAmount(
              Object.values(uqComponentsData),
              category,
              searchValue
            )})`}
            style={
              getComponentsAmount(Object.values(uqComponentsData), category, searchValue) === 0
                ? { display: 'none' }
                : {}
            }
          >
            <Grid
              container
              style={{ paddingLeft: '14px', paddingRight: '14px', paddingBottom: '16px' }}
            >
              {getComponentsByCategory(Object.values(uqComponentsData), category, searchValue)}
            </Grid>
          </CollapseMenu>
          <Divider />
        </Fragment>
      ))}
    </>
  )
}

export default Components
