import React, { useEffect } from 'react'
import { ActionCreators } from 'redux-undo'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useScreens } from 'Components/Firebase/Hooks/FirebaseHooks'

interface DesignScreensHandlerProps {
  workspaceId: string
  selectedDesignId: string
}

const DesignScreensHandler: React.FC<DesignScreensHandlerProps> = ({
  workspaceId,
  selectedDesignId
}) => {
  const dispatch = useDispatch()
  const { screensFetchedFromFirebase } = useTypedSelector(state => state.helpers)

  const { getScreens, clearScreens } = useScreens(workspaceId)

  // Fetch screens
  useEffect(() => {
    getScreens(selectedDesignId)
    // When screens are stored in reducer 'screensFetchedFromFirebase' is set to 'true'
  }, [])

  useEffect(() => {
    if (screensFetchedFromFirebase) {
      // Do not put fetched screens action to redux-undo past-history.
      // User should not be able to undo fetched screens.
      dispatch(ActionCreators.clearHistory())
    }
  }, [screensFetchedFromFirebase])

  // When component unmounts (selectedDesign = undefined), clear screen data
  useEffect(() => {
    return () => {
      clearScreens()
    }
  }, [])

  return null
}

export default React.memo(DesignScreensHandler)
