import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardMedia, CardContent, Grid, ListItem, ListItemText } from '@mui/material'
import DesignCard from './DesignCard'
import ThemeCard from './ThemeCard'
import { editFolderView } from 'Features/workspaceView'
import { WorkspaceSchema, DesignSchema, ThemeSchema } from 'types/firebase'

const genSecondaryText = (designsLength: number, themesLength: number): string => {
  if (designsLength === 0 && themesLength !== 0) {
    return `${themesLength} themes`
  }
  if (designsLength !== 0 && themesLength === 0) {
    return `${designsLength} projects`
  }
  if (designsLength > 0 && themesLength > 0) {
    return `${designsLength} projects and ${themesLength} themes`
  }
  return ''
}

interface FolderCardProps {
  workspace: WorkspaceSchema
  designs: DesignSchema[]
  themes: ThemeSchema[]
  folder: string
}

const FolderCard: React.FC<FolderCardProps> = ({ workspace, designs, themes, folder }) => {
  const dispatch = useDispatch()
  const [designsInFolder, setDesignsInFolder] = useState<DesignSchema[]>([])
  const [themesInFolder, setThemesInFolder] = useState<ThemeSchema[]>([])

  useEffect(() => {
    const folderDesigns: DesignSchema[] = []
    designs.forEach(design => {
      if (design.folder === folder) {
        folderDesigns.push(design)
      }
    })
    setDesignsInFolder(folderDesigns)
  }, [designs])

  useEffect(() => {
    const folderThemes: ThemeSchema[] = []
    themes.forEach(theme => {
      if (theme.folder === folder) {
        folderThemes.push(theme)
      }
    })
    setThemesInFolder(folderThemes)
  }, [themes])

  return (
    <Card
      onClick={() => dispatch(editFolderView({ folder }))}
      sx={{ width: 276, height: 276, cursor: 'pointer' }}
    >
      <CardMedia
        sx={{
          position: 'relative',
          height: '204px',
          backgroundColor: theme => theme.custom.nightBlue[40]
        }}
      >
        <Grid
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            width: '100%',
            height: '100%',
            transform: 'translate(-50%, -50%)',
            marginTop: '-4px',
            marginLeft: '0px'
          }}
          container
          spacing={2}
        >
          {designsInFolder.slice(0, 4).map(design => (
            <Grid key={design.id} item>
              <DesignCard workspace={workspace} themes={themes} design={design} cardSize="small" />
            </Grid>
          ))}
          {themesInFolder.slice(0, 4 - designsInFolder.length).map(theme => (
            <Grid key={theme.id} item>
              <ThemeCard workspace={workspace} theme={theme} cardSize="small" />
            </Grid>
          ))}
        </Grid>
      </CardMedia>
      <CardContent component="div" sx={{ padding: '0px !important' }}>
        <ListItem>
          <ListItemText
            primary={folder}
            secondary={genSecondaryText(designsInFolder.length, themesInFolder.length)}
            primaryTypographyProps={{ color: 'text.primary' }}
            secondaryTypographyProps={{ color: 'text.secondary' }}
          />
        </ListItem>
      </CardContent>
    </Card>
  )
}

export default FolderCard
