import React, { useState, useRef, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Divider, ListItem, ListItemText, IconButton, InputBase, Slide } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import Components from './Components'

export const BottomSpace = styled('div')({
  minHeight: '100px',
  backgroundColor: 'transparent'
})

const Search = styled('div')<{
  searchopen: number // boolean causes console warning
}>(({ searchopen, theme }) => ({
  width: '100%',
  backgroundColor: theme.custom.appleGreen[750],
  zIndex: 99,
  position: searchopen ? 'absolute' : 'relative'
}))

const AddSection: React.FC = () => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (searchOpen) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [searchOpen])

  return (
    <>
      <Slide in={searchOpen}>
        <Search searchopen={searchOpen ? 1 : 0}>
          <Box
            sx={{
              display: !searchOpen ? 'none' : 'flex',
              justifyContent: 'space-between',
              padding: '6px 8px 6px 16px'
            }}
          >
            <InputBase
              value={searchValue}
              onChange={e => setSearchValue(e.target.value.toLowerCase())}
              placeholder="Search for the component..."
              inputProps={{ ref: inputRef }}
              sx={{
                flexGrow: 1,
                '& .MuiInputBase-input': {
                  color: 'white'
                }
              }}
            />
            <IconButton
              onClick={() => {
                setSearchOpen(false)
                setSearchValue('')
              }}
            >
              <ClearIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Search>
      </Slide>
      <ListItem
        onClick={() => setSearchOpen(true)}
        sx={{ cursor: 'pointer' }}
        secondaryAction={
          <IconButton edge="end">
            <SearchIcon />
          </IconButton>
        }
      >
        <ListItemText
          primary="Add new"
          primaryTypographyProps={{ variant: 'subtitle1', sx: { color: 'text.secondary' } }}
        />
      </ListItem>
      <Divider />
      <Components searchValue={searchValue} />
      <BottomSpace />
    </>
  )
}

export default AddSection
