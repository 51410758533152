import React from 'react'
import ColorPicker from 'Components/Common/ColorPicker'
import { ColorResult } from 'react-color'
import { PaletteSchema, ColorSchema, TextSchema, BackgroundSchema } from 'Util/ThemeValues'

interface ColorsBasicProps {
  colors: PaletteSchema
  palette: PaletteSchema
  updateColor: (
    color: ColorResult,
    stateUpdate: boolean,
    key: keyof PaletteSchema,
    colorKey: keyof ColorSchema
  ) => void
  updateBackground: (
    color: ColorResult,
    stateUpdate: boolean,
    backgroundKey: keyof BackgroundSchema
  ) => void
  updateText: (color: ColorResult, stateUpdate: boolean, textKey: keyof TextSchema) => void
}

const ColorsBasic: React.FC<ColorsBasicProps> = ({
  colors,
  palette,
  updateColor,
  updateBackground,
  updateText
}) => {
  return (
    <>
      <ColorPicker
        label="Primary"
        colors={colors}
        colorFromState={palette.primary.main}
        paletteKey="primary"
        colorKey="main"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'primary', 'main')}
      />
      <ColorPicker
        label="Secondary"
        colors={colors}
        colorFromState={palette.secondary.main}
        paletteKey="secondary"
        colorKey="main"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, 'secondary', 'main')}
      />
      <ColorPicker
        label="Background"
        colors={colors}
        colorFromState={palette.background.default}
        paletteKey="background"
        colorKey="default"
        updateColor={(color, stateUpdate) => updateBackground(color, stateUpdate, 'default')}
      />
      <ColorPicker
        label="Text"
        colors={colors}
        colorFromState={palette.text.primary}
        paletteKey="text"
        colorKey="primary"
        updateColor={(color, stateUpdate) => updateText(color, stateUpdate, 'primary')}
      />
    </>
  )
}

export default ColorsBasic
