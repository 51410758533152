import React from 'react'
import { useDispatch } from 'react-redux'
import useTypedSelector from 'Store'
import AddElement from 'Components/Common/AddElement'
import CollapseMenu from 'Components/Common/CollapseMenu'
import DragDropHandler from '../DragDropHandler'
import { editLeftSidebar } from 'Features/layout'

const Content: React.FC = () => {
  const dispatch = useDispatch()
  const { screens, selectedScreenId } = useTypedSelector(
    state => state.undoables.present.canvasScreens
  )
  if (!selectedScreenId || Object.keys(screens).length === 0) return <></>

  return (
    <CollapseMenu text="Content">
      <div style={{ margin: '16px', marginTop: '8px' }}>
        <DragDropHandler
          components={screens[selectedScreenId].components.contents}
          screenId={selectedScreenId}
          section="contents"
        />
        <AddElement
          text="Add content"
          onClick={() =>
            dispatch(
              editLeftSidebar({
                stateKey: 'section',
                value: 'add'
              })
            )
          }
        />
      </div>
    </CollapseMenu>
  )
}

export default Content
