import React, { useState, useReducer } from 'react'
import { useTypedSelector } from 'Store'
import { UqListItemSecondaryAction } from '@uniqore/wrapper'
import { Add, AddProps } from '@uniqore/module'
import { AddPropsCustom } from 'Util/CanvasComponents'

interface Item {
  id: string
  quantity: number
  minQuantity?: number
}

interface Action {
  type: 'increase' | 'decrease' | 'remove' | 'setQuantity'
  payload: {
    id: string | number
    value?: string | number
  }
}

const reducer = (item: Item, action: Action) => {
  switch (action.type) {
    case 'increase':
      if (item.id === action.payload.id) {
        const updateItem = JSON.parse(JSON.stringify(item))
        return { ...updateItem, quantity: (updateItem.quantity += 1) }
      }
      return item
    case 'decrease':
      if (item.id === action.payload.id) {
        const updateItem = JSON.parse(JSON.stringify(item))
        return { ...updateItem, quantity: (updateItem.quantity -= 1) }
      }
      return item
    case 'remove':
      if (item.id === action.payload.id) {
        const updateItem = JSON.parse(JSON.stringify(item))
        return { ...updateItem, quantity: 0 }
      }
      return item
    case 'setQuantity':
      if (item.id === action.payload.id) {
        const updateItem = JSON.parse(JSON.stringify(item))
        return { ...updateItem, quantity: (updateItem.quantity = action.payload.value as number) }
      }
      return item
    default:
      return item
  }
}

interface UqListItemSecondaryActionAddRenderProps {
  componentProps: AddProps
}

const UqListItemSecondaryActionAddRender: React.FC<UqListItemSecondaryActionAddRenderProps> = ({
  componentProps
}) => {
  const props = componentProps as AddPropsCustom
  const previewOn = useTypedSelector(state => state.preview.previewOn)

  // Variant single
  const [checked, setChecked] = useState<boolean>(props.checked || false)
  const handleSingeChange = () => {
    if (previewOn) {
      setChecked(!checked)
    }
  }

  // Variant multi
  const [item, dispatch] = useReducer(reducer, {
    id: '1',
    quantity: props.quantity || 0
  })

  return (
    <UqListItemSecondaryAction>
      {componentProps.variant === 'single' && (
        <Add
          {...componentProps}
          checked={previewOn ? checked : props.checked}
          onClick={handleSingeChange}
          icon={props.icon || undefined}
          checkedIcon={props.checkedIcon || undefined}
          edge={props.edge || 'end'}
        />
      )}
      {componentProps.variant === 'multi' && (
        <Add
          {...componentProps}
          dispatch={previewOn ? dispatch : undefined}
          quantity={previewOn ? item.quantity : props.quantity}
          id={item.id}
          icon={props.icon || undefined}
          checkedIcon={props.checkedIcon || undefined}
          edge={props.edge || 'end'}
        />
      )}
    </UqListItemSecondaryAction>
  )
}

export default UqListItemSecondaryActionAddRender
