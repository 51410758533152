import React from 'react'
import { useTypedSelector } from 'Store'
import { UqCheckbox, UqCheckboxProps, UqFormControlLabel, UqFormGroup } from '@uniqore/wrapper'
import { UqCheckboxPropsExtended } from 'Util/CanvasComponents'
import { handleOptions } from '../ComponentRender'
import { ScreenComponent } from 'Features/canvas'

interface UqCheckboxRenderProps {
  component: ScreenComponent
}

const UqCheckboxRender: React.FC<UqCheckboxRenderProps> = ({ component }) => {
  const { previewOn } = useTypedSelector(state => state.preview)

  const props = component.props as UqCheckboxProps & UqCheckboxPropsExtended

  return (
    <UqFormGroup
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    >
      {handleOptions(props.options).map((option, i) => (
        <UqFormControlLabel
          key={i}
          control={
            <UqCheckbox
              checked={!previewOn ? props.checked : undefined}
              defaultChecked={previewOn ? props.checked : undefined}
              size={props.size}
              color={props.color}
              disabled={props.disabled}
            />
          }
          label={option}
        />
      ))}
    </UqFormGroup>
  )
}

export default UqCheckboxRender
