import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useLazyQuery, useMutation } from '@apollo/client'
import ServiceClient from 'Api/serviceClient'
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  Skeleton
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import SpinnerMui from 'Components/Common/SpinnerMui'
import {
  DESIGN_STUDIO_DOCUMENT_SHARES,
  SHARE_DESIGN_STUDIO_DOCUMENT,
  UNSHARE_DESIGN_STUDIO_DOCUMENT
} from './Queries'
import { editSnackbar } from 'Features/snackbar'
import { openShareDialog } from 'Features/share'

const ButtonStyled = styled(Button)(() => ({
  borderRadius: '100px',
  height: '42px',
  marginTop: '8px'
}))

interface ShareDialogProps {
  workspaceId: string
  selectedDesignId: string
}

const ShareDialog: React.FC<ShareDialogProps> = ({ workspaceId, selectedDesignId }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { shareDialogOpen } = useTypedSelector(state => state.share)
  const [linkCopied, setLinkCopied] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<string | null>(null)

  const [getShares, { loading }] = useLazyQuery(DESIGN_STUDIO_DOCUMENT_SHARES, {
    client: ServiceClient,
    fetchPolicy: 'network-only',
    variables: {
      workspace: workspaceId,
      document: selectedDesignId
    },
    onCompleted(result) {
      if (result && result.designStudioDocumentShares) {
        let link: string | null = null
        for (const value of result.designStudioDocumentShares) {
          if (value.document.id === selectedDesignId) {
            link = value.id
            break
          }
        }
        setShareLink(link)
      } else {
        setShareLink(null)
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const [generateLink, { loading: generateLoading }] = useMutation(SHARE_DESIGN_STUDIO_DOCUMENT, {
    client: ServiceClient,
    variables: {
      workspace: workspaceId,
      design: selectedDesignId
    },
    onCompleted(result) {
      if (result && result.shareDesignStudioDocument) {
        setShareLink(result.shareDesignStudioDocument.id)
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const [revokeLink, { loading: revokeLoading }] = useMutation(UNSHARE_DESIGN_STUDIO_DOCUMENT, {
    client: ServiceClient,
    onCompleted(result) {
      if (result && result.unshareDesignStudioDocument) {
        setShareLink(null)
      } else {
        dispatch(
          editSnackbar({
            message: 'Something went wrong, please try again.',
            type: 'error'
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  useEffect(() => {
    if (shareDialogOpen) {
      getShares()
    } else {
      setLinkCopied(false)
    }
  }, [shareDialogOpen])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={shareDialogOpen}
      onClose={() => dispatch(openShareDialog({ value: false }))}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 16px 0px 24px' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
              Share
            </Typography>
            <IconButton onClick={() => dispatch(openShareDialog({ value: false }))}>
              <CloseRoundedIcon sx={{ color: theme => theme.palette.text.primary }} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <DialogContentText variant="body1" color="text.secondary">
              Share link is{' '}
              <Typography
                variant="body1"
                color="text.secondary"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                {shareLink ? 'active' : 'not active'}
              </Typography>
              . Users who know the link can access the preview mode, but not the project itself.
            </DialogContentText>
          )}
        </DialogContent>
        <Box sx={{ position: 'relative', margin: '0px 24px 24px 24px' }}>
          {loading ? (
            <Skeleton />
          ) : !shareLink ? (
            <>
              {generateLoading ? (
                <SpinnerMui
                  color="white"
                  size={30}
                  style={{
                    position: 'absolute',
                    bottom: '2px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              ) : null}
              <ButtonStyled
                onClick={() => generateLink()}
                disabled={generateLoading}
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
              >
                Generate link
              </ButtonStyled>
            </>
          ) : (
            <>
              <ButtonStyled
                onClick={() => {
                  if (process.env.REACT_APP_IS_LOCAL === 'true') {
                    navigator.clipboard.writeText(`localhost:3000/share/${shareLink}`)
                  } else {
                    navigator.clipboard.writeText(`${process.env.REACT_APP_GO_DOMAIN}/${shareLink}`)
                  }
                  setLinkCopied(true)
                }}
                disabled={revokeLoading}
                fullWidth
                color="secondary"
                variant="contained"
                disableElevation
                startIcon={linkCopied ? <DoneRoundedIcon /> : undefined}
              >
                {linkCopied ? 'Link copied' : 'Copy link'}
              </ButtonStyled>
              {revokeLoading ? (
                <SpinnerMui
                  color={theme.palette.text.secondary}
                  size={30}
                  style={{
                    position: 'absolute',
                    bottom: '2px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              ) : null}
              <ButtonStyled
                onClick={() =>
                  revokeLink({
                    variables: {
                      shareKey: shareLink
                    }
                  })
                }
                disabled={revokeLoading}
                fullWidth
                color="error"
                variant="text"
              >
                Revoke link
              </ButtonStyled>
            </>
          )}
        </Box>
      </>
    </Dialog>
  )
}

export default React.memo(ShareDialog)
