import React, { useState } from 'react'
import Icons from 'Components/Common/Icons'
import { SelectChangeEvent } from '@mui/material'
import { UqSelectProps, UqMenuItem, UqTextField, UqInputAdornment } from '@uniqore/wrapper'
import { UqSelectPropsExtended } from 'Util/CanvasComponents'
import { handleOptions } from '../ComponentRender'
import { ScreenComponent } from 'Features/canvas'

interface UqSelectRenderProps {
  component: ScreenComponent
}

const UqSelectRender: React.FC<UqSelectRenderProps> = ({ component }) => {
  const [value, setValue] = useState<string>('')
  const [valueMultiple, setValueMultiple] = useState<string[]>([])

  const props = component.props as UqSelectProps & UqSelectPropsExtended

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
  }

  const handleChangeMultiple = (event: SelectChangeEvent<typeof valueMultiple>) => {
    const {
      target: { value }
    } = event
    setValueMultiple(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <UqTextField
      style={{ ...component.style }}
      select
      label={props.label}
      helperText={props.helperText}
      fullWidth={props.fullWidth}
      variant={props.variant}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      InputProps={{
        startAdornment: props.icon ? (
          <UqInputAdornment position="start">
            {React.createElement(Icons[props.icon])}
          </UqInputAdornment>
        ) : null
      }}
      SelectProps={{
        value: props.multiple ? valueMultiple : value,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange: props.multiple ? handleChangeMultiple : handleChange,
        multiple: props.multiple,
        disabled: props.disabled
      }}
    >
      {handleOptions(props.options).map((option, i) => (
        <UqMenuItem key={i} value={option}>
          {option}
        </UqMenuItem>
      ))}
    </UqTextField>
  )
}

export default UqSelectRender
