import React, { useState } from 'react'
import { useTypedSelector } from 'Store'
import { RadioButtonGroup, RadioButtonGroupProps, RadioButtonGroupSchema } from '@uniqore/module'
import { ScreenComponent } from 'Features/canvas'
import { useActions } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Hooks/ActionHooks'
import { ComponentActionSchema } from 'Components/Layout/LeftSidebar/EditSection/ComponentEdit/Sections/ActionSection'

interface RadioButtonGroupRenderProps {
  component: ScreenComponent
}

const RadioButtonGroupRender: React.FC<RadioButtonGroupRenderProps> = ({ component }) => {
  const { onClickAction } = useActions()
  const previewOn = useTypedSelector(state => state.preview.previewOn)
  const [value, setValue] = useState<string>('')

  const props = component.props as RadioButtonGroupProps

  const handleChange = (value: string) => {
    setValue(value)
    const findActions = props.radioButtons?.find(
      v => v.value === value
    ) as RadioButtonGroupSchema & { actions?: ComponentActionSchema[] }
    if (findActions) {
      onClickAction(findActions.actions)
    }
  }

  return (
    <RadioButtonGroup
      value={previewOn ? value : undefined}
      onChange={previewOn ? handleChange : undefined}
      radioButtons={props.radioButtons?.map(v => {
        const radioButton = v as RadioButtonGroupSchema
        const color = radioButton.color as RadioButtonGroupSchema['color'] & 'default'
        return {
          ...radioButton,
          color: color !== 'default' ? color : undefined
        }
      })}
      buttonsColor={props.buttonsColor}
      variant={props.variant || 'elevated'}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      style={{ ...component.style }}
    />
  )
}

export default RadioButtonGroupRender
