import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import Fuse from 'fuse.js'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Button, Container, Typography } from '@mui/material/'
import SpinnerMui from 'Components/Common/SpinnerMui'
import WorkspaceAppBar from './WorkspaceComponents/WorkspaceAppBar'
import WorkspaceIntro from './WorkspaceComponents/WorkspaceIntro'
import ProjectsView from './WorkspaceComponents/ProjectsView'
import ThemeCreation from './WorkspaceComponents/ThemeCreation/ThemeCreation'
import NewFolderDialog from './WorkspaceComponents/Dialogs/NewFolderDialog'
import RemoveFolderDialog from './WorkspaceComponents/Dialogs/RemoveFolderDialog'
import NewDesignDialog from './WorkspaceComponents/Dialogs/NewDesignDialog'
import NewThemeDialog from './WorkspaceComponents/Dialogs/NewThemeDialog'
import RemoveDesignDialog from './WorkspaceComponents/Dialogs/RemoveDesignDialog'
import RemoveThemeDialog from './WorkspaceComponents/Dialogs/RemoveThemeDialog'
import ResetThemeDialog from './WorkspaceComponents/Dialogs/ResetThemeDialog'
import MoveFolderDialog from './WorkspaceComponents/Dialogs/MoveFolderDialog'
import ThumbnailDialog from './WorkspaceComponents/Dialogs/ThumbnailDialog'
import { WorkspaceSchema, Document, DesignSchema, ThemeSchema } from 'types/firebase'
import { editHelpers } from 'Features/helpers'

export const ButtonStyled = styled(Button)(() => ({
  borderRadius: '100px',
  height: '40px',
  marginTop: '8px'
}))

const loopDataAndSetFolders = (data: DesignSchema[] | ThemeSchema[], folders: string[]) => {
  data.forEach(data => {
    let folderName: string
    if (data.folder) {
      folderName = data.folder
      let nameExists = false
      for (const folder of folders) {
        if (folder === folderName) {
          nameExists = true
        }
      }
      if (!nameExists) {
        folders.push(data.folder)
      }
    }
  })
}

export const generateFoldersData = (designs: DesignSchema[], themes: ThemeSchema[]) => {
  const folders: string[] = []
  loopDataAndSetFolders(designs, folders)
  loopDataAndSetFolders(themes, folders)
  return folders
}

interface WorkspaceViewProps {
  workspace: WorkspaceSchema
  designsLoading: boolean
  themesStatus: 'error' | 'success' | 'loading'
}

const WorkspaceView: React.FC<WorkspaceViewProps> = ({
  workspace,
  designsLoading,
  themesStatus
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { openNewThemeDialog } = useTypedSelector(state => state.helpers)
  const { sortBy, selectedTheme } = useTypedSelector(state => state.workspaceView)
  const { designs, themes } = useTypedSelector(state => state.firebaseDocuments)
  const [folders, setFolders] = useState<string[]>([])
  // searchValue and fuse are used for document searching
  const [searchValue, setSearchValue] = useState<string>('')
  const [fuse, setFuse] = useState<Fuse<Document>>()
  // Dialogs
  const [documentForDialogs, setDocumentForDialogs] =
    useState<{ document: Document; type: 'design' | 'theme' }>()
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState<boolean>(false)
  const [changeFolderDialogOpen, setChangeFolderDialogOpen] = useState<boolean>(false)
  const [removeFolderDialogOpen, setRemoveFolderDialogOpen] = useState<boolean>(false)
  const [newDesignDialogOpen, setNewDesignDialogOpen] = useState<boolean>(false)
  const [newThemeDialogOpen, setNewThemeDialogOpen] = useState<boolean>(false)
  const [removeDesignDialogOpen, setRemoveDesignDialogOpen] = useState<boolean>(false)
  const [removeThemeDialogOpen, setRemoveThemeDialogOpen] = useState<boolean>(false)
  const [resetThemeDialogOpen, setResetThemeDialogOpen] = useState<boolean>(false)
  const [thumbnailDialogOpen, setThumbnailDialogOpen] = useState<boolean>(false)

  // Set search with fuse again when datas are updated
  useEffect(() => {
    if (designs.length > 0 || themes.length > 0) {
      setFuse(
        new Fuse([...designs, ...themes], {
          keys: ['title'],
          includeScore: true
        })
      )
    }
  }, [designs, themes, sortBy])

  // Generate folders
  useEffect(() => {
    setFolders(generateFoldersData(designs, themes))
  }, [designs, themes])

  useEffect(() => {
    if (openNewThemeDialog) {
      setNewThemeDialogOpen(true)
      dispatch(editHelpers({ key: 'openNewThemeDialog', value: false }))
    }
  }, [openNewThemeDialog])

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          overflow: 'auto',
          height: theme => `calc(100% - ${theme.props.navBarHeight})`,
          paddingTop: theme => theme.props.navBarHeight,
          paddingLeft: '24px',
          paddingRight: '24px'
        }}
      >
        <Container maxWidth="lg" disableGutters>
          <WorkspaceAppBar
            workspace={workspace}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setDocumentForDialogs={setDocumentForDialogs}
            setNewFolderDialogOpen={setNewFolderDialogOpen}
            setChangeFolderDialogOpen={setChangeFolderDialogOpen}
            setRemoveFolderDialogOpen={setRemoveFolderDialogOpen}
            setNewDesignDialogOpen={setNewDesignDialogOpen}
            setNewThemeDialogOpen={setNewThemeDialogOpen}
            setRemoveThemeDialogOpen={setRemoveThemeDialogOpen}
            setResetThemeDialogOpen={setResetThemeDialogOpen}
          />
          {designsLoading && themesStatus !== 'success' && (
            <>
              <Typography
                variant="h4"
                color="text.primary"
                sx={{ textAlign: 'center', marginTop: '64px' }}
              >
                {designsLoading || themesStatus === 'loading'
                  ? 'Fetching projects...'
                  : 'Something went wrong, try reloading page again.'}
              </Typography>
              {themesStatus !== 'error' && (
                <SpinnerMui
                  size={80}
                  color={theme.palette.text.secondary}
                  style={{ marginTop: '64px', textAlign: 'center' }}
                />
              )}
            </>
          )}
          {!selectedTheme &&
            (!designsLoading && designs.length === 0 ? (
              <WorkspaceIntro setNewDesignDialogOpen={setNewDesignDialogOpen} />
            ) : (
              <ProjectsView
                workspace={workspace}
                searchValue={searchValue}
                fuse={fuse}
                designs={designs}
                themes={themes}
                folders={folders}
                setDocumentForDialogs={setDocumentForDialogs}
                setChangeFolderDialogOpen={setChangeFolderDialogOpen}
                setRemoveDesignDialogOpen={setRemoveDesignDialogOpen}
                setRemoveThemeDialogOpen={setRemoveThemeDialogOpen}
                setResetThemeDialogOpen={setResetThemeDialogOpen}
                setThumbnailDialogOpen={setThumbnailDialogOpen}
              />
            ))}
          {/* Show theme creation view when theme card is clicked */}
          {selectedTheme && <ThemeCreation workspace={workspace} selectedTheme={selectedTheme} />}
        </Container>
      </Box>
      {/* Dialogs */}
      <NewFolderDialog
        open={newFolderDialogOpen}
        setOpen={setNewFolderDialogOpen}
        folders={folders}
        setFolders={setFolders}
      />
      <RemoveFolderDialog
        open={removeFolderDialogOpen}
        setOpen={setRemoveFolderDialogOpen}
        folders={folders}
        setFolders={setFolders}
        workspace={workspace}
      />
      <NewDesignDialog
        open={newDesignDialogOpen}
        setOpen={setNewDesignDialogOpen}
        folders={folders}
        workspace={workspace}
      />
      <NewThemeDialog
        open={newThemeDialogOpen}
        setOpen={setNewThemeDialogOpen}
        folders={folders}
        workspace={workspace}
      />
      <RemoveDesignDialog
        open={removeDesignDialogOpen}
        setOpen={setRemoveDesignDialogOpen}
        documentForDialogs={documentForDialogs}
        workspace={workspace}
      />
      <RemoveThemeDialog
        open={removeThemeDialogOpen}
        setOpen={setRemoveThemeDialogOpen}
        documentForDialogs={documentForDialogs}
        designs={designs}
        workspace={workspace}
      />
      <ResetThemeDialog
        open={resetThemeDialogOpen}
        setOpen={setResetThemeDialogOpen}
        documentForDialogs={documentForDialogs}
        workspace={workspace}
      />
      <MoveFolderDialog
        open={changeFolderDialogOpen}
        setOpen={setChangeFolderDialogOpen}
        documentForDialogs={documentForDialogs}
        folders={folders}
        workspace={workspace}
      />
      <ThumbnailDialog
        open={thumbnailDialogOpen}
        setOpen={setThumbnailDialogOpen}
        documentForDialogs={documentForDialogs}
        workspace={workspace}
      />
    </>
  )
}

export default WorkspaceView
