import { gql } from '@apollo/client'

export const GET_DESIGN_STUDIO_FIREBASE_TOKEN = gql`
  query {
    getDesignStudioFirebaseToken
  }
`

export const DESIGN_STUDIO_WORKSPACES = gql`
  query designStudioWorkspaces {
    designStudioWorkspaces {
      id
      owner
      title
    }
  }
`

export const CREATE_DESIGN_STUDIO_WORKSPACE = gql`
  mutation createDesignStudioWorkspace($title: String!) {
    createDesignStudioWorkspace(title: $title) {
      id
      owner
      title
    }
  }
`

export const DESIGN_STUDIO_DESIGNS = gql`
  query designStudioDocuments($workspace: ID!, $deleted: Boolean) {
    designStudioDocuments(workspace: $workspace, deleted: $deleted) {
      id
      title
      folder
      deleted
    }
  }
`

export const CREATE_DESIGN_STUDIO_DESIGN = gql`
  mutation createDesignStudioDocument($workspace: ID!, $title: String, $folder: String) {
    createDesignStudioDocument(workspace: $workspace, title: $title, folder: $folder) {
      id
      title
      folder
    }
  }
`

export const DELETE_DESIGN_STUDIO_DESIGN = gql`
  mutation deleteDesignStudioDocument($workspace: ID!, $design: ID!) {
    deleteDesignStudioDocument(workspace: $workspace, design: $design)
  }
`
