import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useLazyQuery } from '@apollo/client'
import { styled } from '@mui/material/styles'
import { Box, Button, Dialog, ListItem, IconButton, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactComponent as WaveHandImage } from 'assets/images/wave-hand.svg'
import { initLoggedInUser } from 'Features/loggedInUser'
import { changeAuth } from 'Features/auth'
import { THIS_USER } from 'Components/Auth/Hooks/AuthHooks'
import { useFirebaseToken } from 'Components/Firebase/Hooks/FirebaseHooks'

const ButtonStyled = styled(Button)(() => ({
  height: '40px',
  textTransform: 'none',
  borderRadius: '100px'
}))

const LoggedInUserHandler: React.FC = () => {
  const dispatch = useDispatch()
  const { id, firstName, firebaseToken } = useTypedSelector(state => state.loggedInUser)
  const [open, setOpen] = useState<boolean>(false)
  const { getFirebaseToken } = useFirebaseToken()
  const [getLoggedInUser] = useLazyQuery(THIS_USER, {
    fetchPolicy: 'network-only',
    onCompleted(result) {
      if (result.thisUser) {
        dispatch(initLoggedInUser({ value: result.thisUser }))
      } else {
        dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
      }
    },
    onError() {
      dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
    }
  })

  useEffect(() => {
    if (!id) {
      getLoggedInUser()
    }
  }, [id])

  useEffect(() => {
    if (!firebaseToken) {
      getFirebaseToken()
    }
  }, [firebaseToken])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      PaperProps={{
        sx: { borderRadius: '8px' }
      }}
    >
      <Box sx={{ padding: '24px' }}>
        <ListItem
          secondaryAction={
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ marginRight: '-24px' }}
              edge="end"
              aria-label="clear"
            >
              <ClearIcon />
            </IconButton>
          }
        ></ListItem>
        <Box sx={{ textAlign: 'center', paddingTop: '16px' }}>
          <WaveHandImage />
          <Typography
            variant="h4"
            sx={{
              paddingTop: '16px',
              paddingBottom: '8px',
              color: theme => theme.palette.text.primary
            }}
          >
            Hi {firstName}
          </Typography>
          <Typography variant="body1" sx={{ color: theme => theme.palette.text.primary }}>
            Great to see you here!
          </Typography>
          <Typography
            variant="body1"
            component="span"
            sx={{ color: theme => theme.palette.text.primary }}
          >
            If you have any questions regarding to Uniqore Design Studio, just shout it out on{' '}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            sx={{ color: theme => theme.palette.text.primary, fontWeight: 'bold' }}
          >
            #pr-uniqore.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '24px'
          }}
        >
          <ButtonStyled
            onClick={() => setOpen(false)}
            startIcon={<ArrowForwardIcon fontSize="small" />}
            variant="contained"
            color="primary"
            disableElevation
          >
            Continue to Design Studio
          </ButtonStyled>
        </Box>
      </Box>
    </Dialog>
  )
}

export default LoggedInUserHandler
