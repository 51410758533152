import React, { useState, useEffect } from 'react'
import { Property } from 'csstype'
import Select from 'Components/Common/Select'
import { styled } from '@mui/material/styles'
import {
  Box,
  Collapse,
  Paper,
  ListItem,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  SelectChangeEvent
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import TextFieldsIcon from '@mui/icons-material/TextFields'

const ToggleButtonStyled = styled(ToggleButton)<{
  isselected: number // boolean gives console warning
}>(({ isselected, theme }) => ({
  height: '49px',
  width: '49px',
  ...(isselected && {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.text.primary} !important`
  })
}))

interface FontWeightOptions {
  value: number
  label: string
}

const fontWeightOptions: FontWeightOptions[] = [
  {
    value: 100,
    label: 'Thin'
  },
  {
    value: 300,
    label: 'Light'
  },
  {
    value: 400,
    label: 'Regular'
  },
  {
    value: 500,
    label: 'Medium'
  },
  {
    value: 700,
    label: 'Bold'
  },
  {
    value: 900,
    label: 'Black'
  }
]

type FormatTypes = 'italic' | 'underline' | 'uppercase' | ''

interface TypographyCardProps {
  text: string
  fontSize?: Property.FontSize
  fontWeight?: Property.FontWeight
  fontWeightOnChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
  fontStyle?: Property.FontStyle
  fontStyleOnChange: (isItalic: boolean) => void
  textDecoration?: Property.TextDecoration
  textDecorationOnChange: (isUnderline: boolean) => void
  textTransform?: Property.TextTransform
  textTransformOnChange: (isUpperCase: boolean) => void
}

const TypographyCard: React.FC<TypographyCardProps> = ({
  text,
  fontSize,
  fontWeight,
  fontWeightOnChange,
  fontStyle,
  fontStyleOnChange,
  textDecoration,
  textDecorationOnChange,
  textTransform,
  textTransformOnChange
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [formats, setFormats] = useState<FormatTypes[]>([''])

  useEffect(() => {
    const getFormats = () => {
      const formatTypes: FormatTypes[] = []
      if (fontStyle === 'italic') {
        formatTypes.push('italic')
      }
      if (textDecoration === 'underline') {
        formatTypes.push('underline')
      }
      if (textTransform === 'uppercase') {
        formatTypes.push('uppercase')
      }
      return formatTypes
    }
    setFormats(getFormats())
  }, [fontStyle, textDecoration, textTransform])

  const handleFormat = (type: FormatTypes) => {
    if (formats.find(v => v === type)) {
      setFormats(formats.filter(v => v !== type))
      if (type === 'italic') fontStyleOnChange(false)
      if (type === 'underline') textDecorationOnChange(false)
      if (type === 'uppercase') textTransformOnChange(false)
    } else {
      setFormats([...formats, type])
      if (type === 'italic') fontStyleOnChange(true)
      if (type === 'underline') textDecorationOnChange(true)
      if (type === 'uppercase') textTransformOnChange(true)
    }
  }

  const getFontWeightOptionLabel = (): string => {
    return fontWeightOptions.find(v => v.value === fontWeight)?.label || ''
  }

  return (
    <Paper
      sx={{
        backgroundColor: open ? '#FAFAFA' : 'white',
        border: theme => `1px solid ${theme.custom.borderColor}`,
        borderRadius: '4px',
        marginBottom: '8px'
      }}
      elevation={0}
    >
      <ListItem onClick={() => setOpen(!open)} sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                sx={{ color: theme => theme.palette.text.primary }}
                style={{ fontSize, fontWeight, fontStyle, textDecoration, textTransform }}
              >
                {text}
              </Typography>
            </>
          }
          secondary={
            <>
              <Collapse in={!open} timeout="auto" unmountOnExit>
                <Typography sx={{ color: theme => theme.palette.text.secondary }}>
                  {getFontWeightOptionLabel()}
                </Typography>
              </Collapse>
            </>
          }
          secondaryTypographyProps={{
            component: 'span'
          }}
        />
        {open ? (
          <ExpandLessIcon
            sx={{ cursor: 'pointer', color: theme => theme.palette.text.secondary }}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ cursor: 'pointer', color: theme => theme.palette.text.secondary }}
          />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '16px',
            marginRight: '16px',
            marginBottom: '8px'
          }}
        >
          <Select
            label="Weight"
            value={String(fontWeight)}
            onChange={fontWeightOnChange}
            options={fontWeightOptions}
            size="small"
            disablePadding
            style={{ flexGrow: 1, marginRight: '8px' }}
          />
          <ToggleButtonGroup sx={{ height: '48px', backgroundColor: 'white' }} value={formats}>
            <ToggleButtonStyled
              value="italic"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => handleFormat('italic')}
              isselected={formats.find(v => v === 'italic') ? 1 : 0} // boolean gives console warning
            >
              <FormatItalicIcon />
            </ToggleButtonStyled>
            <ToggleButtonStyled
              value="underline"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => handleFormat('underline')}
              isselected={formats.find(v => v === 'underline') ? 1 : 0} // boolean gives console warning
            >
              <FormatUnderlinedIcon />
            </ToggleButtonStyled>
            <ToggleButtonStyled
              value="uppercase"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => handleFormat('uppercase')}
              isselected={formats.find(v => v === 'uppercase') ? 1 : 0} // boolean gives console warning
            >
              <TextFieldsIcon />
            </ToggleButtonStyled>
          </ToggleButtonGroup>
        </Box>
      </Collapse>
    </Paper>
  )
}

export default TypographyCard
