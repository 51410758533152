import React from 'react'
import Icons, { IconType } from 'Components/Common/Icons'
import { UqAlert, UqAlertTitle, UqAlertProps } from '@uniqore/wrapper'
import { UqAlertPropsExtended } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'

interface UqAlertRenderProps {
  component: ScreenComponent
}

const UqAlertRender: React.FC<UqAlertRenderProps> = ({ component }) => {
  const props = component.props as UqAlertProps & UqAlertPropsExtended

  return (
    <UqAlert
      style={{ ...component.style }}
      {...props}
      icon={props.icon ? React.createElement(Icons[props.icon as IconType]) : false}
    >
      {props.title && <UqAlertTitle>{props.title}</UqAlertTitle>}
      {props.body}
    </UqAlert>
  )
}

export default UqAlertRender
