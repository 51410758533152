import React from 'react'
import { UqLinearProgress, UqLinearProgressProps } from '@uniqore/wrapper'

interface UqLinearProgressRenderProps {
  componentProps: UqLinearProgressProps
  style: React.CSSProperties
}

const UqLinearProgressRender: React.FC<UqLinearProgressRenderProps> = ({
  componentProps,
  style
}) => {
  return (
    <UqLinearProgress
      value={componentProps.value}
      variant={componentProps.variant}
      color={componentProps.color}
      style={style}
    />
  )
}

export default UqLinearProgressRender
