import { useState } from 'react'
import equal from 'fast-deep-equal'
import { ActionCreators } from 'redux-undo'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import ServiceClient from 'Api/serviceClient'
import {
  GET_DESIGN_STUDIO_FIREBASE_TOKEN,
  DESIGN_STUDIO_WORKSPACES,
  CREATE_DESIGN_STUDIO_WORKSPACE,
  DESIGN_STUDIO_DESIGNS,
  CREATE_DESIGN_STUDIO_DESIGN,
  DELETE_DESIGN_STUDIO_DESIGN
} from 'Components/Firebase/Queries/firebase'
import { useFirestore } from 'reactfire'
import {
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  serverTimestamp
} from 'firebase/firestore'
import { LayoutProps } from '@uniqore/module'
import { IconType } from 'Components/Common/Icons'
import { documentTimestampsToString } from 'Components/Layout/WorkspaceView/Helpers/functions'
import { updateSchemaScreens } from '../Version/ScreenVersionHandler'
import { editSnackbar } from 'Features/snackbar'
import { addFirebaseToken } from 'Features/loggedInUser'
import { changeAuth } from 'Features/auth'
import {
  setDesignId,
  setThemeId,
  setSelectedDesignScreens,
  removeTheme as removeThemeReducer
} from 'Features/firebaseDocuments'
import { setSelectedDesign, setSelectedTheme } from 'Features/workspaceView'
import { setCurrentlySaving, setIsSaveableChangesMade, clearSaveHelper } from 'Features/saveHelper'
import { ScreenId } from 'Features/canvas'
import { setScreens, clearCanvasScreens } from 'Features/canvasScreens'
import { editHelpers } from 'Features/helpers'
import { PaletteSchema, TypographySchema } from 'Util/ThemeValues'
import { DesignSchema, ThemeSchema, ThemeOverride, CanvasScreen } from 'types/firebase'

export const useFirebaseToken = () => {
  const dispatch = useDispatch()
  const [getFirebaseToken] = useLazyQuery(GET_DESIGN_STUDIO_FIREBASE_TOKEN, {
    client: ServiceClient,
    onCompleted(result) {
      if (result) {
        dispatch(addFirebaseToken({ value: result.getDesignStudioFirebaseToken }))
      } else {
        // Prevents user from using app if can't get firebase-token
        dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
      }
    },
    onError(error) {
      if (error) {
        dispatch(
          editSnackbar({
            message: `Error. ${error.message}`,
            type: 'error'
          })
        )
        dispatch(changeAuth({ value: true, key: 'sessionExpired' }))
      }
    }
  })

  return {
    getFirebaseToken
  }
} // useFirebaseToken

export const useWorkspaces = () => {
  const dispatch = useDispatch()
  const {
    data: workspacesData,
    loading: workspacesLoading,
    refetch
  } = useQuery(DESIGN_STUDIO_WORKSPACES, {
    client: ServiceClient,
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      // Create workspace if non exists.
      if (data.designStudioWorkspaces.length === 0) {
        createWorkspace('Default')
      }
    },
    onError(error) {
      if (error) {
        dispatch(
          editSnackbar({
            message: `Error. ${error.message}`,
            type: 'error'
          })
        )
      }
    }
  })

  const [createData, { loading: createWorkspaceLoading }] = useMutation(
    CREATE_DESIGN_STUDIO_WORKSPACE,
    {
      client: ServiceClient,
      onCompleted() {
        refetch()
      },
      onError(error) {
        if (error) {
          dispatch(
            editSnackbar({
              message: `Error. ${error.message}`,
              type: 'error'
            })
          )
        }
      }
    }
  )
  const createWorkspace = (title: string) => {
    createData({
      variables: { title }
    })
  }

  const refetchWorkSpaces = () => {
    refetch()
  }

  return {
    workspacesData,
    workspacesLoading,
    createWorkspace,
    createWorkspaceLoading,
    refetchWorkSpaces
  }
} // useWorkspaces

export const useDesigns = (workspaceId: string) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const themes = useTypedSelector(state => state.firebaseDocuments.themes)
  const defaultTheme = useTypedSelector(state => state.firebaseDocuments.defaultTheme)
  const loggedInUser = useTypedSelector(state => state.loggedInUser)

  const [fetchDesigns, { data: designsData, loading: designsLoading }] = useLazyQuery(
    DESIGN_STUDIO_DESIGNS,
    {
      client: ServiceClient,
      fetchPolicy: 'no-cache',
      onError(error) {
        if (error) {
          dispatch(
            editSnackbar({
              message: `Error. ${error.message}`,
              type: 'error'
            })
          )
        }
      }
    }
  )
  const getDesigns = () => {
    fetchDesigns({
      variables: {
        workspace: workspaceId,
        deleted: true
      }
    })
  }

  const [createData, { data: createDesignData, loading: createDesignLoading }] = useMutation(
    CREATE_DESIGN_STUDIO_DESIGN,
    {
      client: ServiceClient,
      onCompleted(result) {
        if (result.createDesignStudioDocument) {
          // Add new designs' id to id list so it gets subscribed (LayoutSection-component)
          dispatch(setDesignId({ addId: result.createDesignStudioDocument.id }))
        } else {
          dispatch(
            editSnackbar({
              message: 'Error. Something went wrong, please try again.',
              type: 'error'
            })
          )
        }
      },
      onError(error) {
        if (error) {
          dispatch(
            editSnackbar({
              message: `Error. ${error.message}`,
              type: 'error'
            })
          )
        }
      }
    }
  )
  const createDesign = (title: string, folder?: string) => {
    createData({
      variables: { workspace: workspaceId, title, folder }
    })
  }

  const [deleteData, { data: deleteDesignData, loading: deleteDesignLoading }] = useMutation(
    DELETE_DESIGN_STUDIO_DESIGN,
    {
      client: ServiceClient,
      onError(error) {
        if (error) {
          dispatch(
            editSnackbar({
              message: `Error. ${error.message}`,
              type: 'error'
            })
          )
        }
      }
    }
  )
  const deleteDesign = (design: string) => {
    deleteData({
      variables: { workspace: workspaceId, design }
    })
  }

  const updateDesign = async (
    design: DesignSchema,
    designValues: {
      title?: string
      folder?: string
      themeId?: string
      themeOverride?: ThemeOverride | null
      modifiedBy?: string
      icon?: IconType
      thumbnailColor?: string
      layoutProps?: LayoutProps
    },
    doNotSetAsSelectedDesign?: boolean
  ) => {
    try {
      dispatch(setCurrentlySaving({ isSaving: true }))
      const designRef = doc(firestore, 'Workspaces', workspaceId, 'Designs', design.id)
      await updateDoc(designRef, {
        ...designValues,
        updatedAt: serverTimestamp(),
        modifiedBy: loggedInUser.id
      })
      const updatedDesignRef = await getDoc(designRef)
      const updatedDesign = {
        ...(updatedDesignRef.data() as DesignSchema),
        id: updatedDesignRef.id
      }
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: true }))
      if (!doNotSetAsSelectedDesign) {
        dispatch(
          setSelectedDesign({ design: documentTimestampsToString(updatedDesign) as DesignSchema })
        )
      }
    } catch (error) {
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: false }))
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  const openDesign = (design: DesignSchema) => {
    // if design doesn't have a default theme, add a default theme to it
    if (!design.themeId) {
      updateDesign(design, {
        themeId: defaultTheme?.id
      })
    } else {
      // If theme doesn't exist (wrong themeId etc. set back to default theme)
      const themeExists = [...themes, defaultTheme as ThemeSchema].find(
        t => t.id === design.themeId
      )
      if (!themeExists) {
        updateDesign(design, {
          themeId: defaultTheme?.id
        })
      } else {
        dispatch(setSelectedDesign({ design }))
      }
    }
  }

  return {
    getDesigns,
    designsData,
    designsLoading,
    createDesign,
    createDesignData,
    createDesignLoading,
    deleteDesign,
    deleteDesignData,
    deleteDesignLoading,
    updateDesign,
    openDesign
  }
} // useDesigns

export const useThemes = (workspaceId: string) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const { selectedTheme } = useTypedSelector(state => state.workspaceView)
  const [themeSaveLoading, setSaveLoading] = useState<boolean>(false)
  const [savedTheme, setSavedTheme] = useState<ThemeSchema>()

  const createTheme = async (theme: Omit<ThemeSchema, 'id'>) => {
    try {
      setSaveLoading(true)
      // Create a new theme and send it to firebase
      const newTheme = await addDoc(
        collection(firestore, 'Workspaces', workspaceId, 'Themes'),
        theme
      )
      // Once theme is created, fetch document reference
      const docRef = doc(firestore, 'Workspaces', workspaceId, 'Themes', newTheme.id)
      // Get the data of the created theme using the document reference
      const createdThemeRef = await getDoc(docRef)
      const createdTheme = {
        ...(createdThemeRef.data() as ThemeSchema),
        id: createdThemeRef.id
      }
      setSaveLoading(false)
      // Add new theme's id to id list so it gets subscribed (LayoutSection-component)
      if (!theme.defaultTheme) {
        dispatch(setThemeId({ addId: createdTheme.id }))
      }
      setSavedTheme(createdTheme)
    } catch (error) {
      setSavedTheme(undefined)
      setSaveLoading(false)
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  const updateTheme = async (
    theme: ThemeSchema,
    themeValues: {
      title?: string
      folder?: string
      palette?: PaletteSchema
      typography?: TypographySchema
    }
  ) => {
    try {
      setSaveLoading(true)
      dispatch(setCurrentlySaving({ isSaving: true }))
      const themeRef = doc(firestore, 'Workspaces', workspaceId, 'Themes', theme.id)
      await updateDoc(themeRef, { ...themeValues, updatedAt: serverTimestamp() })
      const updatedThemeRef = await getDoc(themeRef)
      const updatedTheme = {
        ...(updatedThemeRef.data() as ThemeSchema),
        id: updatedThemeRef.id
      }
      setSaveLoading(false)
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: true }))
      if (selectedTheme) {
        // set updated theme in reducer
        dispatch(
          setSelectedTheme({
            theme: documentTimestampsToString(updatedTheme) as ThemeSchema
          })
        )
        dispatch(
          editSnackbar({
            message: 'Theme saved successfully',
            type: 'success'
          })
        )
      }
    } catch (error) {
      setSaveLoading(false)
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: false }))
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  const removeTheme = async (theme: ThemeSchema) => {
    try {
      setSaveLoading(true)
      const themeRef = doc(firestore, 'Workspaces', workspaceId, 'Themes', theme.id)
      await deleteDoc(themeRef)
      setSaveLoading(false)
      // Remove design from reducer
      dispatch(removeThemeReducer({ id: theme.id }))
      // Remove theme's id from the subscription list (LayoutSection-component)
      dispatch(setThemeId({ removeId: theme.id }))
      dispatch(setSelectedTheme({ theme: undefined }))
      dispatch(
        editSnackbar({
          message: 'Theme removed successfully',
          type: 'success'
        })
      )
    } catch (error) {
      setSaveLoading(false)
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  return {
    createTheme,
    updateTheme,
    removeTheme,
    themeSaveLoading,
    savedTheme
  }
} // useThemes

export const useScreens = (workspaceId: string) => {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const { updateDesign } = useDesigns(workspaceId)

  const getScreensForDuplicatedDesign = async (selectedDesignId: string) => {
    try {
      const screensRef = collection(
        firestore,
        'Workspaces',
        workspaceId,
        'Designs',
        selectedDesignId,
        'Screens'
      )
      const querySnapShot = await getDocs(screensRef)
      const screens: Record<ScreenId, CanvasScreen> = {}
      querySnapShot.forEach(screen => {
        const handleScreen: CanvasScreen = {
          id: screen.id,
          geometry: screen.data().geometry,
          sourceId: screen.data().sourceId,
          connections: screen.data().connections || [],
          label: screen.data().label || 'New Screen',
          components: screen.data().components || { headers: [], contents: [], footers: [] },
          flowStartPoint: screen.data().flowStartPoint || false,
          useLocalLayoutProps: screen.data().useLocalLayoutProps || false,
          layoutProps: screen.data().layoutProps || null,
          version: screen.data().version || 0
        }
        screens[screen.id] = handleScreen
      })
      return {
        screens: screens,
        error: false
      }
    } catch {
      return {
        screens: {},
        error: true
      }
    }
  }

  const getScreens = async (selectedDesignId: string) => {
    try {
      dispatch(setCurrentlySaving({ isSaving: true })) // nothing is actually saved but the indicator in NavBar -shows that screens are loading
      const canvasScreens: CanvasScreen[] = []
      const screensRef = collection(
        firestore,
        'Workspaces',
        workspaceId,
        'Designs',
        selectedDesignId,
        'Screens'
      )
      const querySnapShot = await getDocs(screensRef)
      querySnapShot.forEach(screen => {
        const canvasScreen: CanvasScreen = {
          id: screen.id,
          geometry: screen.data().geometry,
          sourceId: screen.data().sourceId,
          connections: screen.data().connections || [],
          label: screen.data().label || 'New Screen',
          components: screen.data().components || { headers: [], contents: [], footers: [] },
          flowStartPoint: screen.data().flowStartPoint || false,
          useLocalLayoutProps: screen.data().useLocalLayoutProps || false,
          layoutProps: screen.data().layoutProps || null,
          version: screen.data().version || 0
        }
        canvasScreens.push(canvasScreen)
      })
      const versionHandledScreens = updateSchemaScreens(canvasScreens)
      // Screens are stored to canvasScreen-reducer, where they can be edited
      dispatch(setScreens({ screens: versionHandledScreens }))
      // Store them also here, where they can't be edited. When screens
      // are edited/deleted in canvasScreen-reducer and
      // user clicks save, all those screens in canvasScreen are compared to this
      // reducer, where edited/deleted ones are updated to firebase.
      dispatch(setSelectedDesignScreens({ screens: canvasScreens }))
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: true }))
      dispatch(editHelpers({ key: 'screensFetchedFromFirebase', value: true }))
    } catch (error) {
      dispatch(setCurrentlySaving({ isSaving: false }))
      dispatch(setSelectedDesign({ design: undefined }))
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  }

  const saveScreens = async (
    selectedDesign: DesignSchema,
    screens: Record<ScreenId, CanvasScreen>, // screens that were fetched from firebase and are edited/deleted
    selectedDesignScreens: Record<ScreenId, CanvasScreen>, // screens that were fetched from firebase and are NOT edited/deleted
    duplicatingDesign?: boolean // set to true if duplicating a design
  ) => {
    try {
      dispatch(setCurrentlySaving({ isSaving: true }))

      const currentScreens = Object.values(screens)
      const currentScreenIds = Object.keys(screens)
      const firebaseScreens = Object.values(selectedDesignScreens)
      const firebaseScreensIds = Object.keys(selectedDesignScreens)

      // Find new screens that are not in firebase
      const newScreensIds = currentScreenIds.filter(id => !firebaseScreensIds.includes(id))
      const newScreens: CanvasScreen[] = []
      Object.values(currentScreens).forEach(screen => {
        newScreensIds.forEach(newScreenId => {
          if (screen.id === newScreenId) {
            newScreens.push(screen)
          }
        })
      })

      // Find screens that are modified
      const modifiedScreens: CanvasScreen[] = []
      currentScreens.forEach(screen => {
        firebaseScreens.forEach(unEditedScreen => {
          if (screen.id === unEditedScreen.id) {
            if (!equal(screen, unEditedScreen)) {
              modifiedScreens.push(screen)
            }
          }
        })
      })

      // Find screens that were deleted
      const screensToBeDeletedIds = firebaseScreensIds.filter(id => !currentScreenIds.includes(id))

      // Save new screens or screens that exists in reducer and were modified
      for (const screen of [...newScreens, ...modifiedScreens]) {
        await setDoc(
          doc(
            firestore,
            'Workspaces',
            workspaceId,
            'Designs',
            selectedDesign.id,
            'Screens',
            screen.id
          ),
          {
            ...screen
          },
          { merge: true }
        )
      }
      // Delete those screens from firebase that aren't in canvasScreen-reducer anymore
      for (const screenId of screensToBeDeletedIds) {
        const screenRef = doc(
          firestore,
          'Workspaces',
          workspaceId,
          'Designs',
          selectedDesign.id,
          'Screens',
          screenId
        )
        await deleteDoc(screenRef)
      }

      if (!duplicatingDesign) {
        // Update Design-document modifiedBy-value with loggedInUser-id
        updateDesign(selectedDesign, {}, true)
        // Update list of screens, should match with firebase
        dispatch(setSelectedDesignScreens({ screens: currentScreens }))
      }

      dispatch(setIsSaveableChangesMade({ value: false }))

      return {
        error: false
      }
    } catch (error) {
      dispatch(setCurrentlySaving({ isSaving: false, isSaveSuccessful: false }))
      if (!duplicatingDesign) {
        dispatch(
          editSnackbar({
            message: `Error. ${error}`,
            type: 'error'
          })
        )
      }
      return {
        error: true
      }
    }
  }

  // Screens are cleared when user clicks away from the selected design/project.
  // This way when another Design is selected, previous screens are not stored anymore
  const clearScreens = () => {
    dispatch(editHelpers({ key: 'screensFetchedFromFirebase', value: false }))
    dispatch(clearSaveHelper())
    dispatch(setSelectedDesignScreens({ screens: null }))
    dispatch(clearCanvasScreens())
    dispatch(ActionCreators.clearHistory())
  }

  return {
    getScreensForDuplicatedDesign,
    getScreens,
    saveScreens,
    clearScreens
  }
} // useScreens
