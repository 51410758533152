import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DesignSchema, ThemeSchema, CanvasScreen } from 'types/firebase'
import { ScreenId } from 'Features/canvas'

interface FirebaseDocumentsSchema {
  // keeps track of subscribed designs, is initialized at start and
  // ids are added/removed to/from this list when new designs are created or removed
  designIds: string[]
  // same as above but with theme-documents
  themeIds: string[]
  // all design-documents are stored here,
  // when updates occur, list will be updated so it matches with firebase
  designs: DesignSchema[]
  // same as above but with theme-documents
  themes: ThemeSchema[]
  // When opening a project (Design), all screens are fetched from firebase and
  // they are stored here (also in canvasScreen-reducer where they are edited/deleted).
  // These ones here are used to compare the changes to canvasScreen-reducer screens,
  // so only changed/removed screens are updated to firebase.
  selectedDesignScreens: Record<ScreenId, CanvasScreen>
  // Default theme is stored here. If needed, it is added to those designs
  // that doesn't have theme pointed to them.
  defaultTheme?: ThemeSchema
}

const initialState: FirebaseDocumentsSchema = {
  designIds: [],
  themeIds: [],
  designs: [],
  themes: [],
  selectedDesignScreens: {}
}

const firebaseDocumentsSlice = createSlice({
  name: 'firebaseDocuments',
  initialState,
  reducers: {
    setDesignId: (
      state,
      action: PayloadAction<{ allIds?: string[]; addId?: string; removeId?: string }>
    ) => {
      const { allIds, addId, removeId } = action.payload
      // Set all ids at the same time (when fetching all designs at start)
      if (allIds) {
        state.designIds = allIds
      } else if (addId) {
        // If id doesn't exist, add it
        if (!state.designIds.find(id => id === addId)) {
          state.designIds.push(addId)
        }
      } else if (removeId) {
        state.designIds = state.designIds.filter(id => id !== removeId)
      }
    },
    setThemeId: (
      state,
      action: PayloadAction<{ allIds?: string[]; addId?: string; removeId?: string }>
    ) => {
      const { allIds, addId, removeId } = action.payload
      // Set all ids at the same time (when fetching all themes at start)
      if (allIds) {
        state.themeIds = allIds
      } else if (addId) {
        // If id doesn't exist, add it
        if (!state.themeIds.find(id => id === addId)) {
          state.themeIds.push(addId)
        }
      } else if (removeId) {
        state.themeIds = state.themeIds.filter(id => id !== removeId)
      }
    },
    updateDesign: (state, action: PayloadAction<{ design: DesignSchema }>) => {
      // If item already exists, update it. Otherwise add it to list
      const { design } = action.payload
      if (state.designs.find(d => d.id === design.id)) {
        state.designs = state.designs.map(d => (d.id !== design.id ? d : design))
      } else {
        state.designs.push(design)
      }
    },
    updateTheme: (state, action: PayloadAction<{ theme: ThemeSchema }>) => {
      // If item already exists, update it. Otherwise add it to list
      const { theme } = action.payload
      if (state.themes.find(d => d.id === theme.id)) {
        state.themes = state.themes.map(d => (d.id !== theme.id ? d : theme))
      } else {
        state.themes.push(theme)
      }
    },
    removeDesign: (state, action: PayloadAction<{ id: string }>) => {
      state.designs = state.designs.filter(v => v.id !== action.payload.id)
    },
    removeTheme: (state, action: PayloadAction<{ id: string }>) => {
      state.themes = state.themes.filter(v => v.id !== action.payload.id)
    },
    setDefaultTheme: (state, action: PayloadAction<{ defaultTheme: ThemeSchema }>) => {
      state.defaultTheme = action.payload.defaultTheme
    },
    setSelectedDesignScreens: (
      state,
      action: PayloadAction<{ screens: CanvasScreen[] | null }>
    ) => {
      if (action.payload.screens === null) {
        state.selectedDesignScreens = {}
      } else {
        const allScreens: Record<ScreenId, CanvasScreen> = {}
        Object.values(action.payload.screens).forEach(screen => {
          allScreens[screen.id] = { ...screen }
        })
        state.selectedDesignScreens = allScreens
      }
    },
    clearfirebaseDocuments: () => initialState
  }
})

export const {
  setDesignId,
  setThemeId,
  updateDesign,
  updateTheme,
  removeDesign,
  removeTheme,
  setDefaultTheme,
  setSelectedDesignScreens,
  clearfirebaseDocuments
} = firebaseDocumentsSlice.actions
export default firebaseDocumentsSlice.reducer
