import React, { useState, Dispatch, SetStateAction } from 'react'
import { useTypedSelector } from 'Store'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@mui/material/'
import { useThemes } from 'Components/Firebase/Hooks/FirebaseHooks'
import NewDocumentButton from 'Components/Layout/WorkspaceView/WorkspaceComponents/NewDocumentButton'
import SmallSelect from 'Components/Common/Editing/SmallSelect'
import TextField from 'Components/Common/TextField'
import {
  ThemeMenu,
  handleCloseThemeMenu,
  handleThemeDelete,
  handleThemeDuplicate,
  handleThemeChangeFolder,
  handleResetTheme
} from 'Components/Layout/WorkspaceView/WorkspaceComponents/ThemeCard'
import { ReactComponent as FolderAddIcon } from 'assets/icons/folder-add.svg'
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  editFolderView,
  editSortBy,
  editShowFilter,
  SortBy,
  ShowFilter
} from 'Features/workspaceView'
import { WorkspaceSchema, Document } from 'types/firebase'
import { setSelectedTheme } from 'Features/workspaceView'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: '40px',
  width: '40px',
  borderRadius: 0,
  cursor: 'pointer',
  '& > svg > path': {
    fill: theme.palette.text.primary
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& > svg > path': {
      fill: theme.palette.primary.light
    }
  }
}))

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: '20px',
  '&:hover': {
    backgroundColor: theme.custom.desertNight
  }
}))

const ListItemIconStyled = styled(ListItemIcon)(() => ({
  mixWidth: '26px',
  '& > svg': {
    width: 14,
    height: 14
  }
}))

interface WorkspaceAppBarProps {
  workspace: WorkspaceSchema
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  setDocumentForDialogs: Dispatch<
    SetStateAction<{ document: Document; type: 'design' | 'theme' } | undefined>
  >
  setNewFolderDialogOpen: Dispatch<SetStateAction<boolean>>
  setChangeFolderDialogOpen: Dispatch<SetStateAction<boolean>>
  setRemoveFolderDialogOpen: Dispatch<SetStateAction<boolean>>
  setNewDesignDialogOpen: Dispatch<SetStateAction<boolean>>
  setNewThemeDialogOpen: Dispatch<SetStateAction<boolean>>
  setRemoveThemeDialogOpen: Dispatch<SetStateAction<boolean>>
  setResetThemeDialogOpen: Dispatch<SetStateAction<boolean>>
}

const WorkspaceAppBar: React.FC<WorkspaceAppBarProps> = ({
  workspace,
  searchValue,
  setSearchValue,
  setDocumentForDialogs,
  setNewFolderDialogOpen,
  setChangeFolderDialogOpen,
  setRemoveFolderDialogOpen,
  setNewDesignDialogOpen,
  setNewThemeDialogOpen,
  setRemoveThemeDialogOpen,
  setResetThemeDialogOpen
}) => {
  const dispatch = useDispatch()
  const { createTheme } = useThemes(workspace.id)
  const { folderView, sortBy, showFilter, selectedTheme } = useTypedSelector(
    state => state.workspaceView
  )
  const [anchorElSortMenu, setAnchorElSortMenu] = useState<null | HTMLElement>(null)
  const openSortMenu = Boolean(anchorElSortMenu)

  const [anchorElThemeMenu, setAnchorElThemeMenu] = useState<null | HTMLElement>(null)
  const openThemeMenu = Boolean(anchorElThemeMenu)

  const handleClickSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorElSortMenu(event.currentTarget)
  }
  const handleCloseSortMenu = () => {
    setAnchorElSortMenu(null)
  }

  const handleClickThemeMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorElThemeMenu(event.currentTarget)
  }

  const handleTitle = (workspace: WorkspaceSchema, folderView?: string) => {
    if (!folderView && !selectedTheme) {
      return (
        <Typography variant="h6" sx={{ color: theme => theme.palette.text.primary }}>
          {workspace.title}
        </Typography>
      )
    }

    return (
      <>
        <Typography
          onClick={() => {
            dispatch(editFolderView({ folder: '' }))
            if (selectedTheme) {
              dispatch(setSelectedTheme({ theme: undefined }))
            }
          }}
          variant="h6"
          component="span"
          sx={{
            color: theme => theme.palette.text.secondary,
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >{`${workspace.title}`}</Typography>
        {folderView && !selectedTheme ? (
          <Typography
            variant="h6"
            component="span"
            sx={{ color: theme => theme.palette.text.primary }}
          >{` / ${folderView}`}</Typography>
        ) : null}
        {selectedTheme && (
          <>
            {selectedTheme.folder && (
              <Typography
                onClick={() => {
                  dispatch(editFolderView({ folder: selectedTheme.folder }))
                  dispatch(setSelectedTheme({ theme: undefined }))
                }}
                variant="h6"
                component="span"
                sx={{
                  color: theme => theme.palette.text.secondary,
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {` / ${selectedTheme.folder}`}
              </Typography>
            )}
            <Typography
              variant="h6"
              component="span"
              sx={{ color: theme => theme.palette.text.primary }}
            >{` / ${selectedTheme.title}`}</Typography>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <NewDocumentButton
        setNewDesignDialogOpen={setNewDesignDialogOpen}
        setNewThemeDialogOpen={setNewThemeDialogOpen}
        style={{
          position: 'absolute',
          right: '66px',
          top: '10px',
          zIndex: 1100,
          visibility: selectedTheme ? 'hidden' : 'visible'
        }}
      />
      <SortMenu open={openSortMenu} anchorEl={anchorElSortMenu} handleClose={handleCloseSortMenu} />
      {selectedTheme && (
        <ThemeMenu
          open={openThemeMenu}
          anchorEl={anchorElThemeMenu}
          position="bottom"
          handleClose={() => handleCloseThemeMenu(setAnchorElThemeMenu)}
          handleThemeDelete={() =>
            handleThemeDelete(
              setDocumentForDialogs,
              setRemoveThemeDialogOpen,
              selectedTheme,
              setAnchorElThemeMenu
            )
          }
          handleThemeDuplicate={() =>
            handleThemeDuplicate(selectedTheme, createTheme, setAnchorElThemeMenu)
          }
          handleThemeChangeFolder={() =>
            handleThemeChangeFolder(
              setDocumentForDialogs,
              setChangeFolderDialogOpen,
              selectedTheme,
              setAnchorElThemeMenu
            )
          }
          handleResetTheme={() =>
            handleResetTheme(
              setDocumentForDialogs,
              setResetThemeDialogOpen,
              selectedTheme,
              setAnchorElThemeMenu
            )
          }
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '32px'
        }}
      >
        <Box>{handleTitle(workspace as WorkspaceSchema, folderView)}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!selectedTheme ? (
            <>
              <SmallSelect
                value={showFilter}
                onChange={e => dispatch(editShowFilter({ value: e.target.value as ShowFilter }))}
                options={['All', 'Projects', 'Themes'].map(v => ({ label: v, value: v }))}
                size="medium"
                disablePadding
                style={{ paddingRight: '16px' }}
              />
              <TextField
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder="Search project..."
                prefixIcon="Search"
                disablePadding
                multiline={false}
                style={{ paddingRight: '8px' }}
              />
              {folderView ? (
                <IconButtonStyled
                  onClick={() => setRemoveFolderDialogOpen(true)}
                  disableFocusRipple
                  disableTouchRipple
                >
                  <RemoveIcon />
                </IconButtonStyled>
              ) : (
                <IconButtonStyled
                  onClick={() => setNewFolderDialogOpen(true)}
                  disableFocusRipple
                  disableTouchRipple
                >
                  <FolderAddIcon />
                </IconButtonStyled>
              )}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginLeft: '8px', marginRight: '16px', alignSelf: 'center', height: '32px' }}
              />
              <Box
                component="button"
                onClick={handleClickSortMenu}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
              >
                <Typography variant="caption" color="text.primary">
                  {sortBy}
                </Typography>
                <ExpandMoreIcon
                  sx={{ marginLeft: '8px', color: theme => theme.palette.text.secondary }}
                />
              </Box>
            </>
          ) : (
            <IconButton onClick={handleClickThemeMenu} sx={{ marginLeft: '16px' }}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider sx={{ marginTop: '16px' }} />
    </>
  )
}

const SortMenu: React.FC<{
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
}> = ({ open, anchorEl, handleClose }) => {
  const dispatch = useDispatch()
  const { sortBy } = useTypedSelector(state => state.workspaceView)

  const handleMenuClick = (value: SortBy) => {
    dispatch(editSortBy({ value }))
    handleClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '12px',
        marginLeft: '12px'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible',
          width: 200,
          color: 'white',
          backgroundColor: theme => theme.palette.secondary.dark,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 25,
            width: 10,
            height: 10,
            bgcolor: theme => theme.palette.secondary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px' }}>
        <MenuItemStyled onClick={() => handleMenuClick('Last modified')}>
          <ListItemIconStyled>
            <CheckIcon style={{ visibility: sortBy === 'Last modified' ? 'visible' : 'hidden' }} />
          </ListItemIconStyled>
          <ListItemText primary="Last modified" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={() => handleMenuClick('Alphabetical')}>
          <ListItemIconStyled>
            <CheckIcon style={{ visibility: sortBy === 'Alphabetical' ? 'visible' : 'hidden' }} />
          </ListItemIconStyled>
          <ListItemText primary="Alphabetical" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
        <MenuItemStyled onClick={() => handleMenuClick('Date created')}>
          <ListItemIconStyled>
            <CheckIcon style={{ visibility: sortBy === 'Date created' ? 'visible' : 'hidden' }} />
          </ListItemIconStyled>
          <ListItemText primary="Date created" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}

export default WorkspaceAppBar
