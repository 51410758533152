import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Container, Typography } from '@mui/material/'
import SpinnerMui from 'Components/Common/SpinnerMui'

const WorkspaceFetch: React.FC<{ workspacesLoading: boolean }> = ({ workspacesLoading }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        overflow: 'auto',
        height: theme => `calc(100% - ${theme.props.navBarHeight})`,
        paddingTop: theme => theme.props.navBarHeight,
        paddingLeft: '24px',
        paddingRight: '24px'
      }}
    >
      <Container maxWidth="lg" disableGutters>
        {workspacesLoading ? (
          <>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{ textAlign: 'center', marginTop: '152px' }}
            >
              Fetching workspaces...
            </Typography>
            <SpinnerMui
              size={80}
              color={theme.palette.text.secondary}
              style={{ marginTop: '64px', textAlign: 'center' }}
            />
          </>
        ) : (
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ textAlign: 'center', marginTop: '152px' }}
          >
            No workspaces yet.
          </Typography>
        )}
      </Container>
    </Box>
  )
}

export default WorkspaceFetch
