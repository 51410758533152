import React from 'react'
import {
  UqAppBar,
  UqAppBarProps,
  UqToolbar,
  UqTypography,
  UqLinearProgressProps
} from '@uniqore/wrapper'
import { UqAppBarPropsCustom } from 'Util/CanvasComponents'
import UqIconButtonRender from './SubComponents/UqIconButtonRender'
import UqLinearProgressRender from './SubComponents/UqLinearProgressRender'
import { ScreenComponent } from 'Features/canvas'

interface UqAppBarRenderProps {
  component: ScreenComponent
}

const UqAppBarRender: React.FC<UqAppBarRenderProps> = ({ component }) => {
  const props = component.props as UqAppBarPropsCustom & UqAppBarProps

  return (
    <>
      <UqAppBar
        position="relative"
        elevation={0}
        color={props.color}
        style={{ ...component.style }}
      >
        <UqToolbar>
          {props.prefix
            ? Object.values(props.prefix).map(prefix => (
                <UqIconButtonRender
                  key={prefix.id}
                  component={prefix}
                  iconColor={props.iconColor}
                  edge="start"
                  sx={{ mr: 1 }}
                />
              ))
            : null}
          <UqTypography
            variant={props.titleVariant || 'h6'}
            marginhorizontal={0}
            sx={{ flexGrow: 1 }}
          >
            {props.title}
          </UqTypography>
          {props.toolbar
            ? Object.values(props.toolbar).map(bar => (
                <UqIconButtonRender
                  key={bar.id}
                  component={bar}
                  iconColor={props.iconColor}
                  edge="end"
                  sx={{ ml: 1 }}
                />
              ))
            : null}
        </UqToolbar>
      </UqAppBar>
      {props.progress &&
        Object.values(props.progress).map((progress, i) => (
          <UqLinearProgressRender
            key={i}
            componentProps={progress.props as UqLinearProgressProps}
            style={{ ...component.style }}
          />
        ))}
    </>
  )
}

export default UqAppBarRender
