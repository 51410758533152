/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import short from 'short-uuid'
import AddElementsMenu, { ActionType } from 'Components/Common/Editing/AddElementsMenu'
import ContentBox from 'Components/Common/Editing/ContentBox'
import PropEditModules from './PropEditModules'
import ActionSection from './Sections/ActionSection'
import { CanvasComponentName, canvasComponents } from 'Util/CanvasComponents'
import { ModuleComponentProps } from 'Util/UqComponentsData'
import { ScreenComponent } from 'Features/canvas'

const showActionSection = (componentName: CanvasComponentName, propName: string): boolean => {
  if (propName === 'carouselItems') {
    return false
  }

  switch (componentName) {
    case 'flowNav':
    case 'radioButtonGroup':
    case 'onboarding':
      return true
    default:
      return false
  }
}

// When creating props, set default values to them based on prop name
const createProps = (propName: string) => {
  switch (propName) {
    case 'radioButtons':
      return {
        text: 'Text',
        value: short.generate(),
        metaText: '',
        startIcon: '',
        color: 'default'
      }
    case 'selectButtons':
      return {
        text: 'Text',
        value: short.generate(),
        metaText: '',
        startIcon: '',
        color: 'default'
      }
    case 'actionComponents':
      return {
        text: 'Text',
        variant: 'contained',
        size: 'large',
        color: 'inherit',
        fullWidth: true,
        disabled: false,
        startIcon: '',
        endIcon: ''
      }
    case 'carouselItems':
      return {
        imageSource: '',
        headline: 'Headline',
        subtitle: 'Subtitle'
      }
    default:
      return {}
  }
}

interface ModuleProps {
  componentProps: unknown
  setComponentProps: React.Dispatch<unknown>
  label: string
  moduleProps: ModuleComponentProps
  propName: string
  componentToEdit: ScreenComponent
  screenId: string
}

const Module: React.FC<ModuleProps> = ({
  componentProps,
  setComponentProps,
  label,
  moduleProps,
  propName,
  componentToEdit,
  screenId
}) => {
  // @ts-ignore
  const values = componentProps[propName]

  if (!values) return <></>

  const { object, maxLimit, primaryTextKey, secondaryTextKey } = moduleProps

  const valueCount = values.length

  const onAdd = () => {
    if (maxLimit && values.length >= maxLimit) return

    const props = canvasComponents[componentToEdit.componentName].props
    // @ts-ignore
    if (props[propName]) {
      // @ts-ignore
      const updatedPropValue = JSON.parse(JSON.stringify(componentProps[propName]))
      updatedPropValue.push(createProps(propName))
      // @ts-ignore
      setComponentProps({ ...componentProps, [propName]: updatedPropValue })
    }
  }

  const clearValues = () => {
    // @ts-ignore
    let updatedPropValue = JSON.parse(JSON.stringify(componentProps[propName]))
    updatedPropValue = []
    // @ts-ignore
    setComponentProps({ ...componentProps, [propName]: updatedPropValue })
  }

  const onDelete = (index: number) => {
    // @ts-ignore
    const updatedPropValue = JSON.parse(JSON.stringify(componentProps[propName]))
    updatedPropValue.splice(index, 1)
    // @ts-ignore
    setComponentProps({ ...componentProps, [propName]: updatedPropValue })
  }

  const useInlineView = maxLimit === 1

  let menuAction: ActionType | undefined
  // If only one child is allowed, the menu action toggles between `+` and `-`
  if (useInlineView && valueCount === 1) {
    menuAction = { type: 'remove', onClick: clearValues }
  } else if (maxLimit && valueCount >= maxLimit) {
    // Button is hidden if limit is reached
    menuAction = undefined
  } else menuAction = { type: 'add', onClick: onAdd }

  return (
    <>
      <AddElementsMenu label={label} action={menuAction} />
      {values.map((propValue: unknown, index: number) => {
        return (
          <ContentBox
            key={index}
            // @ts-ignore
            primaryText={propValue[primaryTextKey] || ''}
            // @ts-ignore
            secondaryText={propValue[secondaryTextKey] || ''}
            collapsible
            onClickRemove={() => onDelete(index)}
          >
            {Object.entries(object).map(([propKey, type], i) => {
              return (
                <PropEditModules
                  key={i}
                  componentProps={componentProps}
                  setComponentProps={setComponentProps}
                  propKey={propKey}
                  type={type}
                  propValue={propValue}
                  index={index}
                  propName={propName}
                />
              )
            })}
            {showActionSection(componentToEdit.componentName, propName) ? (
              <ActionSection
                component={componentToEdit}
                screenId={screenId}
                moduleValues={{
                  propName: propName,
                  propValues: propValue as object,
                  index
                }}
              />
            ) : null}
          </ContentBox>
        )
      })}
    </>
  )
}

export default Module
