import React from 'react'
import ColorPicker from 'Components/Common/ColorPicker'
import { ColorProps } from '../ColorsAdvanced'

interface AdvancedColorProps extends ColorProps {
  type: 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success'
}

const AdvancedColor: React.FC<AdvancedColorProps> = ({ colors, palette, updateColor, type }) => {
  return (
    <div style={{ marginLeft: '16px' }}>
      {/* Main */}
      <ColorPicker
        label="Main"
        colors={colors}
        colorFromState={palette[type].main}
        paletteKey={type}
        colorKey="main"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, type, 'main')}
      />
      {/* Dark */}
      <ColorPicker
        label="Dark"
        colors={colors}
        colorFromState={palette[type].dark}
        paletteKey={type}
        colorKey="dark"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, type, 'dark')}
      />
      {/* Light */}
      <ColorPicker
        label="Light"
        colors={colors}
        colorFromState={palette[type].light}
        paletteKey={type}
        colorKey="light"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, type, 'light')}
      />
      {/* ContrastText */}
      <ColorPicker
        label="ContrastText"
        colors={colors}
        colorFromState={palette[type].contrastText}
        paletteKey={type}
        colorKey="contrastText"
        updateColor={(color, stateUpdate) => updateColor(color, stateUpdate, type, 'contrastText')}
      />
    </div>
  )
}

export default AdvancedColor
