import React from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { Box, InputAdornment, Typography, FormHelperText } from '@mui/material'
import { default as MuiTextField } from '@mui/material/TextField'
import Icons, { IconType } from 'Components/Common/Icons'
import { editHelpers } from 'Features/helpers'

export interface SmallTextFieldProps {
  value?: string | number
  defaultValue?: string | number
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  label?: string
  placeholder?: string
  helperText?: React.ReactNode
  disabled?: boolean
  type?: string
  disablePadding?: boolean
  readOnly?: boolean
  prefixIcon?: IconType
  prefixText?: string
  suffixIcon?: IconType
  suffixText?: string
  style?: React.CSSProperties
}

const SmallTextField: React.FC<SmallTextFieldProps> = ({
  value,
  defaultValue,
  onChange,
  label,
  placeholder,
  helperText,
  disabled,
  type = 'text',
  disablePadding,
  readOnly,
  prefixIcon,
  prefixText,
  suffixIcon,
  suffixText,
  style
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  return (
    <Box style={style} sx={disablePadding ? {} : { padding: '4px 16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          {label && (
            <Typography variant="body2" sx={{ color: theme => theme.palette.text.primary }}>
              {label}
            </Typography>
          )}
          {helperText && <FormHelperText sx={{ marginTop: 0 }}>{helperText}</FormHelperText>}
        </div>
        <MuiTextField
          onFocus={() => dispatch(editHelpers({ key: 'isTextFieldFocused', value: true }))}
          onBlur={() => dispatch(editHelpers({ key: 'isTextFieldFocused', value: false }))}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          variant="filled"
          InputProps={{
            readOnly,
            startAdornment:
              prefixIcon || prefixText ? (
                <InputAdornment position="start" sx={!label ? { marginTop: '0px !important' } : {}}>
                  {prefixIcon
                    ? React.createElement(Icons[prefixIcon], {
                        style: { color: theme.palette.text.secondary }
                      })
                    : prefixText}
                </InputAdornment>
              ) : null,
            endAdornment:
              suffixIcon || suffixText ? (
                <InputAdornment position="end">
                  {suffixIcon
                    ? React.createElement(Icons[suffixIcon], {
                        style: { color: theme.palette.text.secondary }
                      })
                    : suffixText}
                </InputAdornment>
              ) : null
          }}
          sx={{
            marginLeft: '8px',
            '& .MuiFilledInput-root': {
              backgroundColor: 'white !important',
              border: theme => `1px solid ${theme.custom.borderColor}`,
              borderRadius: '4px',
              paddingTop: '0px'
            },
            '& .MuiFilledInput-input': {
              paddingTop: '8px',
              width: type === 'number' ? '48px' : '100%'
            },
            '& > div': {
              '&::before': {
                borderBottom: 'none !important'
              },
              '&::after': {
                borderBottom: 'none !important'
              }
            }
          }}
        />
      </Box>
    </Box>
  )
}

export default SmallTextField
