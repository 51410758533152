import React from 'react'
import { List, ListItem, ListItemText, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export interface ActionType {
  type: 'add' | 'remove' | null
  onClick?: () => void
}

export interface AddElementsMenuProps {
  label: string
  action?: ActionType
  children?: NonNullable<React.ReactNode>
  style?: React.CSSProperties
  backgroundColor?: string
}

const AddElementsMenu: React.FC<AddElementsMenuProps> = ({
  label,
  action,
  children,
  style,
  backgroundColor
}) => {
  return (
    <div style={style}>
      <List disablePadding style={{ backgroundColor: backgroundColor || 'white' }}>
        <ListItem
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              variant: 'subtitle2',
              color: theme => theme.palette.text.primary,
              textTransform: 'uppercase'
            }}
          />
          {action?.type === 'add' ? (
            <IconButton onClick={action?.onClick} sx={{ padding: '0px', cursor: 'pointer' }}>
              <AddIcon
                sx={{
                  color: theme => theme.custom.appleGreen[750]
                }}
              />
            </IconButton>
          ) : action?.type === 'remove' ? (
            <IconButton onClick={action?.onClick} sx={{ padding: '0px', cursor: 'pointer' }}>
              <RemoveIcon
                sx={{
                  color: theme => theme.palette.text.secondary
                }}
              />
            </IconButton>
          ) : null}
        </ListItem>
        {children}
      </List>
    </div>
  )
}

export default AddElementsMenu
