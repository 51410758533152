import React from 'react'
import { UqListItemText, UqTypography } from '@uniqore/wrapper'

const handleSecondaryMultiRow = (secondary: string | undefined): string[] => {
  if (!secondary) return []
  const splitted = secondary.split('\n')
  if (splitted.length === 1 && splitted[0] === '') {
    return []
  }
  return splitted
}

interface UqListItemTextRenderProps {
  primary?: string
  secondary?: string
}

const UqListItemTextRender: React.FC<UqListItemTextRenderProps> = ({ primary, secondary }) => {
  return (
    <UqListItemText
      primary={primary}
      secondary={handleSecondaryMultiRow(secondary).map((text, i) => (
        <UqTypography key={i} variant="body2" component="span" sx={{ display: 'block' }}>
          {text}
        </UqTypography>
      ))}
    />
  )
}

export default UqListItemTextRender
