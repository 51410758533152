import React from 'react'
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent
} from '@mui/material'
import { default as MuiSelect } from '@mui/material/Select'
import ExpandMore from '@mui/icons-material/ExpandMore'

export interface SelectOption {
  value: string | number
  label: string
  disabled?: boolean
}

interface SelectProps {
  value?: string
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void
  options?: SelectOption[]
  label?: string
  helperText?: string
  disabled?: boolean
  disablePadding?: boolean
  readOnly?: boolean
  size?: 'small' | 'medium'
  style?: React.CSSProperties
}

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  options = [],
  label,
  helperText,
  disabled,
  disablePadding,
  readOnly,
  size = 'medium',
  style
}) => {
  return (
    <Box style={style} sx={disablePadding ? {} : { padding: '8px 16px' }}>
      <FormControl
        variant="filled"
        size={size}
        fullWidth
        sx={{
          '& .MuiInputLabel-root': {
            color: disabled
              ? theme => `${theme.palette.text.disabled} !important`
              : theme => `${theme.palette.text.secondary} !important`
          }
        }}
      >
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          IconComponent={ExpandMore}
          displayEmpty
          sx={{
            textAlign: 'left',
            '& .MuiSelect-filled': {
              backgroundColor: 'white !important',
              border: theme => `1px solid ${theme.custom.borderColor}`,
              borderRadius: '4px'
            },
            '& .MuiSelect-iconFilled': {
              color: disabled
                ? theme => `${theme.palette.text.disabled} !important`
                : theme => `${theme.palette.text.secondary} !important`
            },
            '&::before': {
              borderBottom: 'none !important'
            },
            '&::after': {
              borderBottom: 'none !important'
            }
          }}
        >
          {options.map((option, i) => (
            <MenuItem key={i} value={option.value} disabled={option.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default Select
