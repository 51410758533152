import React from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { editSnackbar } from 'Features/snackbar'
import { default as SnackbarMui } from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const Snackbar: React.FC = () => {
  const dispatch = useDispatch()
  const { open, message, type } = useTypedSelector(state => state.snackbar)

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(editSnackbar({ message: '' }))
  }

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={type === 'success' ? 2000 : 6000}
      onClose={handleClose}
      message={message}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={
        type === 'success'
          ? {
              '&.MuiSnackbar-anchorOriginBottomCenter > div:first-of-type': {
                backgroundColor: theme => theme.palette.success.dark
              }
            }
          : {}
      }
    />
  )
}

export default Snackbar
