import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Auth {
  sessionExpired: boolean
  passwordResetDone: boolean
}

const initialState: Auth = {
  sessionExpired: false,
  passwordResetDone: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuth: (state, action: PayloadAction<{ value: boolean; key: keyof Auth }>) => {
      const { value, key } = action.payload
      state[key] = value
    }
  }
})

export const { changeAuth } = authSlice.actions
export default authSlice.reducer
