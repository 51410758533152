import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Collapse } from '@mui/material'
import { SketchPicker, ColorResult, RGBColor } from 'react-color'
import TextField from 'Components/Common/TextField'
import { PaletteSchema, ColorSchema, TextSchema, BackgroundSchema } from 'Util/ThemeValues'

export const ColorContainer = styled('div')(() => ({
  display: 'flex',
  marginBottom: '16px'
}))

export const IndicatorContainer = styled('div')(() => ({
  marginTop: '8px'
}))

export const InputContainer = styled('div')(() => ({
  flexGrow: 1,
  paddingLeft: '16px',
  paddingRight: '16px'
}))

export const RoundColorIndicator = styled('div')(({ theme }) => ({
  alignSelf: 'flex-start',
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  border: `1px solid ${theme.custom.borderColor}`,
  backgroundColor: 'black'
}))

interface Action {
  type: 'modify' | 'reset'
  payload?: {
    value: RGBColor
    key: keyof PaletteSchema
    colorKey?: keyof ColorSchema
    textKey?: keyof TextSchema
    backgroundKey?: keyof BackgroundSchema
  }
  resetPayload?: PaletteSchema
}

// This reducer updates only SketchPicker's and round indicator's value
export const colorReducer = (state: PaletteSchema, action: Action) => {
  const { type, payload } = action
  switch (type) {
    case 'modify':
      if (payload && payload.key === 'background' && payload.backgroundKey) {
        const updatedState: PaletteSchema = {
          ...state,
          background: {
            ...state.background,
            [payload.backgroundKey]: payload.value
          }
        }
        return updatedState
      }
      if (payload && payload.key === 'text' && payload.textKey) {
        const updatedState: PaletteSchema = {
          ...state,
          text: {
            ...state.text,
            [payload.textKey]: payload.value
          }
        }
        return updatedState
      }
      if (payload && payload.key !== 'background' && payload.key !== 'text' && payload.colorKey) {
        const updatedState: PaletteSchema = {
          ...state,
          [payload.key]: {
            ...state[payload.key],
            [payload.colorKey]: payload.value
          }
        }
        return updatedState
      }
      return state
    case 'reset':
      if (action.resetPayload) {
        return action.resetPayload
      }
      return state
    default:
      return state
  }
}

// Hex with alpha value is stored in redux
export const setAlphaToHex = (color: ColorResult): string => {
  const alpha = color.rgb.a || 1
  const alphaCode = Math.floor(255 * alpha).toString(16)
  return `${color.hex}${alphaCode}`
}

// The round indicator's style background color needs to be string
export const convertRgbObjectToString = (rgb: RGBColor | string): string => {
  if (typeof rgb === 'string') {
    return rgb
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
}

export const removeAlphaFromHex = (value: string): string => {
  if (value.length === 9) {
    // has alpha value
    return `${value.substring(0, value.length - 2)}`
  }
  return value
}

interface ColorPickerProps {
  label: string
  /**
   * the whole palette, props paletteKey & colorKey are used to find the wanted color
   */
  colors: PaletteSchema
  /**
   * for example 'primary', 'secondary', 'text' etc... (Mui's color palette)
   */
  paletteKey: string
  /**
   * for example 'main', 'dark', 'light', 'paper' etc... (Mui's color palette)
   */
  colorKey: string
  /**
   * 'onChange' & 'onChangeComplete' -functions are explained below.
   * 'colorFromState' is provided for the TextField-component to display
   * the color value that is stored in the state.
   */
  colorFromState: string
  /**
   * SketchPicker has to functions 'onChange' & 'onChangeComplete'.
   * 'onChange' calls every time the color is changed and
   * 'onChangeComplete' only when the color change is done.
   * We want to update the state only on 'onChangeComplete' call for performance.
   * However RoundColorIndicator and SketchPicker value is called with 'onChange',
   * because it makes the changing of the color smoother.
   */
  updateColor: (color: ColorResult, stateUpdate: boolean) => void
  style?: React.CSSProperties
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  colors,
  paletteKey,
  colorKey,
  colorFromState,
  updateColor,
  style
}) => {
  const [expand, setExpand] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const color = colors[paletteKey][colorKey] || colorFromState

  return (
    <>
      {color && (
        <ColorContainer style={style}>
          <IndicatorContainer>
            <RoundColorIndicator
              onClick={() => setExpand(!expand)}
              style={{ backgroundColor: convertRgbObjectToString(color) }}
            />
          </IndicatorContainer>
          <InputContainer>
            <TextField
              label={label}
              value={removeAlphaFromHex(colorFromState || '')}
              onClick={() => setExpand(!expand)}
              readOnly
              disablePadding
              suffixIcon={expand ? 'ExpandLess' : 'ExpandMore'}
            />
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <SketchPicker
                color={color}
                onChange={color => updateColor(color, false)}
                onChangeComplete={color => updateColor(color, true)}
              />
            </Collapse>
          </InputContainer>
        </ColorContainer>
      )}
    </>
  )
}

export default ColorPicker
