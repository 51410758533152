import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'Store'
import { LayoutProps } from '@uniqore/module'
import { Box } from '@mui/material'
import { ColorResult } from 'react-color'
import { useDesigns } from 'Components/Firebase/Hooks/FirebaseHooks'
import SingleColorPicker from 'Components/Common/SingleColorPicker'
import { setAlphaToHex } from 'Components/Common/ColorPicker'
import SmallTextField from 'Components/Common/Editing/SmallTextField'
import Switch from 'Components/Common/Switch'
import { CanvasScreen, WorkspaceSchema, defaultLayoutPropValues } from 'types/firebase'
import { editScreenLayoutProps } from 'Features/canvasScreens'

interface LayoutStyleMenuProps {
  workspace?: WorkspaceSchema
  screen?: CanvasScreen
}

const LayoutStyleMenu: React.FC<LayoutStyleMenuProps> = ({ workspace, screen }) => {
  const dispatch = useDispatch()
  const selectedDesign = useTypedSelector(state => state.workspaceView.selectedDesign)
  const { updateDesign } = useDesigns(workspace?.id || '')
  const [layoutProps, setLayoutProps] = useState<LayoutProps>(defaultLayoutPropValues)

  useEffect(() => {
    if (screen) {
      const getProps = screen.layoutProps || selectedDesign?.layoutProps || defaultLayoutPropValues
      const props: LayoutProps = JSON.parse(JSON.stringify(getProps))
      // If screen is using local styles, add background color to it
      if (screen.useLocalLayoutProps) {
        const background = screen.layoutProps?.background || '#ffffff'
        props.background = background
      }
      setLayoutProps(props)
      dispatch(editScreenLayoutProps({ screenId: screen.id, layoutProps: props }))
    } else if (selectedDesign) {
      setLayoutProps(selectedDesign.layoutProps || defaultLayoutPropValues)
    }
  }, [selectedDesign, screen?.id])

  // Update to firebase with debounce
  const dispatchToFirebase = (updatedProps: LayoutProps) => {
    if (selectedDesign) {
      // Do not save Layout's background-prop value to Design's 'layoutProps'
      delete updatedProps.background
      updateDesign(selectedDesign, {
        layoutProps: updatedProps
      })
    }
  }
  const debounceFunction = (updatedProps: LayoutProps) => dispatchToFirebase(updatedProps)
  const debounceChange = useMemo(() => {
    return debounce(debounceFunction, 1500)
  }, [])

  const updateColors = (
    colorResult: ColorResult,
    stateUpdate: boolean,
    key: 'background' | 'containerBackground' | 'borderColor'
  ) => {
    const value = setAlphaToHex(colorResult)
    const updatedProps = { ...layoutProps, [key]: value }
    setLayoutProps(updatedProps)
    if (stateUpdate) {
      if (screen) {
        dispatch(editScreenLayoutProps({ screenId: screen.id, layoutProps: updatedProps }))
      } else if (workspace) {
        debounceChange(updatedProps)
      }
    }
  } // updateColors

  const handleChangeBorder = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    key: 'borderWidth' | 'borderRadius'
  ) => {
    const value = `${event.target.value}px`
    const updatedProps = { ...layoutProps, [key]: value }
    setLayoutProps(updatedProps)
    if (screen) {
      dispatch(editScreenLayoutProps({ screenId: screen.id, layoutProps: updatedProps }))
    } else if (workspace) {
      debounceChange(updatedProps)
    }
  } // handleChangeBorder

  const handleElevation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    const updatedProps = { ...layoutProps, useElevation: value }
    setLayoutProps(updatedProps)
    if (screen) {
      dispatch(editScreenLayoutProps({ screenId: screen.id, layoutProps: updatedProps }))
    } else if (workspace) {
      debounceChange(updatedProps)
    }
  } // handleElevation

  return (
    <>
      <Box sx={{ marginLeft: '16px' }}>
        {screen && (
          <SingleColorPicker
            label="Screen background"
            color={layoutProps.background || '#ffffff'}
            updateColor={(color, stateUpdate) => updateColors(color, stateUpdate, 'background')}
          />
        )}
        <SingleColorPicker
          label="Layout background"
          color={layoutProps.containerBackground || defaultLayoutPropValues.containerBackground}
          updateColor={(color, stateUpdate) =>
            updateColors(color, stateUpdate, 'containerBackground')
          }
        />
        <SingleColorPicker
          label="Border color"
          color={layoutProps.borderColor || defaultLayoutPropValues.borderColor}
          updateColor={(color, stateUpdate) => updateColors(color, stateUpdate, 'borderColor')}
        />
      </Box>
      <SmallTextField
        label="Border weight"
        value={(layoutProps.borderWidth || defaultLayoutPropValues.borderWidth).replace('px', '')}
        onChange={e => handleChangeBorder(e, 'borderWidth')}
        type="number"
        suffixText="px"
      />
      <SmallTextField
        label="Border radius"
        value={(layoutProps.borderRadius || defaultLayoutPropValues.borderRadius).replace('px', '')}
        onChange={e => handleChangeBorder(e, 'borderRadius')}
        type="number"
        suffixText="px"
      />
      <Switch label="Elevation" checked={layoutProps.useElevation} onChange={handleElevation} />
    </>
  )
}

export default LayoutStyleMenu
