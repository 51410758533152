import React from 'react'
import { useTypedSelector } from 'Store'
import { UqSwitch, UqSwitchProps, UqFormControlLabel, UqFormGroup } from '@uniqore/wrapper'
import { UqSwitchPropsExtended } from 'Util/CanvasComponents'
import { ScreenComponent } from 'Features/canvas'

interface UqSwitchRenderProps {
  component: ScreenComponent
}

const UqSwitchRender: React.FC<UqSwitchRenderProps> = ({ component }) => {
  const { previewOn } = useTypedSelector(state => state.preview)

  const props = component.props as UqSwitchProps & UqSwitchPropsExtended

  return (
    <UqFormGroup
      style={{ ...component.style }}
      marginVertical={props.marginVertical}
      marginHorizontal={props.marginHorizontal}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
    >
      <UqFormControlLabel
        control={
          <UqSwitch
            checked={!previewOn ? props.checked : undefined}
            defaultChecked={previewOn ? props.checked : undefined}
            size={props.size}
            color={props.color}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    </UqFormGroup>
  )
}

export default UqSwitchRender
