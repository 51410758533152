import React from 'react'
import { styled } from '@mui/material/styles'
import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  height: '20px',
  '&:hover': {
    backgroundColor: theme.custom.desertNight
  }
}))

interface UserMenuProps {
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: () => void
  logoutOnClick: () => void
}

const UserMenu: React.FC<UserMenuProps> = ({ open, anchorEl, handleClose, logoutOnClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '20px',
        marginLeft: '8px'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible',
          width: 200,
          color: 'white',
          backgroundColor: theme => theme.palette.secondary.dark,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 19,
            width: 10,
            height: 10,
            bgcolor: theme => theme.palette.secondary.dark,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      <MenuList disablePadding sx={{ padding: '0px' }}>
        <MenuItemStyled onClick={logoutOnClick}>
          <ListItemIcon sx={{ minWidth: '24px' }}>
            <LogoutIcon sx={{ fontSize: '12px', color: theme => theme.palette.text.disabled }} />
          </ListItemIcon>
          <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'caption' }} />
        </MenuItemStyled>
      </MenuList>
    </Menu>
  )
}

export default UserMenu
